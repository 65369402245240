import { Component, OnInit, Inject, Type } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import * as $ from 'jquery';

import { MapGeneralService } from '../../services/map.general.service'
import { SitesService } from '../../services/sites.service'

import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatBottomSheet } from '@angular/material';
import { timeout } from 'q';
import { dictionnaireInterface, reponseDB } from 'src/app/type';
import { GeoJSON,Feature } from 'src/app/ol-module';
import {ListSitesComponent} from 'src/app/bottom-sheet/list-sites/list-sites.component'

export interface dictionnareInterface {

}
export interface donne_pour_ajout_entiteInterface {
  /**
   * dictionnaire de données
   */
  dictionnaire: {
    infosGeneral: Array<dictionnaireInterface>
  }
  /**
   * titre du modal
   */
  titre: string;
  /**
   * url pour sauvegarder le site dans la BD
   */
  url: string;
  // result: any;
  /**
   * les différentes restrictions, surtout pour les doublons
   */
  attention: {
    /**
     * Pour detecter les entités proches de ceux qu'on veut ajouter
     */
    localisation: {
      active: boolean,
      urlToDetect: string,
      /**
      * champ a recuperer du geojon issu du wfs
      */
      fieldGeosjon: string

    },
    /**
     * Pour detecter les entites qui ont une propriete proche de celui qu'on souhaite ajouter
     */
    doublonsName: Array<{
      active: boolean,
      /**
       * nom à afficher pour spécifier à l'utilisateur
       * @example si il vaut 'les numéro de chantiers' alors on ecrira : " 2 doublons sur les numéro de chantiers"
       */
      nom: string
      /** champ dans le dictionnaire ou formulaire */
      field: string
      /** champ a utiliser pour former l'objet qui servira à faire la requète post
       * @example {fieldToSearch:'valeur à rechercher'}
       */
      fieldToSearch: string
      /**
       * champ à utiliser pour recuperer l'information qui nous interesse dans la reponse de la BD avec l'urlToDetect
       */
      fieldDB:string
      urlToDetect: string
      /**
       * Peut on sauvegarder avec ce doublon ?
       */
      passive:boolean
    }>
  }
  // callback: string
}


export interface nouveauInterface {
  infosGeneral: any
  localisation: {
    mode_modification: string,
    geojson: {
      type: string;
      geometry: {
          type: string;
          coordinates: any[];
      };
      properties: {};
  }
  },
  /**
   * Liste des features representant les emprises déssinés par l'utilisateur
   */
  perimetre: Feature[]
  /**
   * Pour décrire comment on devra detecter des doublons existants en BD etc...
   */
  attention: {
    /**
     * liste des sites_id des sites proches de celui que l'utilisateur veut ajouter
     */
    localisation: number[]
    /**
     * differents doublons detecter dans les champs
     */
    doublonsTodetect: Array<{
      /** id des doublons detecter */
      doublonsId: number[],
      /** champ correspondant dans le dictionnaire donne_pour_ajout_entiteInterface.dictionnaire.infosGeneral */
      field: string
    }>
    /**
     * Recuperer l'objet {doublonsId: number[],field: string} doublon d'un champ spécifique 
     * @param field string le champ dont on désire avoir
     * @return {doublonsId: number[],field: string}
     */
    getDoublonsToDetect(field: string): {
      /** id des doublos detecter */
      doublonsId: number[],
      /** champ correspondant */
      field: string
    }
  }
}

@Component({
  selector: 'app-ajout-site',
  templateUrl: './ajout-site.component.html',
  styleUrls: ['./ajout-site.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false, showError: true }
  }]
})
export class AjoutSiteComponent implements OnInit {

  public nouveau: BehaviorSubject<nouveauInterface> = new BehaviorSubject({} as nouveauInterface);

  formulaire: FormGroup
  objectKeys = Object.keys;
  disabled = true

  loading = {
    doublons: false,
    initialData: false,
    saving:false
  }
  loading_entreprise = false

  isLoading = false;
  public titre: BehaviorSubject<{
    titre: string,
    type: string
  }>
    = new BehaviorSubject({} as {
      titre: string,
      type: string
    });

  constructor(
    private fb: FormBuilder,
    private MapGeneralService: MapGeneralService,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public dialogRef: MatDialogRef<AjoutSiteComponent>,
    private router: Router,
    public SitesService:SitesService,
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_ajout_entiteInterface
  ) {
    this.formulaire = this.fb.group({})
  }

  /**
   * Naviguer vers la page principale
   */
  navigate_to_principal() {
    this.titre.next({
      'titre': this.data.titre,
      'type': 'principal'
    })
    this.router.navigate([{ outlets: { ajout_entite: 'principal' } }]);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.navigate_to_principal()
  }

  /**
   * Definir les valeurs de la nouvelle entité
   * @param nouveau 
   */
  setNouveau(nouveau: nouveauInterface) {
    // nouveau.attention.getDoublonsToDetect = function(field:string){
    //   for (let index = 0; index < this.doublonsTodetect.length; index++) {
    //     if ( this.doublonsTodetect[index].field == field) {
    //       return this.doublonsTodetect[index]
    //     }
    //   }
    // }
    this.nouveau.next(nouveau)

  }

  /**
   * Recuperer les valeurs de la nouvelle entite deja saisie
   * @return nouveauInterface
   */
  getNouveauValue(): nouveauInterface {

    return this.nouveau.getValue()
  }


  ngOnInit() {
    var nouveau: nouveauInterface = {
      infosGeneral: undefined,
      localisation: {
        mode_modification: undefined,
        geojson: undefined,

      },
      perimetre: undefined,
      attention: {
        localisation: [],
        doublonsTodetect: [],
        getDoublonsToDetect: function (field: string) {
          for (let index = 0; index < this.doublonsTodetect.length; index++) {
            if (this.doublonsTodetect[index].field == field) {
              return this.doublonsTodetect[index]
            }
          }
        }
      }
    }
    for (let index = 0; index < this.data.attention.doublonsName.length; index++) {
      const element = this.data.attention.doublonsName[index];
      nouveau.attention.doublonsTodetect.push({
        field: element.field,
        doublonsId: []
      })
    }
    this.setNouveau(nouveau)
    this.navigate_to_principal()

    this.nouveau.subscribe(()=>{
     this.disabled = !this.isFormValid()
    })
  }

  /**
   * Est ce qu'on peut valider le formulaire ?
   * @return boolean
   */
  isFormValid():boolean{
    var reponse = false
    var nouveau = this.getNouveauValue()
    if (nouveau.infosGeneral && nouveau.infosGeneral['num_chant_sites']) {
      if (nouveau.perimetre && nouveau.perimetre.length > 0 && nouveau.localisation ) {
        for (let index = 0; index < this.data.attention.doublonsName.length; index++) {
          const element = this.data.attention.doublonsName[index];
          if (!element.passive && nouveau.attention.getDoublonsToDetect(element.field).doublonsId.length == 0) {
            reponse = true
          }
        }
      }
    }
    return reponse
  }


  /**
   * Definir le titre du modal d'ajout de fenetre
   * @param titre string
   * @param type string la fenetre du modal que nous voulons afficher
   */
  setTitre(titre, type) {
    this.titre.next({
      'titre': titre,
      'type': type
    })
  }

  /**
   * recuperer tous les doublons d'un type dans la nouvelle entite
   * @param type string des type definit dans l'interface donne_pour_ajout_entiteInterface.attention
   * @return number[]
   */
  getDoublons(type: string): number[] {
    return this.nouveau.getValue().attention['type']
  }


  /**
   * Afficher tous les sites dont il faut faire attention dans la localisation:
   * Tous les sites proches du sites proche de celui en ajout
   */
  displayDoublonsLocalisation() {
    var sites_id = this.nouveau.getValue().attention.localisation
    this._bottomSheet.open(ListSitesComponent,{
      data:{sites_id:sites_id}
    });
  }

  /**
   * Afficher tous les sites dont il faut faire attention dans les doublons par attributs
   * @param sites_id Array<number>
   */
  displayDoublonsByAttributes(sites_id:Array<number>) {
    this._bottomSheet.open(ListSitesComponent,{
      data:{sites_id:sites_id}
    });
  }


  disable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: true });
      }
    }
  }

  enable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: false });
      }
    }
  }

  /**
   * Recuperer les valeurs de infos general formaté
   */
  getFormatedResponseFromInfosGeneral(): Object {
    var infos_general = {}
    for (const key in this.getNouveauValue().infosGeneral) {
      // if (this.getNouveauValue().infosGeneral.hasOwnProperty.call(this.getNouveauValue().infosGeneral, key)) {
      const element = this.getNouveauValue().infosGeneral[key];
      var dictionnaire = this.getDictionnaire(key)
      if (dictionnaire) {
        if (["text", "email", "tel", "textarea"].indexOf(dictionnaire.sous_type) != -1) {
          infos_general[key] = element
        } else if (dictionnaire.sous_type == 'list' && element) {
          infos_general[key] = element[dictionnaire.fieldDB]
        }
      }
      // }
    }
    return infos_general
  }

  valider() {
    var response = {
      site: {},
      emprise: [],
      localisation:this.getNouveauValue().localisation.geojson.geometry.coordinates
    }
    response.site = this.getFormatedResponseFromInfosGeneral()

    response.site['commune'] = this.getNouveauValue().localisation.geojson['properties']['commune']
    response.site['adresse']= this.getNouveauValue().localisation.geojson['properties']['adresse']
    response.site['postal']= this.getNouveauValue().localisation.geojson['properties']['cp']

    for (let index = 0; index < this.getNouveauValue().perimetre.length; index++) {
      const feature = this.getNouveauValue().perimetre[index];
      response.emprise.push(new GeoJSON().writeGeometryObject(feature.getGeometry(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }))
    }

   
    this.loading.saving = true
    this.BackendApiService.post_requete(response, '/api/sites/add').then(
      (response: reponseDB) => {

        this._snackBar.open(response.message, 'Fermer', {
          duration: 4000,
        });

        if (!response.erreur) {
          this.SitesService.loadDataFromServer([response.data]).then(
            ()=>{
              this.loading.saving = false
              this.disabled = false
              this.dialogRef.close(response.data)
            },
            ()=>{
              this.loading.saving = false
              this.disabled = false
            }
          )
        }else{
          this.loading.saving = false
          this.disabled = false
        }

      },
      (err) => {
        this.loading.saving = false
        this.disabled = false
        this._snackBar.open(err.message, 'Fermer', {
          duration: 4000,
        });
      }
    )

  }

/**
* recuperer un dictionnaire, à partir de sa pte 'champ'
*/
getDictionnaire(champ: string): dictionnaireInterface {
  for (let index = 0; index < this.data.dictionnaire.infosGeneral.length; index++) {
    const element = this.data.dictionnaire.infosGeneral[index];
    if (element.champ == champ) {
      return element
    }
  }
}

}
