import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service'

export interface donne_pour_sup {
  titre: string;
  url: string;
  decision: boolean;
  champ_id: any[];
  callback: string;
  response?:any
}

@Component({
  selector: 'app-supprimer',
  templateUrl: './supprimer.component.html',
  styleUrls: ['./supprimer.component.scss']
})
export class SupprimerComponent implements OnInit {

  formulaire: FormGroup
  loading = false
  disabled = false
  
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public dialogRef: MatDialogRef<SupprimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_sup
  ) {
    // this.formulaire = fb.group({})
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.formulaire = this.fb.group({
      decision: false
    })
  }

  valider(decision:boolean) {
    this.data.decision = decision
    if (decision == true) {

      this.loading = true
      this.disabled = true

      var donne = {}
      donne[this.data.champ_id[0]] = this.data.champ_id[1]

      var url = this.data.url
      this.BackendApiService.post_requete(donne, url).then((response) => {
        this.loading = false
        this.disabled = false
        this._snackBar.open('Opération réussie', 'Fermer', {
          duration: 4000,
        });
        this.data.response = response
        this.dialogRef.close(this.data)

      },
        (err) => {
          this.loading = false
          this.disabled = false
          this._snackBar.open(err.message, 'Fermer', {
            duration: 4000,
          });
        }
      )
    } else {
      this.dialogRef.close(this.data)
    }

  }

}
