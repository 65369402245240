import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, from } from 'rxjs';
import { MapGeneralService } from './map.general.service'
import { GeneralService } from './general.service'
import { BackendApiService } from './backend-api.service'
import { MatSnackBar } from '@angular/material';
import { StockageService } from './stockage.service'
import * as $ from 'jquery';
import Point from 'ol/geom/Point';

import {
  layerForMap, popupForMap, dataClickOnMap, elemCartoInterface, siteInterface, perimetreInterface
}
  from '../type/index'
import {
  Icon,
  Style, Feature, Overlay, VectorLayer, TileLayer, ImageLayer, Transform, VectorSource, GeoJSON, Stroke, CircleStyle, Fill, Extent, transformExtent, Cluster
}
  from '../ol-module'


import { environment } from 'src/environments/environment';
import { catchError, debounceTime, take, tap } from 'rxjs/operators';
import BaseLayer from 'ol/layer/Base';
import IconOrigin from 'ol/style/IconOrigin';
import OverlayPositioning from 'ol/OverlayPositioning';
import { MapBrowserEvent } from 'ol';
import { FeatureWMSInfo } from '../map/map.component.parent';
import BaseVectorLayer from 'ol/layer/BaseVector';

@Injectable({
  providedIn: 'root'
})
export class SitesCartoService extends MapGeneralService {

  config_projet: any
  /**
    * objet contenant les informations pour un popup et le type de popup 
    */
  feature_mousemove: popupForMap = {} as popupForMap
  featureMouseMoveObserver = new BehaviorSubject({} as popupForMap)
  /**
    * les différents styles pour l'appli 
    */
  style_icon_map: Array<any>


  /**
  * Liste de tous les num chants des perimetres deja load 
  */
  public perimetres_loaded = [];

  legends: Array<{
    title: string
    img: string
  }>

  constructor(
    public BackendApiService: BackendApiService,
    public GeneralService: GeneralService,
    public _snackBar: MatSnackBar,
    public StockageService: StockageService
  ) {
    super(BackendApiService, _snackBar);
    this.featureMouseMoveObserver.subscribe(data => {
      this.feature_mousemove = data
    })
    this.config_projet = this.GeneralService.get_config_projet()

  }


  /**
    * initialiser le layer de la couche cluster du site 
    * @param function cb
    * @return la couhe a ajoutée à la carte
    */
  initialiser_layers_entreprise(cb:(layer:layerForMap)=>void) {

    var couche_sites: layerForMap = {
      'nom': 'sites',
      'type_layer': 'sites',
      'type': 'geojson',
      'crs': 'EPSG:4326',
      'active': true,
      'visible': true,
      'strategy': "bbox",
      'load': false,
      'maxzoom': this.config_projet.levels.sites.maxzoom,
      'minzoom': this.config_projet.levels.sites.minzoom,
      "zindex": this.config_projet.levels.sites.zindex,
      'cluster': true,
      'icon': 'assets/images/logo/rounded_logo_espaces_verts.png',
    }

    // var styles_emprise = [
    //   new Style({
    //     stroke: new Stroke({
    //       color: 'red',
    //       width: 2
    //     })
    //   })
    // ];

    var styles_emprise = (feature: Feature) => {
      var fill: Fill;
      var stroke: Stroke;

      if (feature.get('hover')) {
        fill = new Fill({
          color: 'rgba(0, 0, 0, 0.12)'
        })
      }

      if (feature.get('active')) {
        stroke = new Stroke({
          color: environment.primaryColor,
          width: 3
        })
      } else {
        stroke = new Stroke({
          color: 'red',
          width: 2
        })
      }

      if (fill) {
        return new Style({
          stroke: stroke,
          fill: fill
        })
      } else {
        return new Style({
          stroke: stroke
        })
      }


    }


    var cnv_prospect = document.createElement('canvas');
    var ctx = cnv_prospect.getContext('2d');
    var img_prospect = new Image();
    img_prospect.src = couche_sites.icon

    img_prospect.onload = () => {

      var styleDefaultII = new Style({
        image: new Icon(({
          scale: 0.7,
          // size:[43,35],
          // offsetOrigin:'top-right',
          offset: [0, -50],
          img: img_prospect,
          // imgSize: [img_prospect.width + 200, img_prospect.height + 380],
          imgSize: [img_prospect.width + 40, img_prospect.height + 60],
          // src: 'assets/img/pospect.png'
        }))
      })

      couche_sites.style = styleDefaultII
      cb(couche_sites)
    }

    var couche_emprises: layerForMap = {
      'nom': 'emprise',
      'type_layer': 'emprise',
      'type': 'geojson',
      'crs': 'EPSG:4326',
      'active': true,
      'visible': true,
      'strategy': "bbox",
      'load': false,
      'style': styles_emprise,
      'maxzoom': this.config_projet.levels.perimetres.maxzoom,
      'minzoom': this.config_projet.levels.perimetres.minzoom,
      "zindex": this.config_projet.levels.perimetres.zindex,
    }

    /**
     * utiliser seulement pour l'edition géographique
     */
    var couche_emprisesWFS: layerForMap = {
      'nom': 'emprise_wfs',
      'type_layer': 'emprise_wfs',
      'type': 'wfs',
      'active': true,
      'visible': false,
      'load': false,
      'maxzoom': 0,
      'minzoom': 30,
      "zindex": this.config_projet.levels.perimetres.zindex,
      "url": '/api/wfs',
      "identifiant": 'perimetres'
    }

    this.gestion_carto(couche_emprises)
    this.gestion_carto(couche_emprisesWFS)

  }

  /**
* Comparer les nouveaux identifiants du serveur carto et ceux déja chargé dans l'application
* @param Array<number> old_ids
* @param Array<number> new_ids
* @param Array<any> features_json
* @return Array<number> les nouveaux sites_id à intégrer dans l'application 
*/

  compareIdsFromCartoServeur(old_ids: Array<number>, new_ids: Array<number>, features_json: Array<any>): Array<number> {
    var ids = []
    for (let index = 0; index < features_json.length; index++) {
      if (old_ids.indexOf(new_ids[index]) == -1) {
        ids.push(features_json[index]['properties']['sites_id'])
      }
    }

    return ids
  }

  /**
   * Recuperer tous les composants des sites en un VectorLayer de source WMS.
   * NB: Cette couche n'est ajouté à aucune map
   * @return [VectorLayer]
   */
  getWMSComposantLayer(): [ImageLayer]{
    var layersImages = this.GeneralService.get_config_projet().layers
    var identifiants_wms = [];
    for (const key in layersImages) {
      if (layersImages.hasOwnProperty(key)) {
        const element_cartos = layersImages[key];
        var identifiants = []
        var noms = []
        for (let index = 0; index < element_cartos.length; index++) {
          const one_element_carto = element_cartos[index];
          identifiants.push(one_element_carto.alias + '_' + key)
          noms.push(one_element_carto.alias)
        }

        identifiants_wms.push(identifiants.toString())
      }
    }

    var coucheImages: layerForMap = {
      'nom': 'composants_wms',
      'type_layer': 'composants',
      'type': 'wms',
      'active': true,
      'visible': true,
      "zindex": 100,
      "url": environment.projet_qgis_wms,
      "identifiant": identifiants_wms.join(',')
    }

    let layer = this.formatLayer(coucheImages)
    if (layer instanceof ImageLayer)
      return [layer]
  }
  
   /**
   * Recuperer tous les composants des sites en un VectorLayer de source WFS.
   * NB: Cette couche n'est ajouté à aucune map
   * @return [VectorLayer]
   */
  getWfsAllComposantLayer(): [VectorLayer] {
   
    var layersImages = this.GeneralService.get_config_projet().layers
    var identifiants_wfs = [];
    for (const key in layersImages) {
      if (layersImages.hasOwnProperty(key)) {
        const element_cartos = layersImages[key];
        var identifiants = []
        // var noms = []
        for (let index = 0; index < element_cartos.length; index++) {
          const one_element_carto = element_cartos[index];
          identifiants.push(one_element_carto.alias + '_' + key)
          // noms.push(one_element_carto.alias)
        }

        identifiants_wfs.push(identifiants.toString())
      }
    }

    var coucheVecteurs: layerForMap = {
      'nom': 'composants_wfs',
      'type_layer': 'composants',
      'type': 'wfs',
      'active': true,
      'visible': false,
      'load': false,
      'maxzoom': 0,
      'minzoom': 30,
      "zindex": this.config_projet.levels.elements_cartos.zindex,
      "url": '/api/wfs',
      "identifiant": identifiants_wfs.join(',')
    }
    let layer = this.formatLayer(coucheVecteurs)
    if (layer instanceof VectorLayer)
      return [layer]
    
  }


  getWfsComposantLayer(alias:string, typologie: 'polygon'|'point'|'linestring'): [VectorLayer] {
    const one_element_carto =this.GeneralService.get_config_projet().layers[typologie].find((item)=>item.alias == alias);
    var identifiants = one_element_carto.alias+ '_' + typologie
    var noms = one_element_carto.alias

    var coucheVecteurs: layerForMap = {
      'nom': one_element_carto.valeur,
      'type_layer': 'composants',
      'type': 'wfs',
      'active': true,
      'visible': true,
      'load': false,
      'maxzoom': 0,
      'minzoom': 30,
      "zindex": this.config_projet.levels.elements_cartos.zindex,
      "url": '/api/wfs',
      "identifiant": identifiants
    }
    let layer = this.formatLayer(coucheVecteurs)
    if (layer instanceof VectorLayer)
      return [layer]
  }

  getWfsComposantFromTypologieWithoutLayer(odAlias:string, typologie: 'polygon'|'point'|'linestring'): [VectorLayer] {
    let identifiants:string[] = []
    this.GeneralService.get_config_projet().layers[typologie].filter((item)=>item.alias != odAlias).map((one_element_carto)=>{
      identifiants.push(one_element_carto.alias+ '_' + typologie)
    })
    

    var coucheVecteurs: layerForMap = {
      'nom': "",
      'type_layer': 'composants',
      'type': 'wfs',
      'active': true,
      'visible': true,
      'load': false,
      'maxzoom': 0,
      'minzoom': 30,
      "zindex": this.config_projet.levels.elements_cartos.zindex,
      "url": '/api/wfs',
      "identifiant": identifiants.join(',')
    }
    
    let layer = this.formatLayer(coucheVecteurs)
    if (layer instanceof VectorLayer)
      return [layer]
  }

  /**
   * Recuperer les perimetres des sites en un VectorLayer de source WFS.
   * NB: Cette couche n'est ajouté à aucune map
   * @return [VectorLayer]
   */
  getWfsPerimetre(strategy:"all" | "bbox"='all'): [VectorLayer]{
  
    var couche_emprisesWFS: layerForMap = {
      'nom': 'emprise_wfs',
      'type_layer': 'emprise_wfs',
      'type': 'wfs',
      'active': true,
      'visible': false,
      'load': false,
      'maxzoom': 0,
      'minzoom': 30,
      "zindex": this.config_projet.levels.perimetres.zindex,
      "url": '/api/wfs',
      "identifiant": 'perimetres',
      "strategy":strategy
    }

    let layer = this.formatLayer(couche_emprisesWFS)
    if (layer instanceof VectorLayer)
      return [layer]
  }

  /**
    * initialiser les couches WMS / WMTS des sites 
    */

  initialiserLayersImages() {
    var layersImages = this.GeneralService.get_config_projet().layers
    var identifiants_wfs = [];
    for (const key in layersImages) {
      if (layersImages.hasOwnProperty(key)) {
        const element_cartos = layersImages[key];
        var identifiants = []
        var noms = []
        for (let index = 0; index < element_cartos.length; index++) {
          const one_element_carto = element_cartos[index];
          identifiants.push(one_element_carto.alias + '_' + key)
          noms.push(one_element_carto.alias)
        }
        var coucheImages: layerForMap = {
          'nom': 'composants_' + key,
          'type_layer': 'composants',
          'type': 'wms',
          'active': true,
          'visible': true,
          'load': false,
          'maxzoom': this.config_projet.levels.elements_cartos.maxzoom,
          'minzoom': this.config_projet.levels.elements_cartos.minzoom,
          "zindex": this.config_projet.levels.elements_cartos.zindex,
          "url": environment.projet_qgis_wms,
          "identifiant": identifiants.toString()
        }

        identifiants_wfs.push(identifiants.toString())

        this.gestion_carto(coucheImages)


      }
    }

    var coucheVecteurs: layerForMap = {
      'nom': 'composants_wfs',
      'type_layer': 'composants',
      'type': 'wfs',
      'active': true,
      'visible': false,
      'load': false,
      'maxzoom': 0,
      'minzoom': 30,
      "zindex": this.config_projet.levels.elements_cartos.zindex,
      "url": '/api/wfs',
      "identifiant": identifiants_wfs.join(',')
    }

    this.gestion_carto(coucheVecteurs)

  }

  /**
   * initialise legende des wms
   */
  initaliseLegende() {
    var layersImages = this.GeneralService.get_config_projet().layers
    this.legends = []
    for (const key in layersImages) {
      if (layersImages.hasOwnProperty(key)) {
        const element_cartos = layersImages[key];
        for (let index = 0; index < element_cartos.length; index++) {
          const one_element_carto: elemCartoInterface = element_cartos[index];
          this.legends.push({
            title: one_element_carto.valeur,
            img: environment.apiUrl + '/assets/elem_geo/' + one_element_carto.icon
          })
        }
      }
    }
  }

  /**
    * initialiser les icones pour la carte
    */
  initialise_icon_sous_type() {

    this.style_icon_map = []
    this.GeneralService.config_projet.subscribe((data) => {

      for (const key in data.icon) {
        if (data.icon.hasOwnProperty(key)) {
          const element = data.icon[key];
          var icon_ = new Icon(({
            scale: element.scale,
            src: element.logo
          }))
          var iconStyle_ = new Style({
            image: icon_
          });

          var nom_style = 'style' + element.nom_couche + key
          var obj = {}
          obj[nom_style] = iconStyle_
          this.style_icon_map.push(obj)

          ///////////////////pour l'active icon ///////////
          var icon_active = new Icon(({
            scale: element.scaleActive,
            size: [80, 125],
            offsetOrigin: IconOrigin.TOP_RIGHT ,
            offset: [0, 0],
            src: element.logo
          }))

          var iconStyle_active = new Style({
            image: icon_active
          });
          var nom_style = 'style' + element.nom_couche + key + 'active_icon'
          var obj = {}
          obj[nom_style] = iconStyle_active
          this.style_icon_map.push(obj)

        }
      }
    })

  }

  /**
    * Récuperer le style d'un icone pour la care
    * @param string couche nom de la couche
    * @param string type type de couche
    */
  get_style_sous_type(couche, type) {
    for (let index = 0; index < this.style_icon_map.length; index++) {
      const element = this.style_icon_map[index];
      var nom_style = 'style' + couche + type
      if (element[nom_style]) {
        return element[nom_style]
      }
    }
  }

  /**
     * Formater les sites qui iront dans la carte 
     * @param any site site à formater en geojson
     */
  format_geojson(site: any) {
    var geojson = JSON.parse(site['geojson'])
    var properties = geojson['properties']
    properties['type'] = 'default'

    properties.active = false
    properties.display = site['display']

    site['parse_geojson'] = true
    return geojson
  }

  /**
   * chercher le feature à afficher dans un cluster 
   * @param Array<Feature> features 
   */
  avoir_feature_display(features: Array<Feature>) {
    var feature
    for (let index = 0; index < features.length; index++) {
      const feat = features[index];
      if (feat.get('display') == true) {
        feature = feat
      }
    }
    return feature
  }


  is_clusters(features) {
    var size = 0;
    for (let index = 0; index < features.length; index++) {
      const feat = features[index];
      if (feat.get('display') == true) {
        size = size + 1
      }
    }

    return size
  }

  /**
    * Lorsque la souris bouge sur la carte : pour soir mettre un curseur ou afficher un popup
    * @param Event evt evenemnt 
    */

  mouseHasMoveOnMap(evt) {
    var target = this.map.getTarget();
    var jTarget = typeof target === "string" ? $("#" + target) : $(target);

    var pixel = this.map.getEventPixel(evt.originalEvent);

    var hit = this.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
      if (layer) {
        return true;
      }
    });

    if (hit) {
      jTarget.css("cursor", "pointer");
    } else {
      jTarget.css("cursor", "");

      if (this.feature_mousemove.active) {
        for (const key in this.feature_mousemove.data) {
          if (this.feature_mousemove.data.hasOwnProperty(key)) {
            var element = this.feature_mousemove.data[key];
            element = undefined
          }
        }

        this.feature_mousemove.active = false

        $('#popup').hide()

        for (let index = 0; index < this.StockageService.sites.getValue().length; index++) {
          var element = this.StockageService.sites.getValue()[index];
          element['hover'] = false
        }
      }

    }

    var feature = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      return feature;
    });

    var layer = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      return layer;
    });

    ////layer de type sites 
    if (layer && feature && layer.get('type_layer') == 'sites') {
      var size = this.is_clusters(feature.getProperties().features)
      /// si c'est un cluster ou pas
      if (size == 1) {
        var coord = this.map.getCoordinateFromPixel(pixel)
        this.displayPopupSites(this.avoir_feature_display(feature.getProperties().features), coord)
      } else {
        for (const key in this.feature_mousemove.data) {
          if (this.feature_mousemove.data.hasOwnProperty(key)) {
            var element = this.feature_mousemove.data[key];
            element = undefined
          }
        }

        this.feature_mousemove.active = false

        $('#popup').hide()
      }

    }

    this.featureMouseMoveObserver.next(this.feature_mousemove)

  }

  /**
    * Afficher le popup d'un site
    * @param Feature feature  
    * @param string id champ principal, par defaut vaut environment.id_bd_qgis_server
    */

  displayPopupSites(feature: Feature, coord: Array<number>, id: string = environment.id_bd_qgis_server) {
    if (this.feature_mousemove.overlay == undefined) {
      this.initialiser_popup()
    }
    var site = this.StockageService.getSIte(feature.get(id))
    if (site) {

      this.feature_mousemove.data['site'] = site
      site['hover'] = true

      // this.avoir_filiale_par_id_filiale(id_filiale).hover = true
      $('#popup').show()
      this.feature_mousemove.active = true
      // console.log(Object.create(feature.getGeometry()).getType())
      this.feature_mousemove.overlay.setPosition(coord);

      // if (Object.create(feature.getGeometry()).getType() == 'Point') {
      //   var coordinate = Object.create(feature.getGeometry()).getCoordinates();
      //   this.feature_mousemove.overlay.setPosition(coord);
      // }else if (Object.create(feature.getGeometry()).getType() == 'Polygon') {

      // }
    }

    this.featureMouseMoveObserver.next(this.feature_mousemove)
  }

  /**
    *Initialiser l'overlay de tous types de popup sur la carte
    */
  initialiser_popup() {

    var popup = new Overlay({
      positioning: OverlayPositioning.TOP_LEFT,
      offset: [15, -5],
      element: document.getElementById('popup'),
      autoPan: false,
      stopEvent: false
    });

    this.feature_mousemove = {
      'overlay': popup,
      'data': {
        'site': undefined
      },
      "active": false
    }

    this.map.addOverlay(this.feature_mousemove.overlay);
  }


  /**
  * Recuperer les features des couches qui nous intérressent qui sont dans une étendue definit
  * @param Extent extent
  * @return Array<Feature> les features present dans l'étendue passé en paramètres
  */

  featuresInExtent(extent: any): Array<Feature> {
    var layer_sites = this.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)
    var features = layer_sites[0].getSource().getSource().getFeaturesInExtent(extent)
    return features
  }

  /**
   * Recuperer les num_chant_sites d'une listes de feature d'OL
   * @param Array<Feature> data les features
   * @param string numChantier par defaut vaut environment.id_bd_qgis_server, le nom de la propriété
   * @return Arrar<number> liste des num_chantier
   */

  getListNumChantier(data: Array<Feature>, numChantier: string = environment.id_bd_qgis_server): Array<Number> {
    var list_num_chant = []
    for (let index = 0; index < data.length; index++) {
      const feat = data[index];
      list_num_chant.push(feat.get(numChantier))
    }
    return list_num_chant
  }

  /**
 * Recuperer les périmètres des sites visible et qui sont pas encore dans l'app, pui les get et les charger dans l'app
 */

  getPerimetresOfVisibleSites() {
    var extent_vieuw = this.get_extent_map()
    var featuresInView = this.featuresInExtent(extent_vieuw)
    var numChantInView = this.getListNumChantier(featuresInView)
    

    var numChantInView_butNotInPerimetre = numChantInView.filter(x => !this.perimetres_loaded.includes(x));

    this.perimetres_loaded = this.perimetres_loaded.concat(numChantInView_butNotInPerimetre)

    if (numChantInView_butNotInPerimetre.length > 0) {
      this.getPerimetres(numChantInView_butNotInPerimetre.join(','))
    }


  }

  /**
   * Recuperer les perimetres de la BD et les inserer dans l'appli
   * @param string sites_id_polygones @example 123,145,463
   */
  getPerimetres(sites_id_polygones:string){
    from(this.BackendApiService.post_requete({ 'sites_id_polygones': sites_id_polygones }, '/api/perimetres/get'))
    .pipe(
      take(1),
      catchError(()=>{
        this.perimetres_loaded = this.perimetres_loaded.filter((el) => !sites_id_polygones.split(',').includes(el));
        return EMPTY
      }),
      tap((result:Array<perimetreInterface>)=>{
        var features_json = {
          "type": "FeatureCollection",
          "features": [
          ]
        }

        features_json.features = result.map((emprise)=>{
          var feature = {
            type: "Feature",
            id: emprise.sites_id_polygones,
            properties: {
              sites_id_polygones:emprise.sites_id_polygones,
              display: true,
              active: false,
              hover: false,
            },
            geometry: JSON.parse(emprise.geometry)
          }

          return feature
        })

        var features = new GeoJSON().readFeatures(features_json, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
        var layer_emprise = this.getLayerByAttr<VectorLayer>('emprise', 'nom', true)[0]
        
        layer_emprise.getSource().addFeatures(features)

        this.toogleActiveEmpriseAuto()

      })
    )
    .subscribe()
  }

  /**
   * Activer une emprise d'un site de manière automatique si il est visible et le desactive si il n'est plus activer
   */
  toogleActiveEmpriseAuto() {
    var layer_emprise = this.getLayerByAttr<VectorLayer>('emprise', 'nom', true)
    var layer_sites = this.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)

    if (layer_emprise.length > 0 && layer_sites.length > 0) {
      var source_emprise = layer_emprise[0].getSource()
      var source_sites = layer_sites[0].getSource().getSource()

      /**
         * Activer les perimètres dont les sites sont actifs et les perimetres sont inactifs
         */
      var features_sites_active = this.get_feature_in_source_by_attr(source_sites, 'display', false)
      var num_chant_actives = this.getListNumChantier(features_sites_active)

      for (let index = 0; index < num_chant_actives.length; index++) {
        var features_emprises_to_active = this.get_feature_in_source_by_multiple_attr(source_emprise, [
          {
            attr: 'num_chant_perimetres',
            value: num_chant_actives[index]
          },
          {
            attr: 'active',
            value: false
          }
        ])

        for (let i = 0; i < features_emprises_to_active.length; i++) {
          features_emprises_to_active[i].set('active', true)
        }
      }

      /**
       * desactiver les perimètres dont les sites sont inactifs et les perimetres sont actifs
       */

      var features_perimetres_active = this.get_feature_in_source_by_attr(source_emprise, 'display', true)
      var num_chant_perimetre_actives = this.getListNumChantier(features_perimetres_active, 'num_chant_perimetres')

      for (let index = 0; index < num_chant_perimetre_actives.length; index++) {
        num_chant_perimetre_actives[index];
        //feature site dont l'emprise devra etre desactiver
        var features_sites_for_emprise_to_desactive = this.get_feature_in_source_by_multiple_attr(source_sites, [
          {
            attr: environment.id_bd_qgis_server,
            value: num_chant_perimetre_actives[index]
          },
          {
            attr: 'display',
            value: true
          }
        ])
        var num_chant_perimetre_to_desactive = this.getListNumChantier(features_sites_for_emprise_to_desactive, environment.id_bd_qgis_server)

        // console.log(num_chant_perimetre_actives, num_chant_perimetre_to_desactive, features_sites_for_emprise_to_desactive, source_emprise.getFeatures())

        for (let i = 0; i < num_chant_perimetre_to_desactive.length; i++) {

          var features_to_desactivate = this.get_feature_in_source_by_attr(source_emprise, 'num_chant_perimetres', num_chant_perimetre_to_desactive[i])
          if (features_to_desactivate.length > 0) {
            features_to_desactivate[0].set('active', false)
          }
        }
      }

    }
  }

  /**
  * Activer le hover sur un perimetre
  * @param number num_chant 
  */
  activateHoverEmprise(num_chant: number) {
    if (this.map.getView().getZoom() >= this.config_projet.levels.perimetres.maxzoom) {
      var layer_emprise = this.getLayerByAttr<VectorLayer>('emprise', 'nom', true)

      if (layer_emprise.length > 0) {
        var source_emprise = layer_emprise[0].getSource()
        var features_to_desactivate = this.get_feature_in_source_by_attr(source_emprise, 'num_chant_perimetres', num_chant)
        if (features_to_desactivate.length > 0) {
          features_to_desactivate[0].set('hover', true)
        }
      }
    }

  }

  /**
   * Desactiver tous les hovers sur les emprises
   */

  desactivateHoverEmprise() {
    var layer_emprise = this.getLayerByAttr<VectorLayer>('emprise', 'nom', true)
    if (layer_emprise.length > 0) {
      var source_emprise = layer_emprise[0].getSource()
      this.set_all_features_pte_in_source_by_attr(source_emprise, 'hover', false)
    }

  }

  /**
  * lorsque la carte a fini de bouger il est appelé
  * @param map map l'objet map d'OL
  */
  mapMoved(map) {
    this.gestionClustersByZooms('sites', 'nom')
    if (map.getView().getZoom() >= this.config_projet.levels.elements_cartos.maxzoom) {
      this.getPerimetresOfVisibleSites()
    }
  }

  /**
    *click sur la carte
    *@param evt
    *@param (param :dataClickOnMap)=>void
    */
  mapHasCliked(evt:MapBrowserEvent<PointerEvent>):dataClickOnMap {
    this.map.updateSize()
    var pixel = this.map.getEventPixel(evt.originalEvent);
  
    var feature = this.map.forEachFeatureAtPixel(pixel,
      function (feature, layer) {
        return feature;
      }, {
      hitTolerance: 5
    });

    var layer = this.map.forEachFeatureAtPixel(pixel,
      function (feature, layer) {
        if (layer instanceof VectorLayer) {
          return layer;
        }

      }, {
      hitTolerance: 5
    });

    var layers = []

    if (!feature) {
      var all_pixels = this.calcHitMatrix(evt.pixel)
      for (let index = 0; index < all_pixels.length; index++) {
        var un_pixel = all_pixels[index];
        var nom_layers_load = []

        for (let i = 0; i < layers.length; i++) {
          nom_layers_load.push(layers[i].get('nom'));
        }

        var layers_in_pixels = this.displayFeatureInfo(un_pixel, 'nom', nom_layers_load)
        for (let j = 0; j < layers_in_pixels.length; j++) {
          layers.push(layers_in_pixels[j]);
        }

      }
    }


    if (layer instanceof VectorLayer && feature && layer.get('nom') == 'sites') {

      var size = this.is_clusters(feature.get('features'));
      if (size == 1) {
        var feat = this.avoir_feature_display(feature.getProperties().features)
        var coord = this.map.getCoordinateFromPixel(pixel)
        var data_callback: dataClickOnMap = {
          'type': 'feature_clicked',
          data: {
            coord: coord,
            layers: [layer],
            data: {}
          }
        }
        data_callback.data.data[environment.id_bd_qgis_server] = feat.get(environment.id_bd_qgis_server)

        return data_callback
      } else if (size > 1) {
        if (Object.create(feature.getGeometry()).getType() == 'Point') {
          var coordinate = Object.create(feature.getGeometry()).getCoordinates();
          var geom = new Point(coordinate)
          this.fit_view(geom, this.map.getView().getZoom() + 2, this.get_padding_map())
        }
      }

    } else if (layer instanceof VectorLayer && feature && layer.get('nom') == 'elem_geo_surbrillance') {
      var coord = this.map.getCoordinateFromPixel(pixel)
      var data_callback: dataClickOnMap = {
        'type': 'clear_elem_geo_surbrillance',
        data: {
          coord: coord,
          layers: layers
        }
      }
      return data_callback
    } else if (layer instanceof VectorLayer && feature && layer.get('nom') =='active_icon') {
      var coord = this.map.getCoordinateFromPixel(pixel)
      let feat = feature
      var data_callback: dataClickOnMap = {
        'type': 'active_icon_clicked',
        data: {
          coord: coord,
          layers: [layer],
          data: {}
        }
      }
      data_callback.data.data[environment.id_bd_qgis_server] = feat.get(environment.id_bd_qgis_server)
      return data_callback
    }else if (layers.length >= 0 && layers[0] instanceof ImageLayer) {
      var coord = this.map.getCoordinateFromPixel(pixel)
      var data_callback: dataClickOnMap = {
        'type': 'featureInfoWms',
        data: {
          coord: coord,
          layers: layers
        }
      }
      return data_callback

    } else {
      var coord = this.map.getCoordinateFromPixel(pixel)
      var data_callback: dataClickOnMap = {
        'type': 'clear',
        data: {
          coord: coord,
          layers: layers
        }
      }
      return data_callback
    }
  }

  /**
   * initilialiser et ajouter a la carte la couche qui fera la surbrillance pour les couches d'elements geographiques
   */
  initiliseLayerElemGeoSurbrillance() {
    var vectorSource = new VectorSource({
    });

    var wfsLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        // console.log(feature.get('active')) s
          if (feature.get('typologie') == 'linestring') {
            return new Style({
              stroke: new Stroke({
                color: environment.primaryColor,
                width: 3
              }),
            })
          } else {
            return new Style({
              fill: new Fill({
                color: environment.primaryColorTransparentRgba,
              }),
              image: new CircleStyle({
                radius: 10,
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 1)'
                }),
                fill: new Fill({
                  color: environment.primaryColor,
                })
              }),
            })
          }
      
      },
      minResolution: this.map.getView().getResolutionForZoom(this.config_projet.levels.elements_cartos.minzoom),
      maxResolution: this.map.getView().getResolutionForZoom(this.config_projet.levels.elements_cartos.maxzoom),
    })
    wfsLayer.set('type_layer','elem_geo_surbrillance')
    wfsLayer.set('nom','elem_geo_surbrillance')
    wfsLayer.setZIndex(this.config_projet.levels.elements_cartos.zindex + 1)
    this.map.addLayer(wfsLayer)
  }

  // loadDataInLayerElemGeoSurbrillance(data:FeatureWMSInfo[]) {

  //   var layer = this.getLayerByAttr('elem_geo_surbrillance', 'nom', true)[0]
  //   var source = layer.getSource()
  //   var elemem_loaded = []
  //   var elemem_to_load = []

  //   for (let index = 0; index < source.getFeatures().length; index++) {
  //     var feature = source.getFeatures()[index];
  //     elemem_loaded.push(feature.get('id') + '_' + feature.get('typologie'))
  //   }
  //   for (const key in data) {
  //     if (data.hasOwnProperty(key)) {
  //       var typologies_response = data[key];
  //       for (let index = 0; index < typologies_response.length; index++) {
  //         const element = typologies_response[index];
  //         elemem_to_load.push(element['geom']['properties']['id'] + '_' + element['geom']['properties']['typologie'])
  //       }

  //     }
  //   }

  //   var element_not_loaded = elemem_to_load.filter(x => !elemem_loaded.includes(x));
  //   var all_features = []
  //   for (const key in data) {
  //     if (data.hasOwnProperty(key)) {
  //       var typologies_response = data[key];

  //       for (let index = 0; index < typologies_response.length; index++) {
  //         const element = typologies_response[index];

  //         var id = element['geom']['properties']['id'] + '_' + element['geom']['properties']['typologie']
  //         if (element_not_loaded.indexOf(id) != -1) {
  //           var feat = new GeoJSON().readFeatures(element['geom'], { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
  //           feat[0].set('active', false)
  //           all_features.push(feat[0])
  //         }
  //       }



  //     }
  //   }
  //   source.addFeatures(all_features)

  // }

  /**
   * Initilialiser la couche pour afficher les couches activées
   */
  initialiser_popup_active_icon() {
    var icon = new Icon(({
      scale: 0.11,
      src: 'assets/icones/location-pin.svg'
    }))

    var iconStyle = new Style({
      image: icon
    });

    var source_active_icon = new VectorSource({
      features: []
    });

    var vector_active_icon = new VectorLayer({
      source: source_active_icon,
      style: (feature) => {

        return [iconStyle, this.get_style_sous_type(feature.get('layer'), feature.get('type') + 'active_icon')];

      }
    })
    vector_active_icon.set('type_layer','active_icon')
    vector_active_icon.set('nom','active_icon')
    vector_active_icon.setZIndex(99)
    source_active_icon.clear()


    this.map.addLayer(vector_active_icon)
  }

  /**
   * Ajouter une icone de type "active_icon" sur la carte
   * @param GeoJSON geojson il doit avoir un attribut 'layer' qui est le nom de la couche source de l'icone à activer
   */
  active_popup_active_icon(geojson) {
    if (this.getLayerByAttr('active_icon', 'nom', true).length < 1) {
      this.initialiser_popup_active_icon()
    }

    var layer_icon_active = this.getLayerByAttr<VectorLayer>('active_icon', 'nom', true)[0]
    var source_icon_active = layer_icon_active.getSource()

    var feature = new GeoJSON().readFeatures(geojson, { dataProjection: 'EPSG:3857', featureProjection: 'EPSG:3857' })[0]

    source_icon_active.clear()
    source_icon_active.addFeature(feature)
  }

  /**
   * Desactiver les icones de types 'active_icon'
   */

  desactive_popup_active_icon() {
    var layer_icon_active = this.getLayerByAttr<VectorLayer>('active_icon', 'nom', true)
    if (layer_icon_active.length > 0) {
      var source_icon_active = layer_icon_active[0].getSource()
      source_icon_active.clear()
    }
  }

  /**
   * Vider la source fake_source
   */
  clearFakeSource() {
    var layer = this.getLayerByAttr<BaseVectorLayer<Cluster>>('fake_source', 'nom', true)
    if (layer.length > 0) {
      var source = layer[0].getSource().getSource()
      // marche pas : source.removeLoadedExtent(source.getExtent())
      source.clear()
      /**
       * Va recharger automatiquement la source de données=>du à OL.
       * Le clear() vide juste les données sans recharger comme refresh
       */
      source.refresh()
    }
  }

  /**
 * Vider la source des sites
 */
  clearSitesSource() {
    var layer = this.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)
    if (layer.length > 0) {
      var source = layer[0].getSource().getSource()
      source.clear()
    }
  }

  /**
   * Vider tous les sites de l'application
   * vider les sources de la carte et les sources du stockageservice
   */
  clearSites() {
    this.desactive_popup_active_icon()

    this.clearFakeSource()

    this.clearSitesSource()

    this.StockageService.clearSites()
    this.fit_view
  }

  /**
   * Supprimer un site dans l'interface
   * @param site siteInterface
   */
  deleteSiteInMap(site:siteInterface){
    let layer = this.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)[0]
    let source = layer.getSource().getSource()
    let feature =  this.getFeatureByAttr(source,environment.id_bd_qgis_server, site.sites_id)
    if (feature) {
      source.removeFeature(feature)
    }
  }
}