import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BackendApiService } from '../../../services/backend-api.service'
import { AuthService } from '../../../services/auth.service'
import {Observable, BehaviorSubject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import * as $ from 'jquery';

export interface donne_pour_edit_right {
  agences: [];
  titre:string,
  callback:string,
  height:any;
  id_user:any;
  data?:any
}

@Component({
  selector: 'app-edit-rights',
  templateUrl: './edit-rights.component.html',
  styleUrls: ['./edit-rights.component.scss']
})
export class EditRightsComponent implements OnInit {

  loading = false
  disabled = true
  
  edit_password = false
  count = 0
  public agences= new BehaviorSubject([]);

  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public AuthService: AuthService,
    public dialogRef: MatDialogRef<EditRightsComponent>,
    @Inject(MAT_DIALOG_DATA) public data

  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    for (let index = 0; index < this.data.agences.length; index++) {
      const element = this.data.agences[index];
      this.data.agences[index]['code'] = this.data.agences[index]['code_insee']
    }
    this.agences.next(this.data.agences)

    this.agences.subscribe((donne)=>{
      if (this.count >0) {
        this.disabled = false
      }

      this.count++
    })
   
    $('.content_edit_right').height(this.data['height'] - $('.mat-dialog-title').outerHeight() - 110)
  }


  valider() {

    this.loading = true
    this.disabled = true
    var donne = {}
   
    donne['id'] = this.data.id_user

    donne['agence'] = []
   var new_data = []
    for (let index = 0; index < this.agences.getValue().length; index++) {
      if (this.agences.getValue()[index]['agence_id']) {
        donne['agence'].push(this.agences.getValue()[index]['agence_id'])
        new_data.push(this.agences.getValue()[index])
      }
    }

    var url = '/api/user/update_agence'
    console.log(donne)
    this.BackendApiService.post_requete(donne, url).then((response) => {

      this.loading = false
      this.disabled = false

      this._snackBar.open(response['message'], 'Fermer', {
        duration: 1000,
      });
     
      if (!response['erreur']) {
        this.data.data = new_data
        this.dialogRef.close(this.data)
      } 

    },
      (err) => {
        this.loading = false
        this.disabled = false
        this._snackBar.open(err.message, 'Fermer', {
          duration: 1000,
        });
      }
    )

  }

  agenceChoisi(agence,index){

    this.agences.getValue()[index]= agence
    this.agences.next(this.agences.getValue())
  }

  add_agence(){
    this.agences.getValue().push({
      'nom':"",
      'code':""
    })
  }
  remove_agence(index){
    
    var emit_event = false
    if (this.agences.getValue()[index].code_agence) {
       emit_event = true
    }

    this.agences.getValue().splice(index,1)
    if (emit_event) {
      this.agences.next(this.agences.getValue())
    }
    
  }

}
