import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '../services/auth.service'
import { GeneralService } from '../services/general.service'
import { BackendApiService } from '../services/backend-api.service'
import { environment } from '../../environments/environment'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';
import { ModificationUserComponent, donne_pour_modification_user } from '../modal/gestion-user/modification-user/modification-user.component';
import { GestionUserComponent } from '../modal/gestion-user/gestion-user.component'
import { UserProfileComponent } from '../modal/gestion-user/user-profile/user-profile.component';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
/**
 * profil component
 */
export class ProfilComponent implements OnInit {

  user: User

  title = 'terideal-frontend';

  environment = environment
   /**
  * configuration du projet
  */
 public config_projet: any

  constructor(
    public BackendApiService: BackendApiService,
    public AuthService: AuthService,
    public GeneralService: GeneralService,
    public dialog: MatDialog,
  ) {
    this.GeneralService.config_projet.subscribe(value=>{
      this.config_projet = value
    })

    this.AuthService.user.subscribe((value) => {
      this.user = value
    })
   }

  ngOnInit() {
  }

    /**
   * Modifier son profil
   */
  activer_modification_profile() {

    var dictionnaire = this.config_projet.dictionaire.dictionnaire_user
    var titre = "Modifier mon profil "
    var size = [$(window).width() * 0.6 + 'px', $(window).height() * 0.45 + 'px']


    var champ_id = ['id', this.user.id]

    var url = "/api/user/update"

    var data: donne_pour_modification_user = {
      'dictionnaire': dictionnaire,
      'donne': this.user,
      'titre': titre,
      'url': url,
      'champ_id': champ_id,
      'callback': 'modifier_user_locale'
    }
    
    this.ouvrir_fenetre_de_modification_user(data, size)
  }

  /**
   * Modifier le profil en local après succes en BD
   * @param  {donne_pour_modification_user} data 
   */
  modifier_user_locale(data: donne_pour_modification_user) {
    this.user.email = data.donne['email']
    this.user.name = data.donne['name']
  }

  /**
   * Ouvur le modal d'edition de profil d'utilisateur
   * @param {donne_pour_modification_user} data 
   * @param  {string[]} size dimensions du modal
   */
  ouvrir_fenetre_de_modification_user(data: donne_pour_modification_user, size: string[]) {

    const fenetre_de_modification = this.dialog.open(UserProfileComponent, {
      width: size[0],
      height: size[1],
      disableClose: true,
      data: data
    });

    fenetre_de_modification.afterClosed().subscribe((result: donne_pour_modification_user) => {
      if (result) {
        var fun = this[result.callback](result)
      }
    })
  }

  /**
   * Ouvir la fenetre de gestions des utilisateurs
   */
  ouvir_fenetre_gestion_utilisateur() {
    var size = [$(window).width() * 0.8 + 'px', $(window).height() * 0.9 + 'px']
    const fenetre_gestion_user = this.dialog.open(GestionUserComponent, {
      width: size[0],
      height: size[1],
      disableClose: false,
    });

    fenetre_gestion_user.afterClosed().subscribe((result) => {
      if (result) {
        // var fun = this[result.callback](result)
      }
    })
  }


}
