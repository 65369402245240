import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BackendApiService } from '../../../services/backend-api.service'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {reponseDB} from 'src/app/type/index';
import { AjoutSiteComponent, nouveauInterface } from '../ajout-site.component'
import { switchMap, debounceTime, tap, finalize, filter, startWith, map, skip } from 'rxjs/operators';
import { Observable } from 'rxjs'
import { Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MatBottomSheet, MatAutocomplete } from '@angular/material';


@Component({
  selector: 'app-infos-general',
  templateUrl: './infos-general.component.html',
  styleUrls: ['./infos-general.component.scss']
})
export class InfosGeneralComponent implements OnInit {

  /**
   * List des mat autocomplete
   */
  @ViewChildren("ref_auto") listMatAutocomplete: QueryList<MatAutocomplete>;

  formulaire: FormGroup
  loading = false
  objectKeys = Object.keys;
  disabled = true
  show_all_fields = false


  isLoading = false;
  id: string
  config_projet
  data
  dictionnaire
  filiale_trouver = []
  nbre_fields = 5

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    private _bottomSheet: MatBottomSheet,
    public AjoutSiteComp: AjoutSiteComponent
  ) {
    this.data = this.AjoutSiteComp.data
    this.dictionnaire = this.AjoutSiteComp.data.dictionnaire.infosGeneral
    this.formulaire = this.fb.group({})
  }

  /**
   * Afficher /desaficher tous les formulaires
   */
  toogle_fields_all() {
    this.show_all_fields = !this.show_all_fields
    if (this.show_all_fields) {
      this.nbre_fields = this.AjoutSiteComp.data.dictionnaire.infosGeneral.length
    } else {
      this.nbre_fields = 5
    }
  }


  getErrorMessage(sous_type) {
    if (sous_type == 'email') {
      return 'e-mail invalide'
    }
  }

  /**
 * Recuperer les valeurs de la nouvelle entite deja saisie
 * @return nouveauInterface
 */
  getNouveauValue(): nouveauInterface {
    return this.AjoutSiteComp.getNouveauValue()
  }


  navigate_to_localisation() {
    this.AjoutSiteComp.setTitre("Dessiner l'Emprise", 'localisation')
    this.router.navigate([{ outlets: { ajout_entite: 'localisation' } }]);
  }

  // navigate_to_contact() {
  //   this.AjoutSiteComp.setTitre('Contacts', 'contact')
  //   this.router.navigate([{ outlets: { ajout_entite: 'tableau' } }]);
  // }

  // navigate_to_machines() {
  //   this.AjoutSiteComp.setTitre('Machines', 'dictionnaire_machine')
  //   this.router.navigate([{ outlets: { ajout_entite: 'tableau' } }]);
  // }

  /**
   * initialiser le formulaire: crétion des differents formulaires selon le dictionnaire
   */
  initialiserFormulaire() {
    for (let index = 0; index < this.AjoutSiteComp.data.dictionnaire.infosGeneral.length; index++) {
      const dict = this.AjoutSiteComp.data.dictionnaire.infosGeneral[index];
      if (dict.sous_type == 'email') {
        this.formulaire.addControl(dict['champ'], new FormControl('', [Validators.email]))
      } else if (dict.sous_type == 'text' || dict.sous_type == 'textarea') {
        this.formulaire.addControl(dict['champ'], new FormControl('', [Validators.min(2)]))
      } else if (dict.sous_type == 'list') {

        this.formulaire.addControl(dict['champ'], new FormControl(''))
       
        /**
         * recuperation des valeurs à rechercher dans le select
         */
        if (!dict.valeurs) {
          this.formulaire.controls[dict['champ']].disable()
          this.getDataForSelect(dict.urlToFectchSelectData).then(
            (response: any[]) => {
  
              if (response) {
                this.formulaire.controls[dict['champ']].enable()
                dict.valeurs = response
                this.formulaire.controls[dict['champ']].setValue('')
              } else {
                dict.valeurs = []
              }
            }
          )
        }else{
          this.formulaire.controls[dict['champ']].setValue('')
        }
       
        /**
         * recuperation des valeurs filtres lors de la saisie de l'utilisateur
         */
        dict.filteredOptions = this.formulaire.controls[dict['champ']].valueChanges.pipe(
          filter(value => typeof value === "string"),
          startWith(''),
          debounceTime(300),
          map((value): any[] => {
            if (dict.valeurs && dict.valeurs.length > 0) {
              return this.filterAutocomplete(value, dict.valeurs)
            } else {
              return dict.valeurs ? dict.valeurs : []
            }

          })
        )


      }
    }
    if (this.AjoutSiteComp.getNouveauValue().infosGeneral) {
      var donne = this.AjoutSiteComp.getNouveauValue().infosGeneral
      console.log(donne,this.formulaire.value)
      setTimeout(() => {
        this.formulaire.setValue(donne)
      }, 1000);
      
    }
  }

  /**
   * Initialiser les evenements de detection des doublons basé sur les champs:
   * Ecouter les champs à surveiller et rechercher les doublons
   */
  initialiserDetectDoublons() {
    for (let index = 0; index < this.AjoutSiteComp.data.attention.doublonsName.length; index++) {
      const doublonsTodetect = this.AjoutSiteComp.data.attention.doublonsName[index];
      if (doublonsTodetect.active && this.formulaire.get(doublonsTodetect.field)) {
        this.formulaire
          .get(doublonsTodetect.field)
          .valueChanges
          .pipe(
            debounceTime(300),
            filter(value => value.length > 2),
            startWith(''),
            // skip(1),
            switchMap(value => {
              var postdate = {}
              postdate[doublonsTodetect.fieldToSearch] = value.toString()

              try {
                return this.BackendApiService.searchEntiteReturnId(doublonsTodetect.urlToDetect, postdate)

              } catch (error) {
                return null
              }
            }
            )
          )
          .subscribe((reponses: reponseDB) => {
            var doublonsTrouver = []
            for (let index = 0; index < reponses.data.length; index++) {
              doublonsTrouver.push(reponses.data[index][doublonsTodetect.fieldDB])
            }

            var data = this.AjoutSiteComp.getNouveauValue()
            data.attention.getDoublonsToDetect(doublonsTodetect.field).doublonsId = doublonsTrouver
            this.AjoutSiteComp.setNouveau(data)

          });
      }
    }

  
  }

  /**
   * Filtrer les données d'un autocomplete
   * @param value string valeur à utiliser pour filtrer
   */
  filterAutocomplete(value: string, values: any[]): any[] {

    const filterValue = value.toLowerCase();

    var result_nom = values.filter((option) => {
      for (const key in option) {
        if (option.hasOwnProperty(key) && option[key] != null) {
          const element = option[key];
          if (element.toString().toLowerCase().includes(filterValue)) {
            return true
          }
        }
      }
      // option.nom_regroup.toLowerCase().includes(filterValue)
    })

    return result_nom;
  }

  /**
  * Concatener les ptés des valeurs d'un objet 
  * @param option Object l'objet à utiliser pour concatener
  * @param displayPte string[] clés à utiliser pour concatener
  */
  formatDisplayValue(option: Object, displayPte: string[]): string {
    var text: string[] = []
    if (option) {
      for (let index = 0; index < displayPte.length; index++) {
        const element = displayPte[index];
        text.push(option[element].toString())
      }
    }
    return text.join(' - ')
  }

  /**
   * recuperer les données pour un autocomplete dans les select forms
   * @param url string lien de la requete
   * @return Promise<Array<any>|undefined>
   */
  async getDataForSelect(url: string): Promise<Array<any> | undefined> {
    return await this.BackendApiService.get_requete(url).then(
      (data: Array<any>) => {
        return data
      },
      (err) => {
        console.log(err)
        this._snackBar.open(err.message, 'Fermer', {
          duration: 1000,
        });
        return undefined
      }
    )
  }


  ngOnInit() {
    this.initialiserFormulaire()

    this.initialiserDetectDoublons()
    

    this.formulaire.valueChanges.subscribe((data) => {
      this.disabled = false
      // console.log(data)
      var nouveau = this.AjoutSiteComp.getNouveauValue()
      nouveau.infosGeneral = data
      this.AjoutSiteComp.setNouveau(nouveau)
    })

    


  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    

    /**
       * fonction à utiliser pour afficher la donnée selectionné par l'utilisateur
       */
    this.listMatAutocomplete.changes.subscribe((values) => {

      this.listMatAutocomplete.forEach((item: MatAutocomplete) => {
        var fieldChamp: string = item.template.elementRef.nativeElement.parentElement.id.replace('ref_auto_', '')
        var dictionnaire = this.AjoutSiteComp.getDictionnaire(fieldChamp)
        if (dictionnaire) {
          item.displayWith = (option) => {
            if (typeof dictionnaire.fieldDisplay == 'string') {
              return this.formatDisplayValue(option, [dictionnaire.fieldDisplay])
            } else {
              return this.formatDisplayValue(option, dictionnaire.fieldDisplay)
            }
          }
        } else {
          console.log('merdeee')
        }
      })
    })
  }

  // afficher_liste_entreprise_autour() {

  //   this._bottomSheet.open(ListeEntrepriseComponent, {
  //     data: { 'clients': this.filiale_trouver }
  //   });

  // }

}
