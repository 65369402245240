import { Component, OnInit, Input } from '@angular/core';
import { GeneralService } from '../../../../services/general.service'
import { AuthService, User } from '../../../../services/auth.service'
import { SitesCartoService } from '../../../../services/sites-carto.service'
import { StockageService } from '../../../../services/stockage.service'
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Cluster, Feature, GeoJSON, Point, VectorLayer } from 'src/app/ol-module';
import { environment } from 'src/environments/environment';
import { siteInterface } from 'src/app/type';
import { ScreenHelper } from 'src/app/helpers/screen';
import { EMPTY, ReplaySubject, Subject } from 'rxjs';
import { filter, map, mapTo, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UpdateClientComponent } from '../../modal/update-client/update-client.component';
import { UpdateAgenceComponent } from '../../modal/update-agence/update-agence.component';
import { UpdateRegroupementComponent } from '../../modal/update-regroupement/update-regroupement.component';
import { UpdateSiteComponent } from '../../modal/update-site/update-site.component';
import { UpdateLocalisationSiteComponent } from '../../modal/update-localisation-site/update-localisation-site.component';
import { MapGeneralService } from 'src/app/services/map.general.service';
import BaseVectorLayer from 'ol/layer/BaseVector';

@Component({
  selector: 'app-infos-generales',
  templateUrl: './infos-generales.component.html',
  styleUrls: ['./infos-generales.component.scss'],
})
/**
 * Composant pour les informations générales d'un site dans sa fiche descriptive
 */
export class InfosGeneralesComponent implements OnInit {

  @Input() site: siteInterface

  screenHelper = ScreenHelper
  objectsToArray = Object.keys
  user:User = this.AuthService.get_user()
  

  public onUpdateBlocInstance: (bloc: string) => void;
  public onUpdateLocalisationInstance: () => void;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public GeneralService: GeneralService,
    public translate: TranslateService,
    public SitesCartoService: SitesCartoService,
    public AuthService: AuthService,
    public StockageService:StockageService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public mapService : MapGeneralService
  ) {
    const onUpdateBloc: Subject<string> = new Subject<string>()
    this.onUpdateBlocInstance = (bloc: string) => {
      onUpdateBloc.next(bloc)
    }

    onUpdateBloc.pipe(
      takeUntil(this.destroyed$),
      switchMap((bloc : string)=> {
        if (bloc == 'Site') {
          var titre = ''
          this.translate.get('modification', { value: 'site' }).subscribe((res) => {
            titre = res['site'] + this.site.nom_site
          });


          var size = [$(window).width() * 0.7 + 'px', '350 px']

          var champ_id = ['sites_id', this.site.sites_id]

          var data = {
            champ_id: champ_id,
            titre: titre,
            donne: this.site,
          }
          return this.dialog.open(UpdateSiteComponent, {
            data : data,
            width : size[0],
            height : size[1]
          }).afterClosed().pipe(
            
          )
        } else if (bloc == 'Informations client') {
          return this.dialog.open(UpdateClientComponent, {
            data : this.site
          }).afterClosed().pipe(
            
          )
        } else if (bloc == 'Informations Terideal') {
          return this.dialog.open(UpdateAgenceComponent, {
            data : this.site
          }).afterClosed().pipe(
            
          )
        } else if(bloc == 'Regroupement') {
          return this.dialog.open(UpdateRegroupementComponent, {
            data : this.site,
            autoFocus : false
          }).afterClosed().pipe(
            
          )
        }
      }),
      filter(value => value),
      switchMap(()=> {
        return this.StockageService.updateSite(this.site.sites_id)
      })
    ).subscribe()

    const onUpdateLocalisation: Subject<void> = new Subject<void>()
    this.onUpdateLocalisationInstance = () => {
      onUpdateLocalisation.next()
    }

    onUpdateLocalisation.pipe(
      takeUntil(this.destroyed$),
      switchMap(()=> {
        var layer = this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)
        var source = layer[0].getSource().getSource()
        var features = this.SitesCartoService.get_feature_in_source_by_attr(source, environment.id_bd_qgis_server, this.site[environment.id_bd_qgis_server])

        if (features.length != 1) {
          alert('Impossible de modifier ce site !')
          return
        }

        var style = this.SitesCartoService.get_style_sous_type(layer[0].get('nom'), features[0].get('type'))
        var titre = ''
        this.translate.get('modification', { value: 'localisation' }).subscribe((res) => {
          titre = res['localisation'] + this.site.nom_site
        });

        var size = [$(window).width() * 0.7 + 'px', $(window).height() * 0.8 + 'px']

        var champ_id = ['sites_id', this.site.sites_id]
        var dictionnaire = [

          {
            'champ': 'commune',
            'valeur': 'Ville',
            'type': 'text',
            'sous_type': 'commune',
          },
          {
            'champ': 'postal',
            'valeur': 'Code postal',
            'type': 'text',
            'sous_type': 'cp',
          }
        ]
        var data = {
          dictionnaire: dictionnaire,
          champ_id: champ_id,
          titre: titre,
          donne: this.site,
          field_adresse: 'adresse',
          field_commune: 'commune',
          field_cp: 'postal',
          feature: features[0],
          style: style
        }        

        return this.dialog.open(UpdateLocalisationSiteComponent, {
          data : data,
          width: size[0],
          height: size[1],
          disableClose: true,
          autoFocus : false
        }).afterClosed().pipe(
          filter(value => value),
          switchMap((data)=> {
            let currentFeature =  this.mapService.getFeatureByAttr(source,environment.id_bd_qgis_server, data.donne.sites_id)
            var geojson = JSON.parse(data['donne']['geojson'])
            var features_temp:any = new GeoJSON().readFeatures(geojson, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
            currentFeature.setGeometry(features_temp[0].getGeometry())
            return this.StockageService.updateSite(this.site.sites_id).pipe(
              switchMap((currentSite)=> {
                let map = this.SitesCartoService.map
                map.getLayers().forEach((layer : VectorLayer) => {
                  if (layer && layer.get('nom') ==='active_icon') {
                    let activeSource = layer.getSource()
                    let activeFeature = activeSource.getFeatures()[0]
                    activeFeature.setGeometry(features_temp[0].getGeometry())                    
                  }
                })
                this.SitesCartoService.pan_to(features_temp[0].getGeometry().getCoordinates(), 17)
                return this.translate.get('success', { value: 'site' }).pipe(
                  tap((res)=> {
                    this._snackBar.open(res['modification_localisation_site'], 'Fermer', {
                      duration: 4000,
                    });
                  })
                )
              })
            )
          })
        )
      })
    ).subscribe()
   }

  ngOnInit() {
  }

  round(numb: number) {
    return Math.round(numb)
  }

  removeSpecialCharacters(text): string {
    return text.replace(/[^a-zA-Z ]/g, "")
  }

  ngOnDestroy(){
    this.destroyed$.next()
    this.destroyed$.complete()
  }
}
