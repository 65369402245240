import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BackendApiService } from '../../../services/backend-api.service'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AjoutSiteComponent } from '../ajout-site.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { switchMap, debounceTime, tap, finalize, filter } from 'rxjs/operators';
import { Observable } from 'rxjs'
import { MapGeneralService } from '../../../services/map.general.service'
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector.js';
import { Map, View, Feature } from 'ol';
import { Group as LayerGroup, Vector as VectorLayer } from 'ol/layer.js';
import { transform as Transform, fromLonLat, transform, get as getProjection } from 'ol/proj.js';
import {
  Circle as CircleStyle, Fill, Stroke, Text, RegularShape, Icon
} from 'ol/style.js';
import Style from 'ol/style/Style';
import { feature } from '@turf/turf';
import { Router } from '@angular/router';
import { buffer } from 'ol/extent';
import { environment } from 'src/environments/environment';
import { AdresseFeatureResponse, reponseDB } from 'src/app/type';
import { transformExtent, getCenter, Collection } from 'src/app/ol-module';
import { DrawComponent } from '../draw/draw.component'
import * as $ from 'jquery'
import {SitesCartoService} from 'src/app/services/sites-carto.service'

const view = new View({
  center: fromLonLat([5, 45]),
  zoom: 18,
})

var map = new Map({
  target: 'map1',
  maxTilesLoading: 50,
  view: view
});

@Component({
  selector: 'app-localisation-ajout',
  templateUrl: './localisation-ajout.component.html',
  styleUrls: ['./localisation-ajout.component.scss']
})
export class LocalisationAjoutComponent implements OnInit {

  formulaire: FormGroup
  loading = false
  objectKeys = Object.keys;
  disabled = true

  @ViewChild('mapDraw') mapDrawComp: DrawComponent

  mode_modification = "adresse"
  adresse_form: FormGroup
  map_active = false
  adresse_trouver:AdresseFeatureResponse []
  isLoading = false;
  id: string
  adresse_sup: string
  vectorSource_adresse: VectorSource
  vectorLayer_adresse: VectorLayer
  config_projet
  dictionnaire
  data
  loading_adresse = false
  inverse_geocode: any

  map = map

  constructor(
    private fb: FormBuilder,
    private MapGeneralService: MapGeneralService,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    private AjoutSiteComp: AjoutSiteComponent,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    public SitesCartoService:SitesCartoService
  ) {
    this.data = this.AjoutSiteComp.data
    this.dictionnaire = this.AjoutSiteComp.data.dictionnaire
    this.formulaire = this.fb.group({})

    this.formulaire.addControl('commune', new FormControl({ value: '', disabled: true }))
    this.formulaire.addControl('cp', new FormControl({ value: '', disabled: true }))
    this.formulaire.addControl('adresse', new FormControl({ value: '', disabled: true }))
    this.formulaire.addControl('id_adresse', new FormControl({ value: '', disabled: true }))

  }

  navigate_to_principal() {
    this.AjoutSiteComp.navigate_to_principal()
  }

  update_pte_localisation(champ, valeur, disabled) {
    this.formulaire.controls[champ].reset({ value: valeur, disabled: disabled })
  }

  clear_pte_formulaire() {
    this.update_pte_localisation('commune', '', true)
    this.update_pte_localisation('cp', '', true)
    this.update_pte_localisation('adresse', '', true)
    this.update_pte_localisation('id_adresse', '', true)
    this.adresse_form.controls['adresse'].patchValue('', { emitEvent: false })
  }

  update_pte_formulaire(adresse) {
    var ville = adresse.properties.city
    var cp = adresse.properties.postcode
    var label = adresse.properties.label
    var id_adresse = adresse.properties.id

    this.adresse_form.controls['adresse'].patchValue(adresse, { emitEvent: false })
    this.update_pte_localisation('commune', ville, true)
    this.update_pte_localisation('cp', cp, true)
    this.update_pte_localisation('adresse', label, true)
    this.update_pte_localisation('id_adresse', id_adresse, true)
  }

  display_marker_adresse_change(adresse) {

    // this.update_pte_formulaire(adresse)

    var feature = new GeoJSON().readFeatures(adresse, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })[0]
    this.vectorSource_adresse.clear()
    this.vectorSource_adresse.addFeature(feature)

    var coordinate = Object.create(feature.getGeometry()).getCoordinates();
    map.getView().animate({ center: coordinate }, { zoom: 17 }, { duration: 500 });
    this.find_id_entreprise_autour(coordinate[0], coordinate[1])
    this.disabled = false
  }

  initialise_layer_adresse() {
    var icon = new Icon(({
      scale: 0.1,
      src: 'assets/icones/null.svg'
    }))
    var iconStyle = new Style({
      image: icon
    });

    this.vectorSource_adresse = new VectorSource({
      features: []
    });

    this.vectorLayer_adresse = new VectorLayer({
      source: this.vectorSource_adresse,
      style: iconStyle
    })
    this.vectorLayer_adresse.setZIndex(99)
    this.vectorLayer_adresse.set('nom', 'adresse')
    this.vectorSource_adresse.clear()

    var all_layers = map.getLayers().getArray()
    var boolean_lay_adresse = true
    for (let index = 0; index < all_layers.length; index++) {
      const lay = all_layers[index];
      if (lay.get('nom') == 'adresse' && lay instanceof VectorLayer) {
        boolean_lay_adresse = false
        this.vectorLayer_adresse = lay
        this.vectorSource_adresse = lay.getSource()
        this.vectorSource_adresse.clear()
      }
    }

    if (boolean_lay_adresse) {
      map.addLayer(this.vectorLayer_adresse)
    }


  }

  displayFn(adresse) {
    if (adresse instanceof Object) {

      return adresse.properties.label;
    } else {
      return adresse
    }

  }

  display_pte_after_map_move(coords) {
    var latitude = coords[0]
    var longitude = coords[1]
    this.loading_adresse = true
    this.BackendApiService.geocode_reverse(longitude, latitude).subscribe((data) => {
      this.loading_adresse = false
      if (data.features.length > 0) {
        var adresse = data.features[0]
        this.inverse_geocode = adresse
        this.update_pte_formulaire(adresse)
        this.find_id_entreprise_autour(fromLonLat(coords)[0], fromLonLat(coords)[1])

      } else {
        this.clear_pte_formulaire()

        this._snackBar.open('Aucune adresse trouver', 'Fermer', {
          duration: 4000,
        });

        this.findLimitesAdministratives(coords)

      }

    }, (error) => {
      this._snackBar.open("Un problème est survenu lors de la recherche d'adresse", 'Fermer', {
        duration: 4000,
      });
    })

    map.getView().animate({ center: fromLonLat(coords) }, { zoom: 15 }, { duration: 500 });

  }

  /**
   * Rechercher les limites administravtives
   * @param coords 
   */
  findLimitesAdministratives(coords) {
    var latitude = coords[0]
    var longitude = coords[1]
    this.loading_adresse = true
    this.BackendApiService.geocodeAdmReverse(longitude, latitude).subscribe((data) => {
      this.loading_adresse = false
      if (data.length > 0) {
        var commune = data[0].nom
        var code = data[0].code


        this.adresse_form.controls['adresse'].patchValue(commune, { emitEvent: false })
        this.update_pte_localisation('commune', commune, true)
        this.update_pte_localisation('cp', code, true)
        // this.update_pte_localisation('adresse', commune, true)
        this.update_pte_localisation('id_adresse', null, true)

        this.find_id_entreprise_autour(fromLonLat(coords)[0], fromLonLat(coords)[1])

      } else {
        this.clear_pte_formulaire()
        this._snackBar.open('Aucune limite administrative trouvé', 'Fermer', {
          duration: 4000,
        });

      }

    }, (error) => {
      this._snackBar.open("Un problème est survenu lors de la recherche d'adresse", 'Fermer', {
        duration: 4000,
      });
    })
  }

  getLayerByAttr(layername, attr, layer_group) {
    var layer_to_remove = []

    if (layer_group) {
      var all_layers = map.getLayers().getArray()
    } else {
      var all_layers = map.getLayerGroup().getLayers().getArray()
    }

    for (let index = 0; index < all_layers.length; index++) {
      var layer = all_layers[index]
      if (layer.get(attr) == layername) {
        layer_to_remove.push(layer)
      }

    }
    return layer_to_remove
  }

  /**
   * change base maps bettween aerien and plan
   */
  toogleBaseMaps() {
    var ign = this.getLayerByAttr('ign', 'nom', true)[0]
    var defaultLayer = this.getLayerByAttr('defaultLayer', 'nom', true)[0]
    ign.setVisible(!ign.getVisible())
    defaultLayer.setVisible(!defaultLayer.getVisible())
  }

  initialise_map() {
    this.map_active = true
    map.setTarget('map2')
    map.setTarget('map1')



    var defaultLayer = this.MapGeneralService.getDefaultMapLayer()
    defaultLayer.set('nom', 'defaultLayer')
    defaultLayer.set('type_layer', 'plan')
    defaultLayer.setVisible(false)
    map.addLayer(defaultLayer)
    var ign = this.MapGeneralService.getIgnLayer()
    ign.set('nom', 'ign')
    ign.set('type_layer', 'plan')
    map.addLayer(ign)

    var composantsSites = this.SitesCartoService.getWMSComposantLayer()
    if (composantsSites[0]) {
      map.addLayer(composantsSites[0])
    }

    map.on('moveend', (evt) => {
      if (this.mode_modification == 'coordonne') {
        this.disabled = false
        var coords = transform(view.getCenter(), 'EPSG:3857', 'EPSG:4326');
        this.display_pte_after_map_move(coords)
      }
    })


  }
  ngAfterViewInit(): void {

    setTimeout(() => {
      if (!this.mapDrawComp.drawTools.Polygon.active) {
        // this.mapDrawComp.toogleAddDraw('Polygon')
      }
    }, 1000);
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  /**
   * Le dessin sur la carte est terminé, on cealcule le centroid de la géometrie dessiné et on le géocode
   */
  drawFinish() {
    setTimeout(() => {
      var extent = this.mapDrawComp.source.getExtent()
      var coords = transform(getCenter(extent), 'EPSG:3857', 'EPSG:4326');
      if (coords[0]) {
        this.display_pte_after_map_move(coords)
      }
    }, 1000);

  }

  /**
    * A chaque fermeture du component, vider la map ! 
    * Car l'objet map est persisté
    */
  ngOnDestroy() {
    this.cleanMap()
  }

  /**
   * Clean map : remove all layers
   */
  cleanMap() {
    while (map.getLayers().getArray().length > 0) {
      for (let index = 0; index < map.getLayers().getArray().length; index++) {
        const layer_to_remove = map.getLayers().getArray()[index];
        map.removeLayer(layer_to_remove)
      }
    }
    if (this.mapDrawComp.modifyTool.geometry.active) {
      this.mapDrawComp.desactivateAllModificationTool()
    }

    if (this.mapDrawComp.drawTools.Polygon.active) {
      this.mapDrawComp.desactivateAllAddTool()

    }

    // $(map.getViewport()).unbind()
  }

  ngOnInit() {
    var all_layers = map.getLayers().getArray()
    // for (let index = 0; index < all_layers.length; index++) {
    //   const lay = all_layers[index];
    //   map.removeLayer(lay)
    // }
    // console.log(this.AjoutSiteComp.getNouveauValue())
    // console.log('ngOnInit',map.getLayers().getArray().length)
    this.initialise_map()
    this.initialise_layer_adresse()


    this.adresse_form = this.fb.group({
      adresse: ''
    })

    this.adresse_form
      .get('adresse')
      .valueChanges
      .pipe(
        debounceTime(300),
        filter(value => typeof value === "string"),
        tap(() => this.isLoading = true),
        switchMap(value => {
          try {
            if (value != '' && value != undefined) {
              return this.BackendApiService.search(value.toString().split(" ").join("+"))
                .pipe(
                  finalize(() => this.isLoading = false),
                )
            } else {
              return;
            }
          } catch (error) {
            return;
          }
        }
        )
      )
      .subscribe(reponse => this.adresse_trouver = reponse.features);

    if (this.AjoutSiteComp.getNouveauValue().perimetre) {
      var donne = this.AjoutSiteComp.getNouveauValue().localisation

      var geojson = donne.geojson

      this.mode_modification = donne.mode_modification

      this.display_pte_after_map_move(geojson['geometry']['coordinates'])

      this.mapDrawComp.source.addFeatures(this.AjoutSiteComp.getNouveauValue().perimetre)

    }

  }

  changer_mode_modification(type) {
    this.vectorSource_adresse.clear()
    this.mode_modification = type

    this.clear_pte_formulaire()

    if (type == 'coordonne') {
      this.adresse_form.controls['adresse'].disable({ emitEvent: false })
      this.adresse_form.controls['adresse'].patchValue('', { emitEvent: false })
      var coords = transform(view.getCenter(), 'EPSG:3857', 'EPSG:4326');
      this.display_pte_after_map_move(coords)
    } else {
      this.adresse_form.controls['adresse'].enable({ emitEvent: false })
      this.adresse_form.controls['adresse'].patchValue(this.inverse_geocode, { emitEvent: false })
    }
  }

  ids = []
  find_id_entreprise_autour(lat, lon) {
    // [minX, minY, maxX, maxY] 

    var initial_extent = transformExtent(buffer([lat, lon, lat, lon], 500), 'EPSG:3857', 'EPSG:4326')

    var url = this.AjoutSiteComp.data.attention.localisation.urlToDetect + "&outputFormat=GeoJSON&bbox=" + initial_extent.join(',');
    this.BackendApiService.get_requete(url).then(
      (reponse: reponseDB) => {
        var new_ids = []
        for (let index = 0; index < reponse.data.features.length; index++) {
          const feature = reponse.data.features[index];
          feature.id = feature.properties[this.AjoutSiteComp.data.attention.localisation.fieldGeosjon]
          new_ids.push(feature.id)
        }
        this.ids = new_ids
        var data = this.AjoutSiteComp.getNouveauValue()
        data.attention.localisation = this.ids
        this.AjoutSiteComp.setNouveau(data)

      },
      (err) => {
        console.log(err)
      }
    )

  }

  afficher_liste_entreprise_autour() {
    // this.BackendApiService.post_requete({ ids: this.ids, trashed: true }, '/api/clients_par_id_filiales').then(
    //   (donne) => {
    //     var clients = donne['data']
    //     this._bottomSheet.open(ListeEntrepriseComponent, {
    //       data: { 'clients': clients }
    //     });
    //   },
    //   (err) => {
    //     throw err;
    //   })
  }

  valider() {
    var donne = {
      'mode_modification': this.mode_modification,
      'geojson': { "type": "Feature", "geometry": { "type": "Point", "coordinates": [] }, "properties": {} }
    }

    // if (this.mode_modification == 'coordonne') {
    //   var coords = transform(view.getCenter(), 'EPSG:3857', 'EPSG:4326');
    //   var latitude = coords[0]
    //   var longitude = coords[1]
    //   donne['latitude'] = latitude
    //   donne['longitude'] = longitude
    //   donne['adresse_form'] = this.inverse_geocode
    // } else if (this.mode_modification == 'adresse' && this.adresse_form.controls['adresse'].value instanceof Object) {

    //   var geometry =  this.mapDrawComp.source.getFeatures()[0].getGeometry()
    //   var coords = transform(getCenter( geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
    //   var adresse_form = this.adresse_form.controls['adresse'].value
    //   var latitude =coords[0]
    //   var longitude =coords[1]
    //   donne['latitude'] = latitude
    //   donne['longitude'] = longitude
    //   donne['adresse_form'] = adresse_form
    // }
    // var GeoJSON = { "type": "Feature", "geometry": { "type": "Point", "coordinates": [] }, "properties": {} }
    if (this.mapDrawComp.source.getFeatures().length > 0) {
      var extent = this.mapDrawComp.source.getExtent()
      var coords = transform(getCenter(extent), 'EPSG:3857', 'EPSG:4326');

      if (typeof this.adresse_form.controls['adresse'].value == 'string') {
        var adresse_form: any = this.adresse_form.controls['adresse'].value
      } else {
        var adresse_form: any = this.adresse_form.controls['adresse'].value.properties.label
      }

      var latitude = coords[0]
      var longitude = coords[1]
      donne.geojson.geometry.coordinates = [latitude, longitude]
      donne.geojson.properties['commune'] = this.formulaire.controls['commune'].value
      donne.geojson.properties['cp'] = this.formulaire.controls['cp'].value
      donne.geojson.properties['adresse'] = adresse_form
    }



    if (donne.geojson.geometry.coordinates.length > 0 && donne.geojson.properties['adresse']) {

      var data = this.AjoutSiteComp.getNouveauValue()
      data.localisation = donne
      data.perimetre = this.mapDrawComp.source.getFeatures()
      this.AjoutSiteComp.setNouveau(data)
      this.navigate_to_principal()
    } else {
      this._snackBar.open("Aucune emprise dessinées", 'Fermer', {
        duration: 4000,
      });
    }

  }

}
