import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BackendApiService } from '../../../services/backend-api.service'
import { ConfigProjet, GeneralService } from '../../../services/general.service'
import { AuthService } from '../../../services/auth.service'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as $ from 'jquery';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}



@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {


  formulaire: FormGroup
  loading = false
  disabled = true


  edit_password = false
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  config_projet: ConfigProjet
  dictionnaire: any
  agences: Array<any> = []

 

  isKeyGroupCanSeeAllAgence(key:string):boolean{
    if (this.config_projet) {
      return this.config_projet.listGroupUserFilterAgence.indexOf(key) != -1
    }
  }

  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    private GeneralService: GeneralService,
    public AuthService: AuthService,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data

  ) {
    this.formulaire = fb.group({})

    this.GeneralService.config_projet.subscribe(value => {
      this.config_projet = value
      this.dictionnaire = this.config_projet.dictionaire.dictionnaire_user
    })
    

    this.myForm = fb.group({
      password_new: ['', [Validators.minLength(7), Validators.required]],
      confirmPassword: ['', [Validators.minLength(7), Validators.required]]
    }, { validator: this.checkPasswords });

  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password_new.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    for (let index = 0; index < this.dictionnaire.length; index++) {
      const element = this.dictionnaire[index];

      if (element['sous_type'] != 'id') {

        if (element['sous_type'] == 'email') {
          this.formulaire.addControl(element['champ'], new FormControl('', [Validators.email, Validators.required]))
        } else if (element['type'] == 'name') {
          this.formulaire.addControl(element['champ'], new FormControl('', [Validators.required]))
        }
        else {
          this.formulaire.addControl(element['champ'], new FormControl('', [Validators.required]))
        }
      }
    }


    this.formulaire.valueChanges.subscribe((data) => {
      this.check_formulaire()
    })

    this.myForm.valueChanges.subscribe((data) => {
      this.check_formulaire()
    })

    this.add_agence()
    $('.content_user_add').height(this.data['height'] - $('.mat-dialog-title').outerHeight() - 70)

  }

  toogle_new_passport() {
    this.edit_password = !this.edit_password
    this.check_formulaire()
  }

  check_formulaire() {
    var error1 = this.formulaire.valid
    var error2 = this.myForm.valid
    console.log(error1, error2)
    if (error1 && error2) {
      this.disabled = false
    } else {
      this.disabled = true
    }
  }

  getErrorMessage(sous_type) {
    if (sous_type == 'email') {
      return 'e-mail invalide'
    }
  }

  disable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: true });
      }
    }
  }

  enable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: false });
      }
    }
  }

  valider() {

    this.loading = true
    this.disabled = true

    this.disable_tous_les_form()

    var donne = Object.assign({},this.formulaire.value)
    donne.groupe_users_id = donne.groupe_users_id.groupe_users_id

    donne['password'] = this.myForm.controls.password_new.value
    donne['password_confirmation'] = this.myForm.controls.password_new.value
    donne['agence'] = []
    for (let index = 0; index < this.agences.length; index++) {
      const element = this.agences[index];
      donne['agence'].push(element['agence_id'])
    }
    var url = '/api/user/add'
    
    this.BackendApiService.post_requete(donne, url).then((response) => {

      this.loading = false
      this.disabled = false
      this.enable_tous_les_form()

      this._snackBar.open(response['message'], 'Fermer', {
        duration: 1000,
      });

      if (!response['erreur']) {

        this.dialogRef.close(true)
      }


    },
      (err) => {
        this.enable_tous_les_form()
        this.loading = false
        this.disabled = false
        this._snackBar.open(err.message, 'Fermer', {
          duration: 1000,
        });
      }
    )

  }

  agenceChoisi(agence, index) {
    this.agences[index] = agence
  }

  add_agence() {
    this.agences.push({
      'nom_agence': "",
      'code_agence': ""
    })
  }

}
