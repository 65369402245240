import { Component, OnInit } from '@angular/core';
import { agenceInterface } from 'src/app/type';
import { GeneralService } from '../../../services/general.service'
import { BackendApiService } from '../../../services/backend-api.service'
import { MatDialog, MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { donne_pour_modification } from '../../../modal/modification/modification.component'
import { donne_pour_ajout } from '../../../modal/ajout/ajout.component'
import { TranslateService } from '@ngx-translate/core';
/**
 * Gestion des agences
 */
@Component({
  selector: 'app-gestion-agence',
  templateUrl: './gestion-agence.component.html',
  styleUrls: ['./gestion-agence.component.scss']
})
export class GestionAgenceComponent implements OnInit {

  config_projet: any
  environment = environment
  loading: boolean = false
  agences: Array<agenceInterface>

  constructor(
    public GeneralService: GeneralService,
    public _dialog: MatDialog,
    private BackendApiService: BackendApiService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {
    this.GeneralService.config_projet.subscribe(value => {
      this.config_projet = value
    })
  }

  async ngOnInit() {
    await this.getAgences()
  }

  /**
   * recuperer les clients de la BD
   */
  async getAgences() {
    this.loading = true
    await this.BackendApiService.get_requete("/api/agences/all").then(
      (data: Array<agenceInterface>) => {
        this.agences = data
        this.loading = false
      },
      (err) => {
        this.loading = false
        this.agences = []
        this._snackBar.open(err.message, 'Fermer', {
          duration: 1000,
        });
      }
    )

  }
  
  /**
   * Modifier un agence 
   * @param agence agenceInterface 
   */
  ouvrir_fenetre_edition_agence(agence:agenceInterface){
    var titre = ''
    this.translate.get('administration', { value: 'agence' }).subscribe((res) => {
      titre = res['agence']['modifier_agence'] + agence.nom_agence
    });

    var donne:donne_pour_modification={
      dictionnaire:this.config_projet.dictionaire['dictionnaire_agence'],
      donne:agence,
      champ_id:['agence_id',agence.agence_id],
      titre:titre,
      url:'/api/agences/update'
    }
    this.GeneralService.ouvrir_fenetre_modification(donne,[],(result)=>{
      if (result) {
        this.getAgences()
      }
    })
  }


  /**
   * Ouvur le modal d'ajout de client'
   */
  ouvrir_fenetre_add_agence() {
    var titre = ''

    this.translate.get('administration', { value: 'agence' }).subscribe((res) => {
      titre = res['agence']['ajouter_agence']
    });

    var donne:donne_pour_ajout = {
      dictionnaire:this.config_projet.dictionaire['dictionnaire_agence'],
      titre:titre,
      url:'/api/agences/add'
    }

    this.GeneralService.ouvrir_fenetre_ajout(donne,[],(result)=>{
      if (result) {
        this.getAgences()
      }
    })
    
  }

}
