import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-general',
  templateUrl: './navigation-general.component.html',
  styleUrls: ['./navigation-general.component.scss']
})
export class NavigationGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
