import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, merge } from 'rxjs';
import {BackendApiService} from './backend-api.service'
import {ModificationComponent, donne_pour_modification} from '../modal/modification/modification.component'
import {AjoutComponent, donne_pour_ajout} from '../modal/ajout/ajout.component'
import {RechercheAdresseSiteComponent} from '../composant/recherche-adresse-site/recherche-adresse-site.component'
import { MatDialog } from '@angular/material';
import { dictionnaireInterface, elemCartoInterface, GroupUser } from '../type';
import * as $ from 'jquery'
import { tap } from 'rxjs/operators';
import { ModeCategorie } from '../models/mode_gestion';



export interface ConfigProjet{
  layers:{
    'point':Array<elemCartoInterface>,
    'linestring':Array<elemCartoInterface>,
    'polygon':Array<elemCartoInterface>,
  },
  mode_gestion:Array<ModeCategorie>
  icon:{
    default: {
      logo: string,
      icon_map: string,
      scale:number,
      scaleActive:number,
      nom_couche:string
    }
  },
  levels:{
    [key:string]:{
      maxzoom:number,
      minzoom:number,
      zindex:number
    }
  },
  dictionaire:{
    dictionnaire_client:Array<dictionnaireInterface>,
    dictionnaire_agence:Array<dictionnaireInterface>,
    dictionnaire_regroupement:Array<dictionnaireInterface>,
    dictionnaire_user:Array<dictionnaireInterface>,
    dictionnaire_appli:Array<dictionnaireInterface>,
  },
  /**
   * Durée d'attente après que la carte ai bougé pour aller recuperer les données dans le serveur
   */
  duration_after_map_moved:number
     /**
   * Liste des key groupes d'utilisateurs qui peuvent pas voir toutes les agences
   */
  listGroupUserFilterAgence:Array<string>
}

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  listGroupUser:Array<GroupUser>
  
  public config_projet:BehaviorSubject<ConfigProjet> = new BehaviorSubject({
    "layers": {
      'point':[],
      'linestring':[],
      'polygon':[],
     },
    "icon":{
      'default': {
        'logo': 'assets/images/logo/rounded_logo_espaces_verts.png',
        'icon_map': 'style_default',
        'scale':0.7,
        'scaleActive':0.5,
        'nom_couche':'sites'
      }
    },
    mode_gestion:[],
    "levels":{
      "sites":{
        "maxzoom":0,
        "minzoom":27,
        "zindex":3
      },
      "perimetres":{
        "maxzoom":16,
        "minzoom":27,
        "zindex":3
      },
      "elements_cartos":{
        "maxzoom":16,
        "minzoom":27,
        "zindex":3
      },
    },
    dictionaire:{
      "dictionnaire_client":[
        {
          'champ': 'nom_client',
          'sous_type': 'text',
          'type': 'text',
          'valeur': 'Nom',
          'required':true
        }
      ],
      "dictionnaire_agence":[
        {
          'champ': 'nom_agence',
          'sous_type': 'text',
          'type': 'text',
          'valeur': 'Nom',
          'required':true
        },
        {
          'champ': 'code_agence',
          'sous_type': 'text',
          'type': 'text',
          'valeur': 'Code Agence',
          'required':true
        },
        {
          'champ': 'interlocuteur',
          'sous_type': 'text',
          'type': 'text',
          'valeur': 'Interlocuteur',
          'required':true
        }
      ],
      "dictionnaire_regroupement":[
        {
          'champ': 'nom_regroup',
          'sous_type': 'text',
          'type': 'text',
          'valeur': 'Nom du regroupement',
          'required':true
        },
        {
          'champ': 'num_regroup',
          'sous_type': 'number',
          'type': 'number',
          'valeur': 'Numéro du regroupement',
          'required':true
        }
      ],
      "dictionnaire_user":[
        {
          'champ': 'name',
          'sous_type': 'text',
          'type': 'text',
          'valeur': 'Nom',
          'required':true
        },

        {
          'champ': 'email',
          'sous_type': 'email',
          'type': 'email',
          'valeur': 'E-mail',
          'required':true
        }
        ,
        {
          'champ': 'id',
          'sous_type': 'id',
          'type': 'text',
          'valeur': 'id',
        },
        {
          'champ': 'groupe_users_id',
          'sous_type': 'text',
          'type': 'list',
          'valeur': 'Statut',
          'list':[
           
          ],
          'required':true
        }
      ],
      "dictionnaire_appli":[]
    },
    listGroupUserFilterAgence:['dir_ter', 'resp_ag_char_aff', 'coord_prod', 'chef_equip'],
    "duration_after_map_moved":1000
  });
  public all_users = new BehaviorSubject([]);

  constructor(
    public BackendApiService:BackendApiService,
    public dialog: MatDialog,
  ) { }

  set_config_projet(nouveau_config_projet:ConfigProjet) {

    this.config_projet.next(nouveau_config_projet)
  }

  set_all_users(nouveau_all_users) {

    this.all_users.next(nouveau_all_users)
  }

  public get_all_users() {
    return this.all_users.getValue()
  }

  get_user_by_id(id_user) {
    var resultat;
    for (let index = 0; index < this.get_all_users().length; index++) {
      const element = this.get_all_users()[index];
      if (element.id == id_user) {
        resultat = element
      }

    }

    return resultat;
  }

  public get_config_projet():ConfigProjet {
    return this.config_projet.getValue()
  }

  roundFloat(number): Number {
    if (typeof number == "string") {
      return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100
    } else {
      return Math.round((number + Number.EPSILON) * 100) / 100
    }
  }

  /**
  * Différence entre deux tableaux
  * @param Array a1 premier tableau
  * @param Array a2 deuxième tableau
  * @return Array<any> les features present dans l'étendue passé en paramètres
  * @example console.log(arr_diff(['a', 'b'], ['a', 'b', 'c', 'd'])) => ["c", "d"]
  * @example console.log(arr_diff("abcd", "abcde")); => ["e"]
  */
  arrayDiff (a1, a2):Array<any> {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}

  async querry_config_projet(){
    await  forkJoin(
      from(this.BackendApiService.get_requete('/api/config')),
      from(this.BackendApiService.get_requete('/api/groupe-user/list')),
    ).pipe(
      tap((response)=>{
        let data = response[0]
        this.listGroupUser = response[1]['data']

        let config_projet:ConfigProjet = this.get_config_projet()
        config_projet.mode_gestion = data['mode_gestion']
        
        config_projet.layers = data['elem_carto']
        
        for (const key in data['dictionaire']) {
          if (data['dictionaire'].hasOwnProperty(key)) {
            const element = data['dictionaire'][key];
            config_projet.dictionaire.dictionnaire_appli[key] = element
          }
        }
        
        if (!config_projet['dictionaire']['dictionnaire_appli']['Informations Terideal']) {
          config_projet['dictionaire']['dictionnaire_appli']['Informations Terideal'] = []
        }

        if (!config_projet['dictionaire']['dictionnaire_appli']['Regroupement']) {
          config_projet['dictionaire']['dictionnaire_appli']['Regroupement'] = []
        }
        config_projet.dictionaire.dictionnaire_user
        .filter((dict)=>dict.champ==='groupe_users_id')
        .map((dict)=>dict.list = response[1]["data"])
        
        console.log(config_projet)

        this.config_projet.next(config_projet)
      })
    ).toPromise()
    
  }

  /**
   * Avoir les elements cartos en array
   */
  getElemCartoAsArray():Array<elemCartoInterface>{
    var elem_carto = []
    for (const key in this.config_projet.getValue().layers) {
      if (this.config_projet.getValue().layers.hasOwnProperty(key)) {
        const typologies:Array<elemCartoInterface> = this.config_projet.getValue().layers[key];
        for (let index = 0; index < typologies.length; index++) {
          const element = typologies[index];
          elem_carto.push(element)
        }
        
      }
    }
    return elem_carto
  }

  /**
   * Ouvir une fenetre d'edition attributaire
   * @param donne_pour_modification 
   * @param size 
   * @param cb 
   */
  ouvrir_fenetre_modification(donne_pour_modification:donne_pour_modification,size:Array<string>|[],cb:Function) {

    var proprietes = {
      disableClose: true,
      data:donne_pour_modification
    }
    if (size.length >0) {
      proprietes['width']=size[0]
      proprietes['height']=size[1]
    }
    // var size = [$(window).width() * 0.5 + 'px', $(window).height() * 0.7 + 'px']
    const fenetre_modification = this.dialog.open(ModificationComponent, proprietes);

    fenetre_modification.afterClosed().subscribe(async (result:any) => {
        cb(result)
    })

  }

   /**
   * Ouvir une fenetre d'edition attributaire
   * @param donne_pour_modification 
   * @param size 
   * @param cb 
   */
  ouvrir_fenetre_ajout(donne_pour_ajout:donne_pour_ajout,size:Array<string>|[],cb:Function) {

    // var size = [$(window).width() * 0.5 + 'px', $(window).height() * 0.7 + 'px']
    var proprietes = {
      disableClose: true,
      data:donne_pour_ajout
    }
    if (size.length >0) {
      proprietes['width']=size[0]
      proprietes['height']=size[1]
    }
    const fenetre_ajout = this.dialog.open(AjoutComponent, proprietes);

    fenetre_ajout.afterClosed().subscribe(async (result:any) => {
        cb(result)
    })

  }

  /**
   * Avoir le statut d'un user
   * @param id_statut string
   */

  getGroupUser(groupe_users_id):GroupUser{
    if (this.listGroupUser) {
        return this.listGroupUser.find((group)=>group.groupe_users_id === groupe_users_id)
    }
  }
  
  /**
   * Ouvrir la fenetre de recherche de site et adresse
   */
  openSearchSiteAndAdresse(cb:Function){
    // RechercheAdresseSiteComponent
    
    const searchSiteAdresse = this.dialog.open(RechercheAdresseSiteComponent, {
      width:$(window).width(),
      maxWidth:$(window).width(),
      hasBackdrop:true,
      disableClose:false,
      position:{
        top:'0px',
        left:'0px'
      },
      autoFocus:true,
      panelClass:"modal-recherche-adress-site"
    });

    searchSiteAdresse.afterClosed().subscribe(async (result:any) => {
        cb(result)
    })

  }
}
