import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regroupementInterface,siteInterface } from 'src/app/type';
import { GeneralService } from 'src/app/services/general.service';
import { SitesService } from 'src/app/services/sites.service';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-update-regroupement',
  templateUrl: './update-regroupement.component.html',
  styleUrls: ['./update-regroupement.component.scss']
})
export class UpdateRegroupementComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public onSubmitInstance:()=>void;

  config_projet: any
  showLoading : boolean = false
  environment = environment
  formulaire: FormGroup
  disabled=true
  filteredOptions: Observable<regroupementInterface[]>;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private sitesService: SitesService,
    public GeneralService: GeneralService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateRegroupementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: siteInterface,
    private cdRef:ChangeDetectorRef,
  ) {
    this.formulaire = this.fb.group({
      regroupement_id: [null, [Validators.required]]
    })

    this.GeneralService.config_projet.pipe(
      tap((value) => {
        this.config_projet = value
      }),
      takeUntil(this.destroyed$)
    ).subscribe()

    this.filteredOptions = this.formulaire.controls['regroupement_id'].valueChanges.pipe(
      filter(value=>typeof value === "string"),
      startWith(''),
      debounceTime(300),
      switchMap((value)=> {
        return this.sitesService.getRegroupements().pipe(
          map((regroupement)=> {
            const filterValue = value.toLowerCase();
            var result_nom = regroupement.filter(option => option.nom_regroup.toLowerCase().includes(filterValue))
            let result = result_nom.concat(regroupement.filter(option => option.num_regroup.toString().toLowerCase().includes(filterValue)))
            return result;
          }),
          catchError(()=> {
            this._snackBar.open('Une erreur est survenue lors du chargement des regroupements', 'Fermer', {
              duration: 1000,
            });
            return EMPTY
          }),
          shareReplay(1)
        )
      })
    )

    const onSubmit : Subject<void>=new Subject<void>()
    this.onSubmitInstance = () => {
      onSubmit.next()
    }

    onSubmit.pipe(
      filter(() => this.formulaire.valid),
      tap(()=>{
        this.showLoading = true;
        this.cdRef.detectChanges();
        this.formulaire.disable();
      }),
      takeUntil(this.destroyed$),
      switchMap(()=>{
        return this.sitesService.updateRegroupement({
          sites_id:this.data.sites_id,
          regroupement_id:this.formulaire.get('regroupement_id').value.regroupement_id,
        }).pipe(
          catchError(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this._snackBar.open('Un problème est survenu lors de la mise à jour de la localisation', 'Fermer', {
              duration: 4000,
            });
            return EMPTY;
          }),
          tap(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this.dialogRef.close(true)
          })
        )
      })
    ).subscribe()
   }

  ngOnInit() {
  }

  /**
   * Fermer le modal sans reponse
   */
   onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.formulaire.controls['regroupement_id'].setValue(this.data.regroupement, {emiEvent : false});
    this.cdRef.detectChanges();
  }

  ngOnDestroy(){
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  displayFn(regroupement:regroupementInterface) {
    if (regroupement instanceof Object) {
      try {
        return regroupement.nom_regroup+' - '+regroupement.num_regroup.toString();
      } catch (error) {
        return ''
      }
      
    } else {
      return regroupement
    }

  }

}
