import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentMaterialModule } from './material-module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MapGeneralService } from './services/map.general.service';
import { SitesService } from './services/sites.service';
import { GeneralService } from './services/general.service';
import { ElemCartoService } from './services/elem-carto/elem-carto.service'
import { MapComponent } from './map/map.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HesitateDirective } from './directive/hesitate.directive';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RechercheAdresseComponent } from '../app/composant/recherche-adresse/recherche-adresse.component'
import { StockageService } from './services/stockage.service';
import { NavigationGeneralComponent } from './composant/navigation-general/navigation-general.component'
import { ModificationUserComponent } from './modal/gestion-user/modification-user/modification-user.component';
import { AddUserComponent } from './modal/gestion-user/add-user/add-user.component';
import { GestionUserComponent } from './modal/gestion-user/gestion-user.component'
import { SupprimerComponent } from './modal/supprimer/supprimer.component';
import { EditRightsComponent } from './modal/gestion-user/edit-rights/edit-rights.component';
import { ModificationComponent } from './modal/modification/modification.component';
import { AjoutComponent } from './modal/ajout/ajout.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ColorPickerModule } from 'ngx-color-picker';
import { NouisliderModule } from 'ng2-nouislider';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { AuthGuard } from './guards/auth.guard';
import { IsAuthGuard } from './guards/isauth.guard';
import { RechercherAgenceComponent } from './composant/rechercher-agence/rechercher-agence.component';
import { EditionGeographiqueComponent } from './modal/edition-geographique/edition-geographique.component';

import {randomColors} from '../app/utils/randomColors';
import { NotifierModule } from "angular-notifier";
import { EditElementCartographiqueComponent } from './modal/gestion-user/edit-element-cartographique/edit-element-cartographique.component';
import { GestionElementCartographiqueComponent } from './composant/administration/gestion-element-cartographique/gestion-element-cartographique.component';
import { GestionClientComponent } from './composant/administration/gestion-client/gestion-client.component';
import { GestionAgenceComponent } from './composant/administration/gestion-agence/gestion-agence.component';
import { ModalComponent } from './modal/modal.component';
import { GestionRegroupementComponent } from './composant/administration/gestion-regroupement/gestion-regroupement.component';
import { FiltreSitesComponent } from './composant/filtre-sites/filtre-sites.component';
import { AutocompleteComponent } from './composant/helper/autocomplete/autocomplete.component';
import {AjoutSiteComponent} from './modal/ajout-site/ajout-site.component'
import {LocalisationAjoutComponent} from './modal/ajout-site/localisation-ajout/localisation-ajout.component'
import {InfosGeneralComponent} from './modal/ajout-site/infos-general/infos-general.component';
import { DrawComponent } from './modal/ajout-site/draw/draw.component';
import { ListSitesComponent } from './bottom-sheet/list-sites/list-sites.component';
import { ProfilComponent } from './profil/profil.component';
import { RechercheAdresseSiteComponent } from './composant/recherche-adresse-site/recherche-adresse-site.component'
import { DescriptionSiteModule } from './modules/description-site/description-site.module';
import { DigitaliserModule } from './digitaliser/digitaliser.module';
import { UserProfileComponent } from './modal/gestion-user/user-profile/user-profile.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    LoginComponent,
    HesitateDirective,
    RechercheAdresseComponent,
    NavigationGeneralComponent,
    ModificationUserComponent,
    GestionUserComponent,
    SupprimerComponent,
    EditRightsComponent,
    AddUserComponent,
    RechercherAgenceComponent,
    ModificationComponent,
    AjoutComponent,
    EditionGeographiqueComponent,
    EditElementCartographiqueComponent,
    GestionElementCartographiqueComponent,
    GestionClientComponent,
    GestionAgenceComponent,
    ModalComponent,
    GestionRegroupementComponent,
    FiltreSitesComponent,
    AutocompleteComponent,
    AjoutSiteComponent,
    LocalisationAjoutComponent,
    InfosGeneralComponent,
    DrawComponent,
    ListSitesComponent,
    ProfilComponent,
    RechercheAdresseSiteComponent,
    UserProfileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentMaterialModule,
    DigitaliserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NotifierModule,
    ColorPickerModule,
    AngularResizedEventModule,
    NouisliderModule,
    DescriptionSiteModule,
    SharedModule 
  ],
  providers: [
    MapGeneralService,
    SitesService,
    GeneralService,
    ElemCartoService,
    StockageService,
    { provide: LOCALE_ID, useValue: "fr-CA" },
    AuthGuard, IsAuthGuard,randomColors
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModificationUserComponent,
    GestionUserComponent,
    SupprimerComponent,
    EditRightsComponent,
    AddUserComponent,
    ModificationComponent,
    AjoutComponent,
    EditionGeographiqueComponent,
    EditElementCartographiqueComponent,
    AjoutSiteComponent,
    ListSitesComponent,
    RechercheAdresseSiteComponent,
    UserProfileComponent
  ]
})
export class AppModule { }
