import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService,User } from '../services/auth.service';
import { BackendApiService } from '../services/backend-api.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
      private AuthService: AuthService, 
      private BackendApiService: BackendApiService,
      private router: Router
    ) {}

  //   canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //     //debugger
  //     if(this.AuthService.isLoggedIn()) {
  //       return true 
        
  //     }else{
  //       this.router.navigate(['login']) 
  //     }

      
  // }
  
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      
    
      if(this.AuthService.isLoggedIn()) {
        return new Promise((resolve) => {
          this.BackendApiService.get_user()
            .then(
              (user:User) => {
                // console.log(user,0)
                
              this.AuthService.set_user(user)
              resolve(true);
              },
              (err)=>{
                this.AuthService.cleanLocalstorage()
                this.router.navigate(['login']) 
                resolve(false);
              }
            )
            .catch(err => {
              
              this.AuthService.logout()
              this.router.navigate(['login']) 
              
            });
          })
      }else{
        this.router.navigate(['login']) 
      }
      
  }
}