import { Component, ViewChild, OnInit, AfterViewChecked, Input, EventEmitter, AfterViewInit, NgZone, Injectable, ElementRef } from '@angular/core';
import { MapComponentParent, elmCartoOverlaysForMap, dataClickOnMap, FeatureWMSInfo } from './map.component.parent'
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendApiService } from '../services/backend-api.service'
import { MapGeneralService } from '../services/map.general.service'
import { SitesService } from '../services/sites.service'
import { SitesCartoService } from '../services/sites-carto.service'
import { GeneralService } from '../services/general.service'
import { StockageService } from '../services/stockage.service'
import { FiltreSitesComponent } from '../composant/filtre-sites/filtre-sites.component'
import { AuthService, User } from '../services/auth.service';
import { AjoutSiteComponent, donne_pour_ajout_entiteInterface } from 'src/app/modal/ajout-site/ajout-site.component'
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import {
  fromLonLat,
  VectorSource,
  GeoJSON,
  bboxStrategy,
  Cluster,
  VectorLayer,
  Overlay,
  unByKey,
  Point,
  Polygon,
  MultiPolygon,
}
  from '../ol-module'

import { MatBottomSheet, MatTableDataSource, MatPaginator, MatSidenavContainer } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, from, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { AdresseFeatureResponse, siteInterface } from '../type';
import OverlayPositioning from 'ol/OverlayPositioning';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { MapBrowserEvent } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { FeatureCacheItem } from 'ol/renderer/webgl/PointsLayer';
import { getPointOnFeature } from '../helpers/geo';
import BaseVectorLayer from 'ol/layer/BaseVector';



@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent extends MapComponentParent implements OnInit {
  public onInitInstance: () => void
  /**
   * identifier un élément de la carte au click
   */
  public onGetPropertiesInstance: (data: dataClickOnMap) => void
  /**
   * ouvrir le popup d'un élément de la carte
   */
  public openFeaturePropertiesPopup: (feature:FeatureWMSInfo) => void
  /**
   * Fermer le popup d'un élément carto
   */
   public closeElemCarto :()=>void

  /**
   * children Component des filtres
   */
  @ViewChild(FiltreSitesComponent) FiltreComp: FiltreSitesComponent

  /**
* La sidenav
*/
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  @ViewChild('elemsCartoPopup') elemsCartoPopup : ElementRef<HTMLElement>


  elmCartoOverlays: elmCartoOverlaysForMap = {} as elmCartoOverlaysForMap

  num_chant_not_display = []
  LayersImagesVisible: boolean = true
  siteEnCours: siteInterface
  user: User = this.AuthService.get_user()

  constructor(
    public _ngZone: NgZone,
    public BackendApiService: BackendApiService,
    public MapGeneralService: MapGeneralService,
    public GeneralService: GeneralService,
    public AuthService: AuthService,
    public SitesService: SitesService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public _bottomSheet: MatBottomSheet,
    public SitesCartoService: SitesCartoService,
    public translate: TranslateService,
    public StockageService: StockageService,
    public router: Router,
    private location: Location
  ) {
    super(_ngZone, BackendApiService, MapGeneralService, GeneralService, AuthService, SitesService, dialog, _snackBar, fb, activatedRoute, _bottomSheet, SitesCartoService, translate, StockageService);
    this.initialise_interface()
    this.config_projet = this.GeneralService.get_config_projet()
    translate.setDefaultLang('fr');
    translate.use('fr');


    this.mouseMoveOnMap()


    this.SitesCartoService.initiliseLayerElemGeoSurbrillance()
    this.SitesCartoService.initialise_layer_cercle_flash()

    const onGetProperties:Subject<dataClickOnMap> = new Subject<dataClickOnMap>()
    this.onGetPropertiesInstance = (data: dataClickOnMap)=>{
      onGetProperties.next(data)
    }

    const onInit: Subject<void> = new ReplaySubject<void>(1)
    this.onInitInstance = () => {
      onInit.next()
    }

    onInit.pipe(
      switchMap(() => {
        return from(this.GeneralService.querry_config_projet())
      }),
      tap(() => {
        this.SitesCartoService.initialiserLayersImages()
        this.SitesCartoService.initaliseLegende()
        this.SitesCartoService.initialiser_layers_entreprise((layer) => {
          this.gestion_carto(layer)

          this.StockageService.sites.subscribe((data) => {

            this.updateDataInListByExtent()
          })

          this.mapMoved()
          this.handleMapParamsUrl()
        })
        this.SitesCartoService.initialise_icon_sous_type()



        this.mapBboxChangedAfterduration(this.config_projet.duration_after_map_moved)

        this.fake_source = new VectorSource({
          format: new GeoJSON(),
          loader: (extent, resolution, projection) => {
            this.debouncer.next(4)
          },
          strategy: bboxStrategy
        });

        var clusterSource = new Cluster({
          distance: 80000000,
          source: this.fake_source
        });

        var vector = new VectorLayer({
          source: clusterSource,
          style: null,
          // maxResolution: this.map.getView().getResolutionForZoom(8),
        });
        vector.setZIndex(-9999)
        vector.set('nom', 'fake_source')
        this.map.addLayer(vector)
      })
    ).subscribe()

    onGetProperties.pipe(
      switchMap((data)=>{
        this.closeElemCarto()
        let parametersFeatureInfo = this.SitesCartoService.getParametersForFeatureInfoWMS(data.data.layers, data.data.coord, this.map)
        this.elemsCartoPopup.nativeElement.style.display = 'block'
        this.elmCartoOverlays.overlayActive.overlay.setPosition(data.data.coord)
        this.SitesCartoService.pan_to(data.data.coord, this.map.getView().getZoom(), this.map)
        this.elmCartoOverlays.loading = true
        return from(this.BackendApiService.post_requete<FeatureWMSInfo[]>({ parameters: parametersFeatureInfo }, '/api/elements/getFeatureInfo')).pipe(
          catchError(()=>{
            this.elmCartoOverlays.loading = false
            return EMPTY
          }),
        )
      }),
      tap((response)=>{
        this.elmCartoOverlays.loading = false
        if (response.length == 0) {
          this.closeElemCarto()
        }
      }),
      filter((response)=>response.length >0),
      tap((response)=>{
        this.elmCartoOverlays.data = {
          polygon:response.filter((feature)=> this.GeneralService.get_config_projet().layers.polygon.find((elemCarto) => elemCarto.elem_carto_id == feature.properties.elem_carto_id) != undefined ),
          point : response.filter((feature)=> this.GeneralService.get_config_projet().layers.linestring.find((elemCarto) => elemCarto.elem_carto_id == feature.properties.elem_carto_id) != undefined ),
          linestring:response.filter((feature)=> this.GeneralService.get_config_projet().layers.linestring.find((elemCarto) => elemCarto.elem_carto_id == feature.properties.elem_carto_id) != undefined )
        }
      }),
      tap((response)=>{
        let layer = this.SitesCartoService.getLayerByAttr<VectorLayer>('elem_geo_surbrillance', 'nom', true)[0]
        layer.getSource().clear()
        response.map((feat)=>{
          layer.getSource().addFeatures(new GeoJSON().readFeatures(feat))
        })
        
      }),
      map((response)=>{

        if (this.elmCartoOverlays.data.point.length == 1) {
          return this.elmCartoOverlays.data.point[0]
        }else if (response.length == 1) {
          return response[0]
        }else if (response.length > 1) {
          this.elmCartoOverlays.chooseActive = true
          this.elmCartoOverlays.overlayActive.active = false
          return
        } else {
          this.elmCartoOverlays.loading = false
          this.closeElemCarto()
          return 
        }
      }),
      filter((feature)=>feature != undefined),
      tap((feature)=>{
        this.openFeaturePropertiesPopup(feature)
      })
    ).subscribe()

    this.openFeaturePropertiesPopup = (feature:FeatureWMSInfo)=>{
      this.elmCartoOverlays.overlayActive.active = true
      this.elmCartoOverlays.chooseActive = false
      this.elmCartoOverlays.overlayActive.data =feature
      this.elmCartoOverlays.overlayActive.overlay
      
      let coordinate:Coordinate = getPointOnFeature(new GeoJSON().readFeature(feature.geometry).getGeometry()).getCoordinates()
     
      // this.elmCartoOverlays.overlayActive.overlay.setPosition(coordinate)
      // this.SitesCartoService.pan_to(coordinate, this.map.getView().getZoom())

    }

  this.closeElemCarto = ()=> {
    let layer = this.SitesCartoService.getLayerByAttr<VectorLayer>('elem_geo_surbrillance', 'nom', true)[0]
    layer.getSource().clear()
    this.elemsCartoPopup.nativeElement.style.display = 'none'
    this.elmCartoOverlays.overlayActive.overlay.setPosition(undefined)
  }

    fromOpenlayerEvent<MapBrowserEvent<PointerEvent>>(this.map,'singleclick').pipe(
      map((evt)=>{
        return this.SitesCartoService.mapHasCliked(evt)
      }),
      filter((data)=>data != undefined),
      tap((data)=>{
        if (data.type== 'featureInfoWms') {
          this.onGetPropertiesInstance(data)
        } else if (data.type== 'clear') {
          this.closeElemCarto()
          if (this.screenHelper.getPlatform() == 'dekstop') {
            this.openListSite()
          }

        } else if (data.type== 'clear_elem_geo_surbrillance') {
          this.closeElemCarto()
        } else if (data.type == "feature_clicked") {
          this.openPteSite(data.data.data[environment.id_bd_qgis_server], false)
        } else if (data.type == "active_icon_clicked") {
          this.openPteSite(data.data.data[environment.id_bd_qgis_server], true)
        }
      })
    ).subscribe()

    fromOpenlayerEvent<MapBrowserEvent<PointerEvent>>(this.map,'dblclick').pipe(
      map((evt)=>{
        return this.SitesCartoService.mapHasCliked(evt)
      }),
      filter((data)=>data != undefined),
      tap((data)=>{
        if (data.type == "feature_clicked" || data.type == 'active_icon_clicked') {
          this.openPteSite(data.data.data[environment.id_bd_qgis_server], true)
        }
      })
    ).subscribe()

  }

  ngAfterViewInit() {
    this.handleResponsiveDesign(this.sidenavContainer)

  }

  ngOnInit() {
    this.map.setTarget('map')
    this.inialise_imagettes()
    this.initialiseGeneralMapTools()
    this.changer_url_imagette()
    this.initialise_overlay_adresse()
    this.initialiserOvelayElemGeo()
    this.mesureComp.setMapInstance(this.map)
    this.mesureComp.initialise_mesure()

    this.onInitInstance()
  }

  /**
   * definir l'overlay de à activer
   * @param  string typologie  'polygon'|'point'|'linestring'
   * @param number index 
   */
  // setOverlayElemGeoActive(typologie: string, index: number) {
  //   this.elmCartoOverlays.overlayActive.active = true
  //   this.elmCartoOverlays.chooseActive = false
  //   this.elmCartoOverlays.overlayActive.data = this.elmCartoOverlays.data[typologie][index]
  //   var id_plus_topologie = this.elmCartoOverlays.overlayActive.data['geom']['properties']['id'] + '_' + this.elmCartoOverlays.overlayActive.data['geom']['properties']['typologie']
  //   this.lightFeature(id_plus_topologie)
  // }


  /**
    * Survol de la souris sur un site dans la liste de gauche
    * @param any site
    */
  mouse_on_entreprise_liste(site: any) {

    var layer = this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)

    if (layer.length > 0) {

      site.hover = true
      var source = layer[0].getSource().getSource()

      var feature = this.SitesCartoService.get_feature_in_source_by_attr(source, environment.id_bd_qgis_server, site[environment.id_bd_qgis_server])
      var feature_in_cluster = this.SitesCartoService.feature_in_clusters(layer[0].getSource(), environment.id_bd_qgis_server, site[environment.id_bd_qgis_server])

      if (feature.length > 0) {
        var raduis = 30
        if (feature_in_cluster) {
          raduis = 15
        }
        this.SitesCartoService.active_cercle_flash(feature[0], raduis)
        this.SitesCartoService.activateHoverEmprise(site[environment.id_bd_qgis_server])
      }
    }

  }

  /**
   * Souris ne arrete de survoler une entreprise dans la liste de gauche
   * @param any site
   * @param number duration la durée avant d'appliquer le traitement
   */
  mouse_out_entreprise_liste(site: any, duration: number) {
    setTimeout(() => {
      site.hover = false
      var layer = this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('cercle_flash', 'nom', true)
      this.SitesCartoService.desactivateHoverEmprise()
      if (layer.length > 0) {
        layer[0].getSource().clear()
      }
    }, duration)

  }

  /**
  * initialiser et gestion des évènements du survol de la souris sur la carte.
  */
  mouseMoveOnMap() {
    var target = this.map.getTarget();
    var jTarget = typeof target === "string" ? $("#" + target) : $(target);
    $(this.map.getViewport()).on('mousemove', (evt) => {
      this.SitesCartoService.mouseHasMoveOnMap(evt)
    })

  }


  /**
   * initialiser et gère tous les évènements lorsque la carte bouge
   */

  mapMoved() {
    fromOpenlayerEvent(this.map,'moveend' ).pipe(
      tap(()=>{
        this.changer_url_imagette()
        this.updateDataInListByExtent()
        this.SitesCartoService.mapMoved(this.map)
      })
    ).subscribe()
   
  }

  /**
     * initialiser les overlays des elements geo
     */
  initialiserOvelayElemGeo() {
    this.elmCartoOverlays.overlayActive = {
      'overlay': undefined,
      'text': undefined,
      'data': undefined,
      'active': false,
      'id': 'elemsCarto'
    }
    this.elmCartoOverlays.chooseActive = false
    this.elmCartoOverlays.overlayActive.overlay = new Overlay({
      element: document.getElementById(this.elmCartoOverlays.overlayActive.id),
      positioning: OverlayPositioning.TOP_CENTER
    });
    this.map.addOverlay(this.elmCartoOverlays.overlayActive.overlay);
  }

  

  chooseElemFun(feature: FeatureWMSInfo) {
    this.openFeaturePropertiesPopup(feature)
    
  }

  /**
   * Afficher / enlever les vecteurs des elements cartos sur la cartes
   */
  toogleLayersImagesVisible() {
    this.LayersImagesVisible = !this.LayersImagesVisible
    var layers = this.SitesCartoService.getLayerByAttr<VectorLayer>('composants', 'type_layer', true)

    for (let index = 0; index < layers.length; index++) {
      var layer = layers[index];
      layer.setVisible(this.LayersImagesVisible)
    }
  }

  /**
   * Ouvrir les propriétés d'un site
   * @param number sites_id
   * @param boolean pan si il faut zoomer sur le site. Par défaut = true 
   */
  openPteSite(sites_id: number, pan = true) {
    this.SitesService.desactivateAllSite()

    var site = this.StockageService.getSIte(sites_id)
    if (site && this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true).length > 0) {
      site['active'] = true
      site['display'] = true

      var layer = this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)[0]
      var source = layer.getSource().getSource()

      this.SitesCartoService.set_all_features_pte_in_source_by_attr(source, 'display', true)
      this.SitesCartoService.set_feature_pte_in_source_by_attr(source, environment.id_bd_qgis_server, sites_id, 'display', false)
      this.SitesCartoService.toogleActiveEmpriseAuto()

      this.siteEnCours = site

      var featureEnCours = this.SitesCartoService.get_feature_in_source_by_attr(source, environment.id_bd_qgis_server, sites_id)[0]
      var Pointgeometry = featureEnCours.getGeometry()
      var geojsonFeature = JSON.parse(this.SitesCartoService.convertFeatureToGeoJson(featureEnCours.clone()))
      geojsonFeature['properties']['layer'] = 'sites'
      geojsonFeature['properties']['display'] = 'true'
      geojsonFeature['properties']['active'] = 'true'
      this.SitesCartoService.active_popup_active_icon(geojsonFeature)

      this.open_window_pte_entite()
      this.updateDataInListByExtent()

      setTimeout(() => {
        if (pan) {
          this.SitesCartoService.fit_view(Pointgeometry, 17, [0, 0, 0, this.grid_menu.sidenavStart.width])
        } else {
          this.SitesCartoService.fit_view(Pointgeometry, this.map.getView().getZoom(), [0, 0, 0, this.grid_menu.sidenavStart.width])
        }
      }, 300);




    }

  }

  /**
   * reset les filtres
   */
  resetFiltre() {
    this.FiltreComp.reset()
  }

  /**
   * close Filter component
   */
  closeFilterSite() {
    this.close_all_window()
    if (this.screenHelper.getPlatform() == 'dekstop') {
      this.openListSite()
    }
  }

  /**
   * Fermer les propriétés d'un site
   */
  closePteSite() {
    this.desactivateAllSite()
    this.close_window_pte_entite()
    this.closeSidenav()
    this.location.replaceState('/sites');
  }

  /**
   * Fermer les propriétés d'un site
   */
  openListSite() {
    this.desactivateAllSite()
    this.close_window_pte_entite()
    this.open_list_site()
    this.location.replaceState('/sites');
  }

  /**
   * desactivate all site that are active
   */
  desactivateAllSite() {
    if (this.siteEnCours) {
      this.SitesService.desactivateAllSite()

      var layer = this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)[0]
      var source = layer.getSource().getSource()
      this.SitesCartoService.set_all_features_pte_in_source_by_attr(source, 'display', true)
      this.SitesCartoService.toogleActiveEmpriseAuto()
      this.SitesCartoService.desactive_popup_active_icon()
      this.siteEnCours = undefined
    }
  }

  /**
 * Ouvrir une fenetre d'ajout de site
 * @param data  donne_pour_ajout_entiteInterface
 * @param size 
 * @param cb 
 */
  ouvrir_fenetre_ajout_site(data: donne_pour_ajout_entiteInterface, size: Array<string>, cb: Function) {

    // var size = [$(window).width() * 0.5 + 'px', $(window).height() * 0.7 + 'px']
    const fenetre_modification_localisation = this.dialog.open(AjoutSiteComponent, {
      width: size[0],
      height: size[1],
      maxWidth: size[0],
      maxHeight: size[1],
      disableClose: true,
      data: data
    });

    fenetre_modification_localisation.afterClosed().subscribe(async (result: any) => {
      cb(result)
    })

  }

  /**
   * Ajouter un site
   */
  addSite() {
    var data: donne_pour_ajout_entiteInterface = {
      titre: 'Ajouter un site',
      url: 'api/sites/add',
      attention: {
        localisation: {
          active: true,
          urlToDetect: '/api/wfs?&layers=sites',
          fieldGeosjon: 'sites_id'
        },
        doublonsName: [
          {
            active: true,
            field: 'num_chant_sites',
            urlToDetect: '/api/sites/getByNumChantier',
            fieldToSearch: 'num_chant_sites',
            nom: 'les numéros de chantiers',
            fieldDB: 'sites_id',
            passive: false
          }
        ]
      },
      dictionnaire: {
        infosGeneral: [
          {
            champ: 'num_chant_sites',
            type: 'number',
            sous_type: 'text',
            valeur: 'Numéro de chantier',
          },
          {
            champ: 'nom_site',
            type: 'text',
            sous_type: 'text',
            valeur: 'Nom du site',
          },
          {
            champ: 'region',
            type: 'text',
            sous_type: 'list',
            valeur: 'Région ',
            urlToFectchSelectData: '/api/sites/regions',
            filteredOptions: new Observable(),
            fieldDisplay: ['region'],
            fieldDB: 'region'
          },
          {
            champ: 'client_id_sites',
            type: 'text',
            sous_type: 'list',
            valeur: 'Client',
            urlToFectchSelectData: '/api/clients/all',
            filteredOptions: new Observable(),
            fieldDisplay: ['nom_client'],
            fieldDB: 'client_id'
          },
          {
            champ: 'agence_id_sites',
            type: 'text',
            sous_type: 'list',
            valeur: 'Agence',
            urlToFectchSelectData: '/api/agences/all',
            filteredOptions: new Observable(),
            fieldDisplay: ['nom_agence', 'interlocuteur'],
            fieldDB: 'agence_id'
          },
          {
            champ: 'regroupement_id_sites',
            type: 'text',
            sous_type: 'list',
            valeur: 'Regroupement',
            urlToFectchSelectData: '/api/regroupements/all',
            filteredOptions: new Observable(),
            fieldDisplay: ['nom_regroup', 'num_regroup'],
            fieldDB: 'regroupement_id'
          },
          {
            champ: 'activite',
            type: 'text',
            sous_type: 'text',
            valeur: 'Activité',
          },
          {
            champ: 'depot',
            type: 'text',
            sous_type: 'text',
            valeur: 'Depot',
          }
        ]
      }
    }
    var size = [$(window).width() * 0.95 + 'px', $(window).height() * 0.95 + 'px']

    this.ouvrir_fenetre_ajout_site(data, size, (result: number) => {
      this.openPteSite(result)
    })
  }

  /**
   * Handle parameters of the app when opening with route /map
   */
  handleMapParamsUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id']) {
        var site = this.StockageService.getSIte(params['id'])
        if (site) {
          this.openPteSite(site.sites_id)
        } else {
          this.SitesService.loadDataFromServer([parseInt(params['id'])]).then(
            () => {
              setTimeout(() => {
                var site = this.StockageService.getSIte(params['id'])
                if (site) {
                  this.openPteSite(site.sites_id)
                }
              }, 500);
            }
          )
        }
      }
    })
  }

  /**
   * open search adress and site
   */
  openSearchSiteAndAdresse() {
    this.GeneralService.openSearchSiteAndAdresse((value: { type: 'adresse' | 'site', site: siteInterface, adresse: AdresseFeatureResponse }) => {
      if (value) {
        if (value.type == 'site') {
          var site = this.StockageService.getSIte(value.site[environment.id_bd_qgis_server])
          if (site) {
            this.openPteSite(value.site[environment.id_bd_qgis_server])
          } else {
            this.SitesService.charger_les_donnees([value.site])
            setTimeout(() => {
              this.openPteSite(value.site[environment.id_bd_qgis_server])
            }, 500)

          }
        } else if (value.type == 'adresse') {
          this.display_marker_adresse_change(value.adresse)
        }
      }
    })
  }
}
export function fromOpenlayerEvent<T>(element: any, eventName: string): Observable<T> {

  return new Observable((observer: Subscriber<T>) => {
    const handler = (e: T) => observer.next(e);

    let eventKey = element.on(eventName, handler)

    return () => {
      unByKey(eventKey)
    }
  });
}