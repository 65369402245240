import { Component, OnInit, Inject, ViewChildren, QueryList, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { siteInterface, reponseDB, elemCartoInterface } from 'src/app/type';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BackendApiService } from 'src/app/services/backend-api.service'
import { SitesCartoService } from 'src/app/services/sites-carto.service'
import { GeneralService } from 'src/app/services/general.service'
import { NotifierService } from 'angular-notifier';
import { NouisliderComponent } from 'ng2-nouislider';
import { catchError, debounceTime, take, tap, map as _map, filter, switchMap, takeUntil } from 'rxjs/operators';
import { EMPTY, from, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs'
import { 
  Attribution, 
  View, 
  fromLonLat, 
  defaultControls, 
  Map,
  ImageWMS, 
  ImageLayer, 
  TileLayer, 
  VectorLayer, 
  GeoJSON, 
  Overlay, 
  unByKey } from 'src/app/ol-module';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery'
import TileJSON from 'ol/source/TileJSON'
import { AjoutComponent } from 'src/app/modal/ajout/ajout.component';
import { FeaturePopup } from 'src/app/digitaliser/class/featurePopup';
import { dataClickOnMap, elmCartoOverlaysForMap, FeatureWMSInfo } from 'src/app/map/map.component.parent';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Coordinate } from 'ol/coordinate';
import { getPointOnFeature } from 'src/app/helpers/geo';
import OverlayPositioning from 'ol/OverlayPositioning';
import { MapGeneralService } from 'src/app/services/map.general.service';

/**
 * Model d'un historique à une date précise
 */
export interface modelHistoriqueAtDate {
  /**
   * date de l'historique
   */
  date: string
  composant?: {
    perimetre: Array<{ id: number; id_origine: number; elem_carto_id: number }>
    linestring: Array<{ id: number; id_origine: number; elem_carto_id: number }>
    point: Array<{ id: number; id_origine: number; elem_carto_id: number }>
    polygon: Array<{ id: number; id_origine: number; elem_carto_id: number }>
    sites: Array<{ id: number; id_origine: number }>
  }
}

const weekdays = [
  "Dimanche", "Lundi", "Mardi",
  "Mercredi", "Jeudi", "Vendredi",
  "Samedi"
];

const months = [
  "Janvier", "Février", "Mars",
  "Avril", "Mai", "Juin", "Juillet",
  "Août", "Septembre", "Octobre",
  "Novembre", "Decembre"
];

/**
 * Atrribution dans la carte
 */
var attribution = new Attribution({
  collapsible: false
});

/**
 * Vue de la map
 */
var view = new View({
  center: fromLonLat([5, 45]),
  zoom: 17,
  minZoom: 6,
  constrainResolution: true,
})

/**
 * Objet map
 */
const map = new Map({
  target: 'mapHistorique',
  maxTilesLoading: 50,
  view: view,
  controls: defaultControls({ attribution: false, zoom: false }).extend([attribution]),
});

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements OnInit {
  /**
 * identifier un élément de la carte au click
 */
  public onGetPropertiesInstance: (data: dataClickOnMap) => void
  /**
  * ouvrir le popup d'un élément de la carte
  */
  public openFeaturePropertiesPopup: (feature:FeatureWMSInfo) => void
  /**
  * Fermer le popup d'un élément carto
  */
  public closeElemCarto :()=>void
  
  @ViewChildren('sliderHistorique') sliderHistorique: QueryList<NouisliderComponent>;

  elmCartoOverlays: elmCartoOverlaysForMap = {} as elmCartoOverlaysForMap
  /**
   * Gestionnaire des chargements dans le modal historique
   */
  loading = {
    /** loading jauge */
    jauge: false,
    /**loading l'etat d'un site à une date */
    etatSite: false
  }

  /**
   * historique du site
   */
  historiqueSite: {
    /**
     * toutes les dates de modifications
     */
    data: Array<modelHistoriqueAtDate>,

    /**recuperer un site à une date dans ce model */
    get(date: string): modelHistoriqueAtDate
  } = {
      data: [],
      get: function (date: string): modelHistoriqueAtDate {
        for (let index = 0; index < this.data.length; index++) {
          if (this.data[index].date == date) {
            return this.data[index]
          }

        }
      }
    }

  /**
   * Configuration du slider
   */
  sliderHistoriqueConfig = {
    keyboard: true,
    snap: true,
    range: undefined,
    start: undefined,
    pips: {
      mode: 'steps',
      filter: (value, type) => {
        var allValues = []
        for (const key in this.sliderHistoriqueConfig.range) {
          if (Object.prototype.hasOwnProperty.call(this.sliderHistoriqueConfig.range, key)) {
            const element = this.sliderHistoriqueConfig.range[key][0];
            allValues.push(element)
          }
        }
        if (allValues.indexOf(value) != -1) {
          return 1
        } else {
          return -1
        }
      },
      format: {
        to: (value) => {
          return this.formatDate(new Date(value));
        },
        from: (value) => {
          return this.parseDate(value);
        }
      }
    },
    format: {
      to: (value) => {
        return this.formatDateComplete(new Date(value));
      },
      from: (value) => {
        return this.parseDateComplete(value);
      }
    }
  }

  /**
  * Form d'interaction du slider
  */
  formulaire: FormGroup = this.fb.group({})

  /**
   * Gestionnaire de notifications
   */
  private readonly notifier: NotifierService;

  @ViewChild('elemsCartoPopupHistoric') elemsCartoPopup : ElementRef<HTMLElement>

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public dialogRef: MatDialogRef<AjoutComponent>,
    public SitesCartoService: SitesCartoService,
    public GeneralService: GeneralService,
    notifierService: NotifierService,
    public mapGeneralService : MapGeneralService,
    @Inject(MAT_DIALOG_DATA) public data: {
      site: siteInterface,
      /**Coordinates to zoom map */
      coord: [number, number]
    }
  ) {
    this.notifier = notifierService;

    const onGetProperties:Subject<dataClickOnMap> = new Subject<dataClickOnMap>()
    this.onGetPropertiesInstance = (data: dataClickOnMap)=>{
      onGetProperties.next(data)
    }
    onGetProperties.pipe(
      takeUntil(this.destroyed$),
      switchMap((data)=>{
        this.closeElemCarto()
        let parametersFeatureInfo = this.SitesCartoService.getParametersForFeatureInfoWMS(data.data.layers, data.data.coord, map)
        this.elemsCartoPopup.nativeElement.style.display = 'block'
        this.elmCartoOverlays.overlayActive.overlay.setPosition(data.data.coord)
        this.SitesCartoService.pan_to(data.data.coord, map.getView().getZoom(), map)
        this.elmCartoOverlays.loading = true
        return from(this.BackendApiService.post_requete<FeatureWMSInfo[]>({ parameters: parametersFeatureInfo }, '/api/elements/getFeatureInfo')).pipe(
          catchError(()=>{
            this.elmCartoOverlays.loading = false
            return EMPTY
          }),
        )
      }),
      tap((response)=>{
        this.elmCartoOverlays.loading = false
        if (response.length == 0) {
          this.closeElemCarto()
        }
      }),
      filter((response)=>response.length >0),
      tap((response)=>{
        this.elmCartoOverlays.data = {
          polygon:response.filter((feature)=> this.GeneralService.get_config_projet().layers.polygon.find((elemCarto) => elemCarto.elem_carto_id == feature.properties.elem_carto_id) != undefined ),
          point : response.filter((feature)=> this.GeneralService.get_config_projet().layers.linestring.find((elemCarto) => elemCarto.elem_carto_id == feature.properties.elem_carto_id) != undefined ),
          linestring:response.filter((feature)=> this.GeneralService.get_config_projet().layers.linestring.find((elemCarto) => elemCarto.elem_carto_id == feature.properties.elem_carto_id) != undefined )
        }
      }),
      tap((response)=>{
        // let layer = this.SitesCartoService.getLayerByAttr<VectorLayer>('elem_geo_surbrillance', 'nom', true, map)[0]
        // layer.getSource().clear()
        // response.map((feat)=>{
        //   layer.getSource().addFeatures(new GeoJSON().readFeatures(feat))
        // })
        
      }),
      _map((response)=>{

        if (this.elmCartoOverlays.data.point.length == 1) {
          return this.elmCartoOverlays.data.point[0]
        }else if (response.length == 1) {
          return response[0]
        }else if (response.length > 1) {
          this.elmCartoOverlays.chooseActive = true
          this.elmCartoOverlays.overlayActive.active = false
          return
        } else {
          this.elmCartoOverlays.loading = false
          this.closeElemCarto()
          return 
        }
      }),
      filter((feature)=>feature != undefined),
      tap((feature)=>{
        this.openFeaturePropertiesPopup(feature)
      })
    ).subscribe()

    fromOpenlayerEvent<MapBrowserEvent<PointerEvent>>(map,'singleclick').pipe(
      takeUntil(this.destroyed$),
      _map((evt)=>{
        map.updateSize()
        var pixel = evt.pixel;  
        var feature = map.forEachFeatureAtPixel(pixel,
          function (feature, layer) {
            return feature;
          });
        var layers = []
        if (!feature) {
          var all_pixels = this.mapGeneralService.calcHitMatrix(evt.pixel)
          var nom_layers_load = []
          for (let index = 0; index < all_pixels.length; index++) {
            var un_pixel = all_pixels[index];

            for (let i = 0; i < layers.length; i++) {
              nom_layers_load.push(layers[i].get('nom'));
            }

            var layers_in_pixels = this.mapGeneralService.displayFeatureInfo(un_pixel, 'nom', nom_layers_load, map)
            for (let j = 0; j < layers_in_pixels.length; j++) {
              layers.push(layers_in_pixels[j]);
            }

          }
        }
        if (layers.length >= 0 && layers[0] instanceof ImageLayer) {
          var coord = map.getCoordinateFromPixel(pixel)
          var data_callback: dataClickOnMap = {
            'type': 'featureInfoWms',
            data: {
              coord: coord,
              layers: layers
            }
          }
          return data_callback
        } else {
          var coord = map.getCoordinateFromPixel(pixel)
          var data_callback: dataClickOnMap = {
            'type': 'clear',
            data: {
              coord: coord,
              layers: layers
            }
          }
          return data_callback
        }
      }),
      filter((data)=>data != undefined),
      tap((data)=>{
        if (data.type== 'featureInfoWms') {
          this.onGetPropertiesInstance(data)
        } else if (data.type== 'clear') {
          this.closeElemCarto()
        }
      })
    ).subscribe()

    this.openFeaturePropertiesPopup = (feature:FeatureWMSInfo)=>{
      this.elmCartoOverlays.overlayActive.active = true
      this.elmCartoOverlays.chooseActive = false
      this.elmCartoOverlays.overlayActive.data =feature
      this.elmCartoOverlays.overlayActive.overlay
      
      let coordinate:Coordinate = getPointOnFeature(new GeoJSON().readFeature(feature.geometry).getGeometry()).getCoordinates()
     
      // this.elmCartoOverlays.overlayActive.overlay.setPosition(coordinate)
      // this.SitesCartoService.pan_to(coordinate, map.getView().getZoom(), map)

    }


    this.closeElemCarto = ()=> {
      // let layer = this.SitesCartoService.getLayerByAttr<VectorLayer>('elem_geo_surbrillance', 'nom', true, map)[0]
      // layer.getSource().clear()
      this.elmCartoOverlays.overlayActive.overlay.setPosition(undefined)
      this.elemsCartoPopup.nativeElement.style.display = 'none'
    }
    
  }

  /**
     * initialiser les overlays des elements geo
     */
   initialiserOvelayElemGeo() {
    this.elmCartoOverlays.overlayActive = {
      'overlay': undefined,
      'text': undefined,
      'data': undefined,
      'active': false,
      'id': 'elemsCartoHistoric'
    }
    this.elmCartoOverlays.chooseActive = false
    this.elmCartoOverlays.overlayActive.overlay = new Overlay({
      element: document.getElementById(this.elmCartoOverlays.overlayActive.id),
      positioning: OverlayPositioning.TOP_CENTER
    });
    map.addOverlay(this.elmCartoOverlays.overlayActive.overlay);
  }

  /**
   * Create a new date from a string, return as a timestamp.
  */
  timestamp(str: string): number {
    var date = new Date(str)
    date.setHours(23, 59, 59)
    // date.setMilliseconds(0)
    return date.getTime();
  }

  /**
  * Formater les dates avec le numéro des jours et des mois 
  * @example 24 03 2020
  * @param date Date
  * @return string
  */
  formatDate(date: Date): string {
    // date.setMilliseconds(0)
    date.setHours(23, 59, 59)
    return date.getDate() + " " +
      months[date.getMonth()] + " " +
      date.getFullYear();
  }

  /**
   * parse date, reciproque de la fonction formatDateComplete
   * @param date string date à parser
   * @return number la date parsé
   */
  parseDate(date: string): number {

    let dateResponse = new Date()
    dateResponse.setDate(parseInt(date.split(" ")[0]))
    dateResponse.setFullYear(parseInt(date.split(" ")[2]))
    dateResponse.setMonth(months.indexOf(date.split(" ")[1]))
    dateResponse.setHours(23, 59, 59)

    // dateResponse.setMilliseconds(0)

    return dateResponse.getTime();
  }


  /**
   * Formater les dates avec les jours et mois de manière explicite
   * @example Mardi 24 Mars 2020
   * @param date Date
   * @return string
   */
  formatDateComplete(date: Date): string {
    date.setHours(23, 59, 59)
    // date.setMilliseconds(0)
    return weekdays[date.getDay()] + " " +
      date.getDate() + " " +
      months[date.getMonth()] + " " +
      date.getFullYear();
  }

  /**
   * parse date, reciproque de la fonction formatDateComplete
   * @param date string date à parser
   * @return number la date parsé
   */
  parseDateComplete(date: string): number {

    let dateResponse = new Date()
    dateResponse.setDate(parseInt(date.split(" ")[1]))
    dateResponse.setFullYear(parseInt(date.split(" ")[3]))
    dateResponse.setMonth(months.indexOf(date.split(" ")[2]))
    dateResponse.setHours(23, 59, 59)
    // dateResponse.setMilliseconds(0):
    return dateResponse.getTime();
  }

  /**
   * Fermer le modal
   */
  onNoClick(): void {
    this.cleanMap()
    this.closeElemCarto()
    this.dialogRef.close();
  }


  /**
   * A chaque fermeture du component, vider la map ! 
   * Car l'objet map est persisté
   */
  ngOnDestroy() {
    this.cleanMap()
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  /**
   * Clean map : remove all layers
   */
  cleanMap() {
    while (map.getLayers().getArray().length > 0) {
      for (let index = 0; index < map.getLayers().getArray().length; index++) {
        const layer_to_remove = map.getLayers().getArray()[index];
        map.removeLayer(layer_to_remove)
      }
    }

    $(map.getViewport()).unbind()
  }

  ngOnInit() {
    map.setTarget('map-for-edition45')
    map.setTarget('mapHistorique')
    this.inialiseBasemaps()
    this.initialiserOvelayElemGeo()

    this.getJauge(this.data.site.sites_id)

    this.formulaire.addControl('range', new FormControl([]))

    this.formulaire.get('range').valueChanges.
      pipe(
        debounceTime(300),
      )
      .subscribe(
        (value) => {
          if (this.historiqueSite.get(this.formatDateComplete(new Date(value))) && !this.historiqueSite.get(this.formatDateComplete(new Date(value))).composant) {
            this.getSiteAtDate(new Date(value), this.data.site.sites_id)
          } else if (this.historiqueSite.get(this.formatDateComplete(new Date(value))) && this.historiqueSite.get(this.formatDateComplete(new Date(value))).composant) {
            this.resetMapLayersWithNewQuerry(this.formatDateComplete(new Date(value)))
          }
        }
      )
  }

  /**
  * Initialiser les imagettes de l'application
  */

  inialiseBasemaps() {
    var defaultLayer = this.SitesCartoService.getDefaultMapLayer()
    defaultLayer.set('nom', 'defaultLayer')
    defaultLayer.setVisible(false)
    map.addLayer(defaultLayer)
    var ign = this.SitesCartoService.getIgnLayer()
    ign.set('nom', 'ign')

    map.addLayer(ign)
    map.getView().setCenter(this.data.coord)

    setTimeout(() => {
      map.updateSize()
    }, 1000);
  }
  /**
   * changer la base maps entre aérien et fond de plan
   */
  toogleBaseMaps() {
    var ign = this.getLayerByAttr('ign', 'nom', true)[0]
    var defaultLayer = this.getLayerByAttr('defaultLayer', 'nom', true)[0]
    ign.setVisible(!ign.getVisible())
    defaultLayer.setVisible(!defaultLayer.getVisible())
  }


  /**
   * récuperer la jauge depuis le backend
   * Par défaut on contruira la jauge sur des intervalles de 24h
   * @param number sites_id 
   * @param number periode   l'intervalle en heurre pour regrouper les modifications par défaut 24h
   * @param [string,string]  intervalle la période sur la quelle on veut recupérer les entités si le tableau est vide, on prend toute la durée de vie du site
   * @return Array<>
   */
  getJauge(sites_id: number, periode: number = 24, intervalle: Array<Date> = []) {
    this.loading.jauge = true

    this.BackendApiService.post_requete({
      sites_id: sites_id,
      intervalle: intervalle,
      periode: periode
    }, '/api/historique/jauge').then(
      (response: reponseDB) => {
        this.loading.jauge = false
        if (!response.erreur) {
          this.notifier.notify("succes", response['message']);

          if (response.data.length < 2) {
            this.notifier.notify("error", "Aucun historique trouvé !");
            this.onNoClick()
            return
          }

          this.setParametersOfSlider(response.data, response.data.length - 1)

          for (let index = 0; index < response.data.length; index++) {
            const date = response.data[index];

            this.historiqueSite.data.push({
              date: this.formatDateComplete(new Date(date))
            })
          }

        } else {
          this.notifier.notify("error", response['message']);
        }
      },
      (error: reponseDB) => {
        this.loading.jauge = false
        this.notifier.notify("error", error['message']);
      }
    )
  }

  /**
  * calculer le pourcentage d'une date sur une période
  * @example si la période est [01-01-2020 31-01-2020], le pourcentage de la date 15-01-2020 est 50%,  07-01-2020 -> 25%, 22-01-2020 -> 75%....
  * @param date string la date dont on cherche le pourcentage
  * @param periode [string string] la période qu'on utilisera pour avoir le pourcentage
  * @return string
  */
  calculatePourcentageDate(date: string, periode: [string, string]): string {
    let pourcentage = (this.timestamp(date) - this.timestamp(periode[0])) * 100 / (this.timestamp(periode[1]) - this.timestamp(periode[0]))
    return pourcentage + '%'
  }

  /**
   * parametrer le slider: on le spécifie les différentes étapes (range) et son debut
   * @param etapes Array<string> tableau de nos étapes voulues
   * @param debut number index du debut dans le tableau etapes
   * @return void
   */
  setParametersOfSlider(etapes: Array<string>, debut: number) {
    let rangesSlider = {}
    let periode: [string, string] = [etapes[0], etapes[etapes.length - 1]]

    for (let index = 0; index < etapes.length; index++) {
      const element = etapes[index];
      if (index == 0) {
        rangesSlider['min'] = [this.timestamp(element)]
      } else if (index == etapes.length - 1) {
        rangesSlider['max'] = [this.timestamp(element)]
      } else {
        // rangesSlider[(index/(etapes.length - 1))*100] = [this.timestamp(element)]
        rangesSlider[this.calculatePourcentageDate(element, periode)] = [this.timestamp(element)]
      }
    }

    this.sliderHistoriqueConfig.range = rangesSlider
    /**
     * xxx ne change aucune valeurs, mais il le faut pour avoir une valeur start dans la config et le formulaire
     * Si non on a aura une erreur
     */
    this.sliderHistoriqueConfig.start = this.timestamp(etapes[debut])
    this.formulaire.controls['range'].setValue(this.timestamp(etapes[debut]))
    //

    this.sliderHistorique.changes.pipe(
      take(1),
    ).subscribe(() => {
      if (this.sliderHistorique.toArray().length > 0) {
        this.sliderHistorique.toArray()[0].slider.set(this.formatDateComplete(new Date(rangesSlider['max'][0])))
        /** scroll le slider au fond à droite pour qu'on puisse voir son handle (curseur du slider) */
        $('.historique-dialog-content-slider').scrollLeft(4000)
      }
    })
  }

  /**
   * Recuperer les composants d'un site une seconde avant une date voulue
   * @param date Date la date voulue sur la forme yyyy-mm-dd- hh:mm:ss 2020-08-11 23:59:59
   * @param number sites_id 
   */
  getSiteAtDate(date: Date, sites_id: number) {
    this.loading.etatSite = true

    date.setHours(23, 59, 59)
    var dateString = date.getFullYear() + "-" + (date.getMonth() * 1 + 1) + "-" + date.getDate() + " " +
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

    from<{
      erreur: boolean
      message: string
      data: {
        perimetre: Array<{ id: number; id_origine: number; elem_carto_id: number }>
        point: Array<{ id: number; id_origine: number; elem_carto_id: number }>
        linestring: Array<{ id: number; id_origine: number; elem_carto_id: number }>
        polygon: Array<{ id: number; id_origine: number; elem_carto_id: number }>
        sites: Array<{ id: number; id_origine: number }>
      }
    }>(this.BackendApiService.post_requete({
      sites_id: sites_id,
      date: dateString,
    }, '/api/historique/site')).pipe(
      take(1),
      catchError((error) => {
        this.loading.etatSite = false
        this.notifier.notify("error", error['message']);
        return EMPTY
      }),
      tap((response) => {
        this.loading.etatSite = false
        if (!response.erreur) {
          this.notifier.notify("succes", response['message']);

          if (this.historiqueSite.get(this.formatDateComplete(date))) {
            this.historiqueSite.get(this.formatDateComplete(date)).composant = response.data
          }

          this.resetMapLayersWithNewQuerry(this.formatDateComplete(date))

        } else {
          this.notifier.notify("error", response['message']);
        }
      })
    ).subscribe()

  }

  /**
   * calculer le filtre necessaire pour visualiser les données à une date 
   * @param date string
   * @returns {'polygon':number[],'point':number[],'linestring':number[],'perimetres':number[]}
   */
  getFilter(date: string): { 'polygon': { [key: number]: Array<string> }, 'point': { [key: number]: Array<string> }, 'linestring': { [key: number]: Array<string> }, 'perimetre': { [key: number]: Array<string> } } {

    let response: { 'polygon': { [key: number]: Array<string> }, 'point': { [key: number]: Array<string> }, 'linestring': { [key: number]: Array<string> }, 'perimetre': { [key: number]: Array<string> } } = {
      polygon: {},
      point: {},
      linestring: {},
      perimetre: {},
    }

    if (this.historiqueSite.get(date)) {
      let composant = this.historiqueSite.get(date).composant
    
      for (const key in composant) {
        if (Object.prototype.hasOwnProperty.call(composant, key) && key != 'sites') {
          let typologieComposant: {
            id: number;
            id_origine: number;
            elem_carto_id: number;
          }[] = composant[key]

          let result:{[key: number]: Array<string>} = typologieComposant.reduce(function (r, a) {
            r[a.elem_carto_id] = r[a.elem_carto_id] || [];
            r[a.elem_carto_id].push("'"+a.id+"'" );
            return r;
          }, Object.create(null));

          response[key] = result
        }
      }
    }

    return response

  }

  /**
   * Ajouter les données d'une date à la carte
   * - on construit les wms avec les filtres qu'il faut
   * - si des wms existent deja on les enlevent de la carte
   * - on ajoute les wms construient 
   * @param date string
   */
  resetMapLayersWithNewQuerry(date: string) {
    const ids = this.getFilter(date)
    let layersImages = this.GeneralService.get_config_projet().layers

    for (const key in layersImages) {
      let identifiant = []
      let filtre = []

      if (this.getLayerByAttr(key, 'nom', true).length > 0) {
        map.removeLayer(this.getLayerByAttr(key, 'nom', true)[0])
      }
      if (layersImages.hasOwnProperty(key) && Object.entries(ids[key]).length > 0) {
        const element_cartos: Array<elemCartoInterface> = layersImages[key];
        element_cartos.filter((one_element_carto)=>Object.entries(ids[key]).map(([key, value])=>parseInt(key)).includes(one_element_carto.elem_carto_id) ).map((one_element_carto)=>{
            identifiant.push(one_element_carto.alias + '_' + key)
            filtre.push(ids[key][one_element_carto.elem_carto_id].join(','))
        })

        let couche = {
          url: environment.projet_qgis_historique_wms,
          nom: key,
          identifiant: identifiant.join(','),
          filtre: identifiant.join(',')+':' + '"id" IN ( ' + filtre.join(',') + ' )'
        }
        var layer = this.constructWMSLayer(couche)
        map.addLayer(layer)

      }
    }

     /**
       * gerer la couche perimetres à part puisque elle est pas dans les elements cartographiques (à refacoriser !)
       */
      try {
        var couche = {
          url: environment.projet_qgis_historique_wms,
          nom: 'perimetres',
          identifiant: 'perimetres',
          filtre: 'perimetres:' + '"id" IN ( ' + ids.perimetre[1].join(',') + ' )',
        }
  
        if (this.getLayerByAttr('perimetres', 'nom', true).length > 0) {
          map.removeLayer(this.getLayerByAttr('perimetres', 'nom', true)[0])
        }
  
        if (ids.perimetre[1].length > 0) {
          var layer = this.constructWMSLayer(couche)
          map.addLayer(layer)
        }
      } catch (error) {
        console.log(error)
      }
     
  }

  getLayerByAttr(layername, attr, layer_group) {
    var layer_to_remove = []

    if (layer_group) {
      var all_layers = map.getLayers().getArray()
    } else {
      var all_layers = map.getLayerGroup().getLayers().getArray()
    }

    for (let index = 0; index < all_layers.length; index++) {
      var layer = all_layers[index]
      if (layer.get(attr) == layername) {
        layer_to_remove.push(layer)
      }

    }
    return layer_to_remove
  }

  /**
   * Construire une couche wms 
   */
  constructWMSLayer(couche: { url: string, nom: string, identifiant: string; filtre: string }) {
    var wmsSource = new ImageWMS({
      url: couche.url,
      imageLoadFunction: (image, src) => {
        $.ajax({
          url: src,
          headers: { 'Authorization': 'Bearer  ' + localStorage.getItem('token') },
          type: "GET",
          success: function (response) { image.getImage().setAttribute('src', response); }
        });
      },
      params: { 'LAYERS': couche.identifiant, 'FILTER': couche.filtre },
      serverType: 'qgis',
      crossOrigin: 'anonymous',
    });

    var wmsLayer = new ImageLayer({
      source: wmsSource,
      className: couche.nom,
    });
    wmsLayer.set('nom', couche.nom)
    wmsLayer.setZIndex(500)
    return wmsLayer
  }
}

export function fromOpenlayerEvent<T>(element: any, eventName: string): Observable<T> {

  return new Observable((observer: Subscriber<T>) => {
    const handler = (e: T) => observer.next(e);

    let eventKey = element.on(eventName, handler)

    return () => {
      unByKey(eventKey)
    }
  });
}
