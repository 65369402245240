import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { donne_pour_sup } from 'src/app/modal/supprimer/supprimer.component';

@Component({
  selector: 'app-delete-site-confirmation',
  templateUrl: './delete-site-confirmation.component.html',
  styleUrls: ['./delete-site-confirmation.component.scss']
})
export class DeleteSiteConfirmationComponent implements OnInit {
  formulaire: FormGroup

  onConfirmInstance : () => void;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteSiteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_sup
  ) { 
    this.formulaire = this.fb.group({
      decision: false
    })

    this.onConfirmInstance = () => {
      this.dialogRef.close(true)
    }
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
