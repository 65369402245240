import {  ViewChild, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { FormBuilder } from '@angular/forms';
import { MatDialog,} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendApiService } from '../services/backend-api.service'
import { MapGeneralService } from '../services/map.general.service'
import { SitesService } from '../services/sites.service'
import { StockageService } from '../services/stockage.service'
import { SitesCartoService } from '../services/sites-carto.service'
import { GeneralService } from '../services/general.service'
import { AuthService } from '../services/auth.service';
import {BehaviorSubject } from 'rxjs';
import {debounceTime } from 'rxjs/operators';
import { ScreenHelper } from '../../app/helpers/screen'

import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import {
  fromLonLat,
  View,
  Map,
  VectorSource,
  GeoJSON,
  bboxStrategy,
  Cluster,
  VectorLayer,
  Style,
  CircleStyle,
  Stroke,
  Fill,
  Text,
  transformExtent,
  Zoom,
  Rotate,
  Overlay,
  Attribution,
  defaultControls,
  // Point
}
  from '../ol-module'
import Point from 'ol/geom/Point';
import {
  AdresseFeatureResponse,
  grid, imagettesOptions, imagette_urlOptions, layerForMap
}
  from '../type/index'

import { MatBottomSheet, MatTableDataSource, MatPaginator, MatSidenavContainer } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import OverlayPositioning from 'ol/OverlayPositioning';
import { ModeCodeFeature } from '../models/mode_gestion';
import { MesureComponent } from '../shared/pages/mesure/mesure.component';
export interface overlayForMap {
  'overlay': Overlay,
  'text': string,
  'data': FeatureWMSInfo,
  'active': boolean,
  'id': string
}

export interface overlayAdresseForMap {
  'overlay': Overlay,
  'text': string,
  'data': AdresseFeatureResponse,
  'active': boolean,
  'id': string
}

export interface FeatureWMSInfo{
  geometry:GeoJSON
  id: string
  properties:{
    alias: string
    elem_carto_id: number
    elem_carto_valeur: string
    id: number
    sites_id: number
    surface: number
    perimetre : number
    typo_codes_id: number
    typo_compositions: null
    typo_formes: null
    typo_sous_types: null
    typo_typologies: null
    site:{
      nom_site: string
      num_chant_sites: number
      sites_id: number
    },
    modes:Array<ModeCodeFeature>
  }
}
export interface elmCartoOverlaysForMap {
  'loading': boolean,
  'chooseActive': boolean,
  'overlayActive': overlayForMap,
  'data': {
    'polygon': Array<FeatureWMSInfo>,
    'point': Array<FeatureWMSInfo>,
    'linestring': Array<FeatureWMSInfo>,
  },
}

export interface dataClickOnMap {
  type: string,
  data: {
    coord: Array<number>,
    layers: Array<any>,
    data?: {}
  }
}


var attribution = new Attribution({
  collapsible: false
});

const view = new View({
  center: fromLonLat([5, 45]),
  zoom: 10,
  minZoom: 6,
  constrainResolution: true,
})

const map = new Map({
  // target: 'map',
  // controls: defaultControls().extend([

  // ]),
  maxTilesLoading: 50,
  layers: [

  ],
  view: view,
  controls: defaultControls({attribution: false,zoom:false}).extend([attribution]),
});


// @Component({
//   selector: 'app-map',
//   templateUrl: './map.component.html',
//   styleUrls: ['./map.component.scss']
// })

export class MapComponentParent {
  screenHelper=ScreenHelper
  map: Map
  /**
  * la Grille qui gère l'application, la largeur et la hauter des principaux blocs de l'appli
  */
  grid_menu = {} as grid;

  /**
  * la largeur par défaut de la bande de gauche
  */
  public width_interface: number = environment.width_interface

  /**
  * le nombre de colonme max que peut avoir la bande de gauche
  */
  public colone_max: number = 2.5
  imagette: Array<imagettesOptions> = []
  imagette_url: imagette_urlOptions = {
    'visible': false,
    'url': undefined,
    'titre': undefined
  }

  /**
  * responsable de patienter un moment après que la carte ai bougé
  */
  public debouncer = new BehaviorSubject(2);

  /**
  * source d'ol vector, chargeant les données de manière invisible pour savoir qui est déja chargé ou pas
  */
  public fake_source: any

  /**
  * données pour afficher dans la liste
  */
  public dataSource = new MatTableDataSource([]);

  /**
  * configuration du projet
  */
  public config_projet: any

  overlay_adresse: overlayAdresseForMap = {} as overlayAdresseForMap

  feature_mousemove: any
  sites = new BehaviorSubject([])
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MesureComponent) mesureComp: MesureComponent

  
  environment = environment
  sidenavContainer:MatSidenavContainer
  constructor(
    public _ngZone: NgZone,
    public BackendApiService: BackendApiService,
    public MapGeneralService: MapGeneralService,
    public GeneralService: GeneralService,
    public AuthService: AuthService,
    public SitesService: SitesService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public _bottomSheet: MatBottomSheet,
    public SitesCartoService: SitesCartoService,
    public translate: TranslateService,
    public StockageService:StockageService
  ) {
    this.map = map
    this.MapGeneralService.setMap(map)
    this.SitesCartoService.setMap(map)
    



    this.SitesCartoService.featureMouseMoveObserver.subscribe(data => {
      this.feature_mousemove = data
    })

    this.config_projet = this.GeneralService.get_config_projet()
    translate.setDefaultLang('fr');
    translate.use('fr');
  }
  
  
 

  /**
  * Permet de patienter un moment après qu'on ai changé de bbox avant de demander de nouvelles données aux serveurs sur la nouvelle bbox
  * @param {number}  duration le temps d'attente
  */

  mapBboxChangedAfterduration(duration: number) {
    this.debouncer
      .pipe(
        debounceTime(duration),
      )
      .subscribe(() => {

        this.findNewDataForExtent()

      })
  }

  /**
  * Permet d'initialiser les outils généraux de navigation : zoom, mesure, fonds de plans, recherche d'adresse, compas
  */
  initialiseGeneralMapTools() {
    var zooms = new Zoom({
      'target': 'zooms',
      'zoomInLabel': document.getElementById('plus'),
      'zoomOutLabel': document.getElementById('moins'),
      'zoomInTipLabel': 'Zoomer',
      'zoomOutTipLabel': 'Dézoomer'
    })

    var rotate = new Rotate({
      'target': 'zooms',
      'label': document.getElementById('compass'),
    })

    rotate.setMap(map)
    zooms.setMap(map)

    map.addControl(this.MapGeneralService.scaleControl('scaleline','scaleMap'))
  }

  /**
  * Recherche les nouvelles données dans le serveur carto lorsque l'extent de la carte a changé, 
  * si il trouve de nouvelles données il lance la recuperation des données de la BD
  */

  findNewDataForExtent() {
    this._snackBar.open("Recherche de sites...", 'Fermer', {
      duration: 2000,
      panelClass: 'notification_snackbar'
    });

    var extent_vieuw = this.SitesCartoService.get_extent_map()
    var param = {
      'extent': transformExtent(extent_vieuw, 'EPSG:3857', 'EPSG:4326').join(',')
    }

    this.BackendApiService.post_requete(param, '/api/sites/extent').then(
      (data) => {
        var new_ids = []
        var old_ids = this.StockageService.getAllIds()
        var features_json = {
          "type": "FeatureCollection",
          "bbox": [0, 0, 0, 0],
          "features": [
          ]
        }
        var result = data['data']['features']


        for (let index = 0; index < this.fake_source.getFeatures().length; index++) {
          const feature = this.fake_source.getFeatures()[index];
          if (old_ids.indexOf(feature.getId()) == -1 ) {
            old_ids.push(feature.getId())
          }
          
        }
        var response_carto = this.SitesService.getNewSitesFromCartoServeur(result)
        features_json.features = response_carto[1]
        new_ids = response_carto[0]

        this.fake_source.addFeatures(this.fake_source.getFormat().readFeatures(features_json));
        
        var ids = this.SitesCartoService.compareIdsFromCartoServeur(old_ids, new_ids, features_json.features)
        // console.log(new_ids.length,ids,old_ids.indexOf(1))
        if (ids.length > 0) {
          this.SitesService.loadDataFromServer(ids)
        }
      },
      (err) => {
        this.fake_source.removeLoadedExtent(extent_vieuw);
      }
    )

  }

  /**
  * Initialiser l'interface au demarage de l'application
  */
  initialise_interface() {
    var pte_interface = this.avoir_pte_interface()

    this.grid_menu.sidenavStart = {
      width: pte_interface.width,
      height: pte_interface.height,
      cols: pte_interface.cols,
      liste_sites: {
        colspan: 0,
        rowspan: 0,
        open: false
      },
      pte_sites: {
        colspan: 0,
        rowspan: 0,
        open: false
      },
      requete: {
        colspan: 0,
        rowspan: 0,
        open: false
      }
    }

    if ($(window).width() > 450) {
      this.open_list_site()
    }

  }

  handleResponsiveDesign(sidenavContainer:MatSidenavContainer){
    this.sidenavContainer = sidenavContainer
    if ($(window).width() <= 450) {
      this.colone_max = 1
      this.width_interface = $(window).width()
      setTimeout(() => {
        this.closeSidenav()
      }, 200);
     
    }
  }

  /**
   * Close sidenav
   */
  closeSidenav(){
        this.sidenavContainer.start.close()
        this.grid_menu.sidenavStart.liste_sites.rowspan = 0
        this.grid_menu.sidenavStart.liste_sites.colspan = 0
        this.grid_menu.sidenavStart.liste_sites.open = false
        this.grid_menu.sidenavStart.width = 0
  }

  openSidenav(){
    this.sidenavContainer.start.open()
    this.grid_menu.sidenavStart.width = this.width_interface
  }

  /**
   * Recuperer les parametres du grid de l'interface
   * @return {width:number,height:string,cols:number}
   */
  avoir_pte_interface(): {width:number,height:string,cols:number} {

    var height = $(window).height()
    var width = $(window).width()

    // if ($(window).width() <= 1280) {
    //   this.colone_max = 2
    //   this.width_interface = $(window).width() / 4
    // }
    if (this.grid_menu.sidenavStart) {
      if (this.grid_menu.sidenavStart.liste_sites.open) {
        var pte_interface = {
          width: this.width_interface,
          height: '200%',
          cols: this.colone_max
        }
        pte_interface.height = height
      }else if(this.grid_menu.sidenavStart.pte_sites.open || this.grid_menu.sidenavStart.requete.open){
     
        var pte_interface = {
          width: $(window).width() <= 450 ?this.width_interface:this.width_interface *1.5,
          height:  '100%',
          cols: this.colone_max
        }
        pte_interface.height = height
      }


    } else {
      var pte_interface = {
        width: this.width_interface,
        height: '200%',
        cols: this.colone_max
      }
      pte_interface.height = height
    }
    return pte_interface

  }

  /**
   * Ouvrir la fenetre des propriétes d'une entite
   */
  open_window_pte_entite() {

    this.close_all_window()
    if (!this.sidenavContainer.start.opened) {
      this.openSidenav()
    }

    this.grid_menu.sidenavStart.liste_sites = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    // if ($(window).width() <= 1280) {
    //   this.grid_menu.sidenavStart.liste_sites.colspan = 0
    // }

    

    this.grid_menu.sidenavStart.pte_sites = {
      colspan: this.colone_max,
      rowspan: 1,
      open: true
    }
    this.grid_menu.sidenavStart.requete = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    // if (this.grid_menu.sidenavStart.requete.open) {
    //   this.grid_menu.sidenavStart.liste_sites.rowspan = 2
    // }

    var pte_interface = this.avoir_pte_interface()
    this.grid_menu.sidenavStart.width = pte_interface.width
    this.grid_menu.sidenavStart.height = pte_interface.height
    this.grid_menu.sidenavStart.cols = pte_interface.cols
  }

  /**
   * Ouvrir la fenetre des requetes
   */
  open_window_requete(){
    this.close_all_window()

    this.grid_menu.sidenavStart.liste_sites = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    this.grid_menu.sidenavStart.requete = {
      colspan: this.colone_max,
      rowspan: 1,
      open: true
    }

    this.grid_menu.sidenavStart.pte_sites = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    var pte_interface = this.avoir_pte_interface()
    this.grid_menu.sidenavStart.width = pte_interface.width
    this.grid_menu.sidenavStart.height = pte_interface.height
    this.grid_menu.sidenavStart.cols = pte_interface.cols

  }

  /**
   * open list of sites
   */
  open_list_site(){
    this.close_all_window()

    this.grid_menu.sidenavStart.liste_sites = {
      colspan: this.colone_max,
      rowspan: 1,
      open: true
    }

    this.grid_menu.sidenavStart.requete = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    this.grid_menu.sidenavStart.pte_sites = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    var pte_interface = this.avoir_pte_interface()
    this.grid_menu.sidenavStart.width = pte_interface.width
    this.grid_menu.sidenavStart.height = pte_interface.height
    this.grid_menu.sidenavStart.cols = pte_interface.cols
  }

  /**
   * Fermer la fenetre des propriétes d'une entite
   */
  close_window_pte_entite() {
    this.close_all_window()
  }
  /**
   * Fermer Toutes les fenetres
   */
  close_all_window(){

    this.grid_menu.sidenavStart.pte_sites = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    this.grid_menu.sidenavStart.requete = {
      colspan: 0,
      rowspan: 0,
      open: false
    }

    this.grid_menu.sidenavStart.liste_sites = {
      colspan: 0,
      rowspan: 0,
      open: false
    }
   
    // var pte_interface = this.avoir_pte_interface()
    this.grid_menu.sidenavStart.width = 0
    this.grid_menu.sidenavStart.height = '0px'
    this.grid_menu.sidenavStart.cols = 0
  }

  /**
  * Initialiser les imagettes de l'application
  */

  inialise_imagettes() {
    this.imagette[0] = {
      layer: this.SitesCartoService.getDefaultMapLayer(),
      source: this.SitesCartoService.getDefaultMapLayer().getSource(),
      nom: 'Plan',
      type: 'fond',
      visible: true
    }
    this.imagette[1] = {
      layer: this.SitesCartoService.getIgnLayer(),
      source: this.SitesCartoService.getIgnLayer().getSource(),
      nom: 'Satellite',
      type: 'fond',
      visible: false
    }

    for (let index = 0; index < this.imagette.length; index++) {
      const element = this.imagette[index];
      element.layer.setVisible(element.visible)
      element.layer.set('nom', element.nom)
      element.layer.set('type', element.type)
      map.addLayer(element.layer)
    }
  }

  /**
  * Changer le fond de carte principal et ainsi l'iamgette aussi
  */
  changer_imagette() {
    for (let index = 0; index < this.imagette.length; index++) {
      if (this.imagette[index].visible == false) {
        this.imagette[index].visible = !this.imagette[index].visible
        var imagette_pas_en_cours = this.imagette[index]
        console.log(imagette_pas_en_cours)
        this.SitesCartoService.changer_fond_carte(imagette_pas_en_cours.nom)
      } else {
        this.imagette[index].visible = !this.imagette[index].visible
      }
    }

    this.changer_url_imagette()
  }

  changer_url_imagette() {
    if (view.getZoom() - 2 < 19 && view.getZoom() - 2 > 0) {
      for (let index = 0; index < this.imagette.length; index++) {
        if (this.imagette[index].visible == false) {
          var imlagette_en_cours = this.imagette[index]
          var source = imlagette_en_cours.source

          var url = this.SitesCartoService.display_imagette_carte(source)

          var cnv_prospect = document.createElement('canvas');
          var ctx = cnv_prospect.getContext('2d');
          var img_prospect = new Image();
          img_prospect.src = url

          img_prospect.onload = () => {
            $('#fond_swicther_img').addClass('transitioning-src');
            setTimeout(() => {
              this._ngZone.run(() => {
                this.imagette_url.visible = !imlagette_en_cours.visible
                this.imagette_url.titre = imlagette_en_cours.nom
                this.imagette_url.url = url

                $('#fond_swicther_img').addClass('transitioning-src').removeClass('transitioning-src');
              })
            }, 400);
          }

        }
      }
    }
  }

  /**
  * Mettre à jour la liste de données
  * @param {Array}  data les données
  */

  updateDataSource(data) {
    this._ngZone.run(() => {
      this.dataSource.data = data
      this.dataSource.paginator = this.paginator;
    })
  }


  /**
    * Permet d'afficher juste les entreprises visibles dans la carte dans la liste
    */
  updateDataInListByExtent() {
    setTimeout(() => {

      var extent_vieuw = this.SitesCartoService.get_extent_map()
      var featuresInView = this.SitesCartoService.featuresInExtent(extent_vieuw)
      // console.log(featuresInView.length)
      var numChantInView = this.SitesCartoService.getListNumChantier(featuresInView)
      this.SitesService.numChantSitesToDisplay.next(numChantInView)
      var data = this.SitesService.filterDataForListeByExtent()
      this.updateDataSource(data)

    }, 1000);
  }


  gestion_carto(couche: layerForMap) {
    if (couche.type == "geojson") {
      var vectorSource = new VectorSource({
      });

      if (couche.cluster) {
        var clusterSource = new Cluster({
          distance: 80,
          source: vectorSource
        });

        var styleCache = {};
        var styleCacheCopy = {};

        var wfsLayer = new VectorLayer({
          source: clusterSource,
          style: (feature) => {
            var size = feature.get('features').length;

            if (size > 1) {

              // afiche le numero du marquer avec le marquer 2269
              var styleDefault = styleCache[size];
              if (!styleDefault) {
                var radius = 10
                if (size > 99) {
                  radius = 12, 5
                }
                styleDefault = new Style({

                  image: new CircleStyle({
                    radius: radius,

                    stroke: new Stroke({
                      color: '#fff',
                      width: 2
                    }),
                    fill: new Fill({
                      color: '#3a3a3a',
                    })
                  }),
                  text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                      color: '#fff'
                    }),
                    font: '12px sans-serif',
                    offsetY: 1,
                    offsetX: -0.5
                  })
                });
                styleCache[size] = styleDefault;
              }

              return [couche.style, styleDefault];


            } else if (size == 1) {
              var feat = this.SitesCartoService.avoir_feature_display(feature.get('features'))
              if (feat) {
                return this.SitesCartoService.get_style_sous_type(couche.nom, feat.get('type'));
              }


            } else if (size == 0) {
              return
            }


          },
          minResolution: map.getView().getResolutionForZoom(couche.minzoom),
          maxResolution: map.getView().getResolutionForZoom(couche.maxzoom)
        });

        wfsLayer.set('nom', couche.nom)
        wfsLayer.set('type_layer', couche.type_layer)

        wfsLayer.set('style', couche.style)

        wfsLayer.setZIndex(couche.zindex)
        map.addLayer(wfsLayer)
      }

    }

  }


  locate_user() {
    this.SitesCartoService.geolocate_me()
  }

  /**
     * initialiser l'overlay qui sera utilisé pour la recherche d'adresse dans la carte
     */

  initialise_overlay_adresse() {

    this.overlay_adresse = {
      'overlay': undefined,
      'text': undefined,
      'data': undefined,
      'active': undefined,
      'id': 'overlay_adresse'
    }

    this.overlay_adresse.overlay = new Overlay({
      element: document.getElementById(this.overlay_adresse.id),
      positioning:OverlayPositioning.CENTER_CENTER,
      stopEvent: false
    });
    map.addOverlay(this.overlay_adresse.overlay);
  }

  /**
   * display an adresse from api data gouv
   * @param adresse AdresseFeatureResponse
   */
  display_marker_adresse_change(adresse:AdresseFeatureResponse) {

    var ville = adresse.properties.city
    var cp = adresse.properties.postcode
    var coordinate = fromLonLat(adresse.geometry.coordinates)
    var geom = new Point(coordinate)

    $("#" + this.overlay_adresse.id).show()
    this.overlay_adresse.overlay.setPosition(coordinate)


    this.overlay_adresse.data = adresse
    this.SitesCartoService.fit_view(geom, 16, this.SitesCartoService.get_padding_map())
  }

}
