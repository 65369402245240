import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { BackendApiService } from '../services/backend-api.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators'

@Injectable()
export class IsAuthGuard implements CanActivate {

  constructor(
    private AuthService: AuthService,
    private BackendApiService: BackendApiService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    
    if (!this.AuthService.isLoggedIn()) {
      return true      
    } else {
      this.router.navigate([])

    }
  }
}