import { Component, OnInit, Input, Output, SimpleChanges, ChangeDetectionStrategy, OnChanges, NgZone, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import {
  VectorLayer,
  ImageLayer,
  VectorSource,
  Feature,
  View,
  Map,
  Style,
  Projection,
  ImageStatic,
  getCenter,
  Point,
  Polygon,
  LineString
} from '../../ol-module'
import * as $ from 'jquery';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { Extent } from 'ol/extent';
import { map, tap } from 'rxjs/operators';
import { DataForLegend } from '../model/layer-to-digitalise';

@Component({
  selector: 'app-legende',
  templateUrl: './legende.component.html',
  styleUrls: ['./legende.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class LegendeComponent implements OnInit, OnChanges {


  /**
   * listes des données pour la légende
   */
  @Input() dataForLegends: Array<BehaviorSubject<DataForLegend[]>>
  @Input ()legendToNotShow:{ alias: string; typologie: "polygon" | "point" | "linestring";}[] = []
  @Input() map:Map

  @ViewChildren('mapLegendRowSymbolDiv') mapLegendRowSymbolDiv: QueryList<ElementRef<HTMLElement>>

  constructor(
    public _ngZone: NgZone,
  ) {


  }


  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataForLegends && this.dataForLegends) {
      if (this.dataForLegends.length > 0) {
        combineLatest(this.dataForLegends).pipe(
          map((legends)=>{
            let listOfLegend:Array<DataForLegend> = [].concat.apply([], legends)
            return listOfLegend
            // return listOfLegend.filter((legend)=> this.legendToNotShow.find((l)=> l.typologie === legend.geomType && l.alias === legend.alias ) != undefined )
          }),
          tap((legends) => {
            console.log(legends)
            this.createLegend( legends )
          })
        ).subscribe()
      }
    }
  }

  /**
   * Remplir le tableau HTML avec la légende
   */
  createLegend(legends: DataForLegend[]) {
    setTimeout(() => {
      var extent: Extent = [0, 0, 32, 32];
      var projection = new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent
      });
      this.mapLegendRowSymbolDiv
      .filter((element)=> legends.find((legend)=>legend.geomType+legend.alias === element.nativeElement.id ) != undefined )
      .map((element)=>{
        let targetDiv = element.nativeElement
        const legendRow = legends.find((legend)=>legend.geomType+legend.alias === targetDiv.id );

        targetDiv.innerHTML = '';
        //layer for icon
        var sourceLegend = new VectorSource({ wrapX: false });
        var vectorLegend = new VectorLayer({
          source: sourceLegend,
          // style: legendRow.style
        });
        //map
        var mapLegend = new Map({
          controls: [],
          layers: [
            new ImageLayer({
              source: new ImageStatic({
                projection: projection,
                imageExtent: extent,
                url: ''
              })
            }),
            vectorLegend
          ],
          target: targetDiv,
          view: new View({
            projection: projection,
            center: getCenter(extent),
            zoom: 2,
            maxZoom: 2
          })
        });

        //icon feature depending on type
        var geom;
        if (legendRow.geomType == 'point') {
          geom = new Point([16, 16]);
        } else if (legendRow.geomType == 'polygon') {
          var polyCoords = [];
          polyCoords.push([15.7, 15.7]);
          polyCoords.push([16.3, 15.7]);
          polyCoords.push([16.3, 16.3]);
          polyCoords.push([15.7, 16.3]);
          polyCoords.push([15.7, 15.7]);
          geom = new Polygon([polyCoords]);
        } else {
          var lineCoords = [];
          lineCoords.push([15.6, 15.6]);
          lineCoords.push([16, 16]);
          lineCoords.push([16, 15.8]);
          lineCoords.push([16.4, 16.2]);
          geom = new LineString(lineCoords);
        }
        var feature = new Feature({
          geometry: geom
        });
        feature.setStyle(legendRow.style)
        vectorLegend.getSource().addFeature(feature);
      })
      this.map.getLayers().getArray().filter((layer)=>layer instanceof VectorLayer).map((layer)=>layer.changed())
      this.map.renderSync()
    }, 500)
  }

  hideLegend(legend:DataForLegend):boolean{
    return this.legendToNotShow.find((l)=>l.typologie === legend.geomType && l.alias === legend.alias) === undefined
  }

 

}
