import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionSiteComponent } from './pages/description-site.component';
import { SuiviComponent } from './pages/suivi/suivi.component';
import { InfosGeneralesComponent } from './pages/infos-generales/infos-generales.component';
import { EntiteComponent } from './pages/entite/entite.component';
import { ComponentMaterialModule } from 'src/app/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {SharedModule} from 'src/app/shared/shared.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpdateClientComponent } from './modal/update-client/update-client.component';
import { UpdateAgenceComponent } from './modal/update-agence/update-agence.component';
import { UpdateRegroupementComponent } from './modal/update-regroupement/update-regroupement.component';
import { UpdateSiteComponent } from './modal/update-site/update-site.component';
import { UpdateLocalisationSiteComponent } from './modal/update-localisation-site/update-localisation-site.component';
import { DeleteSiteConfirmationComponent } from './modal/delete-site-confirmation/delete-site-confirmation.component';
import { HistoricComponent } from './modal/historic/historic.component';
import { NouisliderModule } from 'ng2-nouislider';

@NgModule({
  declarations: [
    DescriptionSiteComponent, 
    SuiviComponent, 
    InfosGeneralesComponent, 
    EntiteComponent, 
    UpdateClientComponent, 
    UpdateAgenceComponent, 
    UpdateRegroupementComponent, 
    UpdateSiteComponent, UpdateLocalisationSiteComponent, DeleteSiteConfirmationComponent, HistoricComponent],
  imports: [
    CommonModule,
    ComponentMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    SharedModule,
    BrowserAnimationsModule,
    NouisliderModule
  ],
  exports : [
    DescriptionSiteComponent
  ],
  entryComponents : [
    UpdateClientComponent, 
    UpdateAgenceComponent, 
    UpdateRegroupementComponent,
    UpdateSiteComponent,
    UpdateLocalisationSiteComponent,
    DeleteSiteConfirmationComponent,
    HistoricComponent
  ]
})
export class DescriptionSiteModule { }
