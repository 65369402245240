import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import * as $ from 'jquery';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { donne_pour_modification_user, ModificationUserComponent } from '../modification-user/modification-user.component';
import { GeneralService } from '../../../services/general.service'
import { User } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  onEditInstance :() => void;
  currentUser : User;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    public dialogRef: MatDialogRef<UserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_modification_user,
    public dialog : MatDialog,
    public GeneralService: GeneralService,
    public cdRef : ChangeDetectorRef
  ) { 
    const onEdit: Subject<void> = new Subject<void>()
    this.onEditInstance = () => {
      onEdit.next()
    }
    onEdit.pipe(
      takeUntil(this.destroyed$),
      switchMap(()=> {
        var size = [$(window).width() * 0.5 + 'px', $(window).height() * 0.5 + 'px']
        return this.dialog.open(ModificationUserComponent, {
          width: size[0],
          height: size[1],
          disableClose: true,
          data : data
        }).afterClosed().pipe(
          tap((result) => {
            if (result) {
              // var fun = this[result.callback](result)
            }
          })
        )
      })
    ).subscribe()
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  ngOnDestroy(){
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  ngAfterViewInit() {
    this.currentUser = this.data.donne;
    this.cdRef.detectChanges()
  }

}
