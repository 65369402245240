import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpClientModule } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, retry,tap } from 'rxjs/operators';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { promise } from 'protractor';
import { ResultOfDigitalise } from '../digitaliser/model/layer-to-digitalise';
import { AdresseAPIResponse, siteInterface, reponseDB, ResponseAdresseDataGouv, ResponseAdresseGoogle, PlusCode } from '../type';
@Injectable({
  providedIn: 'root'
})


export class BackendApiService {

  results:Object[];
  loading:boolean;
  // apiURL = 'http://ws.undermap.me/'
  apiURL = environment.apiUrl
  private headers: HttpHeaders=  new HttpHeaders()
  
  private headers_qgis_serveur: HttpHeaders = new HttpHeaders({
  });

  constructor(
    private http: HttpClient
    ) { 
    this.loading = false; 
    // this.headers =  new HttpHeaders()
    this.headers.append('Content-Type','application/x-www-form-urlencoded');
    this.headers.append('Content-Type','application/json');
    // this.headers = this.headers.set('Authorization','Bearer  '+'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRjMDAxZjIxZTQyNDU0MTAyZGFjZDY1NjQ3ZGQyMTBhMzEzZjc2YTI3NTY3YzhhN2VlMzFlNTVkOGNhMGVjMTBlMDFmY2VjODk5ZmU0MGI4In0.eyJhdWQiOiIxIiwianRpIjoiZGMwMDFmMjFlNDI0NTQxMDJkYWNkNjU2NDdkZDIxMGEzMTNmNzZhMjc1NjdjOGE3ZWUzMWU1NWQ4Y2EwZWMxMGUwMWZjZWM4OTlmZTQwYjgiLCJpYXQiOjE1Njc0MjYzODAsIm5iZiI6MTU2NzQyNjM4MCwiZXhwIjoxNTk5MDQ4NzgwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.FQelQ7rKU3xcRA4IIF48TBHcek9JqqkbL8F9QNt90MMG-YOOKbgQUIHmYt4axENa8P-QtfDeAKho2Xlyv9tTMVSlEedZDuTI5hfxaim7eqpqTmBqPkBCjJ_y21rj_FWW4wiLBa8GmpgFLax0zPbPI2ujHTmZJK9nUxargIHn01_uWGA2X8S7ZKcdCI9FMfk206hN3sjTRLGnxNVn-X73qYuAb6hyfJfhooFkVeCbdAdF0c1Rj2btJKuZpev-fQs-a8oDUvhk_uUkWZ4OzjvzUqaTEUN3TaA3-IoTbXjWfilnHz2KTWe6U-xAqTZ6uGcdDirQgXa1hmmr-qL32Xn-Nf1S-0UgJR7tNMVdX37D3Z0cMQn1cTxwQdRmEkZ2N4itjrQDxZSAAYJjntBZ2eRpu95smmsrTTohb36fu-j1VSio64AWstXWyjEk66EQXpJd7XrucR8TRuaJ8SFEftQ42xQb1H4cR0k1lXEEs3lpX_TD_YCgT96q7IpNBuGSQoY2x9VtSTLF3OI3rh67mLS7tpacbzGQxImcBWRknh7dGHJxVrWJ8mp4D22d6mUWneHFzhD9rZKkML0J3GE8b7br8GUc3w7dGqh1BzLY34yMDalInhpwfKwq2dKPD4wY5UQRXrYsmkzeysiLEXrQ1CETJ-_a8u9QucdVgOezGd-NdDg');
    // this.headers_nodejs.append('Content-Type','application/json');
    }

  public getJSON(path): Observable<any> {
      return this.http.get(path);
  }

  /**
   * Avoir le header pour l'authentification par token dans le backend
   */
  get_header(){
    this.headers = this.headers.set('Authorization','Bearer  '+localStorage.getItem('token'))
    return this.headers
  }

  /**
    *Rechercher des entités dans le backend
   * @param name 
   */
  search_entite(name): Observable<Array<siteInterface>> {
    
    return this.http.post(this.apiURL+'/api/sites/search',{'word':name},{headers: this.get_header()})
    .pipe(
      map((response:Array<siteInterface>) => {
        return response;
      }),
      catchError((err)=>of([]))
      );
  }

  /**
   * Rechercher des entités dans le backend
   * @param name Object
   * @return que des les sites id
   */
  searchEntiteReturnId(url:string,postData:Object): Observable<any> {
    return this.http.post(this.apiURL+url,postData,{headers: this.get_header()})
    .pipe(
      tap((response) => {

        return response;
      })
      );
  }

  /**
   * Rechercher des adresses en France
   * @param name adresse à chercher
   */
  search(name): Observable<AdresseAPIResponse> {
    return this.http.get('https://api-adresse.data.gouv.fr/search/?q='+name)
    .pipe(
      map((response: AdresseAPIResponse) => {
        // response.results = response.results
          // .map(user => new User(user.id, user.name))
          // Not filtering in the server since in-memory-web-api has somewhat restricted api
          // .filter(user => user.name.includes(filter.name))

        return response;
      }),
      catchError((err)=>of(undefined))
      );
  }

  searchAdresse(name): Observable<ResponseAdresseDataGouv[]> {
    let empytyResponse:{
      features:ResponseAdresseDataGouv[]
    } = {'features':[]}

    return this.http.get('https://api-adresse.data.gouv.fr/search/?q='+name)
    .pipe(
      catchError( (err) => of(empytyResponse) ),
      map((value: {'features':ResponseAdresseDataGouv[]} ) => value.features)
      );
  }

  searchAdresseGoogle(name): Observable<ResponseAdresseGoogle[]> {

    // var url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyCymRAVIaQTCxSLJjsYiA1t1CdcAaHSkEw&components=country:TN&types=geocode&language=fr&input=Rue+mohamed+khedhr'

    var url = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCymRAVIaQTCxSLJjsYiA1t1CdcAaHSkEw&components=country:TN&address='


    let empytyResponse:{
      results:ResponseAdresseGoogle[]
    } = {'results':[]}

    return this.http.get(url+name)
    .pipe(
      catchError( (err) => of(empytyResponse) ),
      map((value: {'results':ResponseAdresseGoogle[]} ) => value.results)
      );
  }
  
  /**
   * Cherché des départements par leurs noms ou numero insee
   * @param name nom du département cherhcé
   */
  search_departement(name): Observable<any> {
    if (name.length > 2) {
      return this.http.get('https://geo.api.gouv.fr/departements?nom='+name+'&fields=nom,code')
    .pipe(
      tap((response: any) => {
        response.results = response.results
          // .map(user => new User(user.id, user.name))
          // Not filtering in the server since in-memory-web-api has somewhat restricted api
          // .filter(user => user.name.includes(filter.name))

        return response;
      })
      );
    }else{
      return this.http.get('https://geo.api.gouv.fr/departements?code='+name+'&fields=nom,code')
    .pipe(
      tap((response: any) => {
        response.results = response.results
          // .map(user => new User(user.id, user.name))
          // Not filtering in the server since in-memory-web-api has somewhat restricted api
          // .filter(user => user.name.includes(filter.name))

        return response;
      })
      );
    }
    
  }

  search_adresse_entreprise(name): Observable<any>[] {
    
    return [
      this.http.get(this.apiURL+'/api/recherche?word='+name,{headers: this.get_header()})
    .pipe(
      tap((response: any) => {
         
      })
      ),

     this.http.get('https://api-adresse.data.gouv.fr/search/?q='+name)
    .pipe(
      tap((response: any) => {
        
      })
      )];
      
  }

  /**
   * geocodage inverse => adresse ministère
   * @param float lat 
   * @param float lon 
   */
  geocode_reverse(lat,lon) {
    return this.http.get('https://api-adresse.data.gouv.fr/reverse/?lon='+lon+'&lat='+lat)
    .pipe(
      tap((response: any) => {
        response.results = response.results
          // .map(user => new User(user.id, user.name))
          // Not filtering in the server since in-memory-web-api has somewhat restricted api
          // .filter(user => user.name.includes(filter.name))

        return response;
      })
      );
  }

  
  /**
   * geocodage inverse => limites administratives ministère
   * @param float lat 
   * @param float lon 
   */
  geocodeAdmReverse(lat,lon) {
    return this.http.get('https://geo.api.gouv.fr/communes?fields=nom,code&lon='+lon+'&lat='+lat)
    .pipe(
      tap((response: Array<{code:string,nom:string}>) => {
          // .map(user => new User(user.id, user.name))
          // Not filtering in the server since in-memory-web-api has somewhat restricted api
          // .filter(user => user.name.includes(filter.name))

        return response;
      })
      );
  }
  

  getQuerryObservable(url): Observable<any> {
    return this.http.get(url,{headers: this.get_header()})
    .pipe(
      tap((response) => {
        response.results = response.results
          // .map(user => new User(user.id, user.name))
          // Not filtering in the server since in-memory-web-api has somewhat restricted api
          // .filter(user => user.name.includes(filter.name))

        return response;
      })
      );
  }

  getGml(url){
    return this.http.get(url, {responseType: 'text',headers:this.headers_qgis_serveur})
    .pipe(
      tap( // Log the result or error
        // data => console.log(1),
        // error => console.log(error)
      )
    );
  }

  /**
   * Faire des requetes en get avec les headers d'authentification
   * @param string path url
   */
  get_requete(path) {
    // this.headers = this.headers.set('Authorization','Bearer  '+'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRjMDAxZjIxZTQyNDU0MTAyZGFjZDY1NjQ3ZGQyMTBhMzEzZjc2YTI3NTY3YzhhN2VlMzFlNTVkOGNhMGVjMTBlMDFmY2VjODk5ZmU0MGI4In0.eyJhdWQiOiIxIiwianRpIjoiZGMwMDFmMjFlNDI0NTQxMDJkYWNkNjU2NDdkZDIxMGEzMTNmNzZhMjc1NjdjOGE3ZWUzMWU1NWQ4Y2EwZWMxMGUwMWZjZWM4OTlmZTQwYjgiLCJpYXQiOjE1Njc0MjYzODAsIm5iZiI6MTU2NzQyNjM4MCwiZXhwIjoxNTk5MDQ4NzgwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.FQelQ7rKU3xcRA4IIF48TBHcek9JqqkbL8F9QNt90MMG-YOOKbgQUIHmYt4axENa8P-QtfDeAKho2Xlyv9tTMVSlEedZDuTI5hfxaim7eqpqTmBqPkBCjJ_y21rj_FWW4wiLBa8GmpgFLax0zPbPI2ujHTmZJK9nUxargIHn01_uWGA2X8S7ZKcdCI9FMfk206hN3sjTRLGnxNVn-X73qYuAb6hyfJfhooFkVeCbdAdF0c1Rj2btJKuZpev-fQs-a8oDUvhk_uUkWZ4OzjvzUqaTEUN3TaA3-IoTbXjWfilnHz2KTWe6U-xAqTZ6uGcdDirQgXa1hmmr-qL32Xn-Nf1S-0UgJR7tNMVdX37D3Z0cMQn1cTxwQdRmEkZ2N4itjrQDxZSAAYJjntBZ2eRpu95smmsrTTohb36fu-j1VSio64AWstXWyjEk66EQXpJd7XrucR8TRuaJ8SFEftQ42xQb1H4cR0k1lXEEs3lpX_TD_YCgT96q7IpNBuGSQoY2x9VtSTLF3OI3rh67mLS7tpacbzGQxImcBWRknh7dGHJxVrWJ8mp4D22d6mUWneHFzhD9rZKkML0J3GE8b7br8GUc3w7dGqh1BzLY34yMDalInhpwfKwq2dKPD4wY5UQRXrYsmkzeysiLEXrQ1CETJ-_a8u9QucdVgOezGd-NdDg');

    // console.log(this.headers)
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiURL+path,{headers: this.get_header()})
        .toPromise()
        .then(
          res => {  
            // console.log(res)
            resolve(res);
          },
          msg => { // Error
          reject(msg);
          }
        );
    });
    return promise;
  }
  

  get_filiale_dans_extent(url,data){
    return this.http.post(this.apiURL+url, data ,{responseType: 'json',headers:this.headers_qgis_serveur})
    .pipe(
      // map((response)=>{
      //   return response
      // })
      // ,
      tap( // Log the result or error
        // data => console.log(1),
        // error => console.log(error)
      )
    )
    // Promise< Client[]>
  }

  /**
   * Faire des requetes en post avec les headers d'authentification
   * @param string path url
   * @param Object data
   */
  post_requete<T>(data,url):Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post<T>(this.apiURL+url,data,{headers: this.get_header()})
        .toPromise()
        .then(
          res => {  
            // console.log(res)
            resolve(res);
          },
          msg => { // Error
           
          reject(msg.error);
          }
        );
    });
    // return promise;
  }


    /**
   *Recuperer les sites de la BD
   * @param Array<number> ids
   * @return promise
   */
  getSites(ids:Array<number>):Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL+'/api/sites/get',{ ids: ids },{headers: this.get_header()})
        .toPromise()
        .then(
          res => {  
            // console.log(res)
            resolve(res);
          },
          msg => { // Error
           
          reject(msg.error);
          }
        );
    });
    // return promise;
  }

  login(data) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.apiURL+'/api/user/login',data,{headers: this.headers})
        .toPromise()
        .then(
          res => {  
            // console.log(res)
            resolve(res);
          },
          msg => { // Error
          reject(msg);
          }
        );
    });
    return promise;
  }

  get_user() {
   
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.apiURL+'/api/user/get',{headers: this.get_header()})
        .toPromise()
        .then(
          res => {  
            // console.log(res,10)
            resolve(res);
          },
          msg => { // Error
            // console.log(msg,11)
            reject(msg);
          }
        );
    });
    return promise;
  }

  
  saveDigitaliseData(data:ResultOfDigitalise){
    return this.http.post(this.apiURL+'/api/geometry/updateGeometry', data, {headers: this.get_header()})
  }
  
  getSiteFromDB(site_id : number) : Observable<reponseDB>{
    let headers = this.get_header()
    return this.http.post<reponseDB>(this.apiURL+'/api/sites/get',{ ids: [site_id] },{headers: headers})
  }

  reverseGeoCode(lat:number,lon:number):Observable<ResponseAdresseDataGouv[]> {
    let empytyResponse:{
      features:ResponseAdresseDataGouv[]
    } = {'features':[]}

    return this.http.get('https://api-adresse.data.gouv.fr/reverse/?lon='+lon+'&lat='+lat)
    .pipe(
      catchError( (err) => of(empytyResponse) ),
      map((value: {'features':ResponseAdresseDataGouv[]} ) => value.features)
      );
  }

  reverseGeocodeGoogle(lat:number,lon:number):Observable<ResponseAdresseGoogle[]|PlusCode>{
    let empytyResponse:{
      results:ResponseAdresseGoogle[]
      plusCode:PlusCode
    } = {
      'results':[],
      'plusCode':undefined,
    }
    let url = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCymRAVIaQTCxSLJjsYiA1t1CdcAaHSkEw&result_type=street_address&latlng='
    // https://api-adresse.data.gouv.fr/reverse/?lon=5.039535648879041&lat=45.023792129538606

    return this.http.get(url+lat+','+lon)
    .pipe(
      catchError( (err) => of(empytyResponse) ),
      map((value: {'results':ResponseAdresseGoogle[],plus_code:PlusCode} ) =>  value.results.length>0 ?value.results:value.plus_code )
      );
  }

}
