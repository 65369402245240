import { Component, OnInit, Inject, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BackendApiService } from '../../../services/backend-api.service'
import { GeneralService } from '../../../services/general.service'
import { elemCartoInterface } from '../../../type/index'

@Component({
  selector: 'app-edit-element-cartographique',
  templateUrl: './edit-element-cartographique.component.html',
  styleUrls: ['./edit-element-cartographique.component.scss']
})

export class EditElementCartographiqueComponent implements OnInit {

  formulaire: FormGroup
  loading = false
  disabled = true
  color: any

  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    private GeneralService: GeneralService,
    public dialogRef: MatDialogRef<EditElementCartographiqueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: elemCartoInterface
  ) {
    this.formulaire = fb.group({})
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * user a changé la couleur
   * @param new_color {value:string}
   */
  colorChanged(new_color: {value:string}) {
    this.formulaire.get('color').setValue( this.color , { emitEvent: true })
    // console.log(this.color, this.formulaire.get('color').value)
  }

  ngOnInit() {

    this.initialiseForm()
  }

  /**
   * initialiser les formulaire
   */
  initialiseForm() {
    this.formulaire.addControl('valeur', new FormControl(this.data.valeur))
    this.formulaire.addControl('color', new FormControl(this.data.color))
    this.color = this.data.color

    this.formulaire.valueChanges.pipe().subscribe(() => {
      this.disabled = false
    })
  }

  disable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: true });
      }
    }
  }

  enable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: false });
      }
    }
  }

  /**
   * Valider les modifications
   */

  valider() {
    this.loading = true
    var donne = this.data
    donne.valeur = this.formulaire.get('valeur').value
    donne.color = this.formulaire.get('color').value

    this.BackendApiService.post_requete(donne, '/api/elements/update').then(
      (response: any) => {
        if (!response.error) {
          this.loading = false
          this.disabled = false
          this.enable_tous_les_form()
          this.dialogRef.close(this.data)
        } else {
          this.onQuerryError(response.message)
        }

      },
      (err) => {
        this.onQuerryError(err.message)
      }
    )
  }

  /**
   * Requète echouée
   * @param msg 
   */
  onQuerryError(msg: string) {
    this.enable_tous_les_form()
    this.loading = false
    this.disabled = false
    this._snackBar.open(msg, 'Fermer', {
      duration: 4000,
    });
  }
}
