import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialogRef } from '@angular/material';
import * as $ from 'jquery'
import { forkJoin, Observable, of, merge as mergeObservable } from 'rxjs';
import { debounceTime, tap, switchMap, finalize, filter, map, catchError, switchMapTo, switchAll, debounce } from 'rxjs/operators';
import { AdresseFeatureResponse, siteInterface } from 'src/app/type';
import { BackendApiService } from '../../services/backend-api.service';



@Component({
  selector: 'app-recherche-adresse-site',
  templateUrl: './recherche-adresse-site.component.html',
  styleUrls: ['./recherche-adresse-site.component.scss']
})
/**
 * search a site an adress
 */
export class RechercheAdresseSiteComponent implements OnInit {

  search:FormControl = new FormControl(undefined,Validators.required)
  searchResponse:Observable<AdresseFeatureResponse[]|siteInterface[]>
  width = $(window).width()
  isLoading=false

  constructor(
     private BackendApiService:BackendApiService,
     public dialogRef: MatDialogRef<RechercheAdresseSiteComponent>,
    ) { }

  ngOnInit() {
    this.searchResponse = this.search.valueChanges.pipe(
      debounceTime(500),
      filter(value=>(typeof value === "string" && value != undefined && value.length > 2)),
      catchError((err)=>of([])),
      switchMap((value)=>{
        this.isLoading= true
        return forkJoin(...[this.BackendApiService.search_entite(value.toString().split(" ").join("+")),this.BackendApiService.search(value.toString().split(" ").join("+")).pipe(map(value=>value.features))]).pipe(
          map((value) => value[1].concat(value[0]))
          ) 
      })
      
    )
    
    this.searchResponse.subscribe(
      (val)=>{
        setTimeout(()=>{
          this.isLoading= false
        },500)
      }
    )
  }

  /**
   * option selected
   * @param value:MatAutocompleteSelectedEvent
   */
  optionSelected(value:MatAutocompleteSelectedEvent){
    this.dialogRef.close({
      type:this.isInstanceOfAdresse(value.option.value)?'adresse':'site',
      adresse:this.isInstanceOfAdresse(value.option.value)?value.option.value:undefined,
      site:this.isInstanceOfAdresse(value.option.value)?undefined:value.option.value,
    });
  }

  /**
   * is data instance of siteInterface
   * @param data any
   * @return boolean
   */
  isInstanceOfSIte(data:any):boolean{
    return data['type'] != 'Feature'
  }

  /**
   * is data instance of AdresseFeatureResponse
   * @param data any
   * @return boolean
   */
  isInstanceOfAdresse(data:any):boolean{
    return data['type'] == 'Feature'
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
