import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { environment } from '../../../../environments/environment';
import { SitesCartoService } from '../../../services/sites-carto.service'
import { StockageService } from '../../../services/stockage.service'
import { GeneralService } from '../../../services/general.service'
import { BackendApiService } from '../../../services/backend-api.service'
import { AuthService, User } from '../../../services/auth.service'
import { EditionGeographiqueComponent, ModelDataEdition } from '../../../modal/edition-geographique/edition-geographique.component'
import { transformExtent, VectorLayer, Feature, Style, Fill, Stroke, fromLonLat, Geometry } from 'src/app/ol-module';
import { randomColors } from '../../../utils/randomColors'
import { siteInterface, elemCartoInterface } from '../../../type/index';
import { MatDialog, MatSnackBar, MatTabContent } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { SupprimerComponent, donne_pour_sup } from 'src/app/modal/supprimer/supprimer.component'
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { booleanWithinTurf, geoHelper } from '../../../../app/helpers/geo'
import { ScreenHelper } from '../../../../app/helpers/screen'
import * as turf from '@turf/turf'
import { BehaviorSubject, EMPTY, from, ReplaySubject, Subject } from 'rxjs';
import { SitesService } from 'src/app/services/sites.service';
import { DeleteSiteConfirmationComponent } from '../modal/delete-site-confirmation/delete-site-confirmation.component';
import { BasemapsDigitaliser, DataForLegend, LayerToDigitalise } from 'src/app/digitaliser/model/layer-to-digitalise';
import { FeatureLike } from 'ol/Feature';
import { Circle } from 'ol/style';
import { EntiteComponent } from './entite/entite.component';
import { TeridealFeature } from 'src/app/models/teridealFeature';
import { DigitaliserComponent } from 'src/app/digitaliser/digitaliser.component';
import GeometryType from 'ol/geom/GeometryType';

@Component({
  selector: 'app-description-site',
  templateUrl: './description-site.component.html',
  styleUrls: ['./description-site.component.scss']
})

/**
 * Component that contains all the description of a site (information, suivi, entité etc...)
 */
export class DescriptionSiteComponent implements OnInit {

  @Input() site: siteInterface
  @Output() close = new EventEmitter()
  @Output() openListSite = new EventEmitter()
  @Output() openPteSiteFun = new EventEmitter()
  @Output() onCloseMapOverlay = new EventEmitter()

  @ViewChild('properties') set propertiesDiv(propertiesDiv: ElementRef<HTMLElement>) {
    this.width = propertiesDiv.nativeElement.clientWidth
  }

  @ViewChild(EntiteComponent) entiteComponent: EntiteComponent

  environment = environment
  width: number = $('#properties').width()
  user: User = this.AuthService.get_user()

  public onInitInstance: () => void
  public onEditFeatureInstance: () => void
  public onDeleteSiteInstance: (site: siteInterface) => void
  public onCopyShareLinkOfAsite: (site: siteInterface) => void
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  /**
  * Gestionnaire de notifications
  */
  protected readonly notifier: NotifierService;

  screenHelper = ScreenHelper

  constructor(
    public SitesCartoService: SitesCartoService,
    public GeneralService: GeneralService,
    public StockageService: StockageService,
    public AuthService: AuthService,
    public BackendApiService: BackendApiService,
    public randomColors: randomColors,
    notifierService: NotifierService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private sitesService: SitesService
  ) {
    this.notifier = notifierService;

    const onInit: Subject<void> = new ReplaySubject<void>(1)
    this.onInitInstance = () => {
      onInit.next()
    }
    onInit.pipe(
      takeUntil(this.destroyed$),
      tap(() => {
        this.width = $('#properties').width()
      }),
      switchMap(() => {
        return this.StockageService.sites.pipe(
          tap(() => {
            if (this.site) {
              var sites_id = this.site.sites_id
              this.site = this.StockageService.getSIte(sites_id)
            }
          })
        )
      })
    ).subscribe()

    const onEditFeatures: Subject<void> = new Subject<void>()
    this.onEditFeatureInstance = () => {
      onEditFeatures.next()
    }

    /**
   * Ouvrir la fenetre de modification des éléments géographique d'un site
   */
    onEditFeatures.pipe(
      takeUntil(this.destroyed$),
      switchMap(() => {

        let layersToDigitalise: Array<LayerToDigitalise> = []


        let loadWfsFeatures = (layer: VectorLayer) => {
          layer.getSource().setLoader((extent, resolution, projection) => {

            var extent_vieuw = this.SitesCartoService.get_extent_map()
            var url = layer.get('url') +
              '?bbox=' + transformExtent(extent_vieuw, 'EPSG:3857', 'EPSG:4326').join(',') +
              '&layers=' + layer.get('identifiant') +
              '&PLUGIN_PROJECT=terideal&plugin=true&' + environment.id_bd_qgis_server + '=' + this.site.sites_id;
            this.BackendApiService.get_requete(url).then(
              (data: any) => {
                let features: any = layer.getSource().getFormat().readFeatures(data['data'])
                layer.getSource().addFeatures(features);

                layer.getSource().dispatchEvent(
                  'featuresloadend'
                )
              },
              (err) => {
                layer.getSource().removeLoadedExtent(extent_vieuw);
                layer.getSource().dispatchEvent(
                  'featuresloaderror'
                )
              }
            )
          })
        }

        let layerPolygon = this.SitesCartoService.getWfsComposantFromTypologieWithoutLayer('PERI', 'polygon')[0]
        layerPolygon.setOpacity(0.7)
        loadWfsFeatures(layerPolygon)
        layersToDigitalise.push({
          legend: new BehaviorSubject<DataForLegend[]>(this.GeneralService.get_config_projet().layers.polygon.filter((elemCarto) => elemCarto.alias != 'PERI').map(
            (elemCarto) => {
            return {
              style: new Style({
                fill: new Fill({ color: elemCarto.color }),
                stroke: new Stroke({ color: 'black', width: 2 }),

              }),
              alias: elemCarto.alias,
              title: elemCarto.valeur,
              img: environment.apiUrl + '/assets/elem_geo/' + elemCarto.icon,
              geomType: 'polygon',
              checked: true
            } as DataForLegend
          }
          )
          ),
          style: function (feature: FeatureLike, resolution: number) {
            let FMAPfeatures = this.layer.getSource().get('FMAPfeatures') ? this.layer.getSource().get('FMAPfeatures') : []
            let legend = this.legend.value.find((legend) => legend.alias === feature.get('alias'))
            if (FMAPfeatures.filter((item) => item.id == feature.getId() && item.deleted == true).length > 0 || legend.checked == false) {
              return null
            }
            return this.legend.value.find((legend) => legend.alias == feature.get('alias')).style
          },
          nom: "",
          canBeDelete: true,
          canBeAdd: true,
          canBeUpdate: true,
          iconPath: 'string',
          typologie: 'polygon',
          layer: layerPolygon,
          attributeComponentPath: 'elem-carto-form/elem-carto-form.component',
          attributeComponentName: 'ElemCartoFormComponent',
          layer_id: 2,
          model: 'polygones',
          fieldId: 'id',
          geometryCanBeEditByOthers: true
        }
        )



        let layerLinestring = this.SitesCartoService.getWfsComposantFromTypologieWithoutLayer('', 'linestring')[0]
        loadWfsFeatures(layerLinestring)
        layersToDigitalise.push({
          legend: new BehaviorSubject(this.GeneralService.get_config_projet().layers.linestring.map((elemCarto) => {
            return {
              style: new Style({
                stroke: new Stroke({ color: elemCarto.color, width: 5 })
              }),
              alias: elemCarto.alias,
              title: elemCarto.valeur,
              img: environment.apiUrl + '/assets/elem_geo/' + elemCarto.icon,
              geomType: 'linestring',
              checked: true
            } as DataForLegend

          })),
          style: function (feature: FeatureLike, resolution: number) {
            let FMAPfeatures = this.layer.getSource().get('FMAPfeatures') ? this.layer.getSource().get('FMAPfeatures') : []
            let legend = this.legend.value.find((legend) => legend.alias === feature.get('alias'))
            if (FMAPfeatures.filter((item) => item.id == feature.getId() && item.deleted == true).length > 0 || legend.checked == false) {
              return null
            }
            return this.legend.value.find((legend) => legend.alias == feature.get('alias')).style
          },
          nom: "",
          canBeDelete: true,
          canBeAdd: true,
          canBeUpdate: true,
          iconPath: 'string',
          typologie: 'linestring',
          layer: layerLinestring,
          attributeComponentPath: 'elem-carto-form/elem-carto-form.component',
          attributeComponentName: 'ElemCartoFormComponent',
          layer_id: 1,
          model: 'lignes',
          fieldId: 'id',
          geometryCanBeEditByOthers: true
        }
        )

        let layerPoint = this.SitesCartoService.getWfsComposantFromTypologieWithoutLayer('', 'point')[0]
        loadWfsFeatures(layerPoint)
        layersToDigitalise.push({
          legend: new BehaviorSubject(this.GeneralService.get_config_projet().layers.point.map((elemCarto) => {
            let circle = new Circle({
              radius: 10,
              fill: new Fill({ color: elemCarto.color }),
              stroke: new Stroke({ color: 'black', width: 2 }),
            })
            circle.setOpacity(0.7)
            return {
              style: new Style({
                image: circle
              }),
              alias: elemCarto.alias,
              title: elemCarto.valeur,
              img: environment.apiUrl + '/assets/elem_geo/' + elemCarto.icon,
              geomType: 'point',
              checked: true
            } as DataForLegend

          })),
          style: function (feature: FeatureLike, resolution: number) {
            let FMAPfeatures = this.layer.getSource().get('FMAPfeatures') ? this.layer.getSource().get('FMAPfeatures') : []

            let legend = this.legend.value.find((legend) => legend.alias === feature.get('alias'))

            if (FMAPfeatures.filter((item) => item.id == feature.getId() && item.deleted == true).length > 0 || legend.checked == false) {
              return null
            }
            return this.legend.value.find((legend) => legend.alias == feature.get('alias')).style
          },
          nom: "",
          canBeDelete: true,
          canBeAdd: true,
          canBeUpdate: true,
          iconPath: 'string',
          typologie: 'point',
          layer: layerPoint,
          attributeComponentPath: 'elem-carto-form/elem-carto-form.component',
          attributeComponentName: 'ElemCartoFormComponent',
          layer_id: 3,
          model: 'points',
          fieldId: 'id',
          geometryCanBeEditByOthers: true
        }
        )

        let layerPerimetre = this.SitesCartoService.getWfsPerimetre('all')[0]
        layerPerimetre.setVisible(true)
        loadWfsFeatures(layerPerimetre)
        layersToDigitalise.push({
          legend: new BehaviorSubject([
            {
              style: new Style({
                stroke: new Stroke({ color: 'red', width: 5 })
              }),
              alias: 'PERI',
              title: 'Périmètre',
              img: environment.apiUrl + '/assets/elem_geo/perimetre.png',
              checked: true,
              geomType: 'polygon',
            }as DataForLegend
          ]),
          style: function (feature: FeatureLike, resolution: number) {
            let FMAPfeatures = this.layer.getSource().get('FMAPfeatures') ? this.layer.getSource().get('FMAPfeatures') : []
            let legend = this.legend.value.find((legend) => legend.alias === feature.get('alias'))
            if (FMAPfeatures.filter((item) => item.id == feature.getId() && item.deleted == true).length > 0 || legend.checked == false) {
              return null
            }
            return this.legend.value.find((legend) => legend.alias == feature.get('alias')).style
          },
          nom: "",
          canBeDelete: true,
          canBeAdd: true,
          canBeUpdate: true,
          iconPath: 'string',
          typologie: 'polygon',
          layer: layerPerimetre,
          attributeComponentPath: 'elem-carto-form/elem-carto-form.component',
          attributeComponentName: 'ElemCartoFormComponent',
          layer_id: 4,
          model: 'polygones',
          fieldId: 'id',
          geometryCanBeEditByOthers: false
        }
        )

        let basemaps: BasemapsDigitaliser =
        {
          bamesMaps: [
            {
              layer: this.SitesCartoService.getDefaultMapLayer(),
              source: this.SitesCartoService.getDefaultMapLayer().getSource(),
              nom: 'Plan',
              type: 'fond',
              visible: false
            },
            {
              layer: this.SitesCartoService.getIgnLayer(),
              source: this.SitesCartoService.getIgnLayer().getSource(),
              nom: 'Satellite',
              type: 'fond',
              visible: true
            }
          ]
        }

        

        var size = [window.innerWidth * 0.95 + 'px', window.innerHeight * 0.95 + 'px']
        let data: ModelDataEdition = {
          basemaps: basemaps,
          layersToDigitalise: layersToDigitalise,
          sites_id: this.site.sites_id,
          getAllLegendsToNotSHow:function(layersToDigitalise){
            let val = layersToDigitalise.map((layersToD)=>{
              let uniqueAlias = layersToD.layer.getSource().getFeatures().map((feature)=>feature.get('alias')).filter((value, index, self)=>{
                return self.indexOf(value) === index;
              })
    
              return uniqueAlias.map((alias)=>{
                return {
                  alias,
                  typologie:layersToD.typologie
                }
              })
            })

            return [].concat.apply([], val)
          },
          canCommitAttribute: function (params: Array<{
            feature: Feature<Geometry>;
            layerToDigitalise: LayerToDigitalise;
          }>) {
            return params
              .filter((pte) => {
                let prop = pte.feature.getProperties() as TeridealFeature
                return prop.typo_codes_id == undefined
              })
              .slice(0, 1)
              .map((pte) => {
                return pte.feature
              })
          },
          canCommitGeometrys: function (allComitedFeatures: {
            feature: Feature<Geometry>;
            layerToDigitalise: LayerToDigitalise;
          }[]) {
            let emprisesGeometry: Array<Geometry> = this.layersToDigitalise
              .map((item) => {
                let layer: VectorLayer = item.layer;
                return layer
              })
              .map((layer) => layer.getSource().getFeatures()
                .filter((feature) => feature.get('alias') == 'PERI')
              )
              .reduce((acc, val) => acc.concat(val), [])
              .map((feature) => feature.getGeometry())

              return allComitedFeatures
                  .filter((feature) => feature.feature.getGeometry().getType() === GeometryType.POLYGON || GeometryType.LINE_STRING || GeometryType.POINT)
                  .map((feature) => {
                    let checkFeature = emprisesGeometry
                      .map((empriseGeom) => booleanWithinTurf(feature.feature.getGeometry(), empriseGeom))
                      .find((response) => response === true)
                    if(!checkFeature) {
                      return feature
                    }
                    return null
                  })
                  .filter((feature) => feature)
          }
        }

        return this.dialog.open(EditionGeographiqueComponent, {
          width: size[0],
          maxWidth: size[0],
          height: size[1],
          maxHeight: size[1],
          disableClose: true,
          panelClass: ['panelWithNoPadding'],
          data: data
        }).afterClosed().pipe(
          filter((result) => result),
          take(1),
          switchMap((result) => {
            return this.StockageService.updateSite(this.site.sites_id)
          }),
          tap((site) => {
            let map = this.SitesCartoService.map
            map.getView().animate({ center: fromLonLat(JSON.parse(site.geojson)['geometry']['coordinates']) }, { zoom: map.getView().getZoom() + 1 })
            map.getView().animate({ center: fromLonLat(JSON.parse(site.geojson)['geometry']['coordinates']) }, { zoom: map.getView().getZoom() - 1 })

            var layer_emprise = this.SitesCartoService.getLayerByAttr<VectorLayer>('emprise', 'nom', true)[0]
            var oldPerimetre = this.SitesCartoService.get_feature_in_source_by_attr(layer_emprise.getSource(), 'sites_id_polygones', this.site.sites_id)

            if (oldPerimetre.length > 0) {
              layer_emprise.getSource().removeFeature(oldPerimetre[0])
            }
            this.SitesCartoService.getPerimetres(this.site.sites_id.toString())

            try {
              this.entiteComponent.onRefreshtInstance()

            } catch (error) {

            }

          })
        )

      })
    ).subscribe()

    const onDeleteSite: Subject<siteInterface> = new Subject<siteInterface>()
    this.onDeleteSiteInstance = (site: siteInterface) => {
      onDeleteSite.next(site)
    }
    onDeleteSite.pipe(
      takeUntil(this.destroyed$),
      switchMap((site) => {
        let data = {
          'champ_id': ['sites_id', site.sites_id],
          'titre': 'Supprimer le site ' + site.nom_site + ' ?',
        }
        return this.dialog.open(DeleteSiteConfirmationComponent, {
          disableClose: true,
          data: data
        }).afterClosed().pipe(
          filter(value => value),
          switchMap((value) => {
            return this.sitesService.deleteSite({ sites_id: site.sites_id }).pipe(
              tap(() => {
                this.close.emit()
                this.SitesCartoService.deleteSiteInMap(site)
                this.StockageService.deleteSite(site)
              }),
              catchError(() => {
                this._snackBar.open('Une erreur est survenue lors de la suppression du site', 'Fermer', {
                  duration: 4000,
                });
                return EMPTY
              })
            )
          })
        )
      })
    ).subscribe()

    this.onCopyShareLinkOfAsite = (site: siteInterface) => {
      let shareLink = environment.url_projet + '/sites?id=' + site.sites_id
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = shareLink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      let copySucess = document.execCommand('copy');
      document.body.removeChild(selBox);

      if (copySucess) {
        this.notifier.notify("default", "Le lien du site a bien été copié dans votre presse papier ");
      } else {
        this.notifier.notify("error", "La copie du lien du site a échouée ");
      }
    }
  }

  ngOnInit() {
    this.onInitInstance()
  }

  /**
   * arrondir un nombre
   * @param numb 
   */
  round(numb: number) {
    return Math.round(numb)
  }

  /**
   * close properties panel
   */
  closeFun() {
    this.close.emit()
  }

  /**
   * open list sites
   */
  openListSiteFun() {
    this.openListSite.emit()
  }

  /**
   * Ouvrir un site depuis la barre de recherche
   * @param num_chantier 
   */
  openPteSite(num_chantier: number) {
    this.openPteSiteFun.next(num_chantier)
  }

  /**
   * Open url in another panel of the navigator
   * @param url string the url to open
   */
  openUrl(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

}
