import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DigitaliserComponent } from 'src/app/digitaliser/digitaliser.component';
import { BasemapsDigitaliser, LayerToDigitalise } from 'src/app/digitaliser/model/layer-to-digitalise';
import { Feature, Geometry } from 'src/app/ol-module';

export interface ModelDataEdition {
  basemaps:BasemapsDigitaliser,
  layersToDigitalise:LayerToDigitalise[],
  sites_id:number,
  getAllLegendsToNotSHow:(layersToDigitalise:LayerToDigitalise[])=> { alias: string; typologie: "polygon" | "point" | "linestring";}[]
  canCommitAttribute:(params:Array<{
    feature: Feature<Geometry>;
    layerToDigitalise: LayerToDigitalise;
  }>)=>Array<Feature<Geometry>>,
  canCommitGeometrys:(allComitedFeatures: {
    feature: Feature<Geometry>;
    layerToDigitalise: LayerToDigitalise;
}[])=>{
  feature: Feature<Geometry>;
  layerToDigitalise: LayerToDigitalise;
}[]
}

@Component({
  selector: 'app-edition-geographique',
  templateUrl: './edition-geographique.component.html',
  styleUrls: ['./edition-geographique.component.scss']
})
export class EditionGeographiqueComponent implements OnInit {

  @ViewChild(DigitaliserComponent) set digitaliserComponent (digitaliserComponent:DigitaliserComponent){
    this.close = ()=>{
      digitaliserComponent.onCloseInstance()
    }
  }
  
  close:()=>void

  constructor(
    public dialogref:MatDialogRef<EditionGeographiqueComponent>,
    @Inject(MAT_DIALOG_DATA) public data : ModelDataEdition
  ) { 
  }

  ngOnInit() {
  }

  closeDialod(status:boolean){
    this.dialogref.close(status)
  }

}
