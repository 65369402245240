import { Injectable } from '@angular/core';
import { BackendApiService } from './backend-api.service'
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { agenceInterface } from '../type';



export interface User {
  email: string;
  name: string;
  id: number;
  email_verified_at: string;
  updated_at: string;
  deleted_at: string;
  agence: Array<agenceInterface>;
  groupe_users_id:number
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public BackendApiService: BackendApiService,
    private router: Router,
  ) {

  }
  public user = new BehaviorSubject(<User>{});

  // user:User

  set_user(data: User) {

    this.user.next(data)
  }

  get_user() {

    /**
     * Renvois les informations sur un utilisateur
     * 
     * @return {User} les informations retournées : email,name,id,email_verified_at,updated_at
     */
    return this.user.getValue()

  }
  loggin(data) {
    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem('token', data['token'])
    localStorage.setItem('loginDate', new Date().toString())
    window.location.reload();

    // this.router.navigate(['map'])
  }

  cleanLocalstorage() {
    localStorage.setItem('isLoggedIn', 'false')
    localStorage.removeItem('loginDate')
    localStorage.removeItem('token')
  }

  logout() {

    this.BackendApiService.get_requete('/api/user/logout').then(() => {
      this.cleanLocalstorage()
      window.location.reload();
    })
    // this.router.navigate(['login'])
  }

  isLoggedIn() {
    var isLoggedIn = localStorage.getItem('isLoggedIn')
    var token = localStorage.getItem('token')

    if (isLoggedIn != null && isLoggedIn != 'false' && token != undefined) {
      return true
    } else {
      return false
    }
  }
}
