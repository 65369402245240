import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { siteInterface, reponseDB } from 'src/app/type';
import {BackendApiService} from 'src/app/services/backend-api.service'
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-list-sites',
  templateUrl: './list-sites.component.html',
  styleUrls: ['./list-sites.component.scss']
})
/**
 * Composant permettant d'afficher une liste de sites dans un bottom sheet
 */
export class ListSitesComponent implements OnInit {

  /**
   * loading sites from DB ?
   */
  loading:boolean = false
  /**
   * Liste des sites téléchargés
   */
  sites:Array<siteInterface>=[]

   /**
   * Gestionnaire de notifications,
   */
  notifier: NotifierService;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
     /**
      * Liste des sites_id qu'on souhaite afficher
      */
     sites_id: Array<number>
    },
    public _bottomSheetRef: MatBottomSheetRef<ListSitesComponent>,
    public notifierService: NotifierService,
    public BackendApiService:BackendApiService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.notifier = notifierService;
   }

  ngOnInit() {
    this.loadSites()
  }

  /**
   * Recuperer les sites de la BD et les tocker dans la variable this.sites 
   */
  loadSites(){
    this.sites = []
    this.loading = true
    this.BackendApiService.getSites(this.data.sites_id).then(
      (donne:reponseDB)=>{
        this.loading = false
        if (!donne.erreur) {
          console.log(donne,this.loading,1)
          this.notifier.notify("succes", donne.message);
          this.sites = donne.data
        }else{
          this.notifier.notify("error", donne.message);
        }
        this._changeDetectorRef.markForCheck();
      },
      (err)=>{
        this.loading = false
        this.notifier.notify("error", "Un problème est survenu");
        this._bottomSheetRef.dismiss();
        this._changeDetectorRef.markForCheck();
      }
    )
  }

}
