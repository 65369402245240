import { View, Feature  } from 'ol';
import Map from 'ol/Map';
import { getWidth, boundingExtent, getTopLeft, extend as MergeExtend, getCenter, equals as extentEquals } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import { Group as LayerGroup, Vector as VectorLayer } from 'ol/layer';
import { transform as Transform, fromLonLat, get as getProjection, transformExtent } from 'ol/proj';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileImage from 'ol/source/TileImage'
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy, all as allStrategy } from 'ol/loadingstrategy';
import { Cluster, ImageStatic } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorSourceEvent from 'ol/source/Vector';
import {
  Circle as CircleStyle, Fill, Stroke, Text, RegularShape, Icon
} from 'ol/style';
import Style from 'ol/style/Style';
import Overlay from 'ol/Overlay';
import WFS from 'ol/format/WFS';
import { buffer, extend as Extent, createEmpty as createEmptyExtent } from 'ol/extent';
import Zoom from 'ol/control/Zoom';
import Rotate from 'ol/control/Rotate';
import { defaults as defaultControls, Attribution } from 'ol/control';
import LinearRing from 'ol/geom/LinearRing';
import Point from 'ol/geom/Point';
import MultiPoint from 'ol/geom/MultiPoint';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Geometry from 'ol/geom/Geometry';
import { defaults as defaultInteractions, Modify, Select, Snap,Draw } from 'ol/interaction';
import { unByKey } from 'ol/Observable';
import Collection from 'ol/Collection';
import { singleClick, click,pointerMove } from 'ol/events/condition'
import Projection from 'ol/proj/Projection';
// @NgModule({
//   exports: [
//     fromLonLat
//   ]
// })


// export default class Feature<GeomType extends Geometry = Geometry>  extends olFeature {
//   constructor(opt_geometryOrProperties?: GeomType | { [key: string]: any }) {
//       super();
//   }
//   getLayer(map:any): void { };
 
// }

// interface Feature {
//     getLayer(map:any): void;
// }
/**
* This is a workaround.
* Returns the associated layer.
* @param {ol.Map} map.
* @return {ol.layer.Vector} Layer.
*/
// Feature.prototype.getLayer = function(map) {
//   var this_ = this, layer_, layersToLookFor = [];
//   /**
//    * Populates array layersToLookFor with only
//    * layers that have features
//    */
//   var check = function(layer){
//       var source = layer.getSource();
//       if(source instanceof VectorSource){
//           var features = source.getFeatures();
//           if(features.length > 0){
//               layersToLookFor.push({
//                   layer: layer,
//                   features: features
//               });
//           }
//       }
//   };
//   //loop through map layers
//   map.getLayers().forEach(function(layer){
//       if (layer instanceof LayerGroup) {
//           layer.getLayers().forEach(check);
//       } else {
//           check(layer);
//       }
//   });
//   layersToLookFor.forEach(function(obj){
//       var found = obj.features.some(function(feature){
//           return this_ === feature;
//       });
//       if(found){
//           //this is the layer we want
//           layer_ = obj.layer;
//       }
//   });
//   return layer_;
// };

var jsts = require('jsts')
var ol3Parser = new jsts.io.OL3Parser();
ol3Parser.inject(Point, LineString,LinearRing,Polygon,MultiPoint, MultiLineString, MultiPolygon, GeometryCollection);

export {
  fromLonLat,
  View,
  Map,
  VectorSource,
  GeoJSON,
  bboxStrategy,
  allStrategy,
  Cluster,
  VectorLayer,
  Style,
  Icon,
  CircleStyle,
  Stroke,
  Fill,
  Text,
  transformExtent,
  createEmptyExtent,
  Extent,
  Feature,
  ImageWMS,
  ImageLayer,
  Zoom,
  Rotate,
  Overlay,
  Point,
  TileLayer,
  Transform,
  Attribution, defaultControls,
  VectorSourceEvent,
  Projection,
  ImageStatic,
  getCenter,
  Polygon,
  LineString,
  defaultInteractions,
  Modify,
  Select,
  unByKey,
  Collection,
  boundingExtent,
  extentEquals,
  singleClick,
  click,
  LayerGroup,
  Snap,
  MultiPolygon,
  MultiLineString,
  ol3Parser,
  Geometry,
  Draw,
  pointerMove
};

