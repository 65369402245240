import { Component, OnInit, Inject, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { switchMap, debounceTime, tap, finalize, switchAll, map as MAPRJX, filter, takeUntil, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs'

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl, FormGroupName } from '@angular/forms';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector.js';
import { Map, View, Feature } from 'ol';
import { Group as LayerGroup, Vector as VectorLayer } from 'ol/layer.js';
import { transform as Transform, fromLonLat, transform, get as getProjection } from 'ol/proj.js';
import {
  Circle as CircleStyle, Fill, Stroke, Text, RegularShape, Icon
} from 'ol/style.js';
import Style from 'ol/style/Style';
import { feature } from '@turf/turf';
import Rotate from 'ol/control/Rotate';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { SitesCartoService } from 'src/app/services/sites-carto.service';
import { SitesService } from 'src/app/services/sites.service';
import { AdresseAPIResponse, AdresseFeatureResponse } from 'src/app/type';
import { GetAdresseComponent } from 'src/app/shared/pages/get-adresse/get-adresse.component';

const view = new View({
  center: fromLonLat([5, 45]),
  zoom: 18,
})

const map = new Map({
  target: 'map1',
  maxTilesLoading: 50,
  view: view
});

export interface dataLocalisation {
  donne: any;
  titre: string;
  feature: Feature;
  style: any;
  field_cp: string;
  field_adresse: string;
  field_commune: string;
  champ_id: any[];
}

@Component({
  selector: 'app-update-localisation-site',
  templateUrl: './update-localisation-site.component.html',
  styleUrls: ['./update-localisation-site.component.scss']
})
export class UpdateLocalisationSiteComponent implements OnInit {

  public onInitInstance :() => void;
  public onSubmitInstance:()=>void;
  @ViewChild(GetAdresseComponent) getAdresseComponent: GetAdresseComponent;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  formulaire: FormGroup
  showLoading = false

  x_coordinate : number;
  y_coordinate : number;

  constructor(
    private BackendApiService: BackendApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UpdateLocalisationSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data : dataLocalisation,
    private sitesService : SitesService,
    private cdRef : ChangeDetectorRef
  ) {

    // this.data.donne['x'] = parseFloat(this.data.donne['x'].toString())
    // this.data.donne['y'] = parseFloat(this.data.donne['y'].toString())

    var GeoJSON = JSON.parse(this.data.donne['geojson'])
    this.x_coordinate = GeoJSON['geometry']['coordinates'][0]
    this.y_coordinate = GeoJSON['geometry']['coordinates'][1]

    this.formulaire = this.fb.group({
      adresse : new FormControl(this.data.donne['adresse'], [Validators.required]),
      commune : new FormControl(this.data.donne['commune'], [Validators.required]),
      postal: new FormControl(this.data.donne['postal'], [Validators.required]),
      x: new FormControl(this.data.donne['x'], [Validators.required]),
      y: new FormControl(this.data.donne['y'], [Validators.required]),
    })


    const onSubmit : Subject<void>=new Subject<void>()
    this.onSubmitInstance = () => {
      onSubmit.next()
    }

    onSubmit.pipe(
      filter(() => this.formulaire.valid),
      tap(()=>{
        this.showLoading = true;
        this.cdRef.detectChanges();
        this.formulaire.disable();
      }),
      takeUntil(this.destroyed$),
      switchMap(()=>{
        if (this.formulaire.get('x').value) {
          var GeoJSON = JSON.parse(this.data.donne['geojson'])
          GeoJSON['geometry']['coordinates'] = [this.formulaire.get('x').value, this.formulaire.get('y').value]
        }

        var donne = {
          sites_id : this.data.champ_id[1],
          adresse : this.formulaire.get('adresse').value,
          commune : this.formulaire.get('commune').value,
          postal : this.formulaire.get('postal').value,
          x : this.formulaire.get('x').value.toString(),
          y : this.formulaire.get('y').value.toString(),
          geojson : JSON.stringify(GeoJSON)
        }

        var data = {
          donne: donne,
          champ_id: this.data.champ_id,
          feature: this.data.feature,
        }
        
        return this.sitesService.updateLocalisationSite(donne).pipe(
          catchError(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this._snackBar.open('Un problème est survenu lors de la mise à jour de l\'agence', 'Fermer', {
              duration: 4000,
            });
            return EMPTY;
          }),
          tap((response)=>{
            this.showLoading = false;
            this.formulaire.enable()
            data.donne['droits'] = response['droits']
            this.dialogRef.close(data)
          })
        )
      })
    ).subscribe()
   }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }


  ngAfterViewInit(){
    this.getAdresseComponent.adresseResult.pipe(
      filter(value=>value.feature != undefined),
      tap((value)=>{
        this.formulaire.get('adresse').setValue(value.adresse)
        this.formulaire.get('commune').setValue(value.ville)
        this.formulaire.get('postal').setValue(value.cp)
        this.formulaire.get('x').setValue(value.feature.geometry.coordinates[0]),
        this.formulaire.get('y').setValue(value.feature.geometry.coordinates[1])
      }),
      takeUntil(this.destroyed$)
    ).subscribe()
  }


}
