export const environment = {
  apiUrl: 'https://wsespace-verts.undermap.me/',
  projet_qgis_historique_wms:"https://wsespace-verts.undermap.me/api/wms_hist",
  projet_qgis_wms:"https://wsespace-verts.undermap.me/api/wms_visu",
  url_projet:"https://espace-verts.undermap.me",
  production: true,
  primaryColor:"#f9a01b",
  primaryColorTransparentRgba:"rgba(151,26,46,0.2)",
  width_interface:340,
  id_bd_qgis_server:"sites_id"
};