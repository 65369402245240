
import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, ViewContainerRef, ComponentFactoryResolver, Output, EventEmitter, TemplateRef, Inject } from '@angular/core';
import { View, fromLonLat, defaultControls, Attribution, Geometry, GeoJSON, Extent, createEmptyExtent, MultiPolygon, Polygon, Zoom, CircleStyle, Fill, Stroke, Style, VectorLayer } from '../ol-module';
import { LayerToDigitalise, BasemapsDigitaliser, ResultOfDigitalise, DataForLegend } from './model/layer-to-digitalise';
import Map from 'ol/Map';
import { Subject, ReplaySubject, Subscriber, merge, zip, EMPTY, BehaviorSubject } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { unByKey } from 'ol/Observable';
import { Observable } from 'rxjs/internal/Observable';
import Feature, { FeatureLike } from 'ol/Feature';
import { extend, isEmpty } from 'ol/extent';
import { FormDirective } from './directive/form.directive';
import { LoadComponentService } from './load-component/load-component.service';
import { UpdateAttributes } from './class/updateAttributes';
import { UpdateGeometry } from './class/updateGeometry';
import { NotifierService } from 'angular-notifier';
import { AddGeometry } from './class/addGeometry';
import { MatDialog } from '@angular/material';
import { AddRingToGeometry } from './class/addRingToGeometry';
import { SplitGeometry } from './class/splitGeometry';
import { MergeFeature } from './class/mergeFeature';
import { FeaturePopup } from './class/featurePopup';
import { getAllAddFeatureFromLayer, getAllDeletedFeatureFromLayer, getAllUpdatedFeatureFromLayer, initialiseSourceForDigitaliser } from './class/featureCrud';
import { BackendApiService } from '../services/backend-api.service';
import { ConfirmDialogComponent } from '../shared/pages/confirm-dialog/confirm-dialog.component';
import { SitesCartoService } from '../services/sites-carto.service';
import { MesureComponent } from '../shared/pages/mesure/mesure.component';

import { ModelDataEdition } from '../modal/edition-geographique/edition-geographique.component';
import { Draw, Modify, Select, Snap } from 'ol/interaction';
@Component({
  selector: 'app-digitaliser',
  templateUrl: './digitaliser.component.html',
  styleUrls: ['./digitaliser.component.scss']
})

export class DigitaliserComponent implements OnInit {

  public onInitInstance: () => void
  public onSubmitInstance: () => void
  public onCloseInstance: () => void
  /**
   * is the digitaliser modify ?
   */
  public isDigitaliserDirty: () => boolean
  public onDisableAllOperationInstance: () => void
  public toogleUpdateAttributeInstance: () => void
  public toogleUpdateGeometryInstance: () => void
  public toogleAddGeometryInstance: () => void
  public toogleAddRingToGeometryInstance: () => void
  public toogleSplitGeometryInstance: () => void
  public toogleMergeFeatureInstance: () => void

 

  @Input() layersToDigitalise: Array<LayerToDigitalise>
  @Input() basemapsDigitaliser: BasemapsDigitaliser
  @Input() sites_id: number
  @Input() data: ModelDataEdition
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  @ViewChild('mapDiv') set mapDiv(mapDiv: ElementRef) {
    if (mapDiv) {
      this.map.setTarget(mapDiv.nativeElement)
    }
  }
 

  @ViewChild(FormDirective) formHost: FormDirective;
  @ViewChild('popupTemplate', { read: ViewContainerRef }) popupTemplate: ViewContainerRef;
  @ViewChild('customNotification') customNotificationTmpl: TemplateRef<HTMLElement>;
  @ViewChild(MesureComponent) mesureComp: MesureComponent

  public updateAttributesInstance: UpdateAttributes
  public updateGeometryInstance: UpdateGeometry
  public addGeometryIntance: AddGeometry
  public addRingToGeometry: AddRingToGeometry
  public splitGeometry: SplitGeometry
  public mergeFeature: MergeFeature

  view = new View({
    center: fromLonLat([2.6648, 46.7592]),
    zoom: 6,
    minZoom: 6,
    constrainResolution: true,
  })

  map = new Map({
    maxTilesLoading: 50,
    view: this.view,
    controls: defaultControls({ attribution: false, zoom: false }).extend([new Attribution({ collapsible: false })]),
  });

  /**
   * is digitaliser ready ? means every features are loaded
   */
  isReady: boolean = false
   
 /**
   * listes des données pour la légende
   */
  dataForLegends:Array<BehaviorSubject<DataForLegend[]>>  = []
  loading: boolean = false

  /**
   * est ce que le digitaliseur a déja enregistré au moins une fois ? 
   */
  updateSaved:boolean = false

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public loadComponentService: LoadComponentService,
    public sitesCartoService: SitesCartoService,
    public notifierService: NotifierService,
    public dialog: MatDialog,
    public resolver: ComponentFactoryResolver,
    public backendApiService: BackendApiService
  ) {


    this.onDisableAllOperationInstance = () => {
      if (this.updateAttributesInstance.isEnable) {
        this.updateAttributesInstance.disable()
      }
      if (this.updateGeometryInstance.isEnable) {
        this.updateGeometryInstance.disable()
      }
      if (this.addGeometryIntance.isEnable) {
        this.addGeometryIntance.disable()
      }

      if (this.addRingToGeometry.isEnable) {
        this.addRingToGeometry.disable()
      }

      if (this.splitGeometry.isEnable) {
        this.splitGeometry.disable()
      }

      if (this.mergeFeature.isEnable) {
        this.mergeFeature.disable()
      }

    }

    this.toogleUpdateAttributeInstance = () => {
      if (this.updateAttributesInstance.isEnable) {
        this.updateAttributesInstance.disable()
      } else {
        this.onDisableAllOperationInstance()
        this.updateAttributesInstance.enable()
      }
    }

    this.toogleUpdateGeometryInstance = () => {
      if (this.updateGeometryInstance.isEnable) {
        this.updateGeometryInstance.disable()
      } else {
        this.onDisableAllOperationInstance()
        this.updateGeometryInstance.enable()
      }
    }

    this.toogleAddGeometryInstance = () => {
      if (this.addGeometryIntance.isEnable) {
        this.addGeometryIntance.disable()
      } else {
        this.onDisableAllOperationInstance()
        this.addGeometryIntance.enable()
      }
    }

    this.toogleAddRingToGeometryInstance = () => {
      if (this.addRingToGeometry.isEnable) {
        this.addRingToGeometry.disable()
      } else {
        this.onDisableAllOperationInstance()
        this.addRingToGeometry.enable()
      }
    }

    this.toogleSplitGeometryInstance = () => {
      if (this.splitGeometry.isEnable) {
        this.splitGeometry.disable()
      } else {
        this.onDisableAllOperationInstance()
        this.splitGeometry.enable()
      }
    }

    this.toogleMergeFeatureInstance = () => {
      if (this.mergeFeature.isEnable) {
        this.mergeFeature.disable()
      } else {
        this.onDisableAllOperationInstance()
        this.mergeFeature.enable()
      }
    }

    this.isDigitaliserDirty = () => {
      try {
        return this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => {
          return getAllAddFeatureFromLayer(item.layer).length +
            getAllDeletedFeatureFromLayer(item.layer).length +
            getAllUpdatedFeatureFromLayer(item.layer).length
        }).reduce((a, b) => a + b, 0) > 0
      } catch (error) {
        return false
      }

    }

    const onInit: Subject<void> = new ReplaySubject<void>(1)
    this.onInitInstance = () => {
      onInit.next()
    }

    onInit.pipe(
      takeUntil(this.destroyed$),
      filter(() => this.basemapsDigitaliser.bamesMaps.length > 0 && this.layersToDigitalise != undefined),
      tap(_ => {
        this.map.getLayers().getArray().slice().map((layer)=>this.map.removeLayer(layer))
        this.map.getInteractions().getArray().slice()
        .filter((interaction)=> interaction instanceof Snap || interaction instanceof Select || interaction instanceof Modify || interaction instanceof Draw)
        .map((interaction)=>this.map.removeInteraction(interaction))
        
        this.basemapsDigitaliser.bamesMaps.map((baseMap) => {
          let layer = baseMap.layer
          layer.setVisible(baseMap.visible)
          layer.set('nom', baseMap.nom)
          layer.set('type', baseMap.type)
          this.map.addLayer(layer)
          this.map.updateSize()
        })
      }),
      tap(() => {
        this.loading = true
        let featuresloadendEvent: Array<Observable<VectorSourceEvent<Geometry>>> = []
        let featuresloadErrorEvent: Array<Observable<VectorSourceEvent<Geometry>>> = []

        this.layersToDigitalise.map((layerToDigitalise) => {
          let layer = layerToDigitalise.layer
          layer.getSource().refresh()
          layer.set("layerToDigitaliseID", layerToDigitalise.layer_id)
          layer.setStyle((feature: FeatureLike, resolution: number) => {
            return layerToDigitalise.style(feature, resolution)
          })

          this.map.addLayer(layer)

          featuresloadendEvent.push(fromOpenlayerEvent<VectorSourceEvent<Geometry>>(layer.getSource(), 'featuresloadend'))
          featuresloadErrorEvent.push(fromOpenlayerEvent<VectorSourceEvent<Geometry>>(layer.getSource(), 'featuresloaderror'))

        })

        /**
         * if all features have not loaded normally this will fire
         */
        merge(featuresloadErrorEvent).pipe(
          take(1),
          switchMap((event) => {
            return event
          }),
          tap((event) => { console.log('featuresloadErrorEvent', event); this.loading = false; this.isReady = false; alert('UNE erreur lors du chargement des données') }
          )).subscribe()

        /**
         * is all features have load normally, this will fire
         */
        zip(...featuresloadendEvent).pipe(
          take(1),
          tap((events) => {
            let extent = createEmptyExtent()

            events.map((event) => {
              let source: VectorSource = event.target
              initialiseSourceForDigitaliser(source)
              Extent(extent, source.getExtent())
            })
           

            this.dataForLegends = this.layersToDigitalise.map((layerToDigitalise)=>layerToDigitalise.legend)

            const viewContainerRef = this.formHost.viewContainerRef;

            this.updateAttributesInstance = new UpdateAttributes(
              this.map,
              this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => item.layer),
              this.layersToDigitalise,
              viewContainerRef,
              this.loadComponentService,
              this.notifierService,
              this.dialog,
              this.customNotificationTmpl
            )



            this.updateGeometryInstance = new UpdateGeometry(
              this.map,
              this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => item.layer),
              this.layersToDigitalise,
              this.notifierService,
              this.customNotificationTmpl
            )

            this.addGeometryIntance = new AddGeometry(this.map,
              this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => item.layer),
              this.layersToDigitalise,
              viewContainerRef,
              this.loadComponentService,
              this.notifierService,
              this.dialog,
              this.sites_id,
              this.customNotificationTmpl
            )

            this.addRingToGeometry = new AddRingToGeometry(this.map,
              this.layersToDigitalise.filter((item) => item.canBeUpdate && item.geometryCanBeEditByOthers).map((item) => item.layer),
              this.layersToDigitalise.filter((item) => item.geometryCanBeEditByOthers),
              viewContainerRef,
              this.loadComponentService,
              this.notifierService,
              this.dialog,
              this.sites_id,
              this.customNotificationTmpl
            )

            this.splitGeometry = new SplitGeometry(
              this.map,
              this.layersToDigitalise.filter((item) => item.canBeUpdate && item.geometryCanBeEditByOthers).map((item) => item.layer),
              this.layersToDigitalise,
              viewContainerRef,
              this.loadComponentService,
              this.notifierService,
              this.dialog,
              this.sites_id,
              this.customNotificationTmpl
            )

            this.mergeFeature = new MergeFeature(
              this.map,
              this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => item.layer),
              this.layersToDigitalise,
              viewContainerRef,
              this.loadComponentService,
              this.notifierService,
              this.customNotificationTmpl
            )

            new FeaturePopup(
              this.map,
              this.layersToDigitalise.map((item) => item.layer),
              this.popupTemplate,
              this.resolver
            )

            if (!isEmpty(extent)) {
              this.view.fit(extent, { maxZoom: 19, duration: 1000 })
              window.setTimeout(() => {
                this.view.fit(extent, { duration: 500, maxZoom: this.view.getZoom() - 1 })
                this.toogleUpdateAttributeInstance()
              }, 1200)
              this.isReady = true
            }
            this.loading = false

          }
          )).subscribe()

      })
    ).subscribe()

    this.onCloseInstance = () => {
      if (this.isDigitaliserDirty()) {
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            confirmationTitle: "Enregistrez vos modifications avant",
            confirmationExplanation: "Voulez vous enregistrer vos modifications avant de fermer le digitaliseur ?",
            cancelText: "Quitter sans enregistrer",
            confirmText: "Enregistrer",
          }
        }
        ).afterClosed().pipe(
          take(1),
          tap((result) => {
            if (result) {
              this.onSubmitInstance()
            } else {
              this.close.emit(this.updateSaved)
            }
          })
        ).subscribe()
      } else {
        this.close.emit(this.updateSaved)
      }
    }

    const onSubmit: Subject<void> = new Subject<void>()
    this.onSubmitInstance = () => {
      onSubmit.next()
    }

    onSubmit.pipe(
      takeUntil(this.destroyed$),
      map(() => {
        let data: ResultOfDigitalise = {
          update: [],
          delete: [],
          add: []
        }

        let addFeatures: Array<{
          feature: Feature<Geometry>;
          layerToDigitalise: LayerToDigitalise;
        }> = [].concat.apply([], this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => getAllAddFeatureFromLayer(item.layer).map((feat) => { return { feature: feat, layerToDigitalise: item } })))

        let deletedFeatures: Array<{
          feature: Feature<Geometry>;
          layerToDigitalise: LayerToDigitalise;
        }> = [].concat.apply([], this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => getAllDeletedFeatureFromLayer(item.layer).map((feat) => { return { feature: feat, layerToDigitalise: item } })))

        let updatedFeatures: Array<{
          feature: Feature<Geometry>;
          layerToDigitalise: LayerToDigitalise;
        }> = [].concat.apply([], this.layersToDigitalise.filter((item) => item.canBeUpdate).map((item) => getAllUpdatedFeatureFromLayer(item.layer).map((feat) => { return { feature: feat, layerToDigitalise: item } })))


        let wrongFeatures = this.data.canCommitAttribute([...addFeatures, ...updatedFeatures])
        wrongFeatures.map((feature) => {
          notifierService.notify('error', 'Veuillez compléter vos entités')
          if (!this.updateAttributesInstance.isEnable) {
            this.toogleUpdateAttributeInstance()
          }
          this.updateAttributesInstance.selectFeature(feature)
        })

        if (wrongFeatures.length > 0) {
          return null
        }

        let outerFeatures:Array<Feature<Geometry>> = this.data.canCommitGeometrys(updatedFeatures.concat(addFeatures))
          .map((feature) => feature.feature.clone())
        if (
          outerFeatures.length > 0
        ) {

          this.blinkFeature(outerFeatures)
          notifierService.notify('error', 'Toutes vos entités doivent être dans au moins une emprise')
          return null
        }

        addFeatures.map((pte) => {
          data.add.push({
            model: pte.layerToDigitalise.model,
            proprietes: Object.assign(pte.feature.getProperties(), { geometry: null }),
            geometry: new GeoJSON().writeGeometryObject(pte.feature.getGeometry(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
          })
        })

        deletedFeatures.map((pte) => {
          data.delete.push({
            model: pte.layerToDigitalise.model,
            id: pte.feature.get(pte.layerToDigitalise.fieldId),
          })
        })

        updatedFeatures.map((pte) => {
          data.update.push({
            model: pte.layerToDigitalise.model,
            id: pte.feature.get(pte.layerToDigitalise.fieldId),
            proprietes: Object.assign(pte.feature.getProperties(), { geometry: null }),
            geometry: new GeoJSON().writeGeometryObject(pte.feature.getGeometry(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
          })
        })



        if (data.update.length + data.delete.length + data.add.length == 0) {
          this.notifierService.notify('info', "Aucune modification à enregistrer")
        }


        return data

      }),
      filter((data) => data && data.update.length + data.delete.length + data.add.length > 0),
      switchMap((data) => {
        this.loading = true
        return this.backendApiService.saveDigitaliseData(data).pipe(
          catchError((err) => {
            this.loading = false
            this.notifierService.notify('error', "Une érreur est survenue lors de la sauvegarde")
            return EMPTY
          }),
          tap((res) => {
            this.notifierService.notify('success', "Vos modifications ont bien été sauvegardées")
            this.loading = false
            this.updateSaved = true
            this.onInitInstance()
          })
        )
      })
    ).subscribe()

  }

  ngOnInit(): void {
    this.mesureComp.setMapInstance(this.map)
    this.mesureComp.initialise_mesure()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.layersToDigitalise) {
      if (this.layersToDigitalise && this.basemapsDigitaliser) {
        this.onInitInstance()
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let zooms = new Zoom({
      'target': 'zoomsDigitaliser',
      'zoomInLabel': document.getElementById('zoomIn'),
      'zoomOutLabel': document.getElementById('zoomOut'),
      'zoomInTipLabel': 'Zoomer',
      'zoomOutTipLabel': 'Dézoomer'
    })
    zooms.setMap(this.map)
    this.map.updateSize()
  }

  /**
   * Faire clignoter des entités
   */
   blinkFeature(features : Array<Feature<Geometry>>) {
    let layer = new VectorLayer({
      source: new VectorSource({
        features: features
      }),
      style: function () {
        let style = new Style({
          fill: new Fill({ color: '#fc7981' }),
          stroke: new Stroke({ color: 'red', width:2 }),
          image: new CircleStyle({
            radius: 4,
            stroke: new Stroke({ color: 'red', width: 6 }),
            fill: new Fill({
                color: '#fc7981',
            }),
        }),
        })
        return style
      }
    })
    layer.setZIndex(99999999999999999999999)
    this.map.addLayer(layer)
    for (let index = 200; index < 5000; index+=400) {
      setTimeout(() => {
        layer.getSource().clear()
      }, index)
      setTimeout(() => {
        layer.getSource().addFeatures(features)
      }, index+200)
    }
    setTimeout(() => {
      layer.getSource().clear()
      this.map.removeLayer(layer)
    }, 5000)
  }

}

export function fromOpenlayerEvent<T>(element: VectorSource<Geometry>, eventName: string): Observable<T> {

  return new Observable((observer: Subscriber<T>) => {
    const handler = (e: T) => observer.next(e);

    let eventKey = element.on(eventName, handler)

    return () => {
      unByKey(eventKey)
    }
  });
}