import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service'
export interface donne_pour_modification {
  dictionnaire: Array<any>;
  donne: {};
  champ_id: any[];
  titre: string;
  url?: string;
}

@Component({
  selector: 'app-modification',
  templateUrl: './modification.component.html',
  styleUrls: ['./modification.component.scss']
})
export class ModificationComponent implements OnInit {

  formulaire: FormGroup
  loading = false
  disabled = true
  
  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public dialogRef: MatDialogRef<ModificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_modification
  ) {
    this.formulaire = fb.group({})
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    for (let index = 0; index < this.data.dictionnaire.length; index++) {
      const element = this.data.dictionnaire[index];

      if (element['sous_type'] != 'id') {
        var validators = []
        
        if (element['required']) {
          validators.push(Validators.required)
        }

         if (element['sous_type'] == 'email') {
          validators.push(Validators.email)
          this.formulaire.addControl(element['champ'], new FormControl(this.data.donne[element['champ']], validators))
        }else {
          this.formulaire.addControl(element['champ'], new FormControl(this.data.donne[element['champ']],validators))
        }
      }
    }

    this.formulaire.valueChanges.subscribe((data) => {
      if (this.formulaire.valid) {
        this.disabled = false
      }else{
        this.disabled = true
      }
     
    })

    // this.formulaire.valueChanges.subscribe(val => {
    //   console.log(val)
    // });

  }

  getErrorMessage(sous_type) {
    if (sous_type == 'email') {
      return 'e-mail invalide'
    }else if (sous_type == 'text'){
      return 'Remplisserce champ'
    }else if (sous_type == 'number'){
      return "Champ numérique"
    }
  }

  disable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: true });
      }
    }
  }

  enable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: false });
      }
    }
  }
  valider() {

    this.loading = true
    this.disabled = true

    this.disable_tous_les_form()

    var donne = this.formulaire.value
    donne[this.data.champ_id[0]] = this.data.champ_id[1]
    
    var url = this.data.url
    this.BackendApiService.post_requete(donne, url).then((response:any) => {
      if (!response.error) {
        this.data.donne = this.formulaire.value
        this.loading = false
        this.disabled = false
        this.enable_tous_les_form()
        this.dialogRef.close(this.data)
      }else{
        this.onQuerryError(response.message)
      }
     
    },
      (err) => {
        this.onQuerryError(err.message)
      }
    )

  }

  onQuerryError(msg:string){
    this.enable_tous_les_form()
    this.loading = false
    this.disabled = false
    this._snackBar.open(msg, 'Fermer', {
      duration: 4000,
    });
  }

}
