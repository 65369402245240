import { Injectable, NgZone, ViewChild } from '@angular/core';
import ImageLayer from 'ol/layer/Image.js';
import ImageSource from 'ol/source/Image.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import { containsExtent, getWidth, boundingExtent, Extent, getCenter, getTopLeft,getSize,equals as extentEquals,getArea, buffer as BufferExtent } from 'ol/extent.js';
import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import { transform as Transform, fromLonLat, get as getProjection } from 'ol/proj.js';
import TileLayer from 'ol/layer/Tile.js';
import TileImage from 'ol/source/TileImage'
import TileJSON from 'ol/source/TileJSON'
import OSM from 'ol/source/OSM';
import { Source } from 'webpack-sources';
import VectorSource from 'ol/source/Vector.js';
import Static from 'ol/source/ImageStatic';
import VectorImageLayer from 'ol/layer/VectorImage';
// import VectorImageTile from 'ol/VectorImageTile.js';
// import VectorImageLayer from 'ol/layer/VectorImage';
import RasterSource from 'ol/source/Raster';
import Point from 'ol/geom/Point';
import { Group as LayerGroup, Layer, Vector as VectorLayer } from 'ol/layer.js';
import { easeOut } from 'ol/easing.js';
import Feature from 'ol/Feature';
import { unByKey } from 'ol/Observable.js';
import { Circle as CircleStyle, Fill, Stroke, Text, RegularShape, Icon } from 'ol/style.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { BackendApiService } from './backend-api.service'
import Style from 'ol/style/Style';
import * as $ from 'jquery';
import { getVectorContext } from 'ol/render';
import Geolocation from 'ol/Geolocation';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { bboxStrategy,allStrategy , transformExtent, Map } from '../ol-module';
import { layerForMap, PlusCode, ResponseAdresseDataGouv, ResponseAdresseGoogle } from '../type';
import {ScaleLine, defaults as defaultControls} from 'ol/control';
import { HttpClient } from '@angular/common/http';
import { METERS_PER_UNIT } from 'ol/proj/Units';
import BaseLayer from 'ol/layer/Base';
import { Cluster } from 'ol/source';
import BaseVectorLayer from 'ol/layer/BaseVector';
import { Coordinate } from 'ol/coordinate';
@Injectable()
export class MapGeneralService {

  public map: Map;
  zoom_actuel = 0

  public roation_of_map = new BehaviorSubject(0);

  constructor(
    public BackendApiService: BackendApiService,
    public _snackBar: MatSnackBar,
  ) { 
    this.wmsLyon.getSource().set('zMax', 24)
  }
  world_shadow = {
    "type": "FeatureCollection",
    "name": "world_shadow",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
    "features": [
      { "type": "Feature", "properties": { "id": 0 }, "geometry": { "type": "Polygon", "coordinates": [[[-19824886.222640071064234, 19848653.805728208273649], [19467681.065475385636091, 19467681.065475385636091], [19753445.191207133233547, -15987945.626927629113197], [-19824886.222640071064234, -15967070.525261469185352], [-19824886.222640071064234, 19848653.805728208273649]]] } }
    ]
  }

  wmsLyon_extent:Extent = [519604.009078001137823, 5707368.630188261158764, 575804.451451257918961, 5773936.144455613568425]
  wmsLyon = new ImageLayer({
    source: new ImageWMS({
      url: 'https://download.data.grandlyon.com/wms/grandlyon',
      params: { 'LAYERS': 'Ortho2015_vue_ensemble_16cm_CC46' },
      // serverType: 'qgis',
      crossOrigin: 'anonymous',
      attributions: '© <a target="_blank" href="https://data.beta.grandlyon.com/fr/datasets/orthophotographie-2015-metropole-lyon/info">Data Grand Lyon</a> Orthophotographie 2015 de la Métropole de Lyon ',
    }),
    zIndex: 1
  });
 


  async load_emprise_user() {
    await this.BackendApiService.get_requete('/api/user/emprise').then(
      (data) => {
        console.log(data)
        if (!data['erreur'] && data['data'] && data['data'].length > 0) {
          this.get_layer_shadow(JSON.parse(data['data'][0]['geometry']))
        }
      },
      (error) => {

      }
    )
  }

  get_layer_shadow(emprise) {

    var features_cameroun:any= new GeoJSON().readFeatures(emprise, {
      // dataProjection: 'EPSG:3857',
      // dataProjection: 'EPSG:4326',
      // featureProjection: 'EPSG:3857'
    });

    var features_world_shadow:any = new GeoJSON().readFeatures(this.world_shadow['features'][0]);

    var rasterSource_world = new VectorImageLayer({
      source: new VectorSource({features:features_world_shadow}),
      style: new Style({
        fill: new Fill({
          color: [0, 0, 0, 0.3]
        })
      })
    });

    var rasterSource_cmr = new VectorImageLayer({
      source: new VectorSource({features:features_cameroun}),
      style: new Style({
        fill: new Fill({
          color: [0, 0, 0, 0.1]
        })
      })
    });



    var raster = new RasterSource({
      sources: [

        rasterSource_world,
        rasterSource_cmr
      ],
      operation: function (pixels, data) {
        if (pixels[1][3] == 0) {
          return pixels[0];
          //return [0, 0, 0, 1]
        } else {
          return [0, 0, 0, 1]
        }
      }
    });
    var rasterLayer = new ImageLayer({
      source: raster
    });
    rasterLayer.setZIndex(1000)

    let source = rasterSource_cmr.getSource()
    if ( source instanceof VectorSource) {
      var extent = source.getExtent()
    }
    // var center_extent = getCenter(extent)

    this.map.addLayer(rasterLayer);

    this.map.getView().fit(extent, {
      'maxZoom': this.map.getView().getZoom(),
      'padding': this.get_padding_map(),
      // 'duration': 500
    })

  }

  getDefaultMapLayer() {
    return this.getRasterMapTilerLayer()
  }

  getMapboxStreetLayer() {
    var mapboxStreet = new TileLayer({
      zIndex: 1,
      source: new TileImage({ url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJtZWx0YXlvdSIsImEiOiJjaXl2ZnRldWgwMDJ4MnFxbmNicmltdzVhIn0.37ksDA81wL_Y_fP7BtU8mA', crossOrigin: "anonymous", }),
    });
    mapboxStreet.set('title','Mapbox Street')
    return mapboxStreet
  }

  getRasterMapTilerLayer() {
    var source = new TileJSON({
      url: 'https://api.maptiler.com/maps/streets/256/tiles.json?key=9qLFrh5OESYNoovxZe6g',
      tileSize: 256,
      crossOrigin: 'anonymous',
      wrapX: false,
    });
    source.setAttributions(['<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'])
    var layer = new TileLayer({
      source: source,
      zIndex: 1,
    })

    return layer
  }


  getIgnLayer(map?: Map) {
    let mapToInteract : Map
    if(map) {
      mapToInteract = map
    } else {
      mapToInteract = this.map
    }
    var resolutions = [];
    var matrixIds = [];
    var proj3857 = getProjection('EPSG:3857');
    var maxResolution = getWidth(proj3857.getExtent()) / 256;

    for (var i = 0; i < 22; i++) {
      matrixIds[i] = i.toString();
      resolutions[i] = maxResolution / Math.pow(2, i);
    }

    var tileGrid = new WMTSTileGrid({
      origin: [-20037508, 20037508],
      resolutions: resolutions,
      matrixIds: matrixIds
    });

    var ign_source = new WMTS({
      url: 'https://wxs.ign.fr/pratique/geoportail/wmts',
      layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
      // layer: 'GEOGRAPHICALGRIDSYSTEMS.MAPS',pratique choisirgeoportail http://wxs.ign.fr/lrxqwx0cakcvlf8bjyimumhx/wmts?LAYER=TRANSPORTNETWORKS.ROADS&EXCEPTIONS=text/xml&FORMAT=image/png&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&&TILEMATRIX=18&TILECOL=132834&TILEROW=90195
      matrixSet: 'PM',

      format: 'image/jpeg',
      projection: 'EPSG:3857',
      tileGrid: tileGrid,
      style: 'normal',
      crossOrigin: 'anonymous',
      attributions: '© <a target="_blank" href="http://www.geoportail.fr/">IGN</a> - Photographies aériennes <a target="_blank" href="https://www.geoportail.gouv.fr/depot/fiches/photographiesaeriennes/geoportail_dates_des_prises_de_vues_aeriennes.pdf">  dates de prise de vue</a>',
      // attributions: '<a href="http://www.geoportail.fr/" target="_blank">' +
      //   '<img src="https://api.ign.fr/geoportail/api/js/latest/' +
      //   'theme/geoportal/img/logo_gp.gif"></a>'
    });

    var ign = new TileLayer({
      source: ign_source,
      zIndex: 1,
      minResolution: mapToInteract.getView().getResolutionForZoom(22),
    });
    ign.set('zMax',22)
    return ign
  }

  getGoogleAeriene() {
    var googleLayerSatellite = new TileLayer({
      source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', crossOrigin: "anonymous", }),
      zIndex: 1,
    });
    googleLayerSatellite.set('title','Google Satellite')

    return googleLayerSatellite
  }

  getGoogleHybrid() {
    var googleLayerHybrid = new TileLayer({
      source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', crossOrigin: "anonymous", }),
      zIndex: 1,
    });
    googleLayerHybrid.set('title','Google Satellite & Roads')

    return googleLayerHybrid
  }

  getGoogleMaps() {
    var googleLayerMaps = new TileLayer({
      source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', crossOrigin: "anonymous", }),
      zIndex: 1,
    });
    googleLayerMaps.set('title','Google Terrain')

    return googleLayerMaps
  }

  pan_to(coord = this.map.getView().getCenter(), zoom:number, map? : Map ) {
    let mapToPan : Map
    if(map) {
      mapToPan = map
    } else {
      mapToPan = this.map
    }
    mapToPan.getView().animate({ center: coord }, { zoom: zoom }, { duration: 500 });
  }

  fit_view(geom, zoom, padding?) {
    if (padding == undefined) {
      padding = this.get_padding_map()
    }
    this.map.getView().fit(geom, {
      'maxZoom': zoom,
      'padding': padding,
      'duration': 500
    })

  }

  /**
    * Recuperer le padding de la carte pour zoomer sur la vrai extent visible. 
    */
  get_padding_map() {


    return [0, 0, 0, $('.sidenav_start').width()]
  }

  /**
    * Recuperer l'extent visible de la carte pour zoomer sur la vrai extent. 
    */
  get_extent_map(): Extent {

    try {
      var coord_O_N = this.map.getCoordinateFromPixel([$('.sidenav_start').width(), $(window).height()])
      var coord_E_S = this.map.getCoordinateFromPixel([$(window).width(), 0])

      return boundingExtent([coord_O_N, coord_E_S])
    } catch (error) {
      // var coord_O_N = this.map.getCoordinateFromPixel([0, $(window).height()])
      // var coord_E_S = this.map.getCoordinateFromPixel([$(window).width(), 0])

      return this.map.getView().calculateExtent()
    }

    // var coord_O_N = this.map.getCoordinateFromPixel([0, $(window).height()])
    // var coord_E_S = this.map.getCoordinateFromPixel([$(window).width()-environment.width_interface, 0])

    // if ($('.sidenav_start').width() > environment.width_interface) {
    //   var coord_O_N = this.map.getCoordinateFromPixel([$('.sidenav_start').width() - environment.width_interface, $(window).height()])
    //   // var coord_E_S = this.map.getCoordinateFromPixel([$(window).width()-environment.width_interface, 0])
    // }
    // return this.map.getView().calculateExtent(this.map.getSize())
    // return boundingExtent([coord_O_N, coord_E_S])
    // var extent_vieuw = boundingExtent([coord_O_N, coord_E_S])

    // var coord_O_N = this.map.getCoordinateFromPixel([0, $(window).height()])
    //   var coord_E_S = this.map.getCoordinateFromPixel([$(window).width(), 0])

    //   var extent_vieuw11 =boundingExtent([coord_O_N, coord_E_S])

    // var extent_vieuw = this.map.getView().calculateExtent(this.map.getSize()) 
    // // console.log(extentEquals(extent_vieuw,extent_vieuw11))
    // console.log(getSize(extent_vieuw),getSize(extent_vieuw11),this.map.getSize())
    

    // try {
    //   var coord_O_N = this.map.getCoordinateFromPixel([$('.sidenav_start').width(), $(window).height()])
    //   var coord_E_S = this.map.getCoordinateFromPixel([$(window).width(), 0])
    //   var extent_vieuw = boundingExtent([coord_O_N, coord_E_S])
    //   return extent_vieuw
    // } catch (error) {
    //   var coord_O_N = this.map.getCoordinateFromPixel([0, $(window).height()])
    //   var coord_E_S = this.map.getCoordinateFromPixel([$(window).width(), 0])

    //   var extent_vieuw = this.map.getView().calculateExtent()
    //   return extent_vieuw
    // }


  }

  getImageAerienne(extent) {
    console.log(this.wmsLyon_extent, extent)
    if (containsExtent(this.wmsLyon_extent, extent)) {
      return 'LYON'
    } else {
      return 'IGN'
    }
  }

  display_imagette_carte(source) {

    var view = this.map.getView()
    var coord = view.getCenter()
    // var source =  new TileImage({ url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',crossOrigin: "anonymous"})
    var grid = new OSM().getTileGrid();
    var tileCord = grid.getTileCoordForCoordAndZ(coord, view.getZoom() - 2);
    return source.getTileUrlFunction()(tileCord, 1, getProjection('EPSG:3857'))

  }



  setMap(map) {
    this.map = map;
    this.map.getView().on('change:resolution', () => {
      this.zoom_actuel = this.map.getView().getZoom()
    })

    this.map.getView().on('change:rotation', () => {
      this.roation_of_map.next(this.map.getView().getRotation())
    })

  }

  mapScale(dpi = 25.4 / 0.28) {
    var unit = this.map.getView().getProjection().getUnits();
    var resolution = this.map.getView().getResolution();
    var inchesPerMetre = 39.37;

    return resolution * METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
  }

  getZoomFromScale(scale) {
    var units = this.map.getView().getProjection().getUnits();
    var dpi = 25.4 / 0.28;
    var mpu = METERS_PER_UNIT[units];
    var resolution = scale / (mpu * 39.37 * dpi);
    return this.map.getView().getZoomForResolution(resolution);
  }

  changer_fond_carte(nom_fond) {

    var layer_to_remove;

    for (let index = 0; index < this.map.getLayers().getArray().length; index++) {
      var layer = this.map.getLayers().getArray()[index]
      if (layer.get('type') == 'fond') {
        layer.setVisible(false)
      }
      if (layer.get('nom') == nom_fond && layer.get('type') == 'fond') {
        layer_to_remove = layer
      }
    }

    if (nom_fond != 'aucun') {
      layer_to_remove.setVisible(true)
    }

  }

  get_fond_zmax(nom_fond) {
    var layer_to_remove;

    for (let index = 0; index < this.map.getLayers().getArray().length; index++) {
      var layer = this.map.getLayers().getArray()[index]

      if (layer.get('nom') == nom_fond && layer.get('type') == 'fond') {
        layer_to_remove = layer
      }
    }

    if (layer_to_remove) {
      return layer_to_remove.get('zMax')
    } else {
      return 22
    }

  }

  getLayerByAttr<T extends Layer >(layername:string, attr:string, layer_group:boolean, map? : Map):Array<T> {
    let mapToInteract : Map
    if(map) {
      mapToInteract = map
    } else {
      mapToInteract = this.map
    }
    var layer_to_remove = []
    let allLyers:BaseLayer[] =[]
    if (layer_group) {
      allLyers = mapToInteract.getLayers().getArray()
    } else {
      allLyers = mapToInteract.getLayerGroup().getLayersArray()
    }

    for (let index = 0; index < allLyers.length; index++) {
      var layer = allLyers[index]
      if (layer.get(attr) == layername) {
        layer_to_remove.push(layer)
      }

    }
    return layer_to_remove
  }

  set_many_layers_visible(layers, visible: boolean) {
    for (let index = 0; index < layers.length; index++) {
      const layer = layers[index];
      layer.setVisible(visible)
    }
  }
  remove_feature_in_source_by_attr(source: VectorSource, attr, value) {
    var responses = []
    source.forEachFeature((feature) => {
      if (feature.get(attr) == value) {
        responses.push(feature)
      }
    })
    for (let index = 0; index < responses.length; index++) {
      const feat = responses[index];
      source.removeFeature(feat)
    }
    // source.refresh()
    // return responses;
  }

  remove_odd_features_in_source_by_attr(source: VectorSource, attr, value: Array<any>) {
    var responses = []
    source.forEachFeature((feature) => {
      if (value.indexOf(feature.get(attr)) == -1) {
        responses.push(feature)
      }
    })
    for (let index = 0; index < responses.length; index++) {
      const feat = responses[index];
      source.removeFeature(feat)
    }
    // source.refresh()
    // return responses;
  }

  get_feature_in_source_by_attr(source: VectorSource, attr:string, value:any):Array<Feature> {
    var responses = []
    source.forEachFeature((feature) => {
      if (feature.get(attr) == value) {
        responses.push(feature)
      }
    })
    return responses;
  }

  get_feature_in_source_by_multiple_attr(source: VectorSource, data:Array<{attr:string,value:any}>):Array<Feature> {
    var responses = []
    source.forEachFeature((feature) => {
      if (feature.get(data[0].attr) == data[0].value && feature.get(data[1].attr) == data[1].value) {
        responses.push(feature)
      }
    })
    return responses;
  }

  feature_in_clusters(source, attr, value) {
    var responses = 1

    source.forEachFeature((features_clusters) => {
      var features = features_clusters.getProperties().features
      for (let index = 0; index < features.length; index++) {
        const feature = features[index];
        if (feature.get(attr) == value) {
          responses = features.length
        }
      }
    })

    if (responses == 1) {
      return false
    } else {
      return true
    }
  }

  set_all_features_pte_in_source_by_attr(source: VectorSource, attr_change, value_change) {
    var responses = []
    source.forEachFeature((feature) => {
      responses.push(feature)
    })

    for (let index = 0; index < responses.length; index++) {
      const feature = responses[index];
      feature.set(attr_change, value_change)
    }

  }

  getFeatureByAttr(source: VectorSource, attr, value):Feature{
    let featureRes;
    source.forEachFeature((feature) => {
      if (feature.get(attr) == value) {
        featureRes= feature
      }
    })
    return featureRes
  }

  set_feature_pte_in_source_by_attr(source: VectorSource, attr, value, attr_change, value_change) {
    var responses = []
    source.forEachFeature((feature) => {
      if (feature.get(attr) == value) {
        responses.push(feature)
      }
    })

    for (let index = 0; index < responses.length; index++) {
      const feature = responses[index];

      feature.set(attr_change, value_change)
    }

  }

  set_couche_visible(couche) {
    var layer_to_remove;
    var layer_group = couche.type

    if (layer_group == 'groupe') {
      var all_layers = this.map.getLayers().getArray()
    } else {
      var all_layers = this.map.getLayers().getArray()
      // var all_layers =  this.map.getLayerGroup().getArray()
    }

    for (let index = 0; index < all_layers.length; index++) {
      var layer = all_layers[index]
      // console.log(layer.get('identifiant_wms'),layer.get('nom')) 

      if (layer.get('type_layer') == 'reseaux' && couche.type_layer == 'reseaux') {
        if (layer.get('identifiant_wms') == couche.identifiant_wms) {
          layer_to_remove = layer
        }
      } else {
        if (layer.get('nom') == couche.nom) {
          layer_to_remove = layer
        }
      }

    }
    // console.log(layer_to_remove)
    if (layer_to_remove.getVisible()) {
      layer_to_remove.setVisible(false)
      couche.visible = false
    } else {
      layer_to_remove.setVisible(true)
      couche.visible = true
    }

  }

  desactivate_an_icon() {
    var lay;
    this.map.getLayers().forEach(function (layer) {
      if (layer.get('name') == "activate_icon") {
        lay = layer
      }
    });

    if (lay) {
      this.map.removeLayer(lay)
    }

  }

  initialise_layer_cercle_flash() {
    var icon = new Icon(({
      scale: 0.1,
      src: 'assets/img/location-pin.svg'
    }))
    var iconStyle = new Style({
      image: icon
    });

    var vectorSource_cercle_flash = new VectorSource({
      features: []
    });

    var vectorLayer_cercle_flash = new VectorLayer({
      source: vectorSource_cercle_flash,
      style: iconStyle,
    })
    vectorLayer_cercle_flash.set('nom','cercle_flash')
    vectorLayer_cercle_flash.setZIndex(2)
    vectorSource_cercle_flash.clear()
    this.map.addLayer(vectorLayer_cercle_flash)
  }

  active_cercle_flash(feature, raduis) {
    var feature_clone = feature.clone();
    var layer = this.getLayerByAttr<VectorLayer>('cercle_flash', 'nom', true)
    if (layer.length > 0) {
      var style = new Style({
        image: new CircleStyle({
          radius: raduis,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            // color: 'rgba(0, 0, 0, 1)',
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.12)',
            // color: 'rgba(255,255 ,255 , 0.4)',
          })
        })
      });
      layer[0].setStyle(style)
      layer[0].getSource().clear()
      layer[0].getSource().addFeatures([feature_clone])
    }
  }

  flash(feature, duration, raduis) {
    var start = new Date().getTime();
    // var listenerKey = this.map.on('postcompose', (event) => {
    //   console.log("postcompose")
    //   animate(event)
    // }); Plan nom
    var layer = this.getLayerByAttr<VectorLayer>('Plan', 'nom', true)[0]

    var listenerKey = layer.on('postrender', (event) => {
      animate(event)
    });
    this.map.render()
    var animate = (event) => {
      var vectorContext = getVectorContext(event)
      var frameState = event.frameState;
      var flashGeom = feature.getGeometry().clone();
      var feature_clone = feature.clone();
      var elapsed = frameState.time - start;
      var elapsedRatio = elapsed / duration;
      // radius will be 5 at start and 30 at end.
      var radius = easeOut(elapsedRatio) * 25 + 5;
      var opacity = easeOut(1 - elapsedRatio);

      var style = new Style({
        image: new CircleStyle({
          radius: radius,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, ' + opacity + ')',
            width: 0.5 + opacity
          })
        })
      });
      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      if (elapsed > duration) {

        this.active_cercle_flash(feature_clone, raduis)

        unByKey(listenerKey);
        return;
      }
      // tell OpenLayers to continue postcompose animation
      this.map.render();

      // setTimeout(() => {
      //   var layer = this.getLayerByAttr('cercle_flash', 'nom', true)
      //   if (layer.length>0) {
      //     layer[0].setStyle(style)
      //     layer[0].getSource().clear()
      //     layer[0].getSource().addFeatures([feature_clone])
      //   }
      // }, duration/2);

    }
  }

  activate_an_icon(coord, color, type) {
    this.desactivate_an_icon()
    if (type == 'Point') {

      var features = []
      var newMarker = new Feature({
        geometry: new Point(coord),
      });
      features[0] = newMarker;


      var markerSource = new VectorSource({
        features: features
      });


      var LayTheCopy = new VectorLayer({
        source: markerSource,
        style: new Style({
          image: new CircleStyle({
            radius: 24,
            stroke: new Stroke({
              color: color,
              width: 5,

            })
          })
        })
      })

      LayTheCopy.set('name', "activate_icon");
      LayTheCopy.setZIndex(1002)
      this.map.addLayer(LayTheCopy);

    } else {

      /*	var features = []
  
        if (type == 'Polygon') {
          var newMarker = new Feature({
            geometry: new geom.LineString(coordinate[0]),
          });
        } else {
          var newMarker = new Feature({
            geometry: new geom.LineString(coordinate),
          });
        }
  
        features[0] = newMarker;
  
  
        var markerSource = new source.Vector({
          features: features
        });
  
        var myStyle = new style.Style({
  
          stroke: new style.Stroke({
            color: '#1CAC77',
            width: 5
          }),
  
        });
  
        var LayTheCopy = new layer.Vector({
          source: markerSource,
          style: myStyle
        })*/
    }


  }

  coordonne_user = new BehaviorSubject([])

  get_coord_user() {
    var geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: this.map.getView().getProjection()
    });

    geolocation.on('error', (error) => {
      this.coordonne_user.next([0])
      this._snackBar.open(error.message, 'Fermer', {
        duration: 3000,
      });
      // return;

    });
    console.log('hooo')
    geolocation.on('change', () => {
      var coordinates = geolocation.getPosition();
      this.coordonne_user.next(coordinates)
      console.log(coordinates, 'position')
    });
  }

  get_layer_position_user(): VectorLayer {
    var positionFeature = new Feature();
    positionFeature.setStyle(new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#d32f2f'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        })
      })
    }));

    positionFeature.setGeometry(this.coordonne_user.getValue() ?
      new Point(this.coordonne_user.getValue()) : null);

    let myLayer = new VectorLayer({
      source: new VectorSource({
        features: [positionFeature]
      }),
    })
    myLayer.set('nom','localisation_user')
    return myLayer
  }

  geolocate_me(zoom_to_position = true) {

    if (!this.coordonne_user || this.coordonne_user.getValue().length != 2) {


      var geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true
        },
        projection: this.map.getView().getProjection()
      });

      geolocation.setTracking(true);

      // update the HTML page when the position changes.
      geolocation.on('change', () => {
        // el('accuracy').innerText = geolocation.getAccuracy() + ' [m]';
        // el('altitude').innerText = geolocation.getAltitude() + ' [m]';
        // el('altitudeAccuracy').innerText = geolocation.getAltitudeAccuracy() + ' [m]';
        // el('heading').innerText = geolocation.getHeading() + ' [rad]';
        // el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
      });

      // handle geolocation error.
      geolocation.on('error', (error) => {
        this.coordonne_user.next([0])
        this._snackBar.open(error.message, 'Fermer', {
          duration: 3000,
        });

      });

      var accuracyFeature = new Feature();
      geolocation.on('change:accuracyGeometry', () => {
        accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
      });

      var positionFeature = new Feature();
      positionFeature.setStyle(new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#2196F3'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          })
        })
      }));

      accuracyFeature.setStyle(new Style({
        stroke: new Stroke({
          color: '#2196F3',
          width: 2
        })
      }));

      geolocation.once('change:position', () => {
        var coordinates = geolocation.getPosition();
        if (zoom_to_position) {
          this.pan_to(coordinates, 17)
        }

      });

      geolocation.on('change:position', () => {
        var coordinates = geolocation.getPosition();
        this.coordonne_user.next(coordinates)
        positionFeature.setGeometry(coordinates ?
          new Point(coordinates) : null);
      });

      new VectorLayer({
        map: this.map,
        source: new VectorSource({
          features: [accuracyFeature, positionFeature]
        }),
      }).set('nom','localisation_user')
    } else if (this.coordonne_user && this.coordonne_user.getValue().length == 2) {
      var coordinates = this.coordonne_user.getValue();
      if (zoom_to_position) {
        this.pan_to(coordinates, 17)
      }

    }

  }

  refresh_map() {
    var p = new Point(this.map.getView().getCenter())
    p.translate(100, 100)

    this.map.getView().animate({ center: p.getCoordinates() }, { zoom: this.map.getView().getZoom() }, { duration: 500 });
  }

   /**
   * reload style de la map
   */
  updateMap() {
    this.map.renderSync()
    var new_center = [this.map.getView().getCenter()[0], this.map.getView().getCenter()[1] + 0.01]
    // this.map.getView().animate({ center: new_center }, { zoom: this.map.getView().getZoom() });

    setTimeout(() => {
      this.map.renderSync()
      this.map.getView().animate({ center: new_center }, { zoom: this.map.getView().getZoom() });
    }, 100);

    setTimeout(() => {
      this.map.renderSync()
      // this.map.getView().animate({ center: new_center }, { zoom: this.map.getView().getZoom() });
    }, 500);

    setTimeout(() => {
      this.map.renderSync()
      // map.getView().animate({ center: new_center }, { zoom: map.getView().getZoom() });
    }, 1000);
  }

  /**
   * formater les couches selon les providers
   * @param couche 
   * @return le layer
   */
  formatLayer(couche:layerForMap):BaseLayer{

    let layer:BaseLayer;

    if (couche.type == "wms") {

      var wmsSource = new ImageWMS({
        url: couche.url,
        imageLoadFunction:(image, src)=> {
          $.ajax({
            url: src,
            headers: {'Authorization':'Bearer  '+localStorage.getItem('token')},
            type: "GET",
            success: function(response) { image.getImage().setAttribute('src', response)}
         });

        },
        params: { 'LAYERS': couche.identifiant },
        serverType: 'qgis',
        crossOrigin: 'anonymous',
      });

      layer = new ImageLayer({
        source: wmsSource,
        className: couche.nom,
      });

      layer.set('nom',couche.nom)
    

    } else if (couche.type == "geojson") {
      var vectorSource = new VectorSource({
        format: new GeoJSON(),
      })
      layer = new VectorLayer({
        source: vectorSource,
        style: couche.style,
      })

      layer.set('nom',couche.nom)

   
    } else if (couche.type == 'wmts') {
      var projection = getProjection('EPSG:3857');
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(22);
      var matrixIds = new Array(22);
      for (var z = 0; z < 22; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = z;
      }

      let tileGrid = new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds
      })

      var wmts_source = new WMTS({
        url: couche.url + '&REQUEST=GetTile&SERVICE=WMTS&FORMAT=image/png&TILEMATRIXSET=EPSG:3857&TileMatrix={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}',
        matrixSet: 'webmercator',
        format: 'image/png',
        projection: projection,
        tileGrid: tileGrid,
        layer:couche.identifiant,
        style: 'default',
        requestEncoding: 'REST',
        wrapX: true
      });

      layer = new TileLayer({
        source: wmts_source
      });
    
    }else if(couche.type=='wfs'){
      let geStrategyFunction = function(couche:layerForMap){
        if (couche.strategy == 'all') {
          return allStrategy
        }else if (couche.strategy =='bbox') {
          return bboxStrategy
        }else if(couche.strategy == undefined){
          return allStrategy
        }
      }
      var source = new VectorSource({
        format: new GeoJSON({ dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }),
        loader:(extent, resolution, projection)=>{
          // var extent_vieuw = this.get_extent_map()
          var extent_vieuw = extent
          var url = couche.url +
          '?bbox=' + transformExtent(extent_vieuw, 'EPSG:3857', 'EPSG:4326').join(',') +
          '&layers='+couche.identifiant;
          this.BackendApiService.get_requete(url).then(
            (data)=>{
              let features:any = source.getFormat().readFeatures(data['data'])
              source.addFeatures(features );
            },
            (err)=>{
              source.removeLoadedExtent(extent_vieuw);
            }
          )
        },
        strategy:geStrategyFunction(couche)
      });

      layer = new VectorLayer({
        source: source,
        className: couche.nom,
      });

      layer.set('nom',couche.nom)

     
    }

    if (couche.minzoom) {
      layer.setMinResolution(this.map.getView().getResolutionForZoom(couche.minzoom))
    }
    if (couche.maxzoom) {
      layer.setMaxResolution(this.map.getView().getResolutionForZoom(couche.maxzoom))
    }

    if (couche.url) {
      layer.set('url', couche.url)
    }

    if (couche.visible != undefined) {
      layer.setVisible(couche.visible)
    }

    layer.set('identifiant', couche.identifiant)
    layer.set('nom', couche.nom)
    layer.set('type_layer', couche.type_layer)
    layer.setZIndex(couche.zindex)

    return layer

  }

  gestion_carto(couche:layerForMap) {
    var layer = this.formatLayer(couche)
    this.map.addLayer(layer)
  }

  /**
    * Récuperer les informations d'un feature wms
    * @param WMTS source  
    * @param Array<number> coordinates  coodonnées  
    */
  getFeatureInfoFromWmsSource(source: ImageWMS, coordinates: Coordinate, map? : Map) {
    let mapToInteract : Map
    if(map) {
      mapToInteract = map
    } else {
      mapToInteract = this.map
    }
    var viewResolution = mapToInteract.getView().getResolution();
    var url = source.getFeatureInfoUrl(Transform(coordinates, 'EPSG:3857', 'EPSG:3857'), viewResolution, 'EPSG:3857', {}) + "&INFO_FORMAT=application/json&FI_LINE_TOLERANCE=17&FI_POLYGON_TOLERANCE=17&FI_POINT_TOLERANCE=17"
    return url
  }

  /**transform(coordinates, 'EPSG:3857', 'EPSG:4326');
   * calcul d'une matrice de pixels au tour d'un pixel de rayon 5
   * reference : https://github.com/openlayers/openlayers/issues/5862
   * @param number[] pixel 
   * @return Array<Array<number>>
   */
  calcHitMatrix(pixel: number[]): Array<Array<number>> {
    let X = 11; // -5 to + 5 in x
    let Y = 11; // -5 to + 5 in y
    let x = 0;
    let y = 0;
    let dx = 0;
    let dy = -1;
    let t = Math.max(X, Y);
    let maxI = t * t;
    let pixelX = pixel[0];
    let pixelY = pixel[1];
    var all_pixels = []
    for (let i = 0; i < maxI; i++) {
      if ((-X / 2 <= x) && (x <= X / 2) && (-Y / 2 <= y) && (y <= Y / 2)) {
        all_pixels.push([pixelX + x, pixelY + y])
      }
      if ((x === y) || ((x < 0) && (x === -y)) || ((x > 0) && (x === 1 - y))) {
        t = dx;
        dx = -dy;
        dy = t;
      }
      x += dx;
      y += dy;
    }
    return all_pixels
  }

  /**
   * Detecter des layers wms de type  ImageLayer present sous un pixel.
   * ne pas considérer les layers qui ont une certaines valeurs(oddLayersValues) pour un attribut (oddLayersAttr)
   * @param number[] pixel 
   * @param string oddLayersAttr 
   * @param Array<string> oddLayersValues 
   * @returns Array<ImageLayer>
   */
  displayFeatureInfo(pixel: number[], oddLayersAttr: string, oddLayersValues: Array<string>, map?: Map):Array<ImageLayer> {
    var layers = []
    let mapToDisplayFeature : Map
    if (map) {
      mapToDisplayFeature = map
    } else {
      mapToDisplayFeature = this.map
    }
    mapToDisplayFeature.forEachLayerAtPixel(pixel,
      function (layer, rgb: Uint8ClampedArray) {
        if (layer instanceof ImageLayer && oddLayersValues.indexOf(layer.get(oddLayersAttr)) == -1) {
          layers.push(layer)
        }
      });

    return layers;
  }

  findGetParameter(parameterName,url) {
    var result = null,
        tmp = [];
        url
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }

  getParametersForFeatureInfoWMS(layers:Array<ImageLayer>,coord, map : Map):Array<any>{
    var parameters = []
      for (let index = 0; index < layers.length; index++) {
        const layer = layers[index];
        let source = layer.getSource()
        if ( source instanceof ImageWMS ) {
          var url = this.getFeatureInfoFromWmsSource(source,coord, map)
          let extent = this.findGetParameter('BBOX',url).split(',').map((x)=>parseFloat(x))

        var parameters_i = {
          bbox : extent.join(','),
          QUERY_LAYERS : this.findGetParameter('QUERY_LAYERS',url),
          LAYERS : this.findGetParameter('LAYERS',url),
          J : this.findGetParameter('J',url),
          I : this.findGetParameter('I',url),
          CRS : this.findGetParameter('CRS',url),
          WIDTH : this.findGetParameter('WIDTH',url),
          HEIGHT : this.findGetParameter('HEIGHT',url),
          topologie : layer.get('nom').split('_')[1],
        }
        parameters.push(parameters_i)
        }
      }

      return parameters
  }

  convertFeatureToGeoJson(feature:Feature):any{
    return new GeoJSON().writeFeature(feature)
  }
  
   /**
    *Mettre à jour la distance dans les clusters d'une couche en fonctions des zooms
    * @param  attribut string
    * @param  value any
    */
   gestionClustersByZooms(attribut:string,value:any ) {
    var layer_sites = this.getLayerByAttr<BaseVectorLayer<Cluster>>(attribut,value, true)
    if (layer_sites.length > 0) {
      if (this.map.getView().getZoom() >= 14) {
        if (layer_sites[0].getSource().getDistance() != 40) {
          layer_sites[0].getSource().setDistance(40)
        }
      } else if (this.map.getView().getZoom() >= 12) {
        if (layer_sites[0].getSource().getDistance() != 70) {
          layer_sites[0].getSource().setDistance(70)
        }
      } else if (this.map.getView().getZoom() >= 10) {
        if (layer_sites[0].getSource().getDistance() != 100) {
          layer_sites[0].getSource().setDistance(100)
        }

      } else if (this.map.getView().getZoom() >= 8) {
        if (layer_sites[0].getSource().getDistance() != 125) {
          layer_sites[0].getSource().setDistance(125)
        }

      } else if (this.map.getView().getZoom() < 8) {
        if (layer_sites[0].getSource().getDistance() != 150) {
        }

      }
    }
  }

  /**
   * get map scale control for ol map
   * @param scaleType 'scaleline'|'scalebar'
   * @param target string id of the target html element
   */

   scaleControl(scaleType:'scaleline'|'scalebar', target:string){
    let scaleBarSteps = 4;
    let scaleBarText = true;
    let control;
    if (scaleType === 'scaleline') {
      control = new ScaleLine({
        units: 'metric',//'metric','nautical','us','degrees'
        target:target
      });
    }else if (scaleType === 'scalebar'){
      control = new ScaleLine({
        units: 'metric',
        target:target,
        bar: true,
        steps: scaleBarSteps,
        text: scaleBarText,
        minWidth: 140,
      });
    }
    return control;
   }

   transformPlusCodeToGouvAdresse(plusCode:PlusCode,y:number,x:number):ResponseAdresseDataGouv{
    let gouvAdresse:ResponseAdresseDataGouv = {
      geometry:{
        coordinates:[x,y],
        type:'Point'
      },
      properties:{
        label:plusCode.compound_code,
        city:'',
        postcode:'',
        citycode:'',
        context:'',
        y:x,
        x:y
      },
      type: "Feature",
      typeRes:"google"
    }
    return gouvAdresse
  }

  transformGoogleAdresseToGouvAdresse(googleA:ResponseAdresseGoogle):ResponseAdresseDataGouv{
    let gouvAdresse:ResponseAdresseDataGouv = {
      geometry:{
        coordinates:[googleA.geometry.location.lng,googleA.geometry.location.lat],
        type:'Point'
      },
      properties:{
        label:googleA.formatted_address,
        city:googleA.address_components.length>0?googleA.address_components[0].long_name:'',
        postcode:'',
        citycode:'',
        context:'',
        y:googleA.geometry.location.lat,
        x:googleA.geometry.location.lng
      },
      type: "Feature",
      typeRes:"google"
    }
    return gouvAdresse
  }
}
