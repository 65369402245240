import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Feature from 'ol/Feature';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, filter, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { deleteFeature, updateAttributeFeature } from 'src/app/digitaliser/class/featureCrud';
import { LayerToDigitalise } from 'src/app/digitaliser/model/layer-to-digitalise';
import { ModeCodeFeature } from 'src/app/models/mode_gestion';
import { TeridealFeature } from 'src/app/models/teridealFeature';
import { Composition, Forme, SousTypes, TypoCategorie, Typologie } from 'src/app/models/typo_category';
import { ElemCartoService } from 'src/app/services/elem-carto/elem-carto.service';
import { GeneralService } from 'src/app/services/general.service';
import { elemCartoInterface } from 'src/app/type';
import { Map } from 'ol'
import { Select } from 'ol/interaction';
import { CircleStyle, VectorLayer, VectorSource } from 'src/app/ol-module';
import { Fill, Stroke, Style } from 'ol/style';
import { ConfirmDialogComponent } from 'src/app/shared/pages/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-elem-carto-form',
  templateUrl: './elem-carto-form.component.html',
  styleUrls: ['./elem-carto-form.component.scss']
})
export class ElemCartoFormComponent implements OnInit {
  public onInitInstance: () => void
  public onSubmitInstance: () => void
  public onDeleteInstance: () => void
  public onCancelInstance: () => void

  feature: Feature
  layerToDigitalise: LayerToDigitalise
  close: Subject<boolean>
  map: Map
  form: FormGroup
  modeGestionForm: FormArray
  listElemCarto: elemCartoInterface[] = []

  formes: Forme[] = [];
  compositions: Composition[] = [];
  typologies: Typologie[] = [];
  sous_types: SousTypes[] = [];

  display: { [key: string]: boolean } = {
    'alias': true,
    'forme': true,
    'composition': false,
    'typologie': false,
    'sous_type': false
  }

  typoCategorie$: Observable<TypoCategorie[]>
  typoCategories: Subject<TypoCategorie[]> = new Subject<TypoCategorie[]>()
  codeTypoChoose: TypoCategorie

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public fb: FormBuilder,
    public generalService: GeneralService,
    private cdRef: ChangeDetectorRef,
    public elemCartoService: ElemCartoService,
    public dialog : MatDialog,
    public translate : TranslateService
  ) {
    this.form = this.fb.group({
      'alias': new FormControl('', [Validators.required]),
      'deleted': new FormControl(false, [Validators.required]),
      'forme': new FormControl(),
      'composition': new FormControl(),
      'typologie': new FormControl(),
      'sous_type': new FormControl()
    })

    this.modeGestionForm = new FormArray([]);

    this.form.get('forme').disable()
    this.form.get('composition').disable()
    this.form.get('typologie').disable()
    this.form.get('sous_type').disable()

    const onInit: Subject<void> = new ReplaySubject<void>(1)
    this.onInitInstance = () => {
      onInit.next()

      this.cdRef.detectChanges()
    }

    this.typoCategorie$ = merge(
      onInit, this.form.get('alias').valueChanges.pipe(tap(() => {
        this.form.get('forme').setValue(undefined, { emitEvent: false })
        this.form.get('composition').setValue(undefined, { emitEvent: false })
        this.form.get('typologie').setValue(undefined, { emitEvent: false })
        this.form.get('sous_type').setValue(undefined, { emitEvent: false })
      }))
    ).pipe(
      takeUntil(this.destroyed$),
      filter(() => this.form.get('alias').value != undefined),
      filter(() => {
        this.listElemCarto = this.generalService.get_config_projet().layers[this.layerToDigitalise.typologie].filter((item) => {
          if (this.feature.get('alias') != 'PERI') {
            return item.alias != 'PERI'
          } else {
            return item.alias === 'PERI'
          }
        })
        return this.listElemCarto.find((elemCarto: elemCartoInterface) => {
          return elemCarto.alias == this.form.get('alias').value
        }) != undefined
      }),
      switchMap(() => {
        this.listElemCarto = this.generalService.get_config_projet().layers[this.layerToDigitalise.typologie].filter((item) => {
          if (this.feature.get('alias') != 'PERI') {
            return item.alias != 'PERI'
          } else {
            return item.alias === 'PERI'
          }
        })
        let elementCarto = this.listElemCarto.find((elemCarto: elemCartoInterface) => {
          return elemCarto.alias == this.form.get('alias').value
        })
        // let elementCarto =  this.listElemCarto.filter((elemCarto : elemCartoInterface))
        return this.elemCartoService.getTypoCategorie(elementCarto.elem_carto_id)
      }),
      tap((typoCategorie) => {
        this.typoCategories.next(typoCategorie)
        if (this.feature.get('typo_codes_id') && typoCategorie.find((typoCategorie) => typoCategorie.typo_codes_id === this.feature.get('typo_codes_id'))) {
          let typoCategorie_feature = typoCategorie.find((typoCategorie) => typoCategorie.typo_codes_id === this.feature.get('typo_codes_id'))
          this.form.get('forme').setValue(typoCategorie_feature.forme ? typoCategorie_feature.forme.typo_formes_id : undefined, { emitEvent: false })
          this.form.get('composition').setValue(typoCategorie_feature.composition ? typoCategorie_feature.composition.typo_compositions_id : undefined, { emitEvent: false })
          this.form.get('typologie').setValue(typoCategorie_feature.typologie ? typoCategorie_feature.typologie.typo_typologies_id : undefined, { emitEvent: false })
          this.form.get('sous_type').setValue(typoCategorie_feature.sous_type ? typoCategorie_feature.sous_type.typo_sous_types_id : undefined, { emitEvent: false })
        }

        this.formes = typoCategorie.filter((typoCategorie) => typoCategorie.typo_formes_id != undefined).map((typoCategorie) => typoCategorie.forme).filter((value, index, self) => {
          /**
           * unique 
           */
          return self.map((item) => item.typo_formes_id).indexOf(value.typo_formes_id) === index;
        })

        if (this.formes.length == 0) {
          this.form.get('forme').setValidators([])
          this.form.get('forme').disable()
          this.display.composition = true
        }
        else {
          this.display.composition = false
          this.display.typologie = false
          this.display.sous_type = false
          this.form.get('forme').setValidators(Validators.required)
          if (this.form.get('forme').disabled) {
            this.form.get('forme').enable({ emitEvent: false })
          }
          this.form.get('forme').setValue(this.form.get('forme').value)
        }
      }),
      shareReplay(1),
    )

    this.form.get('forme').valueChanges.pipe(
      withLatestFrom(this.typoCategories),
      takeUntil(this.destroyed$),
      tap((parameters: [any, TypoCategorie[]]) => {
        this.compositions = parameters[1].filter((typoCategorie) => typoCategorie.typo_formes_id == this.form.get('forme').value && typoCategorie.composition != undefined).map((typoCategorie) => typoCategorie.composition).filter((value, index, self) => {
          /**
           * unique 
           */
          return self.map((item) => item.typo_compositions_id).indexOf(value.typo_compositions_id) === index;
        })
        if (this.display.forme && this.formes.length == 0) {
          this.display.composition = true
        }
        if (this.display.forme && this.formes.length > 0 && this.form.get('forme').value != undefined) {
          this.display.composition = true
        }
        if (this.display.forme && this.formes.length > 0 && this.form.get('forme').value == undefined) {
          this.display.composition = false
        }
        this.display.typologie = false

        if (this.compositions.length == 0) {
          this.form.get('composition').setValidators([])
          this.form.get('composition').disable()
        }
        else {
          this.form.get('composition').setValidators(Validators.required)
          if (this.form.get('composition').disabled) {
            this.form.get('composition').enable({ emitEvent: false })
          }
          this.form.get('composition').setValue(this.form.get('composition').value)
        }
      })
    ).subscribe()

    this.form.get('composition').valueChanges.pipe(
      withLatestFrom(this.typoCategories),
      takeUntil(this.destroyed$),
      tap((parameters: [any, TypoCategorie[]]) => {
        this.typologies = parameters[1].filter((typoCategorie) => typoCategorie.typo_formes_id == this.form.get('forme').value && typoCategorie.typo_compositions_id == this.form.get('composition').value && typoCategorie.typo_typologies_id != undefined).map((typoCategorie) => typoCategorie.typologie).filter((value, index, self) => {
          /**
           * unique 
           */
          return self.map((item) => item.typo_typologies_id).indexOf(value.typo_typologies_id) === index;

        })
        if (this.display.composition && this.compositions.length == 0) {
          this.display.typologie = true
        }
        if (this.display.composition && this.compositions.length > 0 && this.form.get('composition').value != undefined) {
          this.display.typologie = true
        }
        if (this.display.composition && this.compositions.length > 0 && this.form.get('composition').value == undefined) {
          this.display.typologie = false
        }
        if (!this.display.composition) {
          this.display.typologie = false
        }
        this.display.sous_type = false
        if (this.typologies.length == 0) {
          this.form.get('typologie').setValidators([])
          this.form.get('typologie').disable()
        }
        else {
          this.form.get('typologie').setValidators(Validators.required)
          if (this.form.get('typologie').disabled) {
            this.form.get('typologie').enable({ emitEvent: false })
          }
          this.form.get('typologie').setValue(this.form.get('typologie').value)
        }

      })
    ).subscribe()


    this.form.get('typologie').valueChanges.pipe(
      withLatestFrom(this.typoCategories),
      tap((parameters: [any, TypoCategorie[]]) => {
        this.sous_types = parameters[1].filter((typoCategorie) => typoCategorie.typo_sous_types_id != undefined && typoCategorie.typo_typologies_id == this.form.get('typologie').value && typoCategorie.typo_formes_id == this.form.get('forme').value && typoCategorie.typo_compositions_id == this.form.get('composition').value).map((typoCategorie) => typoCategorie.sous_type).filter((value, index, self) => {
          /**
           * unique 
           */
          return self.map((item) => item.typo_sous_types_id).indexOf(value.typo_sous_types_id) === index;
        })
        if (this.display.typologie && this.typologies.length == 0) {
          this.display.sous_type = true
        }
        if (this.display.typologie && this.typologies.length > 0 && this.form.get('typologie').value != undefined) {
          this.display.sous_type = true
        }
        if (this.display.typologie && this.typologies.length > 0 && this.form.get('typologie').value == undefined) {
          this.display.sous_type = false
        }
        if (!this.display.typologie) {
          this.display.sous_type = false
        }
        if (this.sous_types.length == 0) {
          this.form.get('sous_type').setValidators([])
          this.form.get('sous_type').disable()
          this.form.get('sous_type').setValue(undefined, { emitEvent: false })
        }
        else {
          this.form.get('sous_type').setValidators(Validators.required)
          if (this.form.get('sous_type').disabled) {
            this.form.get('sous_type').enable({ emitEvent: false })
          }
          this.form.get('sous_type').setValue(this.form.get('sous_type').value)
        }
      })
    ).subscribe()


    this.form.valueChanges.pipe(
      tap(() => this.codeTypoChoose = undefined),
      delay(1000),
      debounceTime(500),
      takeUntil(this.destroyed$),
      filter(() => this.form.valid),
      filter(() => Object.keys(this.display).filter((key, index) => this.display[key] === false).length == 0),
      withLatestFrom(this.typoCategories),
      tap((parameters) => {
        this.codeTypoChoose = parameters[1].find((typoCategorie) => {
          return typoCategorie.typo_formes_id == this.form.get('forme').value &&
            typoCategorie.typo_compositions_id == this.form.get('composition').value &&
            typoCategorie.typo_typologies_id == this.form.get('typologie').value &&
            typoCategorie.typo_sous_types_id == this.form.get('sous_type').value
        })
      })
    ).subscribe()


    this.onSubmitInstance = () => {
      let pte: TeridealFeature = this.feature.getProperties() as TeridealFeature

      /**
       * typologie de surface
       */

      pte.alias = this.codeTypoChoose.categorie.alias
      pte.elem_carto_valeur = this.codeTypoChoose.categorie.valeur
      pte.elem_carto_id = this.codeTypoChoose.categorie.elem_carto_id

      pte.typo_codes_id = this.codeTypoChoose.typo_codes_id

      pte.typo_formes = {
        name: this.codeTypoChoose.forme ? this.codeTypoChoose.forme.name : undefined,
        typo_formes_id: this.codeTypoChoose.forme ? this.codeTypoChoose.forme.typo_formes_id : undefined
      }

      pte.typo_compositions = {
        name: this.codeTypoChoose.composition ? this.codeTypoChoose.composition.name : undefined,
        typo_compositions_id: this.codeTypoChoose.composition ? this.codeTypoChoose.composition.typo_compositions_id : undefined
      }

      pte.typo_typologies = {
        name: this.codeTypoChoose.typologie ? this.codeTypoChoose.typologie.name : undefined,
        typo_typologies_id: this.codeTypoChoose.typologie ? this.codeTypoChoose.typologie.typo_typologies_id : undefined
      }

      pte.typo_sous_types = {
        name: this.codeTypoChoose.sous_type ? this.codeTypoChoose.sous_type.name : undefined,
        typo_sous_types_id: this.codeTypoChoose.sous_type ? this.codeTypoChoose.sous_type.typo_sous_types_id : undefined
      }

      /**
       * Mode de gestion
       */
      pte.modes = []

      pte.modes = this.modeGestionForm.value.map(mode => mode.codeModeChoose)

      this.feature.setProperties(pte)

      updateAttributeFeature(this.layerToDigitalise.layer, this.feature)
      this.close.next(true)
    }

    this.onDeleteInstance = () => {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          confirmationTitle: this.translate.instant('edition.delete_feature'),
          confirmationExplanation: this.translate.instant('edition.delete_feature_explanation'),
          cancelText: this.translate.instant('no'),
          confirmText: this.translate.instant('yes'),
        }
      }).afterClosed().pipe(
        take(1),
        filter((result)=> result),
        tap(()=> {
          deleteFeature(this.layerToDigitalise.layer, this.feature)
          this.close.next(true)
        })
      ).subscribe()
    }

    this.onCancelInstance = () => {
      this.close.next(false)
    }

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    if (this.feature && this.layerToDigitalise && this.map) {
      this.form.get('alias').setValue(this.feature.get('alias'), { emitEvent: false })
      if (this.feature.get('modes')) {
        this.feature.get('modes').forEach((mode: ModeCodeFeature) => {
          let modeGestionFormGroup = this.fb.group({
            'frequence': new FormControl(mode.frequence ? mode.frequence : null, [Validators.min(1)]),
            'categorie': new FormControl(mode.mode_categories ? mode.mode_categories.mode_categories_id : null, [Validators.required]),
            'niveau1': new FormControl(mode.mode_niveau_1s ? mode.mode_niveau_1s.mode_niveau_1s_id : null, [Validators.required]),
            'niveau2': new FormControl(mode.mode_niveau_2s ? mode.mode_niveau_2s.mode_niveau_2s_id : null),
            'codeModeChoose': new FormControl(mode)
          })
          this.modeGestionForm.push(modeGestionFormGroup)
        });
      }
      this.map.updateSize()
      this.onInitInstance()
    }
  }

  onAddMode() {
    let modeGestionFormGroup = this.fb.group({
      'frequence': new FormControl(null, [Validators.min(1)]),
      'categorie': new FormControl(null, [Validators.required]),
      'niveau1': new FormControl(null, [Validators.required]),
      'niveau2': new FormControl(),
      'codeModeChoose': new FormControl()
    })
    this.modeGestionForm.push(modeGestionFormGroup)
  }

  onDeleteMode(index: number) {
    this.modeGestionForm.removeAt(index)
  }

  isModeGestionFormUndefined() {
    this.modeGestionForm.controls.filter(mode => mode.get('codeModeChoose') == undefined).length > 1
  }

  /**
   * Zoomer sur une entité
   */
  zoomOnFeature() {
    if (this.feature && this.map) {
      let extent = this.feature.getGeometry().getExtent()
      this.map.getView().fit(extent, {
        maxZoom: 21,
        duration: 1000
      })
    }
  }

  /**
   * Faire clignoter une entité
   */
  blinkFeature() {
    let layer = new VectorLayer({
      source: new VectorSource({
        features: [this.feature.clone()]
      }),
      style: function () {
        let style = new Style({
          fill: new Fill({ color: 'red' }),
          stroke: new Stroke({ color: 'red', width:2 }),
          image: new CircleStyle({
            radius: 4,
            stroke: new Stroke({ color: 'red', width: 6 }),
            fill: new Fill({
                color: 'red',
            }),
        }),
        })
        return style
      }
    })
    layer.setZIndex(99999999999999999999999)
    this.map.addLayer(layer)
    setTimeout(() => {
      layer.getSource().clear()
    }, 200)
    setTimeout(() => {
      layer.getSource().addFeature(this.feature.clone())
    }, 400)
    setTimeout(() => {
      layer.getSource().clear()
      this.map.removeLayer(layer)
    }, 600)
  }

}
