import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BackendApiService } from 'src/app/services/backend-api.service'
import { SitesCartoService } from 'src/app/services/sites-carto.service'
import { AuthService, User } from 'src/app/services/auth.service'
import { GeneralService } from 'src/app/services/general.service'
import { SitesService } from 'src/app/services/sites.service'
import { BehaviorSubject, Observable, forkJoin, from, throwError, of } from 'rxjs';
import { NotifierService } from "angular-notifier";
import { ResizedEvent } from 'angular-resize-event';
import {FiltreSitesParentComponent,ModelFiltreDataInterface} from './filtre-sites.component.parent';
import { reponseDB } from 'src/app/type';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

/**
 * Composant du constructeur de filtre
 */
@Component({
  selector: 'app-filtre-sites',
  templateUrl: './filtre-sites.component.html',
  styleUrls: ['./filtre-sites.component.scss']
})
export class FiltreSitesComponent extends FiltreSitesParentComponent implements OnInit {


  modelFiltreData: Array<ModelFiltreDataInterface> = [
    {
      type: 'select',
      url: '/api/regroupements/in_id_sites',
      httpMethod:'POST',
      titre: 'regroupement',
      displayPte: ['num_regroup', 'nom_regroup'],
      formatBD:{
        champ:'regroupement_id_sites',
        condition:'=',
        champValeur:'regroupement_id'
      },
      champBD:'attributsSites'
    },
    {
      type: 'select',
      url: '/api/clients/in_id_sites',
      httpMethod:'POST',
      titre: 'client',
      displayPte: ['nom_client'],
      formatBD:{
        champ:'client_id_sites',
        condition:'=',
        champValeur:'client_id'
      },
      champBD:'attributsSites'
    },
    {
      type: 'select',
      url: '/api/agences/in_id_sites',
      httpMethod:'POST',
      titre: 'agence',
      displayPte: ['code_agence', 'nom_agence'],
      formatBD:{
        champ:'agence_id_sites',
        condition:'=',
        champValeur:'agence_id'
      },
      champBD:'attributsSites'
    },
    {
      type: 'range',
      subType:'input',
      url: '/api/filtres/rangeSurfaces',
      httpMethod:'POST',
      titre: 'surfaces',
      formatConfig:{
        connect: true,
        keyboard: true,
        pips: {
          mode: 'steps',
          density: 10,
          values: 6,
          stepped: true,
          format: {
            to: function (value) {
              function numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              }
              return numberWithCommas(Math.round(value)) + ' m²';
            },
            from: function (value) {
              return Number(value.replace(' m²', '').replace(/ /g,''));
            }
          }
        },
        format: {
          to: function (value) {
            function numberWithCommas(x) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
            return numberWithCommas(Math.round(value)) + ' m²';
          },
          from: function (value) {
            return Number(value.replace(' m²', '').replace(/ /g,''));
          }
        }
      },
      champBD:'filtrePerimetres'
    }
  ]

  user: User = this.AuthService.get_user()


  constructor(
    public fb: FormBuilder,
    public BackendApiService: BackendApiService,
    notifierService: NotifierService,
    public SitesService:SitesService,
    public AuthService:AuthService,
    public GeneralService:GeneralService,
    public SitesCartoService:SitesCartoService,
    public translate: TranslateService,
  ) {
    super(fb,BackendApiService,notifierService)
  }

  ngOnInit() {

  }

  /**
   * Lorsque la fenetre de filtre est ouverte, l'initialiser
   * @param event 
   */
  onResized(event: ResizedEvent) {
    if (event.newWidth > 50) {

      this.initialiseFiltre()
    }
  }

    /**
   * Reset les filtres
   */
  reset(){
    for (let index = 0; index < this.modelFiltreData.length; index++) {
      const model = this.modelFiltreData[index];
      if (model.type == 'select') {
        this.formulaire.get(model.titre).setValue(undefined)
      }else if (model.type == 'range'){
        this.formulaire.get(model.titre).setValue( model.rangeNumber)
      }
    }
    this.filterObjectsList = []
    this.updateFilterData([])
    this.SitesService.sitesIdToLoad.next([])
    this.SitesCartoService.clearSites()
  }

  /**
   * Filtrer les données
   */
  valider(){
    this.loading.loadindResponseFiltre = true
    this.BackendApiService.post_requete(this.formatQuerry(),'/api/filtres/filter').then(
      (reponse: {data:{sites_id:Array<number>,extent:Array<number>},message:string,
        erreur:boolean}  )=>{
          
        this.filterObjectsList = reponse.data.sites_id
        // this.getNullOrUnchangedModel()
        this.updateFilterData(this.lastChangedModel?[this.lastChangedModel.titre]:[])

        this.loading.loadindResponseFiltre = false
        
        if (reponse.data.extent[0] != 0 && reponse.data.sites_id.length > 0 && reponse.data.sites_id.length <600 ) {
          this.SitesCartoService.fit_view(reponse.data.extent, 16)
        }

        this.SitesService.sitesIdToLoad.next(reponse.data.sites_id)
        this.SitesCartoService.clearSites()

        this.notifier.notify("succes", reponse.data.sites_id.length+ " Sites trouvé(s)");

        if (reponse.data.sites_id.length == 0) {
          this.notifier.notify("default", "Annulation de tous les filtres");
        }else if (reponse.data.sites_id.length > 600) {
          this.notifier.notify("default", "Deplacez la carte pour parcourir les résultats");
        }

      },
      (error:reponseDB)=>{
        this.loading.loadindResponseFiltre = false
        this.notifier.notify("error", "Un problême est survenu lors de l'exécution du filtre");
        this.notifier.notify("error", error.message);
      }
    )
  }

   /**
   * Exporter les données filtrées en excel
   */
  filterExport(){
    this.loading.loadindResponseFiltre = true
    this.BackendApiService.post_requete(this.formatQuerry(),'/api/filtres/export').then(
      (reponse: {data:{url:string},message:string,
        erreur:boolean}  )=>{
          this.loading.loadindResponseFiltre = false
          if (!reponse.erreur) {
            this.notifier.notify("succes", "L'export des sites a réussi");
            var url = environment.apiUrl + "/api/download/?file=" + reponse.data['url']
            window.open(url, '_blank');
          }else{
            this.notifier.notify("error", "Un problême est survenu lors de l'export des sites");
          }
      },
      (error:reponseDB)=>{
        this.loading.loadindResponseFiltre = false
        this.notifier.notify("error", "Un problême est survenu lors de l'exécution du filtre");
        this.notifier.notify("error", error.message);
      }
    )
  }

  /**
   * Exporter les fiches de sites
  */
  filterExportFiches(){
    this.loading.loadindResponseFiltre = true
    this.BackendApiService.post_requete(this.formatQuerry(),'/api/filtres/exportFiches').then(
      (reponse:reponseDB  )=>{
          this.loading.loadindResponseFiltre = false
          if (!reponse.erreur) {
            this.translate.get('filtre').subscribe((res) => {
              this.notifier.notify("succes", res['export_fiche_site_succes']);
              alert(res['export_fiche_site_succes'])
            });

          }else{
            this.notifier.notify("error", "Un problême est survenu lors de l'export des fiches de sites");
          }
      },
      (error:reponseDB)=>{
        this.loading.loadindResponseFiltre = false
        this.notifier.notify("error", "Un problême est survenu lors de l'exécution du filtre");
        this.notifier.notify("error", error.message);
      }
    )
  }

}