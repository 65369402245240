import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MapGeneralService } from './map.general.service'
import { BackendApiService } from './backend-api.service'
import { GeneralService } from './general.service'
import { SitesCartoService } from './sites-carto.service'
import { AuthService } from './auth.service'
import {StockageService} from './stockage.service'
import {handleDataHelper} from 'src/app/helpers/handleData'
import {
  Cluster,
  GeoJSON,
}
  from '../ol-module'
  import {siteInterface, reponseDB, clientInterface, agenceInterface, regroupementInterface, ElementOfSite} from '../type'

import { environment } from 'src/environments/environment';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import BaseVectorLayer from 'ol/layer/BaseVector';
@Injectable({
  providedIn: 'root'
})
export class SitesService {
  apiUrl = environment.apiUrl

  /**
   * Gestionnaire de notifications,
   * Celui ci ne ferme pas automatiquement la notif (cf constructor)
   */
  notifier: NotifierService;

  /**
   * Identifiant des notifications : pour les fermer ou changer plus tards plus tard 
   */
  notificationsId:{
    /**
     * Identifiants des notifications de chargements de sites
     */
    loadingSites:Array<string>,
    /**
     *  Identifiants des notifications d'erreurs de chargements de sites
     */
    errorLoadingSites:Array<string>
    /**
     * Avoir tous les id des notifications
     */
    getAllNotificationsId():Array<string>
  }={
    loadingSites:[],
    errorLoadingSites:[],
    getAllNotificationsId:function():Array<string>{
      return this.loadingSites.concat(this.errorLoadingSites)
    }
  }

  sites =  new BehaviorSubject(Array<siteInterface>())

  /**
  * Liste des numeros de chantiers visibles dans la carte et la liste 
  */
  public numChantSitesToDisplay = new BehaviorSubject([]);

  /**
   * Liste des sites_id à charger lorsqu'ils sont définient (length > 0) : il s'agit des sites_id dont la fonction loadDataFromServer doit chercher
   * Provient des filtres
   */
  sitesIdToLoad = new BehaviorSubject(Array<number>());

  /**
   * Configuration du projet
   */
  config_porjet

  constructor(
    // public MapGeneralService: MapGeneralService,
    public BackendApiService: BackendApiService,
    public GeneralService: GeneralService,
    public SitesCartoService:SitesCartoService,
    public StockageService:StockageService,
    public notifierService: NotifierService,
    public AuthService: AuthService,
    private http : HttpClient
  ) {
    this.config_porjet = this.GeneralService.get_config_projet()
    this.sites = this.StockageService.sites
    /**
     * Ne pas fermer automatiquement les notifications
     */
    this.notifierService.getConfig().behaviour.autoHide = false;
    this.notifier = notifierService;
  }


  setSites(newSites) {
    this.StockageService.setSites(newSites)
  }

  getSIte(num_chantier){
    return this.StockageService.getSIte(num_chantier)
  }
  /**
  * Fonction de filtre en fonction de la vue géographique avant de charger les données dans la liste
  */

  filterDataForListeByExtent(): Array<siteInterface> {
    var data = this.numChantSitesToDisplay.getValue()
    var sites = []
    for (let index = 0; index < this.StockageService.sites.getValue().length; index++) {
      const site = this.StockageService.sites.getValue()[index];
      if (data.indexOf(site[environment.id_bd_qgis_server]) != -1) {
        sites.push(site)
      }
    }
    return sites
  }

  /**
  * Compter le nombre d'entités contenu dans un sites (polygone + point + lignes)
  * @param number num_chantier numero de chantier du site
  * @return Number|undefined 
  */
  countEntitiesInSite(num_chantier: Number): Number | undefined {
    var site = this.getSIte(num_chantier)
    if (site) {
      var number_polygones = 0;
      var number_points = 0;
      var number_lignes = 0;

      for (const key in site['polygones']) {
        if (site['polygones'].hasOwnProperty(key)) {
          const element = site['polygones'][key];
          number_polygones += element.length
        }
      }

      for (const key in site['points']) {
        if (site['points'].hasOwnProperty(key)) {
          const element = site['points'][key];
          number_points += element.length
        }
      }

      for (const key in site['lignes']) {
        if (site['lignes'].hasOwnProperty(key)) {
          const element = site['lignes'][key];
          number_lignes += element.length
        }
      }

      return number_polygones + number_points + number_lignes
    } else {
      return
    }
  }

  /**
     * Charger les données dans les sources 
     * @param array<Object> sites à charger
     */
  charger_les_donnees_dans_les_sources_map(sites: Array<any>) {

    var geojsonObject_client = {
      "type": "FeatureCollection",
      "features": []
    }

    var geojsonObject_prospects = {
      "type": "FeatureCollection",
      "features": []
    }

    for (let index = 0; index < sites.length; index++) {
      const element = sites[index];
      geojsonObject_prospects.features.push(this.SitesCartoService.format_geojson(element))

    }

    var features_prospects = new GeoJSON().readFeatures(geojsonObject_prospects, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
    var layer_prospect = this.SitesCartoService.getLayerByAttr<BaseVectorLayer<Cluster>>('sites', 'nom', true)[0]
    var source_prospect = layer_prospect.getSource().getSource()
    source_prospect.addFeatures(features_prospects)

  }

  /**
   * Filtrer les sites_id à charger dans l'appli: 
   * si les filtres ont définient des sites_id dans sitesIdToLoad qui doivent ètre chargés dans l'appli,
   * alors on triera dans les ids démandé par le fake source ceux qui cooresponent à sitesIdToLoad aussi 
   * @param ids Array<number> liste des sites_id qu'on souhaitent (du fake source le plus souvent) charger dans l'appli 
   * @return Array<number>
   */
  filtrerLesSitesId(ids: Array<number>): Array<number>{
    if (this.sitesIdToLoad.value.length > 0) {
      var sites_id_a_charger: Array<number> = []
      for (let index = 0; index < this.sitesIdToLoad.value.length; index++) {
       
        if (ids.indexOf(this.sitesIdToLoad.value[index]) != -1 ) {
          sites_id_a_charger.push(this.sitesIdToLoad.value[index])
        }
      }
      return sites_id_a_charger

    }else{
      return ids
    }
  }

  /**
     * Récupere les sites de la base de données et les charge dans l'application 
     * @param ids sites à charger
     */
  async loadDataFromServer(ids: Array<number>) {
    var sites_id_a_charger = this.filtrerLesSitesId(ids)

    return new Promise((resolve, reject) => {
      var newNotificationId:string = handleDataHelper.makeid()
      while (this.notificationsId.getAllNotificationsId().indexOf(newNotificationId) != -1 ) {
        newNotificationId = handleDataHelper.makeid()
      }
      this.notificationsId.loadingSites.push(newNotificationId)
      this.notifierService.getConfig().behaviour.autoHide =false;
      
      try {
        if ( this.AuthService.get_user() && this.AuthService.get_user().groupe_users_id == 1) {
          this.notifier.notify("default", "Téléchargement de "+sites_id_a_charger.length+" Site(s) ...",newNotificationId);
        }else{
          this.notifier.notify("default", "Téléchargement de Site(s) ...",newNotificationId);
        }
      } catch (error) {
        this.notifier.notify("default", "Téléchargement de Site(s) ...",newNotificationId);
      }
      
      
       this.BackendApiService.getSites(sites_id_a_charger).then(
        (donne:reponseDB) => {
          var data_bd:Array<siteInterface> = donne.data

          this.notifier.hide(newNotificationId)
          this.notifierService.getConfig().behaviour.autoHide = 5000;

          this.notificationsId.loadingSites = []

          this.charger_les_donnees(data_bd)
          resolve(true);
        },
        (err) => {
          
          var newErrorNotificationId:string = handleDataHelper.makeid()
          while (this.notificationsId.getAllNotificationsId().indexOf(newErrorNotificationId) != -1 ) {
            newErrorNotificationId = handleDataHelper.makeid()
          }
          this.notificationsId.errorLoadingSites.push(newErrorNotificationId)
          this.notifier.notify("error", "Impossible de télécharger les  "+sites_id_a_charger.length+" Site(s)",newErrorNotificationId);
          setTimeout(()=>{
            this.notifier.hide(newErrorNotificationId)
            this.notifierService.getConfig().behaviour.autoHide = 5000;
            this.notificationsId.errorLoadingSites = []
          },5000)

          reject(err);

          throw err;
        }
      )
    })
     
  }

  /**
  * Charger les données dans la carte et dans les listes
  * @param {Array}  data les données issus de la BD à insérer dans l'appli
  */
  charger_les_donnees(data:Array<siteInterface>) {
    for (let index = 0; index < data.length; index++) {
      var element = data[index];
      element['display'] = true
    }
    var donne = this.StockageService.sites.getValue().concat(data)
    this.charger_les_donnees_dans_les_sources_map(data)
    this.setSites(donne)
  }

  /**
  * recuperer les numéros des sites provenant du serveur carto et constituer le geojson pour OL
  * @param Array<any>  data les données issus de la BD à insérer dans l'appli
   * @return Array<Array<any>> les nouveaux sites_id provenant de l'appli et les features
  */

  getNewSitesFromCartoServeur(result: Array<any>): Array<Array<any>> {
    var features_json = {
      "features": [
      ]
    }
    var new_ids = []
    for (let index = 0; index < result.length; index++) {
      const feature = result[index];
      feature.id = feature.properties.sites_id
      new_ids.push(feature.id)
      features_json.features.push(feature)
    }

    return [new_ids, features_json.features]
  }



  /**
   * Desactiver tous les sites
   */

   desactivateAllSite(){
    for (let index = 0; index < this.sites.getValue().length; index++) {
      var un_site = this.sites.getValue()[index];
      un_site['active'] = false 
      un_site['display'] = false 
    }
   }

  updateSite(site : any) : Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/sites/update', site, {headers: headers})
  }

  getClients() : Observable<clientInterface[]> {
    let headers = this.BackendApiService.get_header()
    return this.http.get<clientInterface[]>(this.apiUrl + '/api/clients/all', {headers: headers})
  }

  updateClient(client : any): Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/sites/updateClient', client, {headers: headers})
  }

  getAgences() : Observable<agenceInterface[]> {
    let headers = this.BackendApiService.get_header()
    return this.http.get<agenceInterface[]>(this.apiUrl + '/api/agences/all', {headers: headers})
  }

  updateAgence(agence : any): Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/sites/updateAgence', agence, {headers: headers})
  }

  getRegroupements() : Observable<regroupementInterface[]> {
    let headers = this.BackendApiService.get_header()
    return this.http.get<regroupementInterface[]>(this.apiUrl + '/api/regroupements/all', {headers: headers})
  }

  updateRegroupement(regroupement : any): Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/sites/updateRegroupement', regroupement, {headers: headers})
  }

  updateLocalisationSite(site : any): Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/sites/updateGeometry', site, {headers: headers})
  }

  deleteSite(site : any) : Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/sites/delete', site, {headers: headers})
  }

  updateGeometry(data : any): Observable<any> {
    let headers = this.BackendApiService.get_header()
    return this.http.post<any>(this.apiUrl + '/api/geometry/updateGeometry', data, {headers: headers})
  }

  /**
    * recuperer tous les entités géographique d'un site avec leurs modes de gestion
  */
  getElementsOfSite(site_id:number):Observable<ElementOfSite>{
    let headers = this.BackendApiService.get_header()
    return  this.http.post<ElementOfSite>(this.apiUrl + '/api/sites/elements', {site_id:site_id}, {headers: headers})
  }

}
