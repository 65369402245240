import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { EMPTY, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { donne_pour_modification } from 'src/app/modal/modification/modification.component';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-update-site',
  templateUrl: './update-site.component.html',
  styleUrls: ['./update-site.component.scss']
})
export class UpdateSiteComponent implements OnInit {

  formulaire: FormGroup
  showLoading : boolean = false

  public onSubmitInstance:()=>void;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private sitesService: SitesService,
    public dialogRef: MatDialogRef<UpdateSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data : donne_pour_modification,
    private cdRef:ChangeDetectorRef
  ) {
    this.formulaire = fb.group({
      sites_id :new FormControl(null, Validators.required),
      nom_site : new FormControl('', Validators.required),
      num_chant_sites : new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      activite : new FormControl('')
    })

    const onSubmit : Subject<void>=new Subject<void>()
    this.onSubmitInstance = () => {
      onSubmit.next()
    }

    onSubmit.pipe(
      filter(() => this.formulaire.valid),
      tap(()=>{
        this.showLoading = true;
        this.cdRef.detectChanges();
        this.formulaire.disable();
      }),
      takeUntil(this.destroyed$),
      switchMap(()=>{
        return this.sitesService.updateSite({
          sites_id : this.formulaire.get('sites_id').value,
          nom_site : this.formulaire.get('nom_site').value,
          num_chant_sites : this.formulaire.get('num_chant_sites').value,
          activite : this.formulaire.get('activite').value
        }).pipe(
          catchError(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this._snackBar.open('Un problème est survenu lors de la mise à jour du site', 'Fermer', {
              duration: 4000,
            });
            return EMPTY;
          }),
          tap(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this.dialogRef.close(true)
          })
        )
      })
    ).subscribe()
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.formulaire.get('sites_id').setValue(this.data.donne['sites_id']);
    this.formulaire.get('nom_site').setValue(this.data.donne['nom_site']);
    this.formulaire.get('num_chant_sites').setValue(this.data.donne['num_chant_sites']);
    this.formulaire.get('activite').setValue(this.data.donne['activite']);
    this.cdRef.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.destroyed$.next()
    this.destroyed$.complete()
  }

}
