import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { BackendApiService } from '../../services/backend-api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-rechercher-agence',
  templateUrl: './rechercher-agence.component.html',
  styleUrls: ['./rechercher-agence.component.scss']
})
export class RechercherAgenceComponent implements OnInit {

  controlAgence = new FormControl();
  agences: any[] = [];
  filteredOptions: Observable<any[]>;

  @Output() agenceSelectedFun = new EventEmitter()

  constructor(
    public BackendApiService: BackendApiService,
  ) { }

  displayFn(agence): string {
    return agence && agence.nom_agence ? agence.nom_agence : '';
  }

  private _filter(nom_agence: string) {
    const filterValue = nom_agence.toLowerCase();

    return this.agences.filter(option => option.nom_agence.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
    console.log(this.agences)
    this.getAgences().then(
      (res) => {
        this.filteredOptions = this.controlAgence.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.nom_agence),
            map(name => name ? this._filter(name) : this.agences.slice())
          );
      }
    )
  }


  /**
   * Récupérer toutes les agences de la BD
   */
  getAgences(): Promise<any>  {
    return new Promise(
      (resolve)=>{
        this.BackendApiService.get_requete('/api/agences/all').then(
          (agences: any) => {
            this.agences = agences
            resolve( this.agences)
          }
        )
      }
    )
     
  }

  agenceSelected(data){
    console.log(data)
    this.agenceSelectedFun.next(data)
  }
}
