import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElemCartoFormComponent } from './pages/elem-carto-form/elem-carto-form.component';
import { SharedModule } from '../shared/shared.module';
import { FeaturePopupComponent } from './pages/feature-popup/feature-popup.component';
import { ModeDeGestionComponent } from './pages/mode-de-gestion/mode-de-gestion.component';

@NgModule({
  declarations: [ElemCartoFormComponent, FeaturePopupComponent, ModeDeGestionComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  entryComponents:[ElemCartoFormComponent, FeaturePopupComponent],
  exports:[ElemCartoFormComponent]
})
export class AttributeFormModule { }
