import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { BackendApiService } from '../../services/backend-api.service'

export interface donne_pour_ajout {
  dictionnaire: Object[];
  titre: string;
  url: string;
  // result:any;
  // donne_pour_ajout:any[];
  callback?: string
}

@Component({
  selector: 'app-ajout',
  templateUrl: './ajout.component.html',
  styleUrls: ['./ajout.component.scss']
})
export class AjoutComponent implements OnInit {

  formulaire: FormGroup
  loading = false
  disabled = true
 
  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public dialogRef: MatDialogRef<AjoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_ajout
  ) {
    this.formulaire = fb.group({})
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    for (let index = 0; index < this.data.dictionnaire.length; index++) {
      const element = this.data.dictionnaire[index];

      if (element['sous_type'] != 'id') {
        var validators = []

        if (element['required']) {
          validators.push(Validators.required)
        }

        if (element['sous_type'] == 'email') {
          validators.push(Validators.email)
          this.formulaire.addControl(element['champ'], new FormControl('', validators))
        }else {
          this.formulaire.addControl(element['champ'], new FormControl('',validators))
        }
      }
    }

    this.formulaire.valueChanges.subscribe((data) => {
      if (this.formulaire.valid) {
        
        this.disabled = false
      }else{
        this.disabled = true
      }
      // this.disabled = false
    })

  }

  getErrorMessage(sous_type) {
    if (sous_type == 'email') {
      return 'e-mail invalide'
    }else if (sous_type == 'text'){
      return 'Remplisser ce champ'
    }else if (sous_type == 'number'){
      return "Champ numérique"
    }
  }

  disable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: true });
      }
    }
  }

  enable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: false });
      }
    }
  }

  valider() {

    this.loading = true
    this.disabled = true

    this.disable_tous_les_form()

    var donne = this.formulaire.value
    // donne[this.data.donne_pour_ajout[0]] = this.data.donne_pour_ajout[1]
    var url = this.data.url
    this.BackendApiService.post_requete(donne, url).then((response) => {
      // this.data.donne = this.formulaire.value
      // this.data.result = response['data']
      this.loading = false
      this.disabled = false
      this.enable_tous_les_form()
      this.dialogRef.close(this.data)

    },
      (err) => {
        
        this.enable_tous_les_form()
        this.loading = false
        this.disabled = false
        this._snackBar.open(err.message?err.message:err.error.message, 'Fermer', {
          duration: 4000,
        });
      }
    )

  }

}
