import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BackendApiService } from '../services/backend-api.service'
import { siteInterface, reponseDB } from '../type/index'
@Injectable({
  providedIn: 'root'
})
export class StockageService {

  /**
  * Liste de tous les sites visibles et non visible dans l'appli 
  */
  public sites = new BehaviorSubject(Array<siteInterface>());

  /**
    * Mettre à jour la liste des sites dans l'application
    * @param Array données provenant de la BD
    */
  setSites(newSites: Array<siteInterface>) {
    this.sites.next(newSites)
  }

  /**
   * Vider tous les sites du stockage
   */
  clearSites() {
    this.sites.next([])
  }

  /**
   * Rechercher un site par son sites_id
   * @param number sites_id numero de chantier du site rechercher
   * @return Object|undefined 
   */

  getSIte(sites_id: Number): siteInterface | undefined {
    var tous_les_sites = this.sites.getValue()
    var site;
    
    // console.log(sites_id,this.sites.getValue().find((site)=>site[environment.id_bd_qgis_server] == sites_id))
    for (let index = 0; index < tous_les_sites.length; index++) {
      const element = tous_les_sites[index];
      if (element[environment.id_bd_qgis_server] == sites_id) {
        site = element
      }
    }

    return site
  }

  /**
   * Recuperer un site de la BD pour le mettre à jour en local
   * @param sites_id 
   */
  updateSite(sites_id: number):Observable<siteInterface> {
    return this.BackendApiService.getSiteFromDB(sites_id).pipe(
      map((donne)=> {
        var data_bd: Array<siteInterface> = donne.data
        var tous_les_sites = this.sites.getValue()
        for (let index = 0; index < tous_les_sites.length; index++) {
          const element = tous_les_sites[index];
          if (element[environment.id_bd_qgis_server] == sites_id) {
            tous_les_sites[index] = data_bd[0]
          }
        }
        this.sites.next(this.sites.getValue())
        return this.getSIte(sites_id)
      }),
      // take(1),
      catchError(()=> {
        this._snackBar.open('Un problème est survenu lors du chargement du site', 'Fermer', {
          duration: 4000,
        });
        return EMPTY
      })
    )
  }

  /**
   * Supprimer un site en local
   */
  deleteSite(site: siteInterface) {
    var tous_les_sites = this.sites.getValue()
    for (let index = 0; index < tous_les_sites.length; index++) {
      const element = tous_les_sites[index];
      if (element[environment.id_bd_qgis_server] == site[environment.id_bd_qgis_server]) {
        tous_les_sites.splice(index, 1)
      }
    }
    this.sites.next(tous_les_sites)
  }

  /**
   * Recuperer tous les id deja chargé dans l'appli
   */

  getAllIds(): Array<number> {
    var response = []
    for (let index = 0; index < this.sites.getValue().length; index++) {
      const element = this.sites.getValue()[index];
      response.push(element[environment.id_bd_qgis_server])
    }
    return response
  }

  constructor(
    public BackendApiService: BackendApiService,
    private _snackBar: MatSnackBar,
  ) {
  }
}
