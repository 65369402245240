import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BackendApiService } from '../../../services/backend-api.service'
import { AuthService, User } from '../../../services/auth.service'
export interface donne_pour_modification_user {
  dictionnaire: Array<any>;
  donne: User;
  champ_id: any[];
  titre: string;
  url: string;
  callback: string

}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}
@Component({
  selector: 'app-user-modification',
  templateUrl: './modification-user.component.html',
  styleUrls: ['./modification-user.component.scss']
})



export class ModificationUserComponent implements OnInit {

  formulaire: FormGroup
  loading = false
  disabled = true
  
  edit_password = false
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    public AuthService: AuthService,
    public dialogRef: MatDialogRef<ModificationUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: donne_pour_modification_user
  ) {
    this.formulaire = fb.group({})

    this.myForm = fb.group({
      password_new: ['', [Validators.minLength(7), Validators.required]],
      confirmPassword: ['',[Validators.minLength(7), Validators.required]],
    }, { validator: this.checkPasswords });

  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password_new.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(this.data.dictionnaire)
    for (let index = 0; index < this.data.dictionnaire.length; index++) {
      const element = this.data.dictionnaire[index];

      if (element['sous_type'] != 'id' && element['champ'] != 'groupe_users_id') {

         if (element['sous_type'] == 'email') {
          this.formulaire.addControl(element['champ'], new FormControl(this.data.donne[element['champ']], [Validators.email]))
        }
        else {
          this.formulaire.addControl(element['champ'], new FormControl(this.data.donne[element['champ']]))
        }
      }
    }
    
    this.formulaire.addControl('password', new FormControl('',[Validators.minLength(7), Validators.required]))

  }

  toogle_new_passport() {
    this.edit_password = !this.edit_password
  }

  /**
   * is form valid ?
   */
  isFormValid() {
    // console.log(this.edit_password, this.myForm.valid , this.formulaire.valid)
    if (this.edit_password && this.myForm.valid && this.formulaire.valid) {
      return false
    }else if (!this.edit_password && this.formulaire.valid){
      return false
    }else{
      return true
    }
  }

  getErrorMessage(sous_type) {
    if (sous_type == 'email') {
      return 'e-mail invalide'
    }
  }

  disable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: true });
      }
    }
  }

  enable_tous_les_form() {
    for (const key in this.formulaire.controls) {
      if (this.formulaire.controls.hasOwnProperty(key)) {
        this.formulaire.controls[key].reset({ value: this.formulaire.controls[key].value, disabled: false });
      }
    }
  }
  valider() {

    this.loading = true
    this.disabled = true

    this.disable_tous_les_form()

    var donne = this.formulaire.value
    donne[this.data.champ_id[0]] = this.data.champ_id[1]
    if (this.edit_password) {
      donne['password_new'] = this.myForm.controls.password_new.value
    }

    var url = this.data.url
    this.BackendApiService.post_requete(donne, url).then((response) => {

      this.loading = false
      this.disabled = false
      this.enable_tous_les_form()

      if (!response['erreur']) {
        this.data.donne = this.formulaire.value
        if (this.edit_password) {
          localStorage.setItem('token',response['token'])
        }
        this.dialogRef.close(this.data)
      } else {
        this._snackBar.open(response['message'], 'Fermer', {
          duration: 1000,
        });
      }


    },
      (err) => {
        this.enable_tous_les_form()
        this.loading = false
        this.disabled = false
        this._snackBar.open(err.message, 'Fermer', {
          duration: 1000,
        });
      }
    )

  }

}
