import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import {ElemCartoService} from '../../../../services/elem-carto/elem-carto.service'
import {StockageService} from '../../../../services/stockage.service'
import { environment } from 'src/environments/environment';

import {
  elmCartoOverlaysForMap
}
  from '../../../../type/index'
import { FeatureWMSInfo } from 'src/app/map/map.component.parent';
@Component({
  selector: 'app-elem-carto',
  templateUrl: './elem-carto.component.html',
  styleUrls: ['./elem-carto.component.scss']
})
export class ElemCartoComponent implements OnInit {

  @Input() item:FeatureWMSInfo
  // @Output() lightFeatureFunc = new EventEmitter();
  // @Output() removeAllLightFun = new EventEmitter();
  @Output() closeFun = new EventEmitter();

  environment:any

  constructor(
    public ElemCartoService:ElemCartoService,
    public StockageService:StockageService,
  ) { 
    this.environment=environment
  }

  ngOnInit() {
  }

  close(){
    this.closeFun.emit()
  }

  round(numb:number){
    return Math.round(numb)
  }

}
