import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BackendApiService} from '../services/backend-api.service'

import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formulaire: FormGroup

  loading = false
 
  constructor(
    private AuthService : AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public BackendApiService:BackendApiService
  ) { 
    this.formulaire = fb.group({})
  }

  ngOnInit() {
    this.formulaire = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.email,
        Validators.required,
      ])),
      'password':new FormControl('', Validators.compose([
        Validators.minLength(6),
        Validators.required,
      ])),
    });
  }

  login(){
    this.loading = true
    this.BackendApiService.login(this.formulaire.value).then(
      (response)=>{
        this.AuthService.loggin(response)
        this.loading = false
      },
      (error)=>{
        this.loading = false
        this.snackBar.open('Mot de passe ou email incorrect', 'Fermer', {
          duration: 5000,
        });
      }
    )
    
  }

  passwordForget(){
    if (this.formulaire.get('email').valid) {
      this.loading = true
      this.BackendApiService.post_requete({'email':this.formulaire.get('email').value},'/api/user/forgot_assword').then(
        (response)=>{
          this.loading = false
          this.snackBar.open(response['message'], 'Fermer', {
            duration: 7000,
          });
        },
        (error)=>{
          this.loading = false
          console.log(this.formulaire.get('email').valid,error)
          this.snackBar.open(error['message'], 'Fermer', {
            duration: 7000,
          });
        }
      )
    }else{
      this.snackBar.open("Vous devez entrez un email valide !", 'Fermer', {
        duration: 7000,
      });
    }
  }
}
