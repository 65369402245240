import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Feature from 'ol/Feature';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ModeCategorie, ModeCode, ModeCodeFeature, ModeNiveau1, ModeNiveau2 } from 'src/app/models/mode_gestion';
import { ElemCartoService } from 'src/app/services/elem-carto/elem-carto.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-mode-de-gestion',
  templateUrl: './mode-de-gestion.component.html',
  styleUrls: ['./mode-de-gestion.component.scss']
})
export class ModeDeGestionComponent implements OnInit {
  onInit: () => void
  public onInitModeCategorieInstance: () => void

  @Output() onDeleteModeInstance: EventEmitter<number> = new EventEmitter<number>()
  @Input() modeGestionForm: FormGroup
  @Input() feature: Feature
  modeCategories: ModeCategorie[];
  modeNiveaux1: ModeNiveau1[];
  modeNiveaux2: ModeNiveau2[];

  displayModeGestion: { [key: string]: boolean } = {
    'categorie': true,
    'niveau1': false,
    'niveau2': false
  }

  modeGestionCategories: Subject<ModeCode[]> = new Subject<ModeCode[]>()
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public fb: FormBuilder,
    public generalService: GeneralService,
    public elemCartoService: ElemCartoService
  ) {
    this.modeCategories = this.generalService.get_config_projet().mode_gestion

    this.onInit = () => {

      this.modeGestionForm.get('categorie').valueChanges.pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this.modeGestionForm.get('niveau1').setValue(undefined, { emitEvent: false })
          this.modeGestionForm.get('niveau2').setValue(undefined, { emitEvent: false })
        }),
        filter(() => this.modeGestionForm.get('categorie').value != undefined),
        switchMap(() => {
          return this.elemCartoService.getModeGestionCategorie(this.modeGestionForm.get('categorie').value).pipe(
            tap((modeGestionCategories) => {
              this.modeGestionCategories.next(modeGestionCategories)

              this.modeNiveaux1 = modeGestionCategories
                .filter((modeCategorie) => modeCategorie.mode_niveau_1s.mode_niveau_1s_id != undefined)
                .map((modeCategorie) => modeCategorie.mode_niveau_1s)
                .filter((value, index, self) => self.map((item) => item.mode_niveau_1s_id).indexOf(value.mode_niveau_1s_id) === index)

              if (this.modeNiveaux1.length == 0) {
                this.modeGestionForm.get('niveau1').setValidators([])
                this.modeGestionForm.get('niveau1').disable()
                this.displayModeGestion.niveau2 = true
              } else {
                this.displayModeGestion.niveau1 = true
                this.displayModeGestion.niveau2 = false
                this.modeGestionForm.get('niveau1').setValidators(Validators.required)
                if (this.modeGestionForm.get('niveau1').disabled) {
                  this.modeGestionForm.get('niveau1').enable({ emitEvent: false })
                }
                this.modeGestionForm.get('niveau1').setValue(this.modeGestionForm.get('niveau1').value)
              }
            })
          )
        })
      ).subscribe()

      this.modeGestionForm.get('niveau1').valueChanges.pipe(
        withLatestFrom(this.modeGestionCategories),
        takeUntil(this.destroyed$),
        tap(([, modeCode]) => {

          this.modeNiveaux2 = modeCode
            .filter((modeCategorie) => modeCategorie.mode_niveau_1s.mode_niveau_1s_id == this.modeGestionForm.get('niveau1').value && modeCategorie.mode_niveau_2s != undefined)
            .map((modeCategorie) => modeCategorie.mode_niveau_2s)
            .filter((value, index, self) => self.map((item) => item.mode_niveau_2s_id).indexOf(value.mode_niveau_2s_id) === index)

          if (this.displayModeGestion.niveau1 && this.modeNiveaux1.length == 0) {
            this.displayModeGestion.niveau2 = true
          }

          if (this.displayModeGestion.niveau1 && this.modeNiveaux1.length > 0 && this.modeGestionForm.get('niveau1').value != undefined) {
            this.displayModeGestion.niveau2 = true
          }

          if (this.displayModeGestion.niveau1 && this.modeNiveaux1.length > 0 && this.modeGestionForm.get('niveau1').value == undefined) {
            this.displayModeGestion.niveau2 = false
          }

          if (!this.displayModeGestion.niveau1) {
            this.displayModeGestion.niveau2 = false
          }

          if (this.modeNiveaux2.length == 0) {
            this.modeGestionForm.get('niveau2').setValidators([])
            this.modeGestionForm.get('niveau2').disable()
            this.modeGestionForm.get('niveau2').setValue(undefined, { emitEvent: false })
          } else {
            this.modeGestionForm.get('niveau2').setValidators(Validators.required)
            if (this.modeGestionForm.get('niveau2').disabled) {
              this.modeGestionForm.get('niveau2').enable({ emitEvent: false })
            }
            this.modeGestionForm.get('niveau2').setValue(this.modeGestionForm.get('niveau2').value)
          }

        })
      ).subscribe()

      this.modeGestionForm.valueChanges.pipe(
        // tap(() => {
        //   this.modeGestionForm.get('codeModeChoose').setValue(undefined, {emitEvent: false});
        // }),
        // delay(1000),
        // debounceTime(500),
        takeUntil(this.destroyed$),
        filter(() => this.modeGestionForm.valid),
        filter(() => Object.keys(this.displayModeGestion).filter((key, index) => this.displayModeGestion[key] === false).length == 0),
        withLatestFrom(this.modeGestionCategories),
        tap(([, modeGestionCategories]) => {

          let myMode = modeGestionCategories.find((modeCategorie) => {
            return modeCategorie.mode_categories.mode_categories_id == this.modeGestionForm.get('categorie').value &&
              (!modeCategorie.mode_niveau_1s || (modeCategorie.mode_niveau_1s.mode_niveau_1s_id == this.modeGestionForm.get('niveau1').value)) &&
              (!modeCategorie.mode_niveau_2s || (modeCategorie.mode_niveau_2s.mode_niveau_2s_id == this.modeGestionForm.get('niveau2').value))
          })
          let codeModeChoose: ModeCodeFeature = {
            ...myMode,
          frequence:this.modeGestionForm.get('frequence').value?this.modeGestionForm.get('frequence').value:null
        }
          
          this.modeGestionForm.get('codeModeChoose').setValue(codeModeChoose, {emitEvent: false})
        })
      ).subscribe()
    }

    const onInitModeCategorie: Subject<void> = new Subject<void>()
    this.onInitModeCategorieInstance = () => {
      onInitModeCategorie.next()
    }
    onInitModeCategorie.pipe(
      takeUntil(this.destroyed$),
      filter(() => this.modeGestionForm.get('categorie').value != undefined),
      switchMap(() => {
        return this.elemCartoService.getModeGestionCategorie(this.modeGestionForm.get('categorie').value).pipe(
          tap((modeGestionCategories) => {
            this.modeGestionCategories.next(modeGestionCategories)
            if (this.feature.get('mode_codes_id') && 
            modeGestionCategories.find((modeGestionCategories) => modeGestionCategories.mode_codes_id === this.feature.get('mode_codes_id'))
            ) {
              let modeGestionCategories_feature = modeGestionCategories
                .find((modeGestionCategories) => modeGestionCategories.mode_codes_id === this.feature.get('mode_codes_id'))
              this.modeGestionForm.get('niveau1').setValue(
                modeGestionCategories_feature.mode_niveau_1s
                  ? modeGestionCategories_feature.mode_niveau_1s.mode_niveau_1s_id
                  : undefined,
                { emitEvent: false }
              )
              this.modeGestionForm.get('niveau2').setValue(
                modeGestionCategories_feature.mode_niveau_2s
                  ? modeGestionCategories_feature.mode_niveau_2s.mode_niveau_2s_id
                  : undefined,
                { emitEvent: false }
              )
            }
            this.modeNiveaux1 = modeGestionCategories
              .filter((modeCategorie) => modeCategorie.mode_niveau_1s.mode_niveau_1s_id != undefined)
              .map((modeCategorie) => modeCategorie.mode_niveau_1s)
              .filter((value, index, self) => self.map((item) => item.mode_niveau_1s_id).indexOf(value.mode_niveau_1s_id) === index)

            if (this.modeNiveaux1.length == 0) {
              this.modeGestionForm.get('niveau1').setValidators([])
              this.modeGestionForm.get('niveau1').disable()
              this.displayModeGestion.niveau2 = true
            } else {
              this.displayModeGestion.niveau1 = true
              this.displayModeGestion.niveau2 = false
              // this.modeGestionForm.get('niveau1').setValidators(Validators.required)
              if (this.modeGestionForm.get('niveau1').disabled) {
                this.modeGestionForm.get('niveau1').enable({ emitEvent: false })
              }
              this.modeGestionForm.get('niveau1').setValue(this.modeGestionForm.get('niveau1').value)
            }
          })
        )
      })
    ).subscribe()

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.onInitModeCategorieInstance()
    if (changes.modeGestionForm) {
      this.onInit()
      if (this.modeGestionForm.get('categorie')) {
      }
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  onDeleteMode() {
    this.onDeleteModeInstance.emit()
  }
}
