import { Component, OnInit } from '@angular/core';
import { elemCartoInterface } from 'src/app/type';
import { EditElementCartographiqueComponent } from 'src/app/modal/gestion-user/edit-element-cartographique/edit-element-cartographique.component';
import { GeneralService } from '../../../services/general.service'
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestion-element-cartographique',
  templateUrl: './gestion-element-cartographique.component.html',
  styleUrls: ['./gestion-element-cartographique.component.scss']
})
export class GestionElementCartographiqueComponent implements OnInit {

  config_projet:any
  environment = environment

  constructor(
    public  GeneralService:GeneralService,
    public dialog: MatDialog,
  ) { 
    this.GeneralService.config_projet.subscribe(value => {
      this.config_projet = value
    })
  }

  ngOnInit() {
  }

  /**
   * Ouvrir le menu d'edition des elements cartographique
   */
  ouvrir_fenetre_edition_cartographique(data: elemCartoInterface, size?: string[]) {

    const fenetre_edition_carto = this.dialog.open(EditElementCartographiqueComponent, {
      // width: size[0],
      // height: size[1],
      disableClose: false,
      data: data
    });

    fenetre_edition_carto.afterClosed().subscribe((result: elemCartoInterface) => {
      if (result) {
        this.GeneralService.querry_config_projet()
      }
    })

  }
  
}
