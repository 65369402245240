import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { clientInterface,siteInterface } from 'src/app/type';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { GeneralService } from 'src/app/services/general.service';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss']
})
export class UpdateClientComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  clients$ : Observable<clientInterface[]>
  public onInitInstance: () => void;
  public onSubmitInstance:()=>void;

  config_projet: any
  showLoadingClients : boolean = false
  showLoading : boolean = false
  environment = environment
  formulaire: FormGroup
  disabled=true

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private BackendApiService: BackendApiService,
    private sitesService: SitesService,
    public GeneralService: GeneralService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateClientComponent>,
    private cdRef:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: siteInterface
  ) {
    this.formulaire = this.fb.group({
      'client_id': [null, [Validators.required]]
    })


    this.GeneralService.config_projet.pipe(
      tap((value) => {
        this.config_projet = value
      }),
      takeUntil(this.destroyed$)
    ).subscribe()

    const onInit: Subject<void> = new ReplaySubject<void>(1)
    this.onInitInstance = () => {
      onInit.next()
    }

    this.clients$ = onInit.pipe(
      tap(()=> {
        this.showLoadingClients = true
        this.cdRef.detectChanges()
        this.formulaire.get('client_id').disable()
      }),
      switchMap(()=> {
        return this.sitesService.getClients().pipe(
          tap(()=> {
            this.showLoadingClients = false
            this.formulaire.get('client_id').enable()
          }),
          catchError(()=> {
            this._snackBar.open('Une erreur est survenue lors du chargement des clients', 'Fermer', {
              duration: 1000,
            });
            this.showLoadingClients = false
            return EMPTY
          })
        )
      })
    )

    const onSubmit : Subject<void>=new Subject<void>()
    this.onSubmitInstance = () => {
      onSubmit.next()
    }

    onSubmit.pipe(
      filter(() => this.formulaire.valid),
      tap(()=>{
        this.showLoading = true;
        this.cdRef.detectChanges();
        this.formulaire.disable();
      }),
      takeUntil(this.destroyed$),
      switchMap(()=>{
        return this.sitesService.updateClient({
          sites_id:this.data.sites_id,
          client_id:this.formulaire.get('client_id').value,
        }).pipe(
          catchError(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this._snackBar.open('Un problème est survenu lors de la mise à jour du client', 'Fermer', {
              duration: 4000,
            });
            return EMPTY;
          }),
          tap(()=>{
            this.showLoading = false;
            this.formulaire.enable()
            this.dialogRef.close(true)
          })
        )
      })
    ).subscribe()
   }

  ngOnInit() {
    this.onInitInstance()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.formulaire.get('client_id').setValue(this.data.client.client_id);
    this.cdRef.detectChanges();
  }

  ngOnDestroy(){
    this.destroyed$.next()
    this.destroyed$.complete()
  }

}
