import { Component, OnInit,Input, Renderer2, Output,EventEmitter } from '@angular/core';
import {SitesService} from '../../services/sites.service'
import {GeneralService} from '../../services/general.service'
import {ElemCartoService} from '../../services/elem-carto/elem-carto.service'
import {environment} from '../../../environments/environment'
import { siteInterface } from 'src/app/type';

@Component({
  selector: 'app-un-site',
  templateUrl: './un-site.component.html',
  styleUrls: ['./un-site.component.scss']
})
export class UnSiteComponent implements OnInit {

  objectsToArray=Object.keys
  environment:any

  constructor(
    public SitesService:SitesService,
    public GeneralService:GeneralService,
    public ElemCartoService:ElemCartoService,
    private ren: Renderer2,
    
  ) { 
    this.environment = environment
  }

  @Input() source:string
  @Input() un_site:siteInterface
  @Input() maxWidth:number

  // @Output() displayFunc = new EventEmitter()

  ngOnInit() {

  }

  round(numb:number){
    return Math.round(numb)
  }

  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  // prevButtonTrigger;

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
    // console.log("menuEnter");
  }



}
