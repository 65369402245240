import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { siteInterface } from 'src/app/type';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery'
import { fromLonLat } from 'src/app/ol-module';
import { from, ReplaySubject, Subject, Observable, EMPTY } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HistoricComponent } from '../../modal/historic/historic.component';
import { BackendApiService } from 'src/app/services/backend-api.service';
@Component({
  selector: 'app-suivi',
  templateUrl: './suivi.component.html',
  styleUrls: ['./suivi.component.scss']
})
/**
 * Composant pour la partie suivi d'un site dans sa fiche descriptive
 */
export class SuiviComponent implements OnInit, OnChanges {
  
  @Input() site: siteInterface
  @Output() onCloseMapOverlay = new EventEmitter()

  objectsToArray = Object.keys
  public onInitInstance : () => void;
  public onOpenHistoricInstance : () => void;
  haveHistoric$:Observable<{response:boolean}>
  loading:boolean = false

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    public dialog: MatDialog,
    public backendApiService:BackendApiService
  ) { 

    const onInit:ReplaySubject<void> = new ReplaySubject<void>(1)

    this.onInitInstance = ()=>{
      onInit.next()
    }

    this.haveHistoric$ = onInit.pipe(
      tap(()=>{this.loading =true}),
      switchMap(()=>{
        return from<{response:boolean}>(this.backendApiService.post_requete({sites_id:this.site.sites_id}, '/api/historique/have_historic')).pipe(
          catchError(()=>{
            alert("Une érreur est survenue")
            return EMPTY
          })
        )
      }),
      tap(()=>{this.loading =false}),
    )

    

    this.onOpenHistoricInstance = () => {
      this.onCloseMapOverlay.emit()
      var size = [$(window).width() * 0.95 + 'px', $(window).height() * 0.95 + 'px']
      this.dialog.open(HistoricComponent, {
          data:{site:this.site,coord: fromLonLat(JSON.parse(this.site.geojson)['geometry']['coordinates'])},
          width: size[0],
          maxWidth: size[0],
          height: size[1],
          maxHeight:size[1],
          panelClass: ['panelWithNoPadding'],
      })
    }
    
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.site) {
      if (this.site) {
        this.onInitInstance()
      }
    }
  }

  ngOnDestroy(){
    this.destroyed$.next()
    this.destroyed$.complete()
  }

}
