import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { SitesService } from 'src/app/services/sites.service';
import { ElementOfSite, siteInterface } from 'src/app/type';
import { environment } from '../../../../../environments/environment'
import { ElemCartoService } from '../../../../services/elem-carto/elem-carto.service'


@Component({
  selector: 'app-entite',
  templateUrl: './entite.component.html',
  styleUrls: ['./entite.component.scss']
})
export class EntiteComponent implements OnInit {

  onInitInstance: () => void;
  onRefreshtInstance: () => void;

  @Input() site: siteInterface
  siteElements$: Observable<ElementOfSite>;
  listIdElemCarto:number[] = []
  objectsToArray = Object.keys
  objectsAssign=Object.assign
  environment: any
  loading:boolean = true

  constructor(
    public ElemCartoService: ElemCartoService,
    public sitesService: SitesService,
    public notifier:NotifierService
  ) {
    this.environment = environment

    const onInit: Subject<void> = new ReplaySubject<void>(1)
    this.onInitInstance = () => {
      onInit.next()
    }
    const onRefresh:Subject<void> = new Subject<void>()
    this.onRefreshtInstance=()=>{
      console.log('refresh')
      onRefresh.next()
    }

    this.siteElements$ = merge(onInit,onRefresh).pipe(
      tap(()=>{this.loading = true}),
      switchMap(() => {
        return this.sitesService.getElementsOfSite(this.site.sites_id).pipe(
          tap(()=>{this.loading = false}),
          catchError(()=>{
            this.loading = false
            this.notifier.notify('error',"Un problème est survenu lors de la récuperation des entités du site")
            return EMPTY
          }),
          tap((siteElements)=>{
            this.listIdElemCarto = this.objectsToArray(siteElements.points).concat(this.objectsToArray(siteElements.polygones)).concat(this.objectsToArray(siteElements.lignes)).map((str)=>parseInt(str))
          })
        )
      })
    )
  }

  ngOnInit() {
    this.onInitInstance()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.site){
      this.onRefreshtInstance()
    }
  }

  sumTypologie(element):number{
    return element.map((typologie) => {
      return typologie.features.length
    }).reduce((accumulator, curr) => accumulator + curr, 0)
  }

  getFeaturesAreaSum(features: any[], type: string) {
    if (type == 'item') {
      let areas = features.map((feature) => feature.surface)
      let totalArea: number = Math.floor(areas.reduce((a, b) => a + b, 0))
      return totalArea.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
    else if (type == 'all') {
      let areas = features.map((polygon) => polygon.features.map((feature) => feature.surface))
    }
  }
}
