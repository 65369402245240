import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize, filter } from 'rxjs/operators';
import { BackendApiService } from '../../services/backend-api.service';
import { StockageService } from '../../services/stockage.service';
import { SitesService } from '../../services/sites.service';
import { environment } from 'src/environments/environment';
import { siteInterface } from 'src/app/type';

@Component({
  selector: 'app-recherche-site',
  templateUrl: './recherche-site.component.html',
  styleUrls: ['./recherche-site.component.scss']
})
export class RechercheSiteComponent implements OnInit {

  @Input() width: number
  @Output() openPteSite = new EventEmitter();
  @Input() appearance: string = 'outline'
  @Input() type: string = ''
  /**
   * Formgroup de la barre de recherche des entites
   */
  recherche_form: FormGroup

  /**
   * lorsque l'on recherhe une entrprise
   */
  recherche_is_loading: boolean = false

  /**
   * Résultats des recherches
   */
  recherche_reponse: any[] = []
  constructor(
    public fb: FormBuilder,
    public BackendApiService: BackendApiService,
    public StockageService: StockageService,
    public SitesService: SitesService
  ) { }

  ngOnInit() {
    this.initialiser_la_recherche_entreprise()
  }

  /**
  * 
  * @param response reponse d'un element du serveur de recherhce
  */
  displayFn(response: siteInterface): string {
    if (response && response['nom_site']) {
      return response.num_chant_sites +' - ' + response.nom_site
    } else {
      return ''
    }
  }

  /**
    * initialise la barre de recherche des entreprises
    */
  initialiser_la_recherche_entreprise() {


    this.recherche_form = this.fb.group({
      word: ''
    })

    this.recherche_form
      .get('word')
      .valueChanges
      .pipe(
        filter(value=>typeof value === "string" && value.length>2),
        debounceTime(300),
        tap(() => { this.recherche_is_loading = true; this.recherche_reponse = [] }),
        switchMap(value => {
          if (typeof value === 'string' && value.length > 1) {
            return this.BackendApiService.search_entite(value.toString())
              .pipe(
                finalize(() => this.recherche_is_loading = false),
              )
          }

        }
        )
      )
      .subscribe((reponses) => {
        this.recherche_is_loading = false
        this.recherche_reponse = reponses

      });
  }

  /**
   * Afficher une reponse du serveur de recherche : si il n'est pas encore dans l'appli, on l'ajoute dans l'appli avant d'ouvrir sa fiche
   * @param response reponse du serveur 
   */

  displayResultRerecherche(response: siteInterface) {
    if (response) {
      var site = this.StockageService.getSIte(response[environment.id_bd_qgis_server])
      if (site) {
        this.openPteSite.next(response[environment.id_bd_qgis_server])
      } else {
        // this.SitesService.loadDataFromServer([response[environment.id_bd_qgis_server]]).then(
        //   ()=>{
        //     this.openPteSite.next(response[environment.id_bd_qgis_server])
        //   }
        // )
        this.SitesService.charger_les_donnees([response])
        setTimeout(() => {
          this.openPteSite.next(response[environment.id_bd_qgis_server])
        }, 500)

      }
    }

  }
}
