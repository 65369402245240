import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector.js';
import { Map, View, Feature } from 'ol';
import { Group as LayerGroup, Vector as VectorLayer } from 'ol/layer.js';
import { transform as Transform, fromLonLat, transform, get as getProjection } from 'ol/proj.js';
import {
  Circle as CircleStyle, Fill, Stroke, Text, RegularShape, Icon
} from 'ol/style.js';
import TileLayer from 'ol/layer/Tile.js';
import XYZ from 'ol/source/XYZ';
import Style from 'ol/style/Style';
import { ResponseAdresseDataGouv, ResponseAdresseGoogle,AdresseAPIResponse, PlusCode } from '../../../type/index';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, forkJoin, fromEvent, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, tap, switchMap, finalize, map as MAPRJX, withLatestFrom, catchError, skip, takeUntil } from 'rxjs/operators';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { MatSnackBar } from '@angular/material';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { MapGeneralService } from 'src/app/services/map.general.service';
import { SitesCartoService } from 'src/app/services/sites-carto.service';
import { fromOpenlayerEvent } from 'src/app/digitaliser/class/updateAttributes';
import BaseLayer from 'ol/layer/Base';

@Component({
  selector: 'app-get-adresse',
  templateUrl: './get-adresse.component.html',
  styleUrls: ['./get-adresse.component.scss']
})
export class GetAdresseComponent implements OnInit,AfterViewInit, OnDestroy,OnChanges {

  @Input()adresse:string 
  @Input()cp?:string 
  @Input()ville?:string 
   /**
   * 4326
   */
  @Input()x:number 
  /**
   * 4326
   */
  @Input()y:number 
  public adresseResult:Observable<{
    adresse:string
    feature:ResponseAdresseDataGouv
    ville:string
    cp:string
    x:number
    y:number
  }>
  onGeolocateUserInstance: ()=>void

  view = new View({
    center: fromLonLat([-2, 47]),
    zoom: 5,
  })
  map: Map = new Map({
    maxTilesLoading: 50,
    view: this.view
  });
  formulaire: FormGroup
  vectorSource_adresse: VectorSource
  vectorLayer_adresse: VectorLayer

  responseAdresses:Observable<Array<ResponseAdresseDataGouv>> 

  findAdresseIsLoading:boolean = false
  onGeolocatingUserLoading:boolean = false

  typeGeocode:ReplaySubject<'coordinate'|'adresse'> = new ReplaySubject<'coordinate'|'adresse'>(1)

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.x){
      // console.log(changes, changes.x.currentValue, changes.x.currentValue && changes.y.currentValue)
      if (changes.x.currentValue && changes.y.currentValue) {
        this.typeGeocode.next('coordinate')
        setTimeout(()=>{
          this.formulaire.controls['adresse'].setValue(changes.adresse.currentValue,{ disabled: true,emitEvent:false,observables:false })
          this.map.getView().animate({ center: fromLonLat([changes.x.currentValue,changes.y.currentValue]) }, { zoom: 17 }, { duration: 500 });
        },1000)
      }
    }
  }
  constructor(
    private fb: FormBuilder,
    public MapGeneralService: MapGeneralService,
    private BackendApiService: BackendApiService,
    private _snackBar: MatSnackBar,
    private SitesCartoService : SitesCartoService
  ) {


    this.formulaire = this.fb.group({
      adresse: new FormControl(this.adresse, [Validators.required]),
      feature: new FormControl(null),
      ville: new FormControl(this.ville, [Validators.required]).disable(),
      cp: new FormControl(this.cp, [Validators.required]).disable(),
      x: new FormControl(this.x, [Validators.required]),
      y: new FormControl(this.y, [Validators.required]),
    })

    this.responseAdresses = this.formulaire
    .get('adresse')
    .valueChanges
    .pipe(
      debounceTime(300),
      filter(value => typeof value === "string"),
      switchMap(value => {
        return forkJoin(
          this.getObservableOfQuerryAdress(value.toString())
        ).pipe(
          MAPRJX((value)=>{
            return value[0].concat(value[1])
          })
        )
      })
    )

    this.typeGeocode.next('adresse')

    this.typeGeocode.pipe(
      skip(1),
      tap((type)=>{
        if (type == 'coordinate') {
          this.formulaire.controls['adresse'].setValue(this.formulaire.controls['adresse'].value,{ disabled: true })
        } else {
          this.formulaire.controls['adresse'].setValue(this.formulaire.controls['adresse'].value,{disabled: false })
          if (this.formulaire.controls['feature'].value ) {
            this.updateFormAdresse(this.formulaire.controls['feature'].value)
            this.updateMarkerAdresse(this.formulaire.controls['feature'].value)
          }
        }
      }),
      takeUntil(this.destroyed$)
    ).subscribe()

    fromOpenlayerEvent(this.map,'moveend').pipe(
      withLatestFrom(this.typeGeocode),
      filter((value)=> value[1]=='coordinate'),
      switchMap((value)=>{
        var coords = transform(this.view.getCenter(), 'EPSG:3857', 'EPSG:4326');
        let latitude = coords[0]
        let longitude = coords[1]
        return forkJoin(this.getObservableOfQuerryReverseAdress(latitude ,longitude )).pipe(
          switchMap((value:[ResponseAdresseDataGouv[],ResponseAdresseDataGouv[]])=> {return [value[0].concat(value[1])] }),
          catchError(() => { alert( "Le géocodage a échoué"); return EMPTY })
        )
      }),
      tap((features:ResponseAdresseDataGouv[])=>{
        if(features.length>0){
          let feature:ResponseAdresseDataGouv;
          if (features.filter((feat)=>feat.typeRes == "gouv").length > 0){
             feature = features.filter((feat)=>feat.typeRes == "gouv")[0]
          }else{
             feature = features[0]
          }
          this.updateFormAdresse(feature)
        }else{
          this._snackBar.open('Aucune adresse trouver', 'Fermer', {
            duration: 4000,
          });
        }
        
      }),
      takeUntil(this.destroyed$)
    ).subscribe()

    const onGeolocateUser:Subject<void>= new Subject<void>()
    this.onGeolocateUserInstance = ()=>{
      onGeolocateUser.next()
    }

    combineLatest(this.MapGeneralService.coordonne_user,onGeolocateUser).pipe(
      tap(()=>{ this.onGeolocatingUserLoading = true}),
      filter((value)=>value[0] && value[0].length == 2),
      switchMap((value)=>{
        this.map.getView().animate({ center: value[0] }, { zoom: 17 }, { duration: 500 });
        let coord_user = Transform(value[0], 'EPSG:3857', 'EPSG:4326')
        let latitude = coord_user[0]
        let longitude = coord_user[1]
        return forkJoin(this.getObservableOfQuerryReverseAdress(latitude ,longitude )).pipe(
          switchMap((value)=> {return value}),
          catchError(() => { alert( "Le géocodage a échoué"); return EMPTY })
        )
      }),
      tap((features:ResponseAdresseDataGouv[])=>{
        if(features.length>0){
          let feature:ResponseAdresseDataGouv;
          if (features.filter((feat)=>feat.typeRes == "gouv").length > 0){
             feature = features.filter((feat)=>feat.typeRes == "gouv")[0]
          }else{
             feature = features[0]
          }
          this.updateFormAdresse(feature)
          this.updateMarkerAdresse(feature)
        }else{
          this._snackBar.open('Aucune adresse trouver', 'Fermer', {
            duration: 4000,
          });
        }
        
      }),
      takeUntil(this.destroyed$)
    ).subscribe()

    this.adresseResult = this.formulaire.valueChanges.pipe(
      MAPRJX((value)=>{
        if(value.adresse instanceof Object){
          value.adresse = value.adresse['properties']['label']
        }
        return value
      }),
      takeUntil(this.destroyed$)
      )

      
  }

  @ViewChild('map') set myDiv(myDiv: ElementRef) {
   
    this.map.setTarget(myDiv.nativeElement)
    
    var plan = this.MapGeneralService.getDefaultMapLayer()
    plan.set('nom', 'defaultLayer')
    plan.set('type_layer', 'plan')
    plan.setVisible(false)
    this.map.addLayer(plan)

    var ign = this.MapGeneralService.getIgnLayer()
    ign.set('nom', 'ign')
    ign.set('type_layer', 'plan')
    this.map.addLayer(ign)

    let empriseWfs = this.SitesCartoService.getWfsPerimetre('bbox')[0]
    empriseWfs.setStyle( 
       new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.12)'
        }),
        text:new Text
      })
    )
    empriseWfs.setVisible(true)
    this.map.addLayer(empriseWfs)

    var vectorSource = new VectorSource({
      features: [],
    });

    var wfsLayer = new VectorLayer({
      source: vectorSource,

    });
    wfsLayer.set('type_layer','original')
    wfsLayer.set('nom', 'original')
    // wfsLayer.setStyle(this.data.style)
    wfsLayer.setZIndex(3)

    this.map.addLayer(wfsLayer)

    var icon = new Icon(({
      scale: 0.1,
      src: 'assets/icones/location-pin.svg'
    }))
    var iconStyle = new Style({
      image: icon
    });

    this.vectorSource_adresse = new VectorSource({
      features: []
    });

    this.vectorLayer_adresse = new VectorLayer({
      source: this.vectorSource_adresse,
      style: iconStyle
    })
    this.vectorLayer_adresse.setZIndex(99)
    this.vectorSource_adresse.clear()
    this.map.addLayer(this.vectorLayer_adresse)
    // console.log(this.x && this.y,this.adresse,fromLonLat([this.x,this.y]))
    
  }

  ngOnInit() {
  }

  ngOnDestroy():void{
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.map.updateSize()
  }

  /**
   * Get Array of observable to make reverse geocoding depending of the user
   * @param lon string longitude
   * @param lat string latitude
   */
  getObservableOfQuerryReverseAdress(lon: number,lat:number): Array<Observable<ResponseAdresseDataGouv[]>> {

    return [
      this.BackendApiService.reverseGeoCode(lat,lon)
          .pipe(
            tap(() => this.findAdresseIsLoading = false),
            MAPRJX((value: ResponseAdresseDataGouv[]) => { 
               value.map((res)=>{
                res.typeRes ="gouv"
              })
              return value
            })
          ),
      this.BackendApiService.reverseGeocodeGoogle(lat,lon)
        .pipe(
          tap(() => this.findAdresseIsLoading = false),
          MAPRJX((value:ResponseAdresseGoogle[] | PlusCode) => {
            let responses: Array<ResponseAdresseDataGouv> = []

            if ( value instanceof  Array) {
              for (let index = 0; index < value.length; index++) {
                responses.push(this.MapGeneralService.transformGoogleAdresseToGouvAdresse(value[index]))
              }
            }else if (value instanceof Object) {
                responses.push(this.MapGeneralService.transformPlusCodeToGouvAdresse(value,lat,lon))
            }

            responses.map((res)=>{
              res.typeRes ="google"
            })
            return responses

          })
        )
    ]

  
  }

   /**
   * Get Array of observable to make  geocoding depending of the user
   * @param value string term to search
   */
  getObservableOfQuerryAdress(value: string): Array<Observable<ResponseAdresseDataGouv[]>> {

      let querry = value.toString()
      .replace(/\+/g,'%2B')
      .replace(/\' '/g,'%20')

      return [
        this.BackendApiService.searchAdresse(value.toString().split(" ").join("+"))
          .pipe(
            tap(() => this.findAdresseIsLoading = false),
            MAPRJX((value) => {
              value.map((res)=>{
                res.typeRes ="gouv"
              })
               return value
            })
          ),
          this.BackendApiService.searchAdresseGoogle(querry)
          .pipe(
            tap(() => this.findAdresseIsLoading = false),
            MAPRJX((value) => {
              let responses: Array<ResponseAdresseDataGouv> = []
              for (let index = 0; index < value.length; index++) {
                responses.push(this.MapGeneralService.transformGoogleAdresseToGouvAdresse(value[index]))
              }
              responses.map((res)=>{
                res.typeRes ="google"
              })
              return responses
            })
          )

      ]
  
  }

  updateMarkerAdresse(feature:ResponseAdresseDataGouv){
      let olFeature = new GeoJSON().readFeatures(feature, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })[0]
      this.vectorSource_adresse.clear()
      this.vectorSource_adresse.addFeature(olFeature)
      let coordinate = Object.create(olFeature.getGeometry()).getCoordinates();
      this.map.getView().animate({ center: coordinate }, { zoom: 17 }, { duration: 500 });
  }

  updateFormAdresse(feature:ResponseAdresseDataGouv){
    let ville = feature.properties.city
    let cp = feature.properties.postcode
    let label = feature.properties.label

    this.formulaire.controls['adresse'].setValue(label,{emitEvent:false,observables:false})
    this.formulaire.controls['feature'].setValue(feature,{ disabled: true })
    this.formulaire.controls['ville'].setValue(ville,{disabled: true })
    this.formulaire.controls['cp'].setValue(cp,{ disabled: true })
    
  }

  /**
   * Emis lorsqu'une adresse est choisie
   */
  onAdresseSelected(feature:ResponseAdresseDataGouv){
    if (feature) {
      this.updateFormAdresse(feature)
      this.updateMarkerAdresse(feature)
    }else{
      if(!this.MapGeneralService.coordonne_user.getValue()){
        this.MapGeneralService.geolocate_me(false)
      }
      this.onGeolocateUserInstance()
    }
  }

  displayFn(feature:ResponseAdresseDataGouv) {
    if (feature && feature instanceof Object) {
      return feature.properties.label;
    } else {
     
      return feature
    }
  }

  /**
 * change base maps bettween aerien and plan
 */
  toogleBaseMaps() {
    var ign = this.getLayerByAttr('ign', 'nom', true)[0]
    var defaultLayer = this.getLayerByAttr('defaultLayer', 'nom', true)[0]
    ign.setVisible(!ign.getVisible())
    defaultLayer.setVisible(!defaultLayer.getVisible())
  }

  getLayerByAttr(layername, attr, layer_group) {
    var layer_to_remove = []
    let all_layers:Array<BaseLayer>=[]
    if (layer_group) {
      all_layers = this.map.getLayers().getArray()
    } else {
      all_layers = this.map.getLayerGroup().getLayersArray()
    }

    for (let index = 0; index < all_layers.length; index++) {
      var layer = all_layers[index]
      if (layer.get(attr) == layername) {
        layer_to_remove.push(layer)
      }

    }
    return layer_to_remove
  }

}
