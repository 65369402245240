import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FeatureLike } from 'ol/Feature';
import { filter } from 'rxjs/operators';
import { TeridealFeature } from 'src/app/models/teridealFeature';
import { Feature } from 'src/app/ol-module';

@Component({
  selector: 'app-feature-popup',
  templateUrl: './feature-popup.component.html',
  styleUrls: ['./feature-popup.component.scss']
})
export class FeaturePopupComponent implements OnInit {

  feature: FeatureLike
  properties: TeridealFeature
  typologieSurface: string
  modeGestion: { name: string, frequence: number }[] = []

  constructor() { }

  ngOnInit() {
  }


  onInitInstance() {
    if (this.feature) {
      this.properties = this.feature.getProperties() as TeridealFeature

      try {

        if (this.properties.typo_typologies) {
          this.typologieSurface = this.properties.typo_typologies.name
        } else if (this.properties.typo_compositions) {
          this.typologieSurface = this.properties.typo_compositions.name
        } else if (this.properties.typo_formes) {
          this.typologieSurface = this.properties.typo_formes.name
        } else if (this.properties.elem_carto_valeur) {
          this.typologieSurface = this.properties.elem_carto_valeur
        }

        this.modeGestion = this.properties.modes
          .filter((mode) => mode.mode_niveau_1s != undefined || mode.mode_niveau_1s != undefined)
          .map((mode) => {
            let name;
            if (mode.mode_niveau_2s) {
              name = mode.mode_niveau_2s.name
            } else if (mode.mode_niveau_1s) {
              name = mode.mode_niveau_1s.name
            } else {
              name = mode.mode_categories.name
            }
            return {
              name: name,
              frequence: mode.frequence
            }
          })
      } catch (error) {
        this.modeGestion = undefined
        this.typologieSurface = undefined
      }
    }
  }


}
