import { Component, OnInit } from '@angular/core';
import { regroupementInterface } from 'src/app/type';
import { GeneralService } from '../../../services/general.service'
import { BackendApiService } from '../../../services/backend-api.service'
import { MatDialog, MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { donne_pour_modification } from '../../../modal/modification/modification.component'
import { donne_pour_ajout } from '../../../modal/ajout/ajout.component'
import { TranslateService } from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
/**
 * Gestion des regroupements: ajout/modification/suppresion
 */
@Component({
  selector: 'app-gestion-regroupement',
  templateUrl: './gestion-regroupement.component.html',
  styleUrls: ['./gestion-regroupement.component.scss']
})
export class GestionRegroupementComponent implements OnInit {

  config_projet: any
  environment = environment
  loading: boolean = false
  regroupements: Array<regroupementInterface>
  dataSourceRegroupements = new MatTableDataSource(this.regroupements);
  constructor(
    public GeneralService: GeneralService,
    public _dialog: MatDialog,
    private BackendApiService: BackendApiService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {
    this.GeneralService.config_projet.subscribe(value => {
      this.config_projet = value
    })
  }

  async ngOnInit() {
   
    await this.getRegroupements()
  }

  /**
   * recuperer les regroupements de la BD
   */
  async getRegroupements() {
    this.loading = true
    await this.BackendApiService.get_requete("/api/regroupements/all").then(
      (data: Array<regroupementInterface>) => {
        this.regroupements = data
        this.dataSourceRegroupements= new MatTableDataSource(this.regroupements)
        this.loading = false
      },
      (err) => {
        this.loading = false
        this.regroupements = []
        this._snackBar.open(err.message, 'Fermer', {
          duration: 1000,
        });
      }
    )

  }

  /**
   * @param event Filtrer les regroupements avec tous les champs 
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceRegroupements.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Modifier un regroupement 
   * @param regroupement regroupementInterface 
   */
  ouvrir_fenetre_edition_regroupement(regroupement:regroupementInterface){
    var titre = ''
    this.translate.get('administration', { value: 'site' }).subscribe((res) => {
      titre = res['regroupement']['modifier_regroupement'] + regroupement.nom_regroup
    });

    var donne:donne_pour_modification={
      dictionnaire:this.config_projet.dictionaire['dictionnaire_regroupement'],
      donne:regroupement,
      champ_id:['regroupement_id',regroupement.regroupement_id],
      titre:titre,
      url:'/api/regroupements/update'
    }
    this.GeneralService.ouvrir_fenetre_modification(donne,[],(result)=>{
      if (result) {
        this.getRegroupements()
      }
    })
  }


  /**
   * Ouvur le modal d'ajout de regroupement'
   */
  ouvrir_fenetre_add_regroupement() {
    var titre = ''

    this.translate.get('administration', { value: 'site' }).subscribe((res) => {
      titre = res['regroupement']['ajouter_regroupement']
    });

    var donne:donne_pour_ajout = {
      dictionnaire:this.config_projet.dictionaire['dictionnaire_regroupement'],
      titre:titre,
      url:'/api/regroupements/add'
    }

    this.GeneralService.ouvrir_fenetre_ajout(donne,[],(result)=>{
      if (result) {
        this.getRegroupements()
      }
    })
    
  }

}
