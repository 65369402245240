import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapComponent } from './map/map.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { IsAuthGuard } from './guards/isauth.guard';
import { LocalisationAjoutComponent } from './modal/ajout-site/localisation-ajout/localisation-ajout.component';
import { InfosGeneralComponent } from './modal/ajout-site/infos-general/infos-general.component';

const routes: Routes = [
  { path: 'sites', component: MapComponent, canActivate: [AuthGuard]}, 
  { path: 'sites/:id', component: MapComponent, canActivate: [AuthGuard]}, 
  { path: 'login', component: LoginComponent,canActivate: [IsAuthGuard]}, 
  { path: 'localisation', component: LocalisationAjoutComponent,outlet: "ajout_entite" },
  { path: 'principal', component: InfosGeneralComponent,outlet: "ajout_entite" },
  { path: '', redirectTo: '/sites', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
