import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * confirmation dialog data interface
 */
 export interface ConfirmationDialogData{
  confirmationTitle: string,
  confirmationExplanation: string,
  cancelText: string,
  confirmText: string,
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public onConfirmInstance:()=>void

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    this.onConfirmInstance = ()=>{
      this.dialogRef.close(true)
    }
   }

  ngOnInit(): void {
  }

  /**
   * close dialog
   * @param result 
   */
   closeDialog(result:boolean){
    this.dialogRef.close(result);
  }
}
