import { Component, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { BackendApiService } from 'src/app/services/backend-api.service'
import { Observable, forkJoin, from, throwError, of, merge } from 'rxjs';
import { catchError, filter, startWith, debounceTime, map } from 'rxjs/operators';
import { NotifierService } from "angular-notifier";
import { ResizedEvent } from 'angular-resize-event';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { validateBBox } from '@turf/turf';

/**
 * Interface décrivant le model de donnée pour faire les filtres
 */
export interface ModelFiltreDataInterface {

  /**
   * Titre de la proprieté: sans caractères speciaux, ni espaces:
   * servira pour le nom du form_control et le label. 
   * Il faudra donc le lié dans les traductions
   */
  titre: string
  /**
   * Type de requete:
   * - Select : une liste d'option qui sera encapsulé dans un autocomplete
   * - Number : une jauge
   */
  type: 'select' | 'range'
  /**
   * sous type du type. Fonctionne juste pour le cas 'range'
   * - jauge: on aura une jauge
   * - input : on aura 2 input max et min
   */
  subType?: 'jauge' | 'input'
  /**
   * Au cas ou le type est un select, la liste des options
   * Ne pas specifier si url spécifié
   */
  listData?: Array<any> | undefined
  /**
   * Au cas ou le type est un number, le min et max possible. 
   * Ne pas specifier si url spécifié
   */
  rangeNumber?: [number, number] | undefined
  /**
   * url pour récuperer les données et les stocker soit dans listData ou rangeNumber si ils ne sont pas déja définient
   */
  url?: string
  /**
   * Lorsque le type est select, il sera utilisé pour définir la liste triée.
   * Elle est définit automatiquement dans le code
   */
  filteredOptions?: Observable<any[]>
  /**
   * http method to perform the querry 
   * 'GET'|'POST'
   */
  httpMethod?: 'GET' | 'POST'
  /**
   * Proprietes de l'objet dans listData à afficher dans la liste
   */
  displayPte?: string[]
  /**
   * Ptés du slider pour le type 'range'
   */
  formatConfig?: any
  /**
   * Pour formaté la reponse à envoyer au backend
   * - Pour le type 'select', comment formaté le formulaire pour envoi au backend
   * - Pour le type 'range', le champ qui portera la valeur du formulaire
   */
  formatBD?: {
    /**
     * Champ de la BD
     */
    champ: string
    /**
     * Condition pour la comparaison 
     * '='|'<'|'>'|'!='
     */
    condition: '=' | '<' | '>' | '!='
    /**
     * champ correspondant dans un objet de listData
     */
    champValeur: string
  }
  /**
   * Nom de la proprietes qui portera la valeur du formulaire pour l'envoi au backend
   */
  champBD: string

}

/**
 * Composant générique du constructeur de filtre
 */
export class FiltreSitesParentComponent {
  /**
   * List des mat autocomplete des filtres
   */
  @ViewChildren("ref_auto") listMatAutocomplete: QueryList<MatAutocomplete>;

  @Output() closeRequete: EventEmitter<any> = new EventEmitter()

  modelFiltreData: Array<ModelFiltreDataInterface>

  /**
   * Form d'interaction avec le user
   */
  formulaire: FormGroup = this.fb.group({})

  /**
  * Gestionnaire de notifications
  */
  protected readonly notifier: NotifierService;

  /**
   * Different loading du composant de filtre
   */
  loading = {
    loadingInitialData: true,
    loadindResponseFiltre: false
  }

  /**
   * List of filter object id
   */
  filterObjectsList: number[] = []

  /**
   * the last model that have been changed
   */
  lastChangedModel:ModelFiltreDataInterface = undefined

  objectsToArray = Object.keys


  constructor(
    public fb: FormBuilder,
    public BackendApiService: BackendApiService,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  onResized(event: ResizedEvent) {
    if (event.newWidth > 50) {

      this.initialiseFiltre()
    }
  }

  /**
   * recuperer les model dont les valeurs sont vides ou correspondent aux valeurs de départ
   * @return ModelFiltreDataInterface[]
   */
  getNullOrUnchangedModel():string[]{
    let response:string[] = []

    for (let index = 0; index < this.modelFiltreData.length; index++) {
      const model = this.modelFiltreData[index];
      if (this.isFormControlDirty(model.titre)) {
        response.push(model.titre)
      }
    }

    return response
  }

  /**
   * update filter data:
   * update the list in the autocomple and the range
   */
  updateFilterData(skipModel: Array<string>) {
    this.fecthRequeteData(skipModel,true).then(
      () => {
        for (let index = 0; index < this.modelFiltreData.length; index++) {
          const model = this.modelFiltreData[index];
          if (model.type == 'select') {
            model.filteredOptions = of(model.listData)
          }
        }
      },
      () => {
        this.notifier.notify("error", "Un problême est survenu lors de la mise à jour des données de filtres");
      }
    )
  }

  /**
   * Recuprer toutes les donnees depuis la BD pour stocker soit dansl les variables listData ou rangeNumber si ils ne sont pas déja définient
   * @param force boolean mettre à jour les variables meme si elles sont déja définient
   * @param skipModel Array<string> model à sauter (ne pas les recharger)
   */
  fecthRequeteData(skipModel: Array<string> = [], force: boolean = false): Promise<{ error: boolean, msg?: string }> {
    var urls: { getAllUrl(): string[], data: { url: string, indexModelFiltreData: number }[] } = {
      getAllUrl: function () {
        var allUrl = []
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          allUrl.push(this.data[index].url)
        }
        return allUrl
      },
      data: []
    }

    let ObservablesUrl: Observable<any>[] = []
    for (let index = 0; index < this.modelFiltreData.length; index++) {
      let model: ModelFiltreDataInterface = this.modelFiltreData[index]
      if (skipModel.indexOf(model.titre) == -1) {

        if ((!model.rangeNumber && !model.listData && force == false) || force) {
          if (this.formulaire.get(model.titre)) {
            this.formulaire.get(model.titre).disable()
          }
          urls.data.push({
            url: model.url,
            indexModelFiltreData: index
          })
          if (model.httpMethod == 'GET') {
            ObservablesUrl.push(from(this.BackendApiService.get_requete(model.url)))
          } else if (model.httpMethod == 'POST') {
            // let list_sites_id = []
            // /**
            //  * si son champ est n'est pas vide ou inexistant
            //  */
            // if (this.getNullOrUnchangedModel().indexOf(model.titre) != -1) {
            //   list_sites_id = []
            // }else{
            //   /**
            //    * si le champ est vide
            //    */
            //   list_sites_id =  this.filterObjectsList
            // }
            ObservablesUrl.push(from(this.BackendApiService.post_requete({ sites_id: this.filterObjectsList }, this.modelFiltreData[index].url)))
          }
        }
      }
    }

    if (urls.getAllUrl().length > 0) {
      return new Promise((resolve, reject) => {
        forkJoin(
          ObservablesUrl
        ).pipe(
          catchError(err => {
            reject({
              error: true,
              msg: err
            })
            return '';
          })
        ).subscribe((results) => {
          for (let index = 0; index < urls.data.length; index++) {
            let model: ModelFiltreDataInterface = this.modelFiltreData[urls.data[index].indexModelFiltreData]
            if (this.formulaire.get(model.titre)) {
              this.formulaire.get(model.titre).enable()
            }
            if (model.type == 'range') {
              model.rangeNumber = [parseInt(results[index][0]), parseInt(results[index][1])]
            } else if (model.type == 'select') {
              model.listData = results[index]
            }
          }
          resolve({
            error: false
          })
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve({
          error: false
        })
      })
    }

  }

  /**
   * Initialiser le mode filtre : Récuperer les données dont de la BD, définir les formulaires suivant l'interface ModelFiltreDataInterface
   */
  initialiseFiltre() {
    this.fecthRequeteData().then(
      (res) => {
        this.loading.loadingInitialData = false
        this.initiliaserFormulaire()
      },
      (error) => {
        this.loading.loadingInitialData = false
        this.close()
        this.notifier.notify("error", "Un problême est survenu lors de l'initialisation des données de filtres");
      }
    )
  }


  /**
   * Initialiser le formulaire de constructeur de requete
   */
  initiliaserFormulaire() {
    /**
     * Ajouter les controls
     */
    for (let index = 0; index < this.modelFiltreData.length; index++) {
      const element = this.modelFiltreData[index];
      if (element.type == 'select') {
        this.formulaire.addControl(element.titre, new FormControl())
      } else if (element.type == 'range') {
        this.formulaire.addControl(element.titre, new FormControl(element.rangeNumber))
        this.formulaire.addControl(element.titre + '_min', new FormControl(element.rangeNumber[0]))
        this.formulaire.addControl(element.titre + '_max', new FormControl(element.rangeNumber[1]))
        this.formulaire.get(element.titre + '_min').setValidators((control: AbstractControl) => Validators.max(this.formulaire.get(element.titre + '_max').value - 1)(control))
        this.formulaire.get(element.titre + '_max').setValidators((control: AbstractControl) => Validators.min(this.formulaire.get(element.titre + '_min').value + 1)(control))
      }
    }

    this.formulaire.markAsPristine()
    /**
     * Initialiser les évènements pour les changements de valeurs des filtres
     */
    let allObserverForm:Observable<string>[] = []
    for (let index = 0; index < this.modelFiltreData.length; index++) {
      const model = this.modelFiltreData[index];
      allObserverForm.push(this.formulaire.controls[model.titre].valueChanges.pipe(map(val => model.titre)))
      if (model.type == 'select') {
        this.formulaire.controls[model.titre].valueChanges.pipe(
          filter(value => typeof value === "string"),
          startWith(''),
          debounceTime(300),
          // map((value) => {
          //   model.filteredOptions = of(this._filter(value, model.listData))
          // })
        ).subscribe((value: string) => {
          model.filteredOptions = of(this._filter(value, model.listData))
        })

      } else if (model.type == 'range' && model.subType == 'input') {

        this.formulaire.get(model.titre + '_min').valueChanges.pipe().subscribe(() => {
          if (this.formulaire.get([model.titre + '_min']).valid) {
            this.rangeChange(this.formulaire.get([model.titre + '_min']).value, 'min', model)
          }
        })

        this.formulaire.get(model.titre + '_max').valueChanges.pipe().subscribe(() => {
          if (this.formulaire.get([model.titre + '_max']).valid) {
            this.rangeChange(this.formulaire.get([model.titre + '_max']).value, 'max', model)
          }
        })

      }
    }

    merge(...allObserverForm).subscribe((value:string)=>{
      let model:ModelFiltreDataInterface = this.getFiltreModelByTitre(value)
      this.lastChangedModel = model
    })

    /**
     * Définir la fonction displayWith des model de filtre de type 'select'
     */
    this.listMatAutocomplete.changes.subscribe((values) => {
      this.listMatAutocomplete.forEach((item: MatAutocomplete) => {
        var titreModel: string = item.template.elementRef.nativeElement.parentElement.id.replace('ref_auto_', '')
        var model = this.getFiltreModelByTitre(titreModel)
        item.displayWith = (option) => {
          return this.formatDisplayValue(option, model.displayPte)
        }
      })
    })

  }

  rangeChange(actualValue: string, type: 'max' | 'min', model: ModelFiltreDataInterface) {
    // this.formulaire.get(model.titre).getError
    if (type == 'max') {
      this.formulaire.get(model.titre).setValue([this.formulaire.get(model.titre).value[0], parseInt(actualValue)])
    } else if (type == 'min') {
      this.formulaire.get(model.titre).setValue([parseInt(actualValue), this.formulaire.get(model.titre).value[1]])
    }
  }

  /**
   * Concatener les ptés des valeurs d'un objet 
   * @param option Object l'objet à utiliser pour concatener
   * @param displayPte string[] clés à utiliser pour concatener
   */
  formatDisplayValue(option: Object, displayPte: string[]): string {
    var text: string[] = []
    if (option) {
      for (let index = 0; index < displayPte.length; index++) {
        const element = displayPte[index];
        text.push(option[element].toString())
      }
    }
    return text.join(' - ')
  }

  /**
   * Recuperer un model de filtre à partir du filtre depuis le modelFiltreData
   * @param titre string le titre du model
   * @return ModelFiltreDataInterface
   */
  getFiltreModelByTitre(titre: string): ModelFiltreDataInterface {
    for (let index = 0; index < this.modelFiltreData.length; index++) {
      if (this.modelFiltreData[index].titre == titre) {
        return this.modelFiltreData[index]
      }
    }
  }

  /**
   * Filtrer les données d'un autocomplete
   * @param value string valeur à utiliser pour filtrer
   */
  private _filter(value: string, values: any[]): any[] {

    const filterValue = value.toLowerCase();

    var result_nom = values.filter((option) => {
      for (const key in option) {
        if (option.hasOwnProperty(key) && option[key] != null) {
          const element = option[key];
          if (element.toString().toLowerCase().includes(filterValue)) {
            return true
          }
        }
      }
      // option.nom_regroup.toLowerCase().includes(filterValue)
    })

    return result_nom;
  }


  /**
   * Savoir si un seul control du  formulaire a changé:
   *  - type 'select', si  le control est vide alors il a pas changé
   *  - type 'range', si le control est égale à model.rangeNumber, alors il a pas changé
   */
  isFormulaireDirty(): boolean {

    for (let index = 0; index < this.modelFiltreData.length; index++) {
      const model = this.modelFiltreData[index];
      if (this.isFormControlDirty(model.titre)) {
        return true
      }
    }

    return false

  }

  /**
  * Savoir si un control du  formulaire a changé:
  *  - type 'select', si  le control est vide alors il a pas changé
  *  - type 'range', si le control est égale à model.rangeNumber, alors il a pas changé
  * @param formName string le nom du form control
  */
  isFormControlDirty(formName: string): boolean {
    function compareArray2Dim(arra1: [any, any], arra2: [any, any]): boolean {
      if (arra1[0] == arra2[0] && arra1[1] == arra2[1]) {
        return true
      }
      return false
    }

    var model = this.getFiltreModelByTitre(formName)
    if (model && this.formulaire.get(model.titre) ) {
      if (model.type == 'select' && this.formulaire.get(model.titre).value != undefined && this.formulaire.get(model.titre).value != '') {
        return true
      } else if (model.type == 'range' && this.formulaire.get(model.titre).value != undefined && this.formulaire.get(model.titre).value.length == 2 && !compareArray2Dim(this.formulaire.get(model.titre).value, model.rangeNumber)) {
        return true
      }
    }

    return false

  }



  /**
   * Formater le model  pour effectuer une requête au backend
   */
  formatQuerry(): any {
    var donneRequete = {}
    for (let index = 0; index < this.modelFiltreData.length; index++) {
      const model = this.modelFiltreData[index];
      if (this.isFormControlDirty(model.titre)) {
        if (model.type == 'select') {
          var field = {
            'champ': model.formatBD.champ,
            'condition': model.formatBD.condition,
            'valeur': this.formulaire.controls[model.titre].value[model.formatBD.champValeur]
          }
          if (donneRequete[model.champBD] == undefined) {
            donneRequete[model.champBD] = field
          } else if (Array.isArray(donneRequete[model.champBD])) {
            donneRequete[model.champBD].push(field)
          } else if (typeof donneRequete[model.champBD] == 'object') {
            var copy = donneRequete[model.champBD]
            donneRequete[model.champBD] = [copy]
            donneRequete[model.champBD].push(field)
          }
        } else if (model.type == 'range') {
          if (model.subType == 'jauge') {
            let fieldNumber = this.formulaire.controls[model.titre].value
            donneRequete[model.champBD] = {}
            donneRequete[model.champBD][model.titre] = fieldNumber
          } else if (model.subType == 'input') {
            let fieldNumber = [this.formulaire.controls[model.titre + '_min'].value, this.formulaire.controls[model.titre + '_max'].value]
            donneRequete[model.champBD] = {}
            donneRequete[model.champBD][model.titre] = fieldNumber
          }

        }
      }
    }

    return donneRequete
  }

  /**
   * Si il faut disable le boutton permettant d'effectuer la requète de filtre au backend
   * @return boolean
   */
  disabledFiltreBtn(): boolean {

    if (!this.loading.loadindResponseFiltre) {
      return this.isFormulaireDirty() && this.formulaire.valid
    } else {
      return false
    }
  }
  /**
   * Close filtre componenet
   */
  close() {
    this.closeRequete.emit()
  }

}