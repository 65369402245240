import { Component, OnInit } from '@angular/core';
import { clientInterface } from 'src/app/type';
import { GeneralService } from '../../../services/general.service'
import { BackendApiService } from '../../../services/backend-api.service'
import { MatDialog, MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { donne_pour_modification } from '../../../modal/modification/modification.component'
import { donne_pour_ajout } from '../../../modal/ajout/ajout.component'
import { TranslateService } from '@ngx-translate/core';
/**
 * Gestion des clients
 */

@Component({
  selector: 'app-gestion-client',
  templateUrl: './gestion-client.component.html',
  styleUrls: ['./gestion-client.component.scss']
})
export class GestionClientComponent implements OnInit {

  config_projet:any
  environment = environment
  loading:boolean=false
  clients:Array<clientInterface>

  constructor(
    public  GeneralService:GeneralService,
    public _dialog: MatDialog,
    private BackendApiService:BackendApiService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
  ) { 
    this.GeneralService.config_projet.subscribe(value => {
      this.config_projet = value
    })
  }

  async ngOnInit() {
    await this.getClients()
  }

  /**
   * recuperer les clients de la BD
   */
  async getClients() {
      this.loading = true
      await this.BackendApiService.get_requete("/api/clients/all").then(
        (data:Array<clientInterface>) => {
          this.clients = data
          this.loading = false
        },
        (err) => {
          this.loading = false
          this.clients = []
          this._snackBar.open(err.message, 'Fermer', {
            duration: 1000,
          });
        }
      )

  }

  /**
   * Modifier un client 
   * @param client clientInterface 
   */
  ouvrir_fenetre_edition_client(client:clientInterface){
    var titre = ''
    this.translate.get('administration', { value: 'site' }).subscribe((res) => {
      titre = res['client']['modifier_client'] + client.nom_client
    });

    var donne:donne_pour_modification={
      dictionnaire:this.config_projet.dictionaire['dictionnaire_client'],
      donne:client,
      champ_id:['client_id',client.client_id],
      titre:titre,
      url:'/api/clients/update'
    }
    this.GeneralService.ouvrir_fenetre_modification(donne,[],(result)=>{
      if (result) {
        this.getClients()
      }
    })
  }


  /**
   * Ouvur le modal d'ajout de client'
   */
  ouvrir_fenetre_add_client() {
    var titre = ''

    this.translate.get('administration', { value: 'site' }).subscribe((res) => {
      titre = res['client']['ajouter_client']
    });

    var donne:donne_pour_ajout = {
      dictionnaire:this.config_projet.dictionaire['dictionnaire_client'],
      titre:titre,
      url:'/api/clients/add'
    }

    this.GeneralService.ouvrir_fenetre_ajout(donne,[],(result)=>{
      if (result) {
        this.getClients()
      }
    })
    
  }
}
