import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import {ElemCartoService} from '../../../../services/elem-carto/elem-carto.service'
import { environment } from 'src/environments/environment';
import {
  elmCartoOverlaysForMap
}
  from '../../../../type/index'
import { FeatureWMSInfo } from 'src/app/map/map.component.parent';
import { SitesCartoService } from 'src/app/services/sites-carto.service';
import {

  GeoJSON, VectorLayer, VectorSource,
 
}
  from '../../../../ol-module'
@Component({
  selector: 'app-choose-elem-carto',
  templateUrl: './choose-elem-carto.component.html',
  styleUrls: ['./choose-elem-carto.component.scss']
})
export class ChooseElemCartoComponent implements OnInit {

  @Input() data:{ polygon: FeatureWMSInfo[]; point: FeatureWMSInfo[]; linestring: FeatureWMSInfo[]; }  
  @Output() closeFun = new EventEmitter();
  @Output() chooseElemFun = new EventEmitter<FeatureWMSInfo>();

  config_projet:any
  environment = environment
  constructor(
    public ElemCartoService:ElemCartoService,
    public sitesCartoService:SitesCartoService
  ) {
    
   }

  ngOnInit() {
  }

  optionHover(feature:FeatureWMSInfo){console.log(this.data)
    let layer = this.sitesCartoService.getLayerByAttr<VectorLayer<VectorSource>>('elem_geo_surbrillance', 'nom', true)[0]
    layer.getSource().clear()
    layer.getSource().addFeature(new GeoJSON().readFeature(feature))
  }

  round(numb:number){
    return Math.round(numb)
  }

  buttonEnter(item:FeatureWMSInfo){
    this.optionHover(item)
  }

  buttonLeave(){
    let layer = this.sitesCartoService.getLayerByAttr<VectorLayer<VectorSource>>('elem_geo_surbrillance', 'nom', true)[0]
    layer.getSource().clear()
  }

  close(){
    let layer = this.sitesCartoService.getLayerByAttr<VectorLayer<VectorSource>>('elem_geo_surbrillance', 'nom', true)[0]
    layer.getSource().clear()
    this.closeFun.emit()
  }

  chooseElem(item:FeatureWMSInfo,index:number){
    let layer = this.sitesCartoService.getLayerByAttr<VectorLayer<VectorSource>>('elem_geo_surbrillance', 'nom', true)[0]
    layer.getSource().clear()
    layer.getSource().addFeature(new GeoJSON().readFeature(item))

    this.chooseElemFun.emit(item)
  }

}
