import {
  Injectable,
  ComponentFactoryResolver,
  ViewContainerRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Feature, Map } from "ol";
import { Select } from "ol/interaction";
import { BehaviorSubject, Subject } from "rxjs";
import { TypoCategorie } from "src/app/models/typo_category";
import { VectorLayer } from "src/app/ol-module";
import { LayerToDigitalise } from "../model/layer-to-digitalise";
export interface AttributeForm {
  close: Subject<boolean>;
  feature: Feature;
  layerToDigitalise: LayerToDigitalise;
  form: FormGroup;
  codeTypoChoose: TypoCategorie,
  map : Map
  onSubmitInstance: () => void
}
@Injectable({
  providedIn: "root",
})
export class LoadComponentService {
  constructor(private cfr: ComponentFactoryResolver) {}

  async loadComponent(
    vcr: ViewContainerRef,
    componetPath: string,
    componetName: string
  ) {
    const component = await import(
      "src/app/attribute-form/pages/" + componetPath
    ).then((m) => m[componetName]);
    vcr.clear();
    return vcr.createComponent<AttributeForm>(
      this.cfr.resolveComponentFactory(component)
    );
  }
}
