import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModeCode } from 'src/app/models/mode_gestion';
import { TypoCategorie } from 'src/app/models/typo_category';
import { elemCartoInterface } from 'src/app/type';
import { environment } from 'src/environments/environment';
import {GeneralService} from '../../services/general.service'
import { BackendApiService } from '../backend-api.service';
@Injectable({
  providedIn: 'root'
})

export class ElemCartoService {

  apiURL = environment.apiUrl

  constructor(
    public GeneralService:GeneralService,
    private http : HttpClient,
    private backendService :  BackendApiService
  ) { }

  /**
     * Récuperer un element cartographique par son id et sa typologie 
     * @param number id 
     * @return elemCartoInterface
     */
  getElemCarto(id:number,typologie?:'point'|'polygon'|'linestring'):elemCartoInterface{
    let config_projet = this.GeneralService.get_config_projet()
    return config_projet.layers.polygon.concat(config_projet.layers.point).concat(config_projet.layers.linestring).find((elem)=>elem.elem_carto_id == id)
  }
  

  /**
     * Calculer la somme d'une propriéte sur plusieurs elemes carto (exple : surfaces, permimetres)
     * @param number id 
     * @param string typologie 'point'|'polygon'|'linestring'
     * @return any
     */
  sumPropertiesElemCarto(elem_carto:Array<any>,properties:string,round=true){
    var total = 0
    for (let index = 0; index < elem_carto.length; index++) {
      const element = elem_carto[index];
      total+= element[properties]
    }
    
    if(round){
      return Math.round(total)
    }
  }

  public getTypoCategorie(elem_carto_id : number) : Observable<TypoCategorie[]> {
    let headers = this.backendService.get_header()
    return this.http.post<TypoCategorie[]>(this.apiURL + '/api/typologie/category', {elem_carto_id : elem_carto_id}, {headers : headers})
  }

  public getModeGestionCategorie(mode_categories_id : number): Observable<ModeCode[]> {
    let headers = this.backendService.get_header()
    return this.http.post<ModeCode[]>(this.apiURL + '/api/mode-gestion/category', {mode_categories_id : mode_categories_id}, {headers : headers})
  }

}
