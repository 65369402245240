import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './pages/confirm-dialog/confirm-dialog.component';
import { ComponentMaterialModule } from './material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { MesureComponent } from './pages/mesure/mesure.component';
import { ElemCartoComponent } from './pages/overlay/elem-carto/elem-carto.component';
import { ChooseElemCartoComponent } from './pages/overlay/choose-elem-carto/choose-elem-carto.component';
import { GetAdresseComponent } from './pages/get-adresse/get-adresse.component';
import { RechercheSiteComponent } from '../composant/recherche-site/recherche-site.component';
import { UnSiteComponent } from '../composant/un-site/un-site.component';


@NgModule({
  declarations: [ConfirmDialogComponent, MesureComponent, ElemCartoComponent, ChooseElemCartoComponent, GetAdresseComponent, RechercheSiteComponent, UnSiteComponent],
  imports: [
    CommonModule,
    ComponentMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NotifierModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ],
  exports:[
    ConfirmDialogComponent,
    MesureComponent,
    ElemCartoComponent,
    ChooseElemCartoComponent,
    FlexLayoutModule,
    ComponentMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule,
    CommonModule,
    TranslateModule,
    GetAdresseComponent,
    RechercheSiteComponent,
    UnSiteComponent
  ],
  entryComponents:[ConfirmDialogComponent]
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(["fr"]);
    translate.setDefaultLang('fr');

    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/fr/) ? browserLang : 'fr');
  }

 }
