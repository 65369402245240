import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, filter } from 'rxjs/operators';
import { BackendApiService } from '../../services/backend-api.service';
import {  fromLonLat} from 'ol/proj.js';
import { MapGeneralService } from '../../services/map.general.service'
import Point from 'ol/geom/Point';

import * as $ from 'jquery';

@Component({
  selector: 'app-recherche-adresse',
  templateUrl: './recherche-adresse.component.html',
  styleUrls: ['./recherche-adresse.component.scss']
})
export class RechercheAdresseComponent implements OnInit {

  recherche_form: FormGroup
  adresse_trouver: any[]
  isLoading = false;
  /**
   * is the div must be unfold by default ?
   */
  @Input()  unfold?: boolean = false;
  @Input()  overlay_adresse: any;
  @Output() display_marker = new EventEmitter();

  
  constructor(
    private fb: FormBuilder,
    private BackendApiService:BackendApiService,
    private MapGeneralService: MapGeneralService,
  ) {
    this.recherche_form = this.fb.group({
      word: ''
    })
   }

  clear_search(){
    /**
     * vider la barre de recherche d'adresse dans la carte
     */

    // this.recherche_form.controls['word'].reset({ value: '' });
    this.recherche_form.controls['word'].setValue('')
    $("#"+this.overlay_adresse.id).hide()
    // if (this.unfold) {
    //   setTimeout(() => {
    //     console.log($('.icon_loupe'))
    //     $('.icon_loupe').addClass('icon_loupe_hover');
    //   }, 500);
    // }
  }
  
  ngOnInit() {

    if (!this.unfold) {
      $(document).ready(()=>{
        $('.rechercher_adresse').hover(()=> {
          $('.rechercher_adresse').addClass('rechercher_adresse_hover');

          $('.icon_loupe').addClass('icon_loupe_hover');

          $('.rechercher_adresse_body').addClass('rechercher_adresse_body_hover');
          
        }, () => {
        
          if ( this.recherche_form.get('word').value =='') {
            $('.rechercher_adresse').removeClass('rechercher_adresse_hover');

            $('.icon_loupe').removeClass('icon_loupe_hover');

            $('.rechercher_adresse_body').removeClass('rechercher_adresse_body_hover');
          }
          
        });
      });
    }else{
      $(document).ready(()=>{
        $('.rechercher_adresse').addClass('rechercher_adresse_hover');

        $('.icon_loupe').addClass('icon_loupe_hover');

        $('.rechercher_adresse_body').addClass('rechercher_adresse_body_hover');
      })
      
    }

    this.recherche_form
      .get('word')
      .valueChanges
      .pipe(
        filter(value=>(typeof value === "string" && value != undefined && value != '')),
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => {
          try {
            if (value != '' && value != undefined) {
              return this.BackendApiService.search(value.toString().split(" ").join("+"))
                .pipe(
                  finalize(() => this.isLoading = false),
                )
            }else{
              return ;
            }
          } catch (error) {
            return ;
          }
         
        }
        )
      )
      .subscribe(reponse => this.adresse_trouver = reponse.features);

      this.recherche_form.get('word').valueChanges.subscribe((val)=>{
        if (val == '') {
          $('.label_white .mat-form-field-label').show()
        }else{
          $('.label_white .mat-form-field-label').hide()
        }

      })
  }

  displayFn(adresse) {
    if (adresse instanceof Object) {
      try {
        return adresse.properties.label;
      } catch (error) {
        return ''
      }
      
    } else {
      return adresse
    }

  }

  display_marker_adresse_change(adresse) {
    this.display_marker.emit(adresse)

    // console.log(ville,cp)
    // this.update_pte_localisation('commune', ville, true)
    // this.update_pte_localisation('cp', cp, true)

    // var feature = new GeoJSON().readFeatures(adresse, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })[0]
    // this.vectorSource_adresse.clear()
    // this.vectorSource_adresse.addFeature(feature)

    // var coordinate = Object.create(feature.getGeometry()).getCoordinates();
    // map.getView().animate({ center: coordinate }, { zoom: 17 }, { duration: 500 });
    // this.disabled = false
  }

}
