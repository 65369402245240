import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatSelectionList, MAT_DIALOG_DATA } from '@angular/material';
import GeometryType from 'ol/geom/GeometryType';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Style } from 'src/app/ol-module';
import { DataForLegend } from '../../model/layer-to-digitalise';

export interface  LayerToAddChoose extends DataForLegend{
  geometryType:GeometryType
  layer_id:number
}
@Component({
  selector: 'app-choose-layer-to-add',
  templateUrl: './choose-layer-to-add.component.html',
  styleUrls: ['./choose-layer-to-add.component.scss']
})

export class ChooseLayerToAddComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChooseLayerToAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LayerToAddChoose[]
  ) { }

  ngOnInit(): void {
  }

  choose(value:LayerToAddChoose){
    this.dialogRef.close(value)
  }

  close(){
    this.dialogRef.close()
  }

}
