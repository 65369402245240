import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegendComponent } from './pages/legend/legend.component';
import { ChooseLayerToAddComponent } from './modal/choose-layer-to-add/choose-layer-to-add.component';
import { DigitaliserComponent } from './digitaliser.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotifierModule } from 'angular-notifier';
import { SharedModule } from '../shared/shared.module';
import { AttributeFormModule } from '../attribute-form/attribute-form.module';
import { FormDirective } from './directive/form.directive';
import { MesureComponent } from '../shared/pages/mesure/mesure.component';
import { LegendeComponent } from './legende/legende.component';

@NgModule({
  declarations: [LegendComponent, 
    ChooseLayerToAddComponent, 
    DigitaliserComponent, 
    FormDirective,
    LegendeComponent
    ],
  imports: [
    CommonModule,
    SharedModule,
    AttributeFormModule
    ],
  exports:[
    DigitaliserComponent
  ],
  entryComponents:[ChooseLayerToAddComponent]
})
export class DigitaliserModule { }
