import { Component, OnInit } from '@angular/core';
import { MapGeneralService } from '../../services//map.general.service'
import { GeneralService } from '../../services/general.service'
import { BackendApiService } from '../../services/backend-api.service'
import { SupprimerComponent, donne_pour_sup } from '../../modal/supprimer/supprimer.component';
import { AddUserComponent } from '../../modal/gestion-user/add-user/add-user.component';
import { EditRightsComponent, donne_pour_edit_right } from '../../modal/gestion-user/edit-rights/edit-rights.component';
import { EditElementCartographiqueComponent  } from '../../modal/gestion-user/edit-element-cartographique/edit-element-cartographique.component';
import * as $ from 'jquery';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { AuthService, User } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-gestion-user',
  templateUrl: './gestion-user.component.html',
  styleUrls: ['./gestion-user.component.scss']
})
export class GestionUserComponent implements OnInit {

  config_projet: any
  user: User
  all_users: {data:Array<User>}
  all_objectifs: any
  loading = {
    "users": false,
  }

  environment = environment

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private AuthService: AuthService,
    private BackendApiService: BackendApiService,
    public GeneralService: GeneralService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GestionUserComponent>
  ) {
    this.user = this.AuthService.get_user()
    this.GeneralService.config_projet.subscribe(value => {
      this.config_projet = value
      // this.dictionnaire = this.config_projet.dictionaire.dictionnaire_user
    })
  }


  async ngOnInit() {

    await this.get_list_users()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async get_list_users() {
    // if (this.user.statut) {
      this.loading.users = true
      await this.BackendApiService.get_requete("/api/user/list").then(
        (data:any) => {
          this.all_users = data
          this.loading.users = false
        },
        (err) => {
          this.loading.users = false
          this.all_users = {data:[]}
          this._snackBar.open(err.message, 'Fermer', {
            duration: 1000,
          });
        }
      )
    // }

  }

  isKeyGroupCanSeeAllAgence(key:string):boolean{
    if (this.config_projet) {
      return this.config_projet.listGroupUserFilterAgence.indexOf(key) != -1
    }
  }

  ouvrir_fenetre_add_user() {

    var size = [$(window).width() * 0.5 + 'px', $(window).height() * 0.7 + 'px']
    const fenetre_ajout_user = this.dialog.open(AddUserComponent, {
      width: size[0],
      height: size[1],
      disableClose: true,
      data: {
        'height': $(window).height() * 0.7
      }
    });

    fenetre_ajout_user.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.get_list_users()
      }
    })

  }

  ouvrir_fenetre_edit_right_user(data: donne_pour_edit_right) {

    var size = [$(window).width() * 0.5 + 'px', $(window).height() * 0.7 + 'px']
    data.height = $(window).height() * 0.7

    const fenetre_ajout_user = this.dialog.open(EditRightsComponent, {
      width: size[0],
      height: size[1],
      disableClose: true,
      data: data
    });

    fenetre_ajout_user.afterClosed().subscribe(async (result) => {
      if (result) {
        var fun = this[result.callback](result)
      }
    })

  }

  edit_user_local_edit(data: donne_pour_edit_right) {
    for (let index = 0; index < this.all_users.data.length; index++) {
      const element = this.all_users.data[index];
      if (element.id == data.id_user) {
        element.agence = data.data
      }
    }
  }
/**
 * Editer les agences d'un user
 * @param user_to_edit 
 */
  activer_edit_right(user_to_edit) {
    var data: donne_pour_edit_right = {
      'titre': "Modifier les agences de " + user_to_edit.name,
      'agences': Object.assign([], user_to_edit['agence']),
      'callback': 'edit_user_local_edit',
      'id_user': user_to_edit.id,
      'height': '',
    }

    this.ouvrir_fenetre_edit_right_user(data)
  }

  ouvrir_fenetre_de_suppression(data: donne_pour_sup, size: string[]) {

    const fenetre_suppression = this.dialog.open(SupprimerComponent, {
      // width: size[0],
      // height: size[1],
      disableClose: true,
      data: data
    });

    fenetre_suppression.afterClosed().subscribe((result: donne_pour_sup) => {
      if (result) {
        var fun = this[result.callback](result)
      }
    })

  }

  /**
   * Supprimer un user en local
   * @param result 
   */
  delete_user_locale(result: donne_pour_sup) {

    if (result.decision) {
      var id_user_to_delete;
      for (let index = 0; index < this.all_users.data.length; index++) {
        if (this.all_users.data[index].id == result.champ_id[1]) {
          id_user_to_delete = index
          this.all_users.data[index]['deleted_at'] = new Date().toISOString()
        }
      }

    }

  }

  /**
   * Supprimer un user
   * @param user_to_delete 
   */
  activer_supprimer_user(user_to_delete) {
    var donne: donne_pour_sup = {
      "titre": "Supprimer l'utilisateur " + user_to_delete.name + " ?",
      "champ_id": ["id", user_to_delete.id],
      'decision': false,
      'url': '/api/user/desactivate',
      'callback': "delete_user_locale"
    }

    var size = [$(window).width() * 0.7 + 'px', $(window).height() * 0.8 + 'px']
    this.ouvrir_fenetre_de_suppression(donne, size)
  }

  /**
   * Restaurer un user
   * @param user_to_restore 
   */
  activer_restaurer_user(user_to_restore) {
    var donne: donne_pour_sup = {
      "titre": "Restaurer l'utilisateur " + user_to_restore.name + " ?",
      "champ_id": ["id", user_to_restore.id],
      'decision': false,
      'url': '/api/user/activate',
      'callback': "restore_user_locale"
    }

    var size = [$(window).width() * 0.7 + 'px', $(window).height() * 0.8 + 'px']
    this.ouvrir_fenetre_de_suppression(donne, size)
  }

  /**
   * Restaurer un user en local
   */
  restore_user_locale(result: donne_pour_sup) {
    if (result.decision) {
      var id_user_to_delete;
      for (let index = 0; index < this.all_users.data.length; index++) {
        if (this.all_users.data[index].id == result.champ_id[1]) {
          this.all_users.data[index]['deleted_at'] = null
        }
      }
    }
  }

  
 
}
